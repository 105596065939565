import React from "react";
import { Link } from "react-router-dom";
import "./rewards.css"

export default function RewardsInfoScreen() {
  return (
    <div style={{ paddingTop: "3rem",paddingBottom:"3rem",marginTop:"2rem",marginBottom:"2.5rem", display: "flex", justifyContent: "center", fontFamily: "'Epilogue', sans-serif" }}>
      <div className=" rewards-info-container" >
        <img
          style={{ maxWidth: "20rem" }}
          src="/images/coin.png"
          alt="cannabis rewards coin"
          className="img-fluid"
        />

        <div className="reward-info-header" >
          Get Rewarded for Using Merp!
        </div>
        <p className="reward-info-subtext" >
          We understand that searching products is both important and time
          consuming, we are here to streamline the process and give you some
          perks along the way.
        </p>
        <div className="rewards-info-content" style={{ display: "flex", justifyContent: "center" }}>
          <div  className="rewards-list">
            <p>
              <div className="rewards-list-header" >How Can I Earn Canna Coins?</div>
              <div className="rewards-list" style={{ textAlign: "left" }}>
                <ul style={{ listStyleType: "unset" }}>
                  <li>Signup = 10 coins</li>
                  <li>Refer a friend = 15 coins</li>
                  <li>Visiting our brand partners site = 3 coins</li>
                  <li>Answering the “Merp Q of the week” = 2 coins</li>
                  <li>Reviewing & Rating  a dispensary = 5 coins</li>
                  <li>Reviewing & Rating  a product = 5 coins </li>
                  <li>Purchasing a <Link to="/search/shop/merchandise">merch</Link> item = 12 coins</li>
                </ul>
              </div>
              <div className="rewards-list-header"  style={{marginTop:"4rem"}}>How can I use my coins to redeem rewards?</div>
              <div className="rewards-list" style={{ textAlign: "left" }}>
              <ul style={{ listStyleType: "unset" }}>
                  <li>We will have a rewards section where you can spend your canna-coins and we will let you know which you are eligible to redeem:
                      <ul style={{ listStyleType: "square", marginLeft:"2em" }}>
                      <li>Ex. Free shipping (25 coins), 25% off Merp event ticket  (50 coins), free Merp event ticket (100 coins)</li>
                      </ul>
                      
                  </li>
              </ul>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
