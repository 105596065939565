import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import zxcvbn from "zxcvbn";


export const BorderLinearProgress = ({ password }) => {


  const BorderLinearProgressStyling = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: functionProgressColor()
    },
  }))(LinearProgress);
  
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
  });
  const results = zxcvbn(password);
  const num = (results.score * 100) / 4;

  const createPasswordStrengthLabel =()=>{
    switch (results.score) {
      case 0:
        return "Very Weak";
      case 1:
        return "Weak";
      case 2:
        return "Moderate";
      case 3:
        return "Strong";
      case 4:
        return "Very Strong";
      default:
        return "";
    }
  }
  const functionProgressColor = () => {
    switch (results.score) {
      case 0:
        return "#828282";
      case 1:
        return "#EA1111";
      case 2:
        return "#FFAD00";
      case 3:
        return "#9bc158";
      case 4:
        return "#00b500";
      default:
        return "none";
    }
  };

  
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ marginTop: "5px", textAlign:"right"}}>
      <BorderLinearProgressStyling variant="determinate" value={num} />
  <p style={{color:functionProgressColor(), marginTop:0, marginBottom:0}}>{createPasswordStrengthLabel()}</p>
    </div>
  );
};
