import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
import { removeItem, addItemToCart } from "../actions/cartActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from "cloudinary-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const CartItem = ({ item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const removeCartItem = (productID) => {
    dispatch(removeItem(productID));
  };
  return (
    <div className={classes.root} style={{ padding: "2rem" }}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase className={classes.image}>
              <Image
                cloudName="merp"
                publicId={item.image}
                secure="true"
                width="100"
                crop="scale"
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  {item.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  qty:
                  <input
                    type="number"
                    name="quantity"
                    value={item.qty}
                    onChange={(e) =>
                      dispatch(
                        addItemToCart(
                          item.product,
                          item.price,
                          Number(e.target.value)
                        )
                      )
                    }
                  />
                </Typography>
                <Link to={`/product/${item.product}`}>
                  <Typography variant="body2" color="textSecondary">
                    view {item.name}
                  </Typography>
                </Link>
                {item.size && (
                  <Typography variant="body2" color="textSecondary">
                    size: {item.size}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  onClick={() => removeCartItem(item.product)}
                  style={{ cursor: "pointer" }}
                >
                  Remove
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                $ {(item.price * item.qty).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CartItem;
