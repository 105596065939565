import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listWithUs } from "../../actions/userActions";
import { useEffect } from "react";
import "./listScreens.css";

export default function ListWithUsInfoScreen() {
  const dispatch = useDispatch();
  const [isMerch, setIsMerch] = useState(false);
  const [isCBD, setIsCBD] = useState(false);
  const [isDispensary, setIsDispensary] = useState(false);
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [multipleLocations, setMultipleLocations] = useState(false);
  const [locations, setLocations] = useState([]);
  const [address, setAddress] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [alias, setAlias] = useState("");
  const [telephone, setTelephone] = useState("");

  const listWith = useSelector((state) => state.listWith);

  const { loading, error, success } = listWith;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      listWithUs({
        name: name,
        contactName: contactName,
        contactEmail: contactEmail,
        website: website,
        isDispensary: true,
        isBrand: false,
        isTHC: false,
        isCBD: false,
        isMerch: false,
        locations: locations,
      })
    );
  };
  const addLocation = function () {
    setLocations((current) => [
      ...current,
      { alias, telephone, address, street2, city, zip, state },
    ]);
    setAddress("");
    setStreet2("");
    setCity("");
    setZip("");
    setState("");
    setAlias("");
    setTelephone("");
  };

  useEffect(() => {}, [dispatch, success, locations]);
  return (
    <>
      <div className="form">
        <div>
          <div
            className="merp-logo justify-content-center p-4"
            style={{ display: "flex" }}
          >
            <div>
              {" "}
              <img
                src="/images/merp_logo_sticker.png"
                alt="merp logo "
                className="merp-logo-list"
                // style={{ height: "auto", width: "20rem" }}
              />
            </div>
          </div>

          <div className="brand_list_subsection">
            <div className="brand_list_main_header" s>
              Looking to list a dispensary on Merp?
            </div>
            <div className="brand_list_section">
              Merp’s goal is to amplify and empower cannabis brands in an
              accessible way while giving cannabis consumers better information
              about what they’re buying. As the main connection point between
              brands and buyers, dispensaries are core partners to Merp - for
              more information on how we can work together, please fill out the
              form below. !
            </div>
            <div className="brand_list_section">
              <div className="brand_list_main_header">
                What are the benefits of using Merp for your dispensary?
              </div>
              <ul className="brand_list_section bullets">
                <li>
                  <strong>Insight into customers:</strong> We gather and deliver
                  data on which brands and products are gaining attention from
                  customers, going beyond just POS data.
                </li>
                <li>
                  <strong>Free for all your brands:</strong> Many platforms
                  charge brands to list, which means that smaller brands or
                  those with less market budget aren’t always represented. We
                  partner with dispensaries directly to ensure that all their
                  brands have representation, with a focus on highlighting their
                  unique story and supply chain. s.
                </li>
                <li>
                  <strong>Flexible partnership model:</strong> We know each
                  dispensary is different, even if it’s one of many locations -
                  we work with you across each storefront to drive customized
                  results. with Merp we charge just $10/month!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!success ? (
        <form className="form brand_list_form" onSubmit={submitHandler}>
          <div className="brand_list_section" style={{ paddingTop: "2rem" }}>
            <div
              className="brand_list_main_header"
              style={{ textAlign: "center" }}
            >
              Start Using Merp at Your Dispensary
            </div>
            <section>
              <div className="brand_list_section">
                <p style={{ textAlign: "center" }}>
                  Please complete this form to get started. We will review and
                  reach out with final steps to set up your account.
                </p>
              </div>
            </section>
          </div>

          <div>
            <label>
              <b>*Dispensary Name</b>
            </label>
            <input
              type="text"
              id="companyName"
              placeholder="enter company name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            ></input>
            <br />
          </div>
          <div>
            <label>
              <b>*Dispensary Contact Name</b>
            </label>
            <input
              type="text"
              id="contactName"
              placeholder="enter company contact name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
            ></input>
            <br />
          </div>
          <div>
            <label>
              <b>*Dispensary Contact Email</b>
            </label>
            <input
              type="email"
              id="contactEmail"
              placeholder="enter company contact email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              required
            ></input>
            <br />
          </div>
          <div>
            <div onSubmit={submitHandler} autocomplete="on">
              <div style={{ paddingBottom: ".5rem" }}>
                <b>Dispensary Location(s)</b>
              </div>
              <section className="dispensary_locations">
                {locations.length === 0 ? (
                  <div>You currently have not input any locations. </div>
                ) : (
                  locations.map((location) => (
                    <div className="dispensary_list_item">
                      <div style={{ padding: "5px" }}>
                        <div style={{ paddingBottom: "1rem" }}>
                          <div>
                            <strong>{location.alias}</strong>
                          </div>
                          {location.address}
                          <br />
                          {location.telephone}
                          <br />
                          {location.street2 && (
                            <div>
                              {" "}
                              {location.street2} <br />
                            </div>
                          )}
                          {location.city} {location.state} {location.zip}
                        </div>
                        <button className="dispensary_edit_btn" type="button">
                          edit
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </section>
              <div>
                <div>
                  <b>
                    {" "}
                    <label htmlFor="alias">
                      *Location alias (this is how the location will display on
                      the site ex. Merp - Seattle) if you only have one location
                      you should re-enter the dispensary name.{" "}
                    </label>
                  </b>
                  <br />
                  <input
                    type="text"
                    id="alias"
                    required={locations.length === 0}
                    placeholder="Enter location alias"
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                  ></input>
                </div>
                <div>
                  <b>
                    {" "}
                    <label htmlFor="address">*Address</label>
                  </b>
                  <br />
                  <input
                    type="text"
                    id="address1"
                    placeholder="Enter full street address"
                    required={locations.length === 0}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></input>
                </div>
                <div>
                  <b>
                    {" "}
                    <label htmlFor="address2">Apt/Suite/Unit</label>
                  </b>
                  <br />
                  <input
                    type="text"
                    id="address2"
                    placeholder="Enter Apt/Suite/Unit"
                    value={street2}
                    onChange={(e) => setStreet2(e.target.value)}
                  ></input>
                </div>
                <div>
                  <b>
                    {" "}
                    <label htmlFor="address">*Telephone</label>
                  </b>
                  <br />
                  <input
                    type="text"
                    id="telephone"
                    required={locations.length === 0}
                    placeholder="Enter dispensary telephone"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  ></input>
                </div>
                <div>
                  <b>
                    <label htmlFor="city">*City</label>
                  </b>
                  <br />
                  <input
                    type="text"
                    id="city"
                    placeholder="Enter city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required={locations.length === 0}
                  ></input>
                </div>
                <div>
                  <b>
                    <label htmlFor="state">*State</label>
                  </b>
                  <br />
                  <select
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required={locations.length === 0}
                  >
                    <option value="">Select One</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div>
                  <b>
                    <label htmlFor="postalCode">*Postal Code</label>
                  </b>
                  <br />
                  <input
                    type="text"
                    id="zipCode"
                    placeholder="Enter postal code"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    required={locations.length === 0}
                  ></input>
                </div>
              </div>

              <div style={{ marginTop: "1rem" }}>
                <label />

                <button
                  type="button"
                  style={{
                    marginBottom: "1rem",
                    backgroundColor: "#6b80b3",
                    color: "white",
                  }}
                  onClick={addLocation}
                >
                  Add location
                </button>
              </div>
            </div>{" "}
          </div>

          <div>
            <label>
              <b>Website link (if applicable)</b>
            </label>
            <input
              type="url"
              id="websiteLink"
              placeholder="enter your website url"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            ></input>
            <br />
          </div>
          <div style={{ paddingTop: "1.5rem" }}>
            {locations.length === 0 && (
              <small>
                <strong>
                  You must enter at least one location before submitting.
                </strong>
              </small>
            )}
            <button
              disabled={locations.length === 0}
              className="brand_list_section"
              style={{ paddingTop: "1.5rem" }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      ) : (
        <div
          className="form"
          style={{ textAlign: "center", fontSize: "3rem", padding: "3rem" }}
        >
          <div>
            <b>SUCCESS!</b>
          </div>
          <b>
            Thanks for your interest! Some one from our team will reach out to
            you soon!
          </b>
        </div>
      )}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <div className="brand_list_main_header_footnote">
          Just a friendly reminder...
        </div>
        <section className="brand_list_section_footnote">
          <div>
            We are excited that you are considering listing your business on our
            site! Merp has diligently thought about how to make our platform the
            best it can be not just for our users but also for our brand
            partners, which could be you! There's a just few things you should
            be informed of before reaching out:
          </div>
        </section>
        <ol className="brand_list_section_footnote bullets-nums">
          <li>
            <p>
              It has to be said that we can only list, market, and/or work
              directly with brands and dispensaries that are legally operating
              in your state of business.
            </p>
            <p>
              We understand there are plenty of barriers to entry that keep many
              people from being able to start and operate a fully legal and
              standalone cannabis business. If you are not able to fully operate
              a legal dispensary or cannabis brand because of the associated
              prices or other barriers to entry, we would still love to hear
              from you to see how we could be of support in the future or assist
              you in finding the correct resources to get you to a point of
              owning and operating a fully legal cannabis business.
            </p>
          </li>
          <li>
            <p>
              We <strong>CANNOT</strong> promote the sell of and/or purchasing
              of anything that contains THC and is sold and shipped online. At
              this time cannabis products that contain THC are not federally
              legal and are illegal to sell online. Merp itself does not sell
              any THC products through our site and any brand we list that
              contains THC must be sold through a registered dispensary to be
              able to list on our platform.
            </p>
            <p>
              At this time Merp is not offering direct purchasing or direct sell
              of CBD products (we can list your brand and link to external
              site/product) through our site. We are sorry for any inconvience
              this may cause and hope to be able to offer direct sell and
              purchasing of CBD products on our platform in the near future.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
}
