import React from 'react'
import MessageBox from '../components/MessageBox';

export default function SubscriberListScreen() {
    return (
        <div class="container-fluid" style={{padding:"2rem"}}>
          <div className="row-cart" style={{paddingBottom:"2rem"}}>
            <h1>Subscribers</h1>
          </div>
          {/* {loadingDelete && <LoadingBox></LoadingBox>}
          {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
    
          {loadingCreate && <LoadingBox></LoadingBox>}
          {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : ( */}
            <div >
            <table className="table">
              <tr>
                <th>ID</th>
                <th>EMAIL</th>
                <th>SUBSCRIBED</th>
              </tr>
              <tbody>
                
                
                <MessageBox>No products listed</MessageBox>
              </tbody>
            </table>
           
            {/* <div className="row center pagination">
            {[...Array(pages).keys()].map((x) => (
              <Link className={x+1===page?'active':''} key={x + 1} to={`/productlist/pageNumber/${x+1}`}>
                {x + 1}
              </Link>
            ))} */}
          </div>
          </div>
     
      );
}
