import {
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_LIST_FAIL,
  BRAND_CREATE_REQUEST,
  BRAND_CREATE_SUCCESS,
  BRAND_CREATE_FAILURE,
  BRAND_CREATE_RESET,
  BRAND_DELETE_REQUEST,
  BRAND_DELETE_SUCCESS,
  BRAND_DELETE_FAILURE,
  BRAND_DELETE_RESET,
  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_SUCCESS,
  BRAND_UPDATE_FAILURE,
  BRAND_UPDATE_RESET,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAIL,
  GET_DISPENSARY_BRANDS_REQUEST,
  GET_DISPENSARY_BRANDS_SUCCESS,
  GET_DISPENSARY_BRANDS_FAILURE,
} from "../constants/brandConstants";

export const brandListReducer = (
  state = { loading: true, brands: [] },
  action
) => {
  switch (action.type) {
    case BRAND_LIST_REQUEST:
      return { loading: true };
    case BRAND_LIST_SUCCESS:
      return { loading: false, brands: action.payload };
    case BRAND_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBrandsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BRANDS_REQUEST:
      return { loading: true };
    case GET_BRANDS_SUCCESS:
      return { loading: false, brands: action.payload };
    case GET_BRANDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const brandCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BRAND_CREATE_REQUEST:
      return { loading: true };
    case BRAND_CREATE_SUCCESS:
      return { loading: false, success: true, brand: action.payload };
    case BRAND_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case BRAND_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const brandDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BRAND_DELETE_REQUEST:
      return { loading: true };
    case BRAND_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BRAND_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case BRAND_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const brandUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BRAND_UPDATE_REQUEST:
      return { loading: true };
    case BRAND_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case BRAND_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case BRAND_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getDispensaryBrandsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DISPENSARY_BRANDS_REQUEST:
      return { loading: true };
    case GET_DISPENSARY_BRANDS_SUCCESS:
      return { loading: false, success: true, dispensary: action.payload };
    case GET_DISPENSARY_BRANDS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
