import React, { useEffect, useState } from "react";
import "./postPage.css";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Image } from "cloudinary-react";

import { getPost, deletePost } from "../../../actions/blogActions";
import LoadingBox from "../../../components/LoadingBox";
import MessageBox from "../../../components/MessageBox";

import { BLOG_DELETE_RESET } from "../../../constants/blogConstants";
import parse from "html-react-parser";

export default function PostPage(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const postDelete = useSelector((state) => state.postDelete);
  const { loading: loadingDelete, error: errorDelete, success } = postDelete;

  const [videoID, setVideoID] = useState("");

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  }

  useEffect(() => {
    if (success) {
      // dispatch({ type: BLOG_DELETE_RESET})
      props.history.push(`/blog`);
    }
    dispatch(getPost(id));
  }, [dispatch, success, props, id]);

  const editBlog = () => {
    props.history.push(`/blog/edit/${id}`);
  };

  const deleteBlog = () => {
    dispatch(deletePost(id));
  };

  const postDetails = useSelector((state) => state.postDetails);
  const { loading, error, blog } = postDetails;

  let description = blog ? blog.description : "";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  return (
    <div className="post-page">
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox> </LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className="post-page-col-wrapper">
         
          <div  style={{display:"flex", justifyContent:"center"}} >
            <div >
            <img
            className="img-container"
             src={blog.image}
             alt={blog.title}
            />
            </div>
          </div>
          <div className="blog-title">{blog.title}</div>

          <div className="post-page-title">
            {userInfo && userInfo.isAdmin && (
              <div className="post-page-edit-container">
                <i
                  className="post-page-icon far fa-edit"
                  onClick={editBlog}
                ></i>

                <i
                  className="post-page-icon far fa-trash-alt"
                  onClick={deleteBlog}
                ></i>
              </div>
            )}
          </div>
          <div className="post-page-info">
            <span className="post-page-date">
              Published On: <strong> {formatDate(blog.createdAt)} </strong>
            </span>
          </div>
          <div>
            <div className="post-page-description">{parse(description)}</div>
          </div>
        </div>
      )}
    </div>
  );
}
