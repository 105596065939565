import React, { useEffect, useState } from "react";
import "./homescreen2.css";

// import { Carousel } from "react-responsive-carousel";
// import Product from "../components/Product";

// import LoadingBox from "../../components/LoadingBox";
// import MessageBox from "../../components/MessageBox";
// import Budtender from "../../components/Budtender";
import { useSelector, useDispatch } from "react-redux";
import { listDispensaries, listProducts } from "../../actions/productActions";
import {
  castMyVote,
  getQuestion,
  listTopSellers,
  notifyWhenInMyArea,
  submitQuestion,
} from "../../actions/userActions";
// import { Link } from "react-router-dom";
// import ShopBIPOC from "../../components/ShopBIPOC";
// import ShopWomanOwned from "../components/ShopWomanOwned";
// import ShopLgbtOwned from "../components/ShopLgbtOwned";
// import ShopSustainably from "../components/ShopSustainably";
// import ShopWorkerCoop from "../components/ShopWorkerCoop";
// import ShopAllBrands from "../components/ShopAllBrands";
// import ShopCBD from "../../components/ShopCBD";
// import ShopTHC from "../../components/ShopTHC";
// import ShopEquityVerified from "../components/ShopEquityVerified";

// import ShopUnionized from "../../components/ShopUnionized";
// import ShopMerchandise from "../../components/ShopMerchandise";
// import ShopAccessories from "../components/ShopAccessories";
// import ShopDisabledVeteran from "../components/ShopDisabledVeteran";

import { Link } from "react-router-dom";
// import Dispensaries from "../../components/Dispensaries";
// import Merchandise from "../../components/Merchandise";
// import CBD from "../../components/CBD";
// import Rating from "../../components/Rating";
import { GET_QUESTION_RESET } from "../../constants/userConstants";
import MainSlider from "../../components/MainSlider/MainSlider";
import FeaturedProductsSlider from "../../components/FeaturedProductsSlider/FeaturedProductsSlider";
import DispensarySlider from "../../components/DispensarySlider";

// import ReferAFriendView from "../components/ReferAFriendView";

const HomeScreen = () => {
  const [currQuestion, setCurrQuestion] = useState("");

  const dispatch = useDispatch();

  // const local = "local";

  const merchandise = "merchandise";
  const cbd_products = "cbd_products";
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  const dispensaryList = useSelector((state) => state.dispensaryList);
  const {
    loading: loadingDispensary,
    error: errorDispensary,
    dispensaries,
  } = dispensaryList;

  const currentQuestion = useSelector((state) => state.currentQuestion);
  const {
    loading: loadinQuestion,
    error: errorQuestion,
    question,
    success: successQuestion,
  } = currentQuestion;

  const castedVote = useSelector((state) => state.castedVote);
  const {
    loading: castedVoteLoading,
    error: castedVoteError,
    success: castedVoteSuccess,
    question: castedVoteQuestion,
  } = castedVote;

  const questionSubmitted = useSelector((state) => state.questionSubmitted);
  const {
    loading: questionSubmittedLoading,
    error: questionSubmittedError,
    success: questionSubmittedSuccess,
  } = questionSubmitted;

  const userTopSellersList = useSelector((state) => state.userTopSellersList);
  // const notifyNear = useSelector((state) => state.notifyNear);

  // const {
  //   loading: loadingNear,
  //   error: errorNear,
  //   success: successNear,
  // } = notifyNear;
  const [weeklyAnswer, setWeeklyAnswer] = useState("");
  const [timeElapsed, setTimeElapsed] = useState(0);

  // const {
  //   loading: loadingTopSellers,
  //   error: errorTopSellers,
  //   users: topSellers,
  // } = userTopSellersList;

  const submitHandler = (e) => {
    localStorage.setItem("answer", weeklyAnswer);
    e.preventDefault();
    dispatch(
      castMyVote({
        _id: currQuestion._id,
        answer: weeklyAnswer,
      })
    );
  };

  const [newQuestion, setNewQuestion] = useState("");
  const [answerA, setAnswerA] = useState("");
  const [answerB, setAnswerB] = useState("");

  const submitNewQuestion = (e) => {
    e.preventDefault();
    dispatch(submitQuestion({ question: newQuestion, a: answerA, b: answerB }));
  };

  useEffect(() => {
    dispatch(
      listProducts({
        status: true,
        advancedCategory: { isMinorityOwned: true },
        category: "all",
      })
    );
    dispatch(listDispensaries({ advancedCategory: { isMinorityOwned: true } }));
    dispatch(listTopSellers());

    if (castedVoteSuccess) {
      localStorage.setItem("answered", true);
    }

    function getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    }

    if (!localStorage.getItem("Idx")) {
      localStorage.setItem("Idx", 0);
    }
    if (!localStorage.getItem("questionSetDate")) {
      localStorage.setItem("questionSetDate", new Date());
    }
    setTimeElapsed(
      getNumberOfDays(
        new Date(localStorage.getItem("questionSetDate")),
        new Date()
      )
    );

    if (successQuestion) {
      if (timeElapsed >= 7 && localStorage.getItem("answered") === "true") {
        localStorage.setItem("answered", false);
        localStorage.setItem("questionSetDate", new Date());
        setCurrQuestion(question);
        dispatch({ type: GET_QUESTION_RESET });
      } else {
        setCurrQuestion(question);
      }
    }
    if (!question) {
      if (timeElapsed >= 7 && localStorage.getItem("answered") === "true") {
        let idx = Number(localStorage.getItem("Idx"));
        idx++;
        localStorage.setItem("Idx", idx);
        dispatch(getQuestion(localStorage.getItem("Idx")));
      } else {
        dispatch(getQuestion(localStorage.getItem("Idx")));
      }
    }
  }, [dispatch, castedVoteSuccess, question]);

  return (
    <div style={{ height: "auto" }}>
      <div className="mission-banner">
        <div className="mission">
          <div className="mission-subtitle">
            Welcome to Merp! A Cannabis Brand & User's bestfriend! <br />
            <div className="mission-sub-header">
              Offering in-depth information on your favorite, and some soon to
              be new favorite, brands.
            </div>
          </div>
          <Link to="/about">
            <button className="mission-btn">Learn More</button>
          </Link>
        </div>
      </div>
      <div>
        <div className="slider-cont">
          <MainSlider />
        </div>
      </div>
      {/* <div>
        <div className="cont text-center">
          <div className="feat">
            <div className="features-title">New Brands</div>
            <p className="features-text">
              Looking for some equitably-owned dispensaries, browse somoe of our
              featured dispensaries. That includes retailers that are
              minority-owned or are implementing socially-equitable practices!
            </p>
          </div>
        </div>
        {dispensaries && dispensaries.length && (
          <DispensarySlider dispensaries={dispensaries} />
        )}
      </div> */}
      <div>
        <div className="cont text-center">
          <div className="feat">
            <div className="features-title">Popular Dispensaries</div>
            <p className="features-text">
              Looking for some equitably-owned dispensaries, browse somoe of our
              featured dispensaries. That includes retailers that are
              minority-owned or are implementing socially-equitable practices!
            </p>
          </div>
        </div>
        {dispensaries && dispensaries.length && (
          <DispensarySlider dispensaries={dispensaries} />
        )}
      </div>

      {/* <div>
          <div className="cont text-center">
            <div className="feat">
              <div className="features-title">Brand Recommendations</div>
              <p className="features-text">
                Looking for some equitably-owned dispensaries, browse somoe of
                our featured dispensaries. That includes retailers that are
                minority-owned or are implementing socially-equitable practices!
              </p>
            </div>
          </div>
          {dispensaries && dispensaries.length && (
            <DispensarySlider dispensaries={dispensaries} />
          )}
        </div> */}

      {products && products.length > 0 && (
        <>
          <div>
            <div className="cont text-center">
              <div className="feat">
                <div className="features-title">Featured Products</div>
                <p className="features-text">
                  Come browse some of the products the Merp team and the rest of
                  our Merp community are really excited about and/or are
                  currently LOVING! You can expect to find some cool and nice
                  products featured here.
                </p>
              </div>
            </div>

            <FeaturedProductsSlider products={products} />
          </div>
        </>
      )}

      <div style={{ backgroundColor: "#FFFBEC" }}>
        <div className="container deals  p-5">
          <div className="row featured-product">
            <img
              className="col-sm-12 col-lg-5 featured-img"
              style={{ float: "left" }}
              src="/images/juice.png"
              alt="hvsnt album cover and juice mazelee"
            />

            <div className="col-sm-12 col-lg-7">
              <div className="featured-title">
                Checkout Our Featured Artist: Juice Mazelee
              </div>
              <p className="featured-text">
                This month we are spotlighting the artistic talent of rapper
                Juice Mazelee. Juice incorporates cannabis into all parts of his
                creative process. Want to find out how? Click the button below
                to checkout our blog post on him and find out how you can
                support!
              </p>
              <Link to="/blog">
                <button className="border site-btn btn-span bg-primary text-white featured-btn">
                  Read More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
