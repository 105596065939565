import React from "react";
import { useSelector } from "react-redux";

export default function RefundPolicyScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  return (
    <>
      {userInfo && userInfo.isSeller ? (
        <div className="form">
          <div>
            <strong>
              <div style={{ fontSize: "2.75rem" }}>Refund Policy </div>
            </strong>
            <p>
              Thank you for using and trusting Merp Inc. to list and market your
              brand and/or dispensary. We hope you are happy with our service.
              If you are unsatisfied with our service and feel a referral charge
              to your invoice was made in error you may reach out and dispute a
              referral charge for a full refund of the referral charge in
              question. Please see below for more detail on our refund policy.
              If you are unhappy with our analytics dashboard you may cancel
              before the next subscription billing charge and your service will
              be terminated and you will not be charged for the upcoming
              subscription cycle referenced .{" "}
            </p>
            <h1>Refunds</h1>
            <h2>Referrals:</h2>
            <p>
              All refunds must be accompanied with evidence that the referral in
              question was not completed. Merp defines a referral as any person
              whose pre-order code is input into the system to search for the
              pre-order of the customer who has arrived at the dispensary (No
              pre-order code should ever be taken over the phone and Merp Inc.
              is not liable for any person whose age was not verified, which is
              needed to fulfill the pre-order). If the order is returned to the
              seller from our database screen, it verifies the pre-order exists
              and the input of the pre-order code verifies the customer has
              shown up to the dispensary and Merp Inc. has fulfilled its
              service.
            </p>

            <p>
              Merp Inc. does not guarantee a sale and does not get paid from the
              sale of any product. We are a referral service, we get paid per
              referral, if a pre-order code is submitted to our system we have
              referred someone to your business and you will be charged.
            </p>

            <p>
              You have 2 days(48 hours) after your most recent billing cycle
              invoice charge to dispute any referral charge for the invoice
              cycle being referenced. Refund requests made after this time will
              not be allowed or honored.
            </p>

            <p>
              Merp is not responsible for anyone who is underage who shows up
              and is turned away, it is the responsibility of the
              dispensary/smoke shop to verify the person who has arrived to the
              dispensary/smoke shop in question is the age required by local
              and/or federal law to purchase the product(s) in the pre-order,
              before checking their pre-order code. A refund WILL NOT be
              permitted if the person presenting the code is found to be under
              the age required by local and/or federal law to purchase the
              product(s) contained in the pre-order AFTER entering the pre-order
              code into the system. If the person presenting the code does not
              match the name on the order Merp is not responsible and considers
              the term of the referral still met with a person still arriving at
              the dispensary/smoke shop in question.{" "}
            </p>

            <h2>Analytics dashboard:</h2>
            <p>
              If you are not happy with the dashboard you must cancel your
              subscription prior to the next subscription charge date, if you do
              not cancel before the subscription charge date you will be charged
              for the entire subscription cycle and your cancellation will go
              into effect AFTER the subscription cycle being referenced.
            </p>

            <h1>Exceptions</h1>
            <h2>Referrals:</h2>
            <p>
              If you are the cardholder on file and have experienced a grave
              illness, hospitalization or death the request for refund of a
              referral charge will be extended out 3 extra days.
            </p>
            <h2>Analytics Dashboard:</h2>
            <p>
              You have 24-hours after the subscription charge to cancel the
              subscription in which case you will get a full refund for the
              subscription billing cycle and your service will be terminated
              immediately
            </p>
            <h1>Questions?</h1>
            <p>
              If you have any questions concerning our refund policy , please
              contact us at: info@merp.page
            </p>
          </div>
        </div>
      ) : (
        <div className="form">
          <div>
            <strong>
              <div style={{ fontSize: "2.75rem" }}>Refund Policy </div>
            </strong>
            <p>
              Thank you for using and trusting Merp Inc. to list and market your
              brand and/or dispensary. We hope you are happy with our service.
              If you are unsatisfied with our service and feel a referral charge
              to your invoice was made in error you may reach out and dispute a
              referral charge for a full refund of the referral charge in
              question. Please see below for more detail on our refund policy.
              If you are unhappy with our analytics dashboard you may cancel
              before the next subscription billing charge and your service will
              be terminated and you will not be charged for the upcoming
              subscription cycle referenced .{" "}
            </p>
            <h1>Refunds</h1>

            <h2>Event tickets:</h2>
            <p>There are no refund on event tickets.</p>

            <h2>Merchandise:</h2>
            <p>
              All returns are up to the individual seller. Merp Merchandise (not
              including, stickers) is able to be returned with within 14 days of
              recieving your product(s) and must not be damaged, used or opened.
            </p>
            <h1>Questions?</h1>
            <p>
              If you have any questions concerning our refund policy , please
              contact us at: info@merp.page
            </p>
          </div>
        </div>
      )}
    </>
  );
}
