import React from 'react'
import "./header.css"

export default function Header() {
    return (
        <div  className="hero">
            <div className="hero-inner">
                <div className="text">Blog</div>
                {/* <img className="headers-img" src="/images/3.png" alt="hand holding canna leaf"/> */}
            </div>
        </div>
    )
}
