import React, { useState, useEffect } from "react";
import MessageBox from "../components/MessageBox";
import LoadingBox from "../components/LoadingBox";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser, updateUser } from "../actions/userActions";
import { USER_UPDATE_RESET } from "../constants/userConstants";

export default function UserEditScreen(props) {
  // const cart = useSelector((state) => state.cart);
  
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const userId = props.match.params.id;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isSeller, setIsSeller] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOnline, setIsOnline] = useState(false)
  const [sellsAtDispensary, setSellsAtDispensary] = useState(false)
  const [isBlackOwned, setBlackOwned] = useState(false);
  const [isIndigenousOwned, setIndigenousOwned] = useState(false);
  const [isPOCOwned, setPOCOwned] = useState(false);
  const [isWomanOwned, setWomanOwned] = useState(false);
  const [isWorkerCoop, setWorkerCoop] = useState(false);
  const [isDispensary, setIsDispensary]= useState(false)
  const [equityVerified, setEquityVerified] = useState(false);
  const [isSustainable, setSustainable] = useState(false);
  const [isLgbt, setLgbt] = useState(false);
  const [isUnionized, setIsUnionized] = useState(false)
  const [sellTHC, setSellTHC] = useState(false);
  const [sellCBD, setSellCBD] = useState(false)
  const [rewardsProgram, setRewardsProgram] = useState(false)
  // const [address, setAddress] = useState("");
  const [companyBadgeDescription, setCompanyBadgeDescription] = useState("");
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  


  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  useEffect(() => {
    // AIzaSyCT4jOjDT5klhAtABCGw1pdBtw2uWYUF1g
    // dispatch(getAddress(encodeURIComponent(address)))

    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      props.history.push("/userlist");
    }
    if (!user) {
      dispatch(detailsUser(userId));
    } else {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setIsSeller(user.isSeller);
      setIsAdmin(user.isAdmin);
      setIsDispensary(user.seller.dispensary)
      setSellsAtDispensary(user.seller.sellsAtDispensary)
      setIsOnline(user.seller.isOnline)
      // if (!user.isSeller) {
      //   setBlackOwned(isBlackOwned);
      //   setEquityVerified(equityVerified);
      //   setIndigenousOwned(isIndigenousOwned);
      //   setLgbt(isLgbt);
      //   setPOCOwned(isPOCOwned);
      //   setSustainable(isSustainable);
      //   setWomanOwned(isWomanOwned);
      //   setWorkerCoop(isWorkerCoop);
      // }
      if (user.seller.sellTHC) {
        setSellTHC(user.seller.sellTHC);
        setIsDispensary(true)
      }
    
      if (user.seller.rewardsProgram) {
        setRewardsProgram(user.seller.rewardsProgram);
      }
      if(user.seller.companyAddress){
        setFullName(user.seller.companyAddress.fullName)
        setAddress(user.seller.companyAddress.address)
        setCity(user.seller.companyAddress.city)
        setCountry(user.seller.companyAddress.country)
        setState(user.seller.companyAddress.state)
        setPostalCode(user.seller.companyAddress.postalCode)
      }
      if (!user.badges) {
        setBlackOwned(isBlackOwned);
        setEquityVerified(equityVerified);
        setIndigenousOwned(isIndigenousOwned);
        setLgbt(isLgbt);
        setPOCOwned(isPOCOwned);
        setSustainable(isSustainable);
        setWomanOwned(isWomanOwned);
        setWorkerCoop(isWorkerCoop);
        setCompanyBadgeDescription(companyBadgeDescription);
        setIsUnionized(isUnionized)
      } else {
        setLgbt(user.badges.isLgbt.status);
        setBlackOwned(user.badges.isBlackOwned.status);
        setEquityVerified(user.badges.equityVerified.status);
        setIndigenousOwned(user.badges.isIndigenousOwned.status);
        setPOCOwned(user.badges.isPOCOwned.status);
        setSustainable(user.badges.isSustainable.status);
        setWomanOwned(user.badges.isWomanOwned.status);
        setWorkerCoop(user.badges.isWorkerCoop.status);
        setCompanyBadgeDescription(user.badges.description);
        setIsUnionized(user.badges.isUnionized.status)
      }
    }
  }, [dispatch, user, userId, successUpdate, props.history]);

  const submitHandler = (e) => {
    e.preventDefault();

    //dispatch update user
    dispatch(
      updateUser({
        _id: userId,
        firstName,
        lastName,
        email,
        isSeller,
        isAdmin,
        isLgbt,
        isBlackOwned,
        isIndigenousOwned,
        isPOCOwned,
        isWomanOwned,
        isSustainable,
        isWorkerCoop,
        equityVerified,
        companyBadgeDescription,
        isUnionized,
        sellTHC,
        sellCBD,
        rewardsProgram,
        sellsAtDispensary,
        isDispensary,isOnline,
     
        dispensaryAddress: {
          fullName,
         address,
          city,
          state,
          postalCode,
          country,
        },
      })
    );
  };
  return (
    <div>
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h1>Edit User {firstName}</h1>
          {loadingUpdate && <LoadingBox></LoadingBox>}
          {errorUpdate && (
            <MessageBox variant="danger">{errorUpdate}</MessageBox>
          )}
        </div>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div>
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                type="text"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}></input>
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                type="text"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}></input>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}></input>
            </div>
            <div>
              <div>
                <input
                  id="isAdmin"
                  type="checkbox"
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}></input>
                <label htmlFor="isAdmin">Is Admin</label>
              </div>
            </div>
            <div>
              <div>
                <input
                  id="isSeller"
                  type="checkbox"
                  checked={isSeller}
                  onChange={(e) => setIsSeller(e.target.checked)}></input>
                <label htmlFor="isSeller">Is Seller</label>
              </div>
            </div>
            {isSeller &&(
              <div>
                <h1>How does this business operate?</h1>
                <div>
                <input
                    id="isOnline"
                    type="checkbox"
                    checked={isOnline}
                    onChange={(e) => setIsOnline(e.target.checked)}></input>
                  <label htmlFor="isOnline">Brand sells online</label>
                </div>
                <div>
                <input
                    id="sellsThroughDispensary"
                    type="checkbox"
                    checked={sellsAtDispensary}
                    onChange={(e) => setSellsAtDispensary(e.target.checked)}></input>
                  <label htmlFor="sellsThroughDispensary">Brand sells through a Dispensary</label>
                </div>
                <div>
                <input
                    id="isDispensary"
                    type="checkbox"
                    checked={isDispensary}
                    onChange={(e) => setIsDispensary(e.target.checked)}></input>
                  <label htmlFor="isDispensary">Is a Dispensary</label>
                </div>
              </div>
            )}
            {isSeller &&(
              <div>
                  <h1>Which badge(s) apply to this business?</h1>
                <div>
                  <input
                    id="isBlackOwned"
                    type="checkbox"
                    checked={isBlackOwned}
                    onChange={(e) => setBlackOwned(e.target.checked)}></input>
                  <label htmlFor="isBlackOwned">Black Owned</label>
                </div>
                <div>
                  <input
                    id="isIndigenousOwned"
                    type="checkbox"
                    checked={isIndigenousOwned}
                    onChange={(e) =>
                      setIndigenousOwned(e.target.checked)
                    }></input>
                  <label htmlFor="isIndigenousOwned">Indigenous Owned</label>
                </div>
                <div>
                  <input
                    id="isPOCOwned"
                    type="checkbox"
                    checked={isPOCOwned}
                    onChange={(e) => setPOCOwned(e.target.checked)}></input>
                  <label htmlFor="isPOCOwned">POC Owned</label>
                </div>
                <div>
                  <input
                    id="isWomanOwned"
                    type="checkbox"
                    checked={isWomanOwned}
                    onChange={(e) => setWomanOwned(e.target.checked)}></input>
                  <label htmlFor="isWomanOwned">Woman Owned</label>
                </div>
                <div>
                  <input
                    id="isWorkerCoop"
                    type="checkbox"
                    checked={isWorkerCoop}
                    onChange={(e) => setWorkerCoop(e.target.checked)}></input>
                  <label htmlFor="isWorkerCoop">Worker COOP</label>
                </div>
                <div>
                  <input
                    id="isLgbt"
                    type="checkbox"
                    checked={isLgbt}
                    onChange={(e) => setLgbt(e.target.checked)}></input>
                  <label htmlFor="isLgbt">LGBT Owned</label>
                </div>
                <div>
                  <input
                    id="equityPlanVerified"
                    type="checkbox"
                    checked={equityVerified}
                    onChange={(e) =>
                      setEquityVerified(e.target.checked)
                    }></input>
                  <label htmlFor="equityPlanVerified">
                    Equity Plan Verified
                  </label>
                </div>
                <div>
                  <input
                    id="isSustainable"
                    type="checkbox"
                    checked={isSustainable}
                    onChange={(e) => setSustainable(e.target.checked)}></input>
                  <label htmlFor="isSustainable">
                    Sustainably grown/sourced
                  </label>
                </div>
                <div>
                  <input
                    id="isUnionized"
                    type="checkbox"
                    checked={isUnionized}
                    onChange={(e) => setIsUnionized(e.target.checked)}></input>
                  <label htmlFor="isUnionized">
                  Unionized
                  </label>
                </div>
              </div>
              
            )}

            
            {isSeller && (
              <>
                <div>
                  <label htmlFor="companyBadgeDescripton">
                    <h4>Company Badge Description</h4>
                  </label>
                  <textarea
                    id="companyBadgeDescripton"
                    value={companyBadgeDescription}
                    onChange={(e) =>
                      setCompanyBadgeDescription(e.target.value)
                    }></textarea>
                </div>
                <div>
                  <h4> Does this Seller sell CBD products?</h4>
                  <div>
                    <input
                      id="sellCBD"
                      type="checkbox"
                      checked={sellCBD}
                      onChange={(e) => setSellCBD(e.target.checked)}></input>
                    <label htmlFor="sellCBD">Seller carries CBD Products</label>
                  </div>
                </div>
                <div>
                  <h4> Does this Seller sell THC products?</h4>
                  <div>
                    <input
                      id="sellTHC"
                      type="checkbox"
                      checked={sellTHC}
                      onChange={(e) => setSellTHC(e.target.checked)}></input>
                    <label htmlFor="sellTHC">Seller carries THC Products</label>
                  </div>
                </div>
                {sellTHC && (
                  <div>
                    <div>
                  <h4> Does this Dispensary have a rewards program?</h4>
                  <div>
                    <input
                      id="rewardsProgram"
                      type="checkbox"
                      checked={rewardsProgram}
                      onChange={(e) => setRewardsProgram(e.target.checked)}></input>
                    <label htmlFor="rewardsProgram">Dispensary has a rewards program</label>
                  </div>
                </div>
                    <div>
                      <h1>Enter Dispensary Address</h1>
                    </div>
                    <div>
                      <label htmlFor="fullName">Dispensary Name</label>
                      <br />

                      <input
                        type="text"
                        id="fullname"
                        placeholder="Enter your full name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required></input>
                    </div>
                    <br />
                    <div>
                      <label htmlFor="address">Address</label>
                      <br />
                      <input
                        type="text"
                        id="address"
                        placeholder="Enter full street address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required></input>
                    </div>
                    <br />
                    <div>
                      <label htmlFor="city">City</label>
                      <br />
                      <input
                        type="text"
                        id="city"
                        placeholder="Enter city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required></input>
                    </div>
                    <br />
                    <div>
                      <label htmlFor="state">State</label>
                      <br />
                      <select
                        id="state"
                        value={state}
                        onChange={(e) => setState(e.target.value)}>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>

                    <br />
                    <div>
                      <label htmlFor="postalCode">Zip Code</label>
                      <br />
                      <input
                        type="text"
                        id="postalCode"
                        placeholder="Enter Zip code"
                        required
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}></input>
                    </div>
                    <br />
                    <div>
                      <label htmlFor="country">Country</label>
                      <br />
                      <input
                        type="text"
                        id="country"
                        placeholder="Enter country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required></input>
                    </div>
                    <br />
                    {/* <label htmlFor="dispensaryAdress">Dispensary Address</label>
                    <input
                      id="dispensaryAdress"
                      placeholder="Enter your address"
                      value={address}
                      // onFocus="geolocate()"
                      // onChange={(e) => setAddress(e.target.value)}
                      type="text"
                      class="form-control"
                    /> */}
                  </div>
                )}
              </>
            )}
            <div>
              <button type="submit" className="primary">
                Update
              </button>
            </div>
          </>
        )}
      </form>
      {/* <script
        src="https://maps.googleapis.com/maps/api/js?key=XXXXXXXXXXXXXXXXXXXXXXXXX&libraries=places&callback=initAutocomplete"
        async
        defer></script> */}
    </div>
  );
}
