import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import LoadingBox from "../components/LoadingBox";
import { BorderLinearProgress } from "../components/PasswordProgressbar";

const RegisterScreen = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [referralCode, setReferralCode] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  // const [subscribeToMailing, setSubscribeToMailing] = useState(false);
  const [age, setAge] = useState("");
  const [frequency, setFrequency] = useState("");
  const [intake, setIntake] = useState("");
  const [price, setPrice] = useState(false);
  const [quality, setQuality] = useState(false);
  const [potent, setPotent] = useState(false);
  const [ethical, setEthical] = useState(false);
  const [review, setReview] = useState(false);
  const [username, setUsername] = useState("");

  const redirect = "/email_sent";

  const userRegister = useSelector((state) => state.userRegister);
  const { success, loading, error } = userRegister;

  // const referralStatus = useSelector((state) => state.referralCode);
  // const {
  //   success: referralSuccess,
  //   error: referralError,
  //   message,
  // } = referralStatus;

  const dispatch = useDispatch();
  const submitHandlerFunction = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Password and password confirmation do NOT match");
    }
    if (!agreeToTerms) {
      alert(
        "You must read and agree to Terms before signing up and while accessing the site"
      );
    }

    dispatch(
      register({
        firstName,
        lastName,
        username,
        age,
        email,
        password,
        frequency,
        intake,
        purchaseHabits: {
          price,
          quality,
          potent,
          ethical,
          review,
        },
        // referralCode,
        // subscribeToMailing,
      })
    );
  };
  useEffect(() => {
    if (success) {
      props.history.push(redirect);
    }
  }, [props.history, redirect, success, dispatch]);
  return (
    <div>
      <form
        className="form register-form"
        onSubmit={submitHandlerFunction}
        style={{ marginTop: "3rem" }}
      >
        <div className="register-title" style={{ textAlign: "center" }}>
          Register
        </div>
        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger"> {error}</MessageBox>}
        <div>
          <label htmlFor="name">First Name</label>
          <input
            type="text"
            id="firstName"
            placeholder="enter your first name"
            required
            onChange={(e) => setFirstName(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="name">Last Name</label>
          <input
            type="text"
            id="lastName"
            placeholder="enter your last name"
            required
            onChange={(e) => setLastName(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="age">*Age</label>
          <select
            id="age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            required
          >
            <option value="">Select One</option>
            <option value="21-25">21-25</option>
            <option value="26-30">26-30</option>
            <option value="31-35">31-35</option>
            <option value="36-40">36-40</option>
            <option value="41-45">41-45</option>
            <option value="46-50">46-50</option>
            <option value="51-55">51-55</option>
            <option value="56-60">56-60</option>
            <option value="61-65">61-65</option>
            <option value="66-70">66-70</option>
            <option value="71-75">71-75</option>
            <option value="76-80">76-80</option>
            <option value="81-85">81-85</option>
            <option value="86-90">86-90</option>
            <option value="91-95">91-95</option>
            <option value="96-100">96-100</option>
            <option value="100+">100+</option>
          </select>
        </div>
        <div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            placeholder="enter a username"
            required
            onChange={(e) => setUsername(e.target.value)}
          ></input>
        </div>

        <div>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            placeholder="enter email"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="age">*Age</label>
          <select
            id="age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            required
          >
            <option value="">Select One</option>
            <option value="21-25">21-25</option>
            <option value="26-30">26-30</option>
            <option value="31-35">31-35</option>
            <option value="36-40">36-40</option>
            <option value="41-45">41-45</option>
            <option value="46-50">46-50</option>
            <option value="51-55">51-55</option>
            <option value="56-60">56-60</option>
            <option value="61-65">61-65</option>
            <option value="66-70">66-70</option>
            <option value="71-75">71-75</option>
            <option value="76-80">76-80</option>
            <option value="81-85">81-85</option>
            <option value="86-90">86-90</option>
            <option value="91-95">91-95</option>
            <option value="96-100">96-100</option>
            <option value="100+">100+</option>
          </select>
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="enter password"
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <div style={{ marginTop: "5px" }}>
            <BorderLinearProgress password={password} />
          </div>
        </div>
        <div>
          <label htmlFor="confirmPassword"> Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="please confirm password"
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
        </div>

        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <div className="register-title">Canna Profile</div>
          <p style={{ fontWeight: "unset" }}>
            When you review your "Canna Profile" will appear and let's others
            see what kind of canna consumer you to better gauge how the same
            product might impact them based on their consumption habits!
          </p>
        </div>
        <div>
          <label htmlFor="frequency">
            *How often do you use/consume cannabis (containing THC)
          </label>
          <br />
          <select
            id="frequency"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            required
          >
            <option value="">Select One</option>
            <option value="never">I new to this</option>
            <option value="CBD">I stick to CBD</option>
            <option value="yearly">A few times a year (uh barely!)</option>
            <option value="monthly">
              Once or twice a month (just a little treat)
            </option>
            <option value="weekly">
              {" "}
              Once or twice a week (ok!ok! We see you!)
            </option>
            <option value="daily"> Daily (Hell yea! We don't judge)</option>
          </select>
        </div>
        <div>
          <label htmlFor="intake">
            *What is your go to consumption method?
          </label>
          <br />
          <select
            id="intake"
            value={intake}
            onChange={(e) => setIntake(e.target.value)}
            required
          >
            <option value="">Select One</option>
            <option value="none">I'm new to add of this</option>
            <option value="flower">Smoking (flower)</option>
            <option value="concentrate">Vaping (concentrate)</option>
            <option value="dab-concentrate"> Dabs/Smokable Concentrate</option>
            <option value="Edible"> Edible</option>
            <option value="tincture">Tincture</option>
          </select>
        </div>
        <div>
          <div>
            What is important to you when selecting a product to buy? (click all
            that apply)
          </div>
          <div className="signup-checkbox" style={{ flexDirection: "row" }}>
            <input
              style={{ marginRight: 2 }}
              type="checkbox"
              checked={price}
              onChangeCapture={(e) => setPrice(e.target.checked)}
            />
            <label> Price </label>
          </div>
          <div className="signup-checkbox" style={{ flexDirection: "row" }}>
            <input
              style={{ marginRight: 2 }}
              type="checkbox"
              checked={quality}
              onChangeCapture={(e) => setQuality(e.target.checked)}
            />
            <label> Quality of product / ingredients</label>
          </div>
          <div className="signup-checkbox" style={{ flexDirection: "row" }}>
            <input
              style={{ marginRight: 2 }}
              type="checkbox"
              checked={potent}
              onChangeCapture={(e) => setPotent(e.target.checked)}
            />
            <label>Potency</label>
          </div>
          <div className="signup-checkbox" style={{ flexDirection: "row" }}>
            <input
              style={{ marginRight: 2 }}
              type="checkbox"
              checked={ethical}
              onChangeCapture={(e) => setEthical(e.target.checked)}
            />
            <label>Brand ownership / business practices</label>
          </div>
          <div className="signup-checkbox" style={{ flexDirection: "row" }}>
            <input
              style={{ marginRight: 2 }}
              type="checkbox"
              checked={review}
              onChangeCapture={(e) => setReview(e.target.checked)}
            />
            <label>Reviews , Ratings , Recommendations</label>
          </div>
        </div>

        {/* <div>
          <label htmlFor="referralCode">Did someone refer you?</label>

          <input
            type="text"
            id="referralCode"
            placeholder="Enter referral code"
            value={referralCode}
            style={{ marginBottom: "1rem" }}
            onChange={(e) => setReferralCode(e.target.value)}
          ></input>

          {referralError && referralCode !== "" && (
            <MessageBox variant="danger">{referralError}</MessageBox>
          )}
          {referralSuccess && referralCode !== "" && (
            <MessageBox>{message.message}</MessageBox>
          )}
        </div> */}
        <div className="register-title" style={{ textAlign: "center" }}>
          Almost done...
        </div>
        {/* <div className="signup-checkbox" style={{ flexDirection: "row" }}>
          <input
            style={{ marginRight: 2 }}
            type="checkbox"
            checked={subscribeToMailing}
            onChangeCapture={(e) => setSubscribeToMailing(e.target.checked)}
          />
          <label style={{ marginLeft: 3 }}>
            Subscribe me to the Merp newsletter!
          </label>
        </div> */}
        <div className="signup-checkbox" style={{ flexDirection: "row" }}>
          <input
            style={{ marginRight: 2 }}
            id="agreeToTerms"
            type="checkbox"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
          ></input>
          <label>
            {"   "}
            *By accessing this site you accept the{" "}
            <Link to="/terms">
              Terms of Use , Privacy Policy and Disclaimer
            </Link>{" "}
          </label>
        </div>
        <div>
          <label />
          <button className="primary" type="submit">
            Register
          </button>
        </div>

        <div style={{ textAlign: "center" }}>
          <label />
          <div>
            Already have an account?{" "}
            <Link to={`/signin?redirect=${redirect}`}>Sign In</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterScreen;
