import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  // Redirect,
  // useHistory,
} from "react-router-dom";
import LogRocket from "logrocket";
import HomeScreen from "./Screens/HomeScreen2/HomeScreen2";
import ProductScreen from "./Screens/ProductScreen";
import CartScreen from "./Screens/CartScreen";
import SignInScreen from "./Screens/SignInScreen";
import { useSelector, useDispatch } from "react-redux";
import { signout, addToEmailLIst, register } from "./actions/userActions";
import RegisterScreen from "./Screens/RegisterScreen";
import ShippingAddressScreen from "./Screens/ShippingAddressScreen";
import PaymentMethodScreen from "./Screens/PaymentMethodScreen";
import PlaceOrderScreen from "./Screens/PlaceOrderScreen";
import OrderDetailsScreen from "./Screens/OrderDetailsScreen";
import OrderHistoryScreen from "./Screens/OrderHistoryScreen";
import ProfileScreen from "./Screens/ProfileScreen";
import { PrivateRoute } from "./components/PrivateRoute";
import { AdminRoute } from "./components/AdminRoute";
import { VerifiedRoute } from "./components/VerifiedRoute";
import ProductListScreen from "./Screens/ProductListScreen";
import ProductEditScreen from "./Screens/ProductEditScreen";
import OrderListScreen from "./Screens/OrderListScreen";
import UserListScreen from "./Screens/UserListScreen";
import UserEditScreen from "./Screens/UserEditScreen";
import { SellerRoute } from "./components/SellerRoute";
// import SellerScreen from "./Screens/SellerScreen";
import SearchBox from "./components/SearchBox";
import SearchScreen from "./Screens/SearchScreen/SearchScreen";
import MerchandiseSearchScreen from "./Screens/MerchandiseSearchScreen/MerchandiseSearchScreen";
import { useEffect, useState } from "react";
import { listProductCategories } from "./actions/productActions";
// import MessageBox from "./components/MessageBox";
// import LoadingBox from "./components/LoadingBox";
import CompanyBadgeScreen from "./Screens/CompanyBadgeScreen";
import AllProductsScreen from "./Screens/AllProductsScreen";
// import ShopBIPOCScreen from "./Screens/ShopBIPOCScreen";
// import SellerDashboardScreen from "./Screens/SellerDashboardScreen";
// import BudtenderScreen from "./Screens/BudtenderScreen";
import CannabisAdministrationInfoScreen from "./Screens/CannabisAdministrationInfoScreen";
import PickupLocationScreen from "./Screens/PickupLocationScreen";
import OrderSummaryScreen from "./Screens/OrderSummaryScreen";
import PickupPaymentMethodScreen from "./Screens/PickupPaymentMethodScreen";
// import Socials from "./components/Socials";
import AboutUsScreen from "./Screens/AboutUsScreen/AboutUsScreen";
// import TeamScreen from "./Screens/TeamScreen";
import EducationalListScreen from "./Screens/EducationalListScreen";

import RewardsScreen from "./Screens/Rewards/RewardsScreen";
import ContactUsScreen from "./Screens/ContactUsScreen";
import ListWithUsBrandInfoScreen from "./Screens/listScreens/ListWithUsBrandInfoScreen";
import ListWithUsDispensaryInfoScreen from "./Screens/listScreens/ListWithUsDispensaryInfoScreen";
import EmailValidationScreen from "./Screens/EmailValidationScreen";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen";
import RefundPolicyScreen from "./Screens/RefundPolicyScreen";
import NotFoundPageScreen from "./Screens/NotFoundPageScreen";
import EmailSentScreen from "./Screens/EmailSentScreen";
import EnterNewPasswordScreen from "./Screens/EnterNewPasswordScreen";
import InvoiceDetailsScreen from "./Screens/InvoiceDetailsScreen";
import TaxesScreen from "./Screens/TaxesScreen";
// import EventsScreen from "./Screens/EventsScreen";
import DrugAndAlcoholResourceScreen from "./Screens/DrugAndAlcoholResourceScreen";
import SAResourceScreen from "./Screens/SAResourceScreen";
import JobResourceScreen from "./Screens/JobResourceScreen";
// import EventSignUpScreen from "./Screens/EventSignUpScreen";

import SymoneVarnado from "./Screens/SymoneVarnado";

// import LegalResourcesScreen from "./Screens/LegalResourcesScreen";
// import HousingResourcesScreen from "./Screens/HousingResourcesScreen";
import MerpEquityScreen from "./Screens/MerpEquityScreen";
// import PurchaseTicketsScreen from "./Screens/PurchaseTicketsScreen";
// import EmailScreen from "./Screens/EmailScreen";
// import SocialMediasScreen from "./Screens/SocialMediasScreen";
import BrandListScreen from "./Screens/BrandListScreen";
// import EventCheckoutScreen from "./Screens/EventCheckoutScreen";
// import SuccessfulEventPaymentScreen from "./Screens/SuccessfulEventPaymentScreen";
// import EventTicketScanScreen from "./Screens/EventTicketScanScreen";
import PasswordResetScreen from "./Screens/PasswordResetScreen";
import BrandScreen from "./Screens/BrandScreen";
// import EventDetailsScreen from "./Screens/EventDetailsScreen";
import SellerProfileScreen from "./Screens/SellerProfileScreen";
import DispensaryListScreen from "./Screens/DispensaryListScreen";
import DispensaryEditScreen from "./Screens/DispensaryEditScreen";
import DispensaryScreen from "./Screens/DispensaryScreen/DispensaryScreen";

import BrandEditScreen from "./Screens/BrandEditScreen";
import PaymentRecievedScreen from "./Screens/PaymentRecievedScreen";
import SubscriberListScreen from "./Screens/SubscriberListScreen";

import BlogListScreen from "./Screens/Blog/BlogListScreen";
import PostPage from "./Screens/Blog/PostPage/PostPage";
import WritePost from "./Screens/Blog/write/WritePost";
import EditPost from "./Screens/Blog/EditPost/EditPost";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import ThankYouScreen from "./Screens/Bootcamp/ThankYouScreen";
import RewardsInfoScreen from "./Screens/Rewards/RewardsinfoScreen";
import TermsOfUseScreen from "./Screens/LegalityScreens/TermsOfUseScreen";
import DispensarySearchScreen from "./Screens/DispensarySearch/DispensarySearchScreen";
import CBDSearchScreen from "./Screens/CbdSearchScreen/CBDSearchScreen";
import ClaimBrandScreen from "./Screens/ClaimBrandScreen";
import BugTrackerFormScreen from "./Screens/BugTrackerFormScreen";
import VendorScreen from "./Screens/Vendors/VendorScreen";
import VendorInfoScreen from "./Screens/Vendors/VendorInfoScreen";
import AgeVericationScreen from "./Screens/AgeVerification/AgeVerification";

//This is the App this is where all the super important things for our app and frontend happen'
function App() {
  const [show, setShow] = useState(false);
  const [keepClosed, setKeepedClosed] = useState(false);
  const [daysElapsed, setDaysElapsed] = useState(8);

  const handleClose = () => {
    localStorage.setItem("declinedSignUpDate", new Date());

    setShow(false);
    setKeepedClosed(true);
  };
  const handleShow = () => setShow(true);
  // const local = "local";
  var nowSession = new Date();
  LogRocket.init("dsv6yt/merp");

  LogRocket.identify("dsv6yt/merp", {
    name: String(nowSession),

    // Add your own custom user variables here, ie:
    subscriptionType: "pro",
  });
  const [frequency, setFrequency] = useState("");
  const [intake, setIntake] = useState("");
  const [price, setPrice] = useState(false);
  const [quality, setQuality] = useState(false);
  const [potent, setPotent] = useState(false);
  const [ethical, setEthical] = useState(false);
  const [review, setReview] = useState(false);

  // I would look up React hooks to get familar with the ones you will see in many of the files  like 'useSelector()'
  const cart = useSelector((state) => state.cart);

  const emailSignup = useSelector((state) => state.emailSignup);
  const { newEmail } = emailSignup;

  // const ageVerification = localStorage.getItem("ageVerified");
  // const verifiedDate = localStorage.getItem("verifiedDate");
  // const [ageVerified, setAgeVerified] = useState(false);

  // const checkAgeVerification = (ageVerification, verifiedDate) => {
  //   if (ageVerification) {
  //     if (verifiedDate) {
  //       let date1 = new Date(verifiedDate);
  //       let date2 = new Date();
  //       let elapsedDays = date2.getTime() - date1.getTime();
  //       const checkExpiration = elapsedDays / (1000 * 60 * 60 * 24);
  //       if (checkExpiration <= 30) setAgeVerified(true);
  //       else {
  //         localStorage.removeItem("verifiedDate");
  //         setAgeVerified(false);
  //       }
  //     }
  //   } else {
  //     setAgeVerified(false);
  //   }
  // };

  // let location = {};
  // const getGeolocation = () => {
  //   if ("geolocation" in navigator) {
  //     // check if geolocation is supported/enabled on current browser
  //     navigator.geolocation.getCurrentPosition(
  //       function success(position) {
  //         // for when getting location is a success
  //         const lat = position.coords.latitude;
  //         const long = position.coords.longitude;
  //         location = { latitude: lat, longitude: long };
  //       },
  //       function error(error_message) {
  //         // for when getting location results in an error
  //         console.error(
  //           `An error has occured while retrieving
  //                           location`,
  //           error_message
  //         );
  //       }
  //     );
  //   } else {
  //     // geolocation is not supported
  //     // get your location some other way
  //
  //   }
  // };
  // getGeolocation();

  // const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  // const [saveThirtyDays, setSaveThirtyDays] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [subscribeToMailing, setSubscribeToMailing] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailList, setEmailList] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const { cartItems } = cart;
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const first = userInfo ? userInfo.firstName : userInfo;

  const signoutHandler = () => {
    dispatch(signout());
  };

  // const runAgeVerification = () => {
  //   const arg = saveThirtyDays
  //     ? localStorage.setItem("verifiedDate", new Date())
  //     : "";
  //   localStorage.setItem("ageVerified", true);

  //   setAgeVerified(true);
  // };

  // const firstName= userInfo ? userInfo.name.split(" ") : userInfo
  const submitHandlerFunction = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Password and password confirmation do NOT match");
    }
    if (!agreeToTerms) {
      alert(
        "You must read and agree to Terms before signing up and while accessing the site"
      );
    } else {
      dispatch(
        register({ firstName, lastName, email, password, subscribeToMailing })
      );
    }
  };

  const userRegister = useSelector((state) => state.userRegister);
  const {
    success,
    loading: loadingUserRegister,
    error: errorUserRegister,
  } = userRegister;

  useEffect(() => {
    dispatch(listProductCategories());
    function getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    }
    if (localStorage.getItem("declinedSignUpDate")) {
      let elapsedDays = getNumberOfDays(
        new Date(localStorage.getItem("declinedSignUpDate")),
        new Date()
      );
      setDaysElapsed(elapsedDays);
    }
    if (!keepClosed && !userInfo && daysElapsed > 7) {
      const timer = setTimeout(() => handleShow(), 5000);
      return () => clearTimeout(timer);
    }

    if (newEmail) {
      setEmailList("");
    }
    // checkAgeVerification(ageVerification, verifiedDate);
  }, [dispatch, newEmail, keepClosed, daysElapsed]);

  return (
    <BrowserRouter>
      <header>
        <div className="nav navbar navbar-expand-lg">
          <div className=" container navbar-inner">
            <div className="nav-left">
              <div className="nav-logo">
                {/* <button
                type="button"
                onClick={() => setSidebarIsOpen(true)}
                className="open-sidebar">
                <i className="fa fa-bars"></i>
              </button> */}
                <Link to="/">
                  <img src="/merp_svg_2.svg" alt="merp logo" />
                </Link>
              </div>
              <div className="nav-list-brand">
                <Link
                  to="/brand_listing_info"
                  style={{
                    padding: 0,
                    marginLeft: "10px",
                  }}
                >
                  list on Merp
                </Link>
              </div>
            </div>
            <span className="search-bar-md">
              <div>
                <Route
                  render={({ history }) => (
                    <SearchBox history={history}></SearchBox>
                  )}
                />
              </div>
            </span>
            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <ul className="nav-row ">
                  {userInfo ? (
                    <li class="nav-item dropdown">
                      <Link
                        to="#"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {first}
                      </Link>
                      {/* <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Dropdown link
                     </a> */}
                      <ul
                        className="dropdown-content"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            User Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/orderhistory">
                            {" "}
                            Order History
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to={`/rewards`}>
                            {" "}
                            Rewards
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#signout"
                            className="dropdown-item"
                            onClick={signoutHandler}
                          >
                            Sign Out
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    // <li>
                    //   <div class="nav-item dropdown">
                    //     <Link to="#" class="nav-link dropdown-toggle">
                    //       {firstName[0]}
                    //     </Link>
                    //     <ul class="dropdown-menu">
                    //       <li class="dropdown-item">
                    //         <Link to="/profile">User Profile</Link>
                    //       </li>
                    //       <li class="dropdown-item">
                    //         <Link to="/orderhistory"> Order History</Link>
                    //       </li>
                    //       <li class="dropdown-item">
                    //         <Link to={`/rewards`}> Rewards</Link>
                    //       </li>
                    //       <li class="dropdown-item">
                    //         <Link to="#signout" onClick={signoutHandler}>
                    //           Sign Out
                    //         </Link>
                    //       </li>
                    //     </ul>
                    //   </div>
                    // </li>
                    <li>
                      <Link to="/signin">Sign In</Link>
                    </li>
                  )}
                  {/* <li>
                    <a href="#" className="mission-nav">
                      Mission
                    </a>
                  </li> */}
                  {userInfo && userInfo.isSeller && (
                    <li className="nav-item dropdown">
                      <Link
                        to="#admin"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Seller
                      </Link>
                      <ul className="dropdown-content">
                        <li>
                          <Link to={`/seller/${userInfo._id}/dashboard`}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link to="/seller/sellerProfile">Seller Profile</Link>
                        </li>
                        <li>
                          <Link to="/productlist/seller">Products</Link>
                        </li>
                        {userInfo.seller.dispensary && (
                          <li>
                            <Link to="/orderlist/seller">Orders</Link>
                          </li>
                        )}
                        <li className="row">
                          <Link to="/brands"></Link>

                          <Link to="#">Payment Details</Link>
                        </li>
                      </ul>
                    </li>
                  )}

                  {userInfo && userInfo.isAdmin && (
                    <li className="nav-item dropdown">
                      <Link
                        to="#admin"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Admin
                      </Link>
                      <ul
                        className="dropdown-content "
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <li>
                          <Link className="dropdown-item" to="/dashboard">
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/blog/write">
                            add blog post
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/brandlist">
                            Brands
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/bug_tracker">
                            Bug Form
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/dispensaries_list"
                          >
                            Dispensaries
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/productlist">
                            Products
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/orderlist">
                            Orders
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/userlist">
                            Users
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/subscribers">
                            Subscribers
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#signout"
                            className="dropdown-item"
                            onClick={signoutHandler}
                          >
                            Sign Out
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  <li>
                    <Link to="/cart" style={{ padding: 0 }}>
                      <div id="ex4">
                        <span
                          className="p1 fa-stack fa-lg has-badge"
                          data-count={cartItems.length}
                        >
                          <i className="cart fa fa-shopping-cart fa-xs"></i>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <span className="search-bar">
          <div style={{ backgroundColor: "#D89947", width: "100%" }}>
            <div>
              <Route
                render={({ history }) => (
                  <SearchBox history={history}></SearchBox>
                )}
              />
            </div>
          </div>
        </span>
      </header>
      <div>
        <div className="nav-header">
          <section className="middle scroll">
            {/* <span className="item">
                  <Link className="link" to={`/search/shop/${local}`}>
                    Local
                  </Link>
                </span>{" "} */}
            {/* <Link to="/search?shop=mothers_day">
              <span className="item" style={{ color: "green" }}>
                <u>
                  <i>
                    <strong>Mother's Day</strong>
                  </i>
                </u>
              </span>
            </Link> */}
            {/* <span className="item">
              <Link className="link" to="/cannabis_products">
                Brands
              </Link>
            </span> */}
            <span className="item">
              {" "}
              <Link to={`/dispensaries`}>Dispensaries</Link>
            </span>
            <span className="item">
              <Link className="link" to="/merchandise">
                Merchandise
              </Link>
            </span>

            {/* <span className="item">
                  <Link className="link" to="/search/shop/petFriendly">
                    Pet-friendly CBD
                  </Link>{" "}
                </span> */}
            {/* <span className="item">
                  <Link className="link" to="/search/shop/householdHemp">
                    Household Hemp
                  </Link>
                </span> */}
            <span className="item">
              <Link
                className="link"
                to="/search?advancedCategory=isSustainable"
              >
                Sustainable
              </Link>
            </span>
            <span className="item">
              <Link
                className="link"
                to="/search?advancedCategory=isMinorityOwned"
              >
                Minority Owned
              </Link>
            </span>
            <span className="item">
              <Link className="link" to="/search?advancedCategory=coop">
                Co-ops
              </Link>
            </span>
            <span className="item">
              <Link
                className="link"
                to="/search?advancedCategory=equityVerified"
              >
                Equity Verified
              </Link>
            </span>
            {/* <span className="item">
                  <i>
                    <u>
                      <strong>New</strong>
                    </u>
                  </i>
                </span> */}
          </section>
        </div>
      </div>
      <main>
        <>
          <Modal className="signup-modal" show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {/* {!success ? (
                <form className="modal-form" onSubmit={submitHandlerFunction}> */}
              <div style={{ textAlign: "center" }}>
                <img
                  className="modal-img"
                  src="/images/merp_social_profile.png"
                  alt="merp logo"
                />
                <div className="modal-header">Hey! Want Free Gifts?</div>
                <div
                  className="modal-text"
                  style={{ margin: 5, fontSize: "1.5rem" }}
                >
                  <p>
                    <Link to="/register">Signup </Link> today for all the best
                    deals and products!
                  </p>
                  <p>
                    Plus, you also instantly become a rewards member and where
                    you can points towards{" "}
                    <Link to="/merchandise">merp merchandise</Link> just for
                    reviewing products you already enjoy!
                  </p>
                  <Link to="/register">
                    <button
                      style={{
                        fontSize: "1.5rem",
                        padding: "1rem 1.25rem 1rem 1.25rem",
                        border: "1px solid transparent",
                        borderRadius: "20px",
                        backgroundColor: "#93a1e5",
                        color: "#fff9e5",
                        fontWeight: "bold",
                      }}
                    >
                      Sign Me Up!
                    </button>
                  </Link>
                </div>
                {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "1.5rem",
                    }}
                  >
                    <div style={{ width: "25rem", textAlign: "left" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 5,
                          fontSize: "1.5rem",
                        }}
                      >
                        <strong>
                          <label>First</label>
                        </strong>
                        <input
                          style={{ fontSize: "1.5rem" }}
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "1.5rem",
                          margin: 5,
                        }}
                      >
                        <strong>
                          <label>Last</label>{" "}
                        </strong>
                        <input
                          style={{ fontSize: "1.5rem" }}
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          fontSize: "1rem",
                          flexDirection: "column",
                          margin: 5,
                        }}
                      >
                        <strong>
                          <label htmlFor="password">Password</label>
                        </strong>
                        <input
                          style={{ fontSize: "1.5rem" }}
                          type="password"
                          id="password"
                          placeholder="enter password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        ></input>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 5,
                          fontSize: "1.5rem",
                        }}
                      >
                        <strong>
                          <label htmlFor="confirmPassword">
                            {" "}
                            Confirm Password
                          </label>
                        </strong>
                        <input
                          type="password"
                          id="confirmPassword"
                          placeholder="please confirm password"
                          required
                          style={{ fontSize: "1.5rem" }}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        ></input>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 5,
                          fontSize: "1.5rem",
                        }}
                      >
                        <strong>
                          <label>Email</label>
                        </strong>
                        <input
                          type="text"
                          style={{ fontSize: "1.5rem" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div style={{ textAlign: "center", marginTop: "2rem" }}>
                        <div className="register-title">Canna Profile</div>
                        <p style={{ fontWeight: "unset" }}>
                          When you review your "Canna Profile" will appear and
                          let's others see what kind of canna consumer you to
                          better gauge how the same product might impact them
                          based on their consumption habits!
                        </p>
                      </div>
                      <div>
                        <label htmlFor="frequency">
                          *How often do you use/consume cannabis (containing
                          THC)
                        </label>
                        <br />
                        <select
                          id="frequency"
                          value={frequency}
                          style={{ fontSize: "1.5rem" }}
                          onChange={(e) => setFrequency(e.target.value)}
                          required
                        >
                          <option value="">Select One</option>
                          <option value="never">I new to this</option>
                          <option value="CBD">I stick to CBD</option>
                          <option value="yearly">
                            A few times a year (uh barely!)
                          </option>
                          <option value="monthly">
                            Once or twice a month (just a little treat)
                          </option>
                          <option value="weekly">
                            {" "}
                            Once or twice a week (ok!ok! We see you!)
                          </option>
                          <option value="daily">
                            {" "}
                            Daily (Hell yea! We don't judge)
                          </option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="intake">
                          *What is your go to consumption method?
                        </label>
                        <br />
                        <select
                          id="intake"
                          value={intake}
                          style={{ fontSize: "1.5rem" }}
                          onChange={(e) => setIntake(e.target.value)}
                          required
                        >
                          <option value="">Select One</option>
                          <option value="none">I'm new to add of this</option>
                          <option value="flower">Smoking (flower)</option>
                          <option value="concentrate">
                            Vaping (concentrate)
                          </option>
                          <option value="dab-concentrate">
                            {" "}
                            Dabs/Smokable Concentrate
                          </option>
                          <option value="Edible"> Edible</option>
                          <option value="tincture">Tincture</option>
                        </select>
                      </div>
                      <div>
                        <div>
                          What is important to you when selecting a product to
                          buy? (click all that apply)
                        </div>
                        <div
                          className="signup-checkbox"
                          style={{ flexDirection: "row" }}
                        >
                          <input
                            style={{ marginRight: 2 }}
                            type="checkbox"
                            checked={price}
                            onChangeCapture={(e) => setPrice(e.target.checked)}
                          />
                          <label> Price </label>
                        </div>
                        <div
                          className="signup-checkbox"
                          style={{ flexDirection: "row" }}
                        >
                          <input
                            style={{ marginRight: 2 }}
                            type="checkbox"
                            checked={quality}
                            onChangeCapture={(e) =>
                              setQuality(e.target.checked)
                            }
                          />
                          <label> Quality of product / ingredients</label>
                        </div>
                        <div
                          className="signup-checkbox"
                          style={{ flexDirection: "row" }}
                        >
                          <input
                            style={{ marginRight: 2 }}
                            type="checkbox"
                            checked={potent}
                            onChangeCapture={(e) => setPotent(e.target.checked)}
                          />
                          <label>Potency</label>
                        </div>
                        <div
                          className="signup-checkbox"
                          style={{ flexDirection: "row" }}
                        >
                          <input
                            style={{ marginRight: 2 }}
                            type="checkbox"
                            checked={ethical}
                            onChangeCapture={(e) =>
                              setEthical(e.target.checked)
                            }
                          />
                          <label>Brand ownership / business practices</label>
                        </div>
                        <div
                          className="signup-checkbox"
                          style={{ flexDirection: "row" }}
                        >
                          <input
                            style={{ marginRight: 2 }}
                            type="checkbox"
                            checked={review}
                            onChangeCapture={(e) => setReview(e.target.checked)}
                          />
                          <label>Reviews , Ratings , Recommendations</label>
                        </div>
                      </div>
                      <small
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginLeft: 3,
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <input
                            type="checkbox"
                            checked={subscribeToMailing}
                            onChangeCapture={(e) =>
                              setSubscribeToMailing(e.target.checked)
                            }
                          />
                          <label style={{ marginLeft: 3 }}>
                            Subscribe me to the Merp newsletter!
                          </label>
                        </div>
                        <div style={{ display: "flex" }}>
                          <input
                            id="agreeToTerms"
                            type="checkbox"
                            checked={agreeToTerms}
                            onChange={(e) => setAgreeToTerms(e.target.checked)}
                          ></input>
                          <label
                            style={{
                              fontWeight: "bold",
                              marginLeft: 3,
                              fontSize: "1rem",
                            }}
                          >
                            {"   "}
                            *By accessing this site you accept the{" "}
                            <Link to="/terms">
                              Terms of Use , Privacy Policy and Disclaimer
                            </Link>{" "}
                          </label>
                        </div>
                      </small>
                    </div>
                  </div> */}
                {/* <Button type="submit" variant="primary" class="modal-btn">
                    Submit
                  </Button> */}
                {/* </form>
              ) : ( */}
                {/* <div style={{ textAlign: "center" }}>
                  <img
                    className="modal-img"
                    src="/images/merp_social_profile.png"
                    alt="merp logo"
                  />
                  <div className="modal-header">You're Almost Done!</div>
                  <div className="modal-text" style={{ margin: 5 }}>
                    Please check your email for a link to confirm your email
                    address and complete registration. Hurry!{" "}
                    <strong>This link expires in 20 minutes</strong>!
                  </div>
                </div>
              )} */}
              </div>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
          </Modal>
        </>
        <Switch>
          <Route
            exact
            path="/age_verification"
            component={AgeVericationScreen}
          ></Route>
          <VerifiedRoute
            exact
            path="/dispensaries"
            component={DispensarySearchScreen}
          />
          <VerifiedRoute
            exact
            path="/dispensaries?page=:page"
            component={DispensarySearchScreen}
          />
          {/* <Route exact path="/seller/:id" component={SellerScreen} /> */}
          <VerifiedRoute exact path="/brands/:id" component={BrandScreen} />
          <VerifiedRoute exact path="/product/:id" component={ProductScreen} />
          <VerifiedRoute
            exact
            path="/companybadgeInfo/:id"
            component={CompanyBadgeScreen}
          />
          <VerifiedRoute
            exact
            path="/product/:id/edit"
            component={ProductEditScreen}
          />
          <AdminRoute
            exact
            path="/dispensary/:id/edit"
            component={DispensaryEditScreen}
          />
          <AdminRoute
            exact
            path="/brand/:id/edit"
            component={BrandEditScreen}
          />
          <AdminRoute exact path="/blog/write" component={WritePost} />
          <AdminRoute
            exact
            path="/bug_tracker"
            component={BugTrackerFormScreen}
          />

          <AdminRoute
            exact
            path="/subscribers"
            component={SubscriberListScreen}
          />
          <VerifiedRoute
            exact
            path="/rewards/info"
            component={RewardsInfoScreen}
          />
          <VerifiedRoute exact path="/cart" component={CartScreen} />
          <VerifiedRoute path="/signin" component={SignInScreen} />
          <VerifiedRoute path="/register" component={RegisterScreen} />

          <Route exact path="/vendors" component={VendorInfoScreen} />

          <Route exact path="/black_farmers_market" component={VendorScreen} />
          <VerifiedRoute path="/shipping" component={ShippingAddressScreen} />
          <VerifiedRoute
            path="/pickup_location"
            component={PickupLocationScreen}
          />
          <VerifiedRoute
            path="/confirm_payment_method"
            component={PickupPaymentMethodScreen}
          />
          <Route
            exact
            path="/thank_you"
            component={PaymentRecievedScreen}
          ></Route>
          <VerifiedRoute path="/payment" component={PaymentMethodScreen} />
          <VerifiedRoute path="/order_summary" component={OrderSummaryScreen} />
          <VerifiedRoute path="/placeorder" component={PlaceOrderScreen} />
          <VerifiedRoute path="/orderhistory" component={OrderHistoryScreen} />
          <VerifiedRoute path="/order/:id" component={OrderDetailsScreen} />
          {/* <Route path="/budtender" component={BudtenderScreen} /> */}
          <Route exact path="/about" component={AboutUsScreen} />
          {/* <VerifiedRoute exact path="/team" component={TeamScreen} /> */}
          <VerifiedRoute exact path="/contact" component={ContactUsScreen} />
          <VerifiedRoute
            exact
            path="/brand_listing_info"
            component={ListWithUsBrandInfoScreen}
          />
          <VerifiedRoute
            exact
            path="/dispensary_listing_info"
            component={ListWithUsDispensaryInfoScreen}
          />
          <Route
            exact
            path="/education_&_resources"
            component={EducationalListScreen}
          />

          <VerifiedRoute
            exact
            path="/education/routes_of_cannabis_administration"
            component={CannabisAdministrationInfoScreen}
          />
          <Route exact path="/education/taxes" component={TaxesScreen} />
          <VerifiedRoute path="/search" component={SearchScreen} />
          <VerifiedRoute
            exact
            path="/search?advancedCategory=:advancedCategory"
            component={SearchScreen}
          />
          <VerifiedRoute
            exact
            path="/search?shop=:shop"
            component={SearchScreen}
          />
          <VerifiedRoute exact path="/search?q=" component={SearchScreen} />
          <VerifiedRoute
            exact
            path="/merchandise"
            component={MerchandiseSearchScreen}
          />
          <VerifiedRoute
            exact
            path="/merchandise?advancedCategory=:advancedCategory"
            component={MerchandiseSearchScreen}
          />
          <VerifiedRoute
            exact
            path="/cannabis_products"
            component={CBDSearchScreen}
          />
          <VerifiedRoute
            exact
            path="/cannabis_products?page=:page"
            component={CBDSearchScreen}
          />
          {/* <Route
              exact
              path="/search/category/:category"
              component={SearchScreen}
            />
            <Route
              exact
              path="/search/category/:category/name/:name"
              component={SearchScreen}
            />
            <Route
              exact
              path="/search/category/:category/name/:name/min/shop/:shop/:min/max/:max/rating/:rating/order/:order/pageNumber/:pageNumber"
              component={SearchScreen}
            /> */}
          <PrivateRoute path="/profile" component={ProfileScreen} />
          <PrivateRoute path={`/rewards`} component={RewardsScreen} />
          <AdminRoute path="/blog/edit/:id" exact component={EditPost} />
          <AdminRoute
            path="/dispensaries_list"
            exact
            component={DispensaryListScreen}
          />
          <AdminRoute
            path="/dispensaries_list?page=:page"
            exact
            component={DispensaryListScreen}
          />

          <AdminRoute path="/productlist" exact component={ProductListScreen} />
          <AdminRoute
            path="/productlist/pageNumber/:pageNumber"
            exact
            component={ProductListScreen}
          />
          <AdminRoute
            path="/brandlist/pageNumber/:pageNumber"
            exact
            component={BrandListScreen}
          />
          <AdminRoute path="/orderlist" exact component={OrderListScreen} />

          <AdminRoute path="/userlist" exact component={UserListScreen} />
          <AdminRoute path="/user/:id/edit" exact component={UserEditScreen} />
          <AdminRoute exact path="/brandlist" component={BrandListScreen} />
          {/* <AdminRoute exact path="/emailing" component={EmailScreen} /> */}
          {/* <SellerRoute
                path="/seller/:id/dashboard"
                exact
                component={SellerDashboardScreen}
              /> */}
          <SellerRoute
            exact
            path="/productlist/seller"
            component={ProductListScreen}
          />
          <SellerRoute
            exact
            path="/orderlist/seller"
            component={OrderListScreen}
          />
          <SellerRoute exact path="/invoice" component={InvoiceDetailsScreen} />
          <SellerRoute
            exact
            path="/seller/sellerProfile"
            component={SellerProfileScreen}
          />
          {/* <Route exact path="/seller/:id" component={SellerScreen} /> */}
          <VerifiedRoute
            exact
            path="/dispensary/:id"
            component={DispensaryScreen}
          />
          <VerifiedRoute
            exact
            path="/products/all_products"
            component={AllProductsScreen}
          />
          <VerifiedRoute
            exact
            path="/search/shop/:shop?"
            component={SearchScreen}
          />
          <VerifiedRoute
            exact
            path="/search/category/:category/shop/:shop"
            component={SearchScreen}
          />
          {/* <Route
              exact
              path="/search/category/:category/shop/:shop/min/:min/max/:max/rating/:rating/order/:order/pageNumber/:pageNumber"
              component={SearchScreen}
            /> */}
          <Route exact path="/refunds" component={RefundPolicyScreen} />
          <Route
            exact
            path="/validate_account/:token"
            component={EmailValidationScreen}
          />
          <Route exact path="/email_sent" component={EmailSentScreen} />
          <Route
            exact
            path="/forgot_password"
            component={ResetPasswordScreen}
          />
          <Route exact path="/password_reset" component={PasswordResetScreen} />
          <Route
            exact
            path="/password_reset/:token"
            component={EnterNewPasswordScreen}
          />
          <Route exact path="/terms" component={TermsOfUseScreen} />
          <Route
            exact
            path="/education_&_resources/:type"
            component={JobResourceScreen}
          />
          <Route
            exact
            path="/crisis_resources/sexual_assault"
            component={SAResourceScreen}
          />
          <Route
            exact
            path="/crisis_resources/drug_and_alcohol"
            component={DrugAndAlcoholResourceScreen}
          />
          <Route
            exact
            path="/education_&_resources/job"
            component={JobResourceScreen}
          />
          {/* <Route
                exact
                path="/education_&_resources/legal"
                component={LegalResourcesScreen}
              />
            <Route
                exact
                path="/education_&_resources/housing"
                component={HousingResourcesScreen}
              /> */}

          <VerifiedRoute exact path="/blog" component={BlogListScreen} />
          <VerifiedRoute exact path="/blog/:id" component={PostPage} />
          <VerifiedRoute
            exact
            path="/claim_brand"
            component={ClaimBrandScreen}
          />
          {/* <Route exact path="/badges" component={BadgeInfoScreen}/> */}
          {/* <Route
            exact
            path="/merp_team/symone_varnado"
            component={SymoneVarnado}
          /> */}
          <Route exact path="/equity_efforts" component={MerpEquityScreen} />

          <VerifiedRoute path="/" component={HomeScreen} />

          <VerifiedRoute path="*" component={NotFoundPageScreen} />
        </Switch>
      </main>
      <footer>
        <div className="container-fluid px-5 contact-socials">
          <div className="row" style={{ margin: "2rem" }}>
            <div className="col-md-4 col-sm-12 text-white ">
              <div className="footer-heading">Contact Us</div>
              <div className="row pl-md-1 ">
                <div className="col-12 footer-item">
                  <i className="fa fa-home px-md-2"></i>
                  <>Chicago, IL</>
                </div>
              </div>
              <div className="row pl-md-1 t py-4 footer-item">
                <div className="col-12">
                  <i className="fa fa-paper-plane px-md-2"></i>
                  <>
                    <Link to="/">www.merp.page</Link>
                  </>
                </div>
              </div>
              <div className="row pl-md-1 ">
                <div className="col-12 footer-item">
                  <i className="fa fa-envelope px-md-2"></i>
                  <>info@merp.page</>
                </div>
              </div>
              <div className="row social">
                <div className="col-12 py-3">
                  <a
                    href="https://twitter.com/merp_inc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/merp_inc/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/merp-inc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    href="https://open.spotify.com/user/8nytib10m2r1fo4l406te43ho?si=uuZWSzRSQBKYKv0tkls0lQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-spotify"></i>
                  </a>
                  <a
                    href="https://m.tiktok.com/h5/share/usr/6919573499464778757.html?_d=secCgYIASAHKAESMgowrlcihl5eBu%2F6N15LvwhBkjbO4fgRE0IfHipzDCt9%2BOsVoipfZnoVXTdTmWadH%2Fm%2FGgA%3D&language=en&sec_uid=MS4wLjABAAAAhl9kcDx1G7vT4DTB-tilPpvlzcYQS1PUbmpXDnu3FG6zsvfjAS-Jl9zMoMdJOvhj&sec_user_id=MS4wLjABAAAAhl9kcDx1G7vT4DTB-tilPpvlzcYQS1PUbmpXDnu3FG6zsvfjAS-Jl9zMoMdJOvhj&share_author_id=6919573499464778757&share_link_id=31F9F9A7-9F1C-45B5-8C1D-E8CA57A2F398&tt_from=sms&u_code=dgh6b8bc4eielm&user_id=6919573499464778757&utm_campaign=client_share&utm_medium=ios&utm_source=sms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-tiktok"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 extras">
              <div className="footer-heading">Merp</div>
              <div className="d-flex extras flex-column pl-md-3">
                <div className="footer-item">
                  <Link to="/about">About</Link>
                </div>
            {/* {   <div className="footer-item">
                  <Link to="/team">Team</Link>
                </div>} */}
                {/* <small>
                  <Link to="/badges">Our Badges</Link>
                </small> */}

                <div className="footer-item">
                  <Link to="/equity_efforts">Social Equity Efforts </Link>
                </div>
                <div className="footer-item">
                  <Link to="/contact">Contact Us</Link>
                </div>
                <div className="footer-item">
                  <Link to="/brand_listing_info">List a brand</Link>
                </div>
                <div className="footer-item">
                  <Link to="/dispensary_listing_info">List a dispensary</Link>
                </div>
                <div className="footer-item">
                  <Link to="/terms">
                    Terms of Use , Privacy Policy and Disclaimer
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 extras">
              <div className="footer-heading">Education & Blog</div>
              <div className="d-flex extras flex-column pl-md-3">
                {/* <small>
                  <Link to="/badges">Our Badges</Link>
                </small> */}
                <div className="footer-item">
                  <Link to="/education_&_resources">Education & Resources</Link>
                </div>

                <div className="footer-item">
                  <Link to="/blog">Blog</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </BrowserRouter>
  );
}

export default App;
