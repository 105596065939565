import React from 'react'
import { Link } from 'react-router-dom';

export default function DrugAndAlcohol() {
  
  return (
    <div className="shop-image" id="woman_owned">
      {/* <img src="/images/woman_owned_badge.png" alt="shop-bipoc" /> */}
      <div className="details">
        <h2>
           <span>Drug and Alcohol Addiction Resources</span> 
        </h2>
        <p>
          If you feel you may struggle with using alcohol or drugs too frequently and you would categorize it as an addiction, we have provided resources to get support for your addiction.
        </p>
        <div className="more">
          <Link to={'/crisis_resources/drug_and_alcohol'} className="read-more">
            Browse <span>Now</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
