import React from "react";

export default function EmailSentScreen() {
  return (
    <div style={{textAlign:"center", paddingTop:"4rem", margin:"auto"}}>
                <img
                    className="modal-img-screen"
                    src="/images/merp_social_profile.png"
                    alt="merp logo"
                  />
                  <div style={{padding:"2rem"}}>
                  <div className="modal-header">You're Almost Done!</div>
                  <div className="modal-text" style={{ margin: 5 }}>
                   Please check your email for a link to confirm your email address and complete registration. Hurry! <strong>This link expires in 20 minutes</strong>!
                  </div>
                  </div>
                  </div>
  );
}
