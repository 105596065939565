import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckoutSteps from "../components/CheckoutSteps";
import { saveShippingAddress } from "../actions/cartActions";

export default function PickupLocationScreen(props) {
  const dispatch = useDispatch()
  const userSignin = useSelector((state) => state.userSignin);
  // const { userInfo } = userSignin;
  const cart = useSelector((state) => state.cart);

  const { cartItems } = cart;
  const address = cartItems[0].seller.seller.companyAddress;


  const confirmHandler = () => {
    dispatch(saveShippingAddress({ fullName:address.fullName, address:address.address, city:address.city,state:address.state, postalCode:address.postalCode, country:address.country }))
    props.history.push("/confirm_payment_method");
  };
  //   const {shippingAddress}=cart
  // if (!userInfo) {
  //   props.history.push("/signin");
  // }
  return (
    <div>
      <CheckoutSteps step1 step2 includesTHC={true}></CheckoutSteps>
      <div>
        <div>
          <div className="row center">
            <img
              className="storefront"
              src="/images/storefront.png"
              alt="storefront"
            />
          </div>
          <div className="dispensary_info_card">
            <h4>Dispensary Name</h4>
            {address.fullName}
            <h4>Dispensary Location</h4>
            {address.address}
            <br />
            {address.city},{address.state} {address.postalCode}
          </div>
        </div>
        <div className="row center">
          <br />
          <br/>
          <button type="button" onClick={confirmHandler} className="primary">
            Confirm
          </button>
        </div>
        <div className="disclaimer row center">
          Icons made by{" "}
          <a
            href="https://www.flaticon.com/authors/vectors-market"
            title="Vectors Market">
            Vectors Market
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </div>
    </div>
  );
}
