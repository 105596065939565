export const EVENT_SIGNUP_REQUEST = "EVENT_SIGNUP_REQUEST"
export const EVENT_SIGNUP_SUCCESS="EVENT_SIGNUP_SUCCESS"
export const EVENT_SIGNUP_FAILURE ="EVENT_SIGNUP_FAILURE"

export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST"
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS"
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE"

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST"
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS"
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE"

export const TICKET_PURCHASE_REQUEST ="TICKET_PURCHASE_REQUEST"
export const TICKET_PURCHASE_SUCCESS ="TICKET_PURCHASE_SUCCESS"
export const TICKET_PURCHASE_FAILURE="TICKET_PURCHASE_FAILURE"

export const TICKET_ORDER_REQUEST ="TICKET_ORDER_REQUEST"
export const TICKET_ORDER_SUCCESS ="TICKET_ORDER_SUCCESS"
export const TICKET_ORDER_FAILURE="TICKET_ORDER_FAILURE"