import React from 'react'
import { Link } from 'react-router-dom';

export default function SA() {
   
  return (
    <div className="shop-image" id="woman_owned">
      {/* <img src="/images/woman_owned_badge.png" alt="shop-bipoc" /> */}
      <div className="details">
        <h2>
         <span>SA Resources</span> 
        </h2>
        <p>
          We understand cannabis, unfortunately, may play a role in sexual assaults, so we've provided resources for those who have been sexually assaulted.
        </p>
        <div className="more">
          <Link to={'/crisis_resources/sexual_assault'} className="read-more">
            Browse <span>Now</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
