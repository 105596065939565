import React, { useEffect, useState } from "react";
import Rating from "../components/Rating";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { detailProduct, createReview } from "../actions/productActions";
import { addItemToCart } from "../actions/cartActions";
import { Image } from "cloudinary-react";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import { Alert } from "react-bootstrap";

const ProductScreen = (props) => {
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const productId = props.match.params.id;
  const cart = useSelector((state) => state.cart);
  const { error: errorCart } = cart;
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(0);
  const [size, setSize] = useState("");
  const [isRelaxed, setIsRelaxed] = useState(false);
  const [show, setShow] = useState(false);
  const [isAroused, setIsAroused] = useState(false);
  const [isAnxious, setIsAnxious] = useState(false);
  const [isParanoid, setIsParanoid] = useState(false);
  const [isEnergized, setIsEnergized] = useState(false);
  const [isHungry, setIsHungry] = useState(false);
  const [isCreative, setIsCreative] = useState(false);
  const [painRelief, setPainRelief] = useState(false);
  const [isEuphoric, setIsEuphoric] = useState(false);
  const [isConfident, setIsConfident] = useState(false);
  const [isSleepy, setIsSleepy] = useState(false);
  const [feltNothing, setFeltNothing] = useState(false);
  const [item, setItem] = useState({});
  // const [slept, setSlept] = useState(false)
  // const [intimate, setIntimate] = useState(false)
  // const [socialized, setSocialized] = useState(false)
  // const [danced, setDanced] = useState(false)

  const { product, loading, error } = productDetails;

  const productCreateReview = useSelector((state) => state.productCreateReview);
  const {
    loading: loadingReviewCreate,
    error: errorReviewCreate,
    success: successReviewCreate,
  } = productCreateReview;

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    dispatch(detailProduct(productId));

    //remove this before pushing to production
    if (product) {
      setPrice(product.price);
    }
    if (successReviewCreate) {
      window.alert("review submitted successfully");
      setRating(0);
      setComment("");
      setIsRelaxed(false);
      setIsAroused(false);
      setIsAnxious(false);
      setIsParanoid(false);
      setIsEnergized(false);
      setIsHungry(false);
      setIsCreative(false);
      setPainRelief(false);
      setIsEuphoric(false);
      setIsSleepy(false);
      setFeltNothing(false);
      setIsConfident(false);
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
  }, [dispatch, productId, successReviewCreate]);

  const addCartItem = (product) => {
    if (size === "" && product.sizes && product.sizes.length !== 0) {
      alert("please select a size");
    } else {
      setItem(product);
      setShow(true);
      dispatch(addItemToCart(product._id, product.price, qty, size));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (comment && rating) {
      dispatch(
        createReview(productId, {
          comment,
          rating,
          userId: userInfo._id,
          name: `${userInfo.firstName} ${userInfo.lastName[0]}`,
          effects: {
            isRelaxed,
            isAroused,
            isAnxious,
            isParanoid,
            isEnergized,
            isHungry,
            isCreative,
            painRelief,
            isEuphoric,
            isSleepy,
            isConfident,
            feltNothing,
          },
        })
      );
    } else {
      window.alert("please sign in ");
    }
  };
  return (
    <div>
      {show && (
        <div className="container-fluid " style={{ paddingTop: 10 }}>
          <Alert
            variant="success"
            onClose={() => setShow(false)}
            dismissible
            style={{ color: "black" }}
          >
            <Alert.Heading>Item added to cart!</Alert.Heading>
            <p>
              <strong>{item.name}</strong> added to cart.ready to{" "}
              <Link to="/cart">checkout </Link> ?
            </p>
          </Alert>
        </div>
      )}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className="container p-5">
          <Link to="/">Back to results</Link>
          {errorCart && <MessageBox variant="danger">{errorCart}</MessageBox>}

          <div className="row">
            <div className="col-md-4 col-12">
              <Image
                cloudName="merp"
                publicId={product.image}
                secure="true"
                width="300"
                crop="scale"
                className="img-fluid"
              />
            </div>
            <div className="col-md-4 col-12">
              <div>
                <ul>
                  <li>
                    <h1>{product.name}</h1>
                  </li>
                  <li>
                    <Rating
                      rating={product.rating}
                      numReviews={product.numReviews}
                    />
                  </li>

                  {/* {product.comingSoon ? (
                  <li>
                    <div> </div>
                  </li>
                ) : (
                  <li>Price: $ {product.price.toFixed(2)}</li>
                )} */}
                  <li>Category: {product.category}</li>
                  {/* <li>Weight: {product.weight.toFixed(2)} grams</li> */}
                  <li>
                    Description:
                    <p>{product.description}</p>
                    {product.category === "merchandise" && (
                      <>
                        <div className="disclaimer">
                          * At this time we are not allowing returns or exhanges
                          on Merchandise. We are sorry for any inconvience this
                          may cause.
                        </div>

                        <div className="disclaimer">
                          ** All Merchandise deisgns for our 'Catnip' line come
                          in 10" x 10" print, regardless of the size of the
                          actual apparel item. E.g. if you order a Medium or
                          3xxl the design will always be 10" x 10".
                        </div>
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div>
                <ul>
                  <li>
                    Brand{" "}
                    <h2>
                      <Link to={`/brands/${product.brand._id}`}>
                        {product.brand.name}
                      </Link>
                    </h2>
                    {/* <Rating
                        rating={product.seller.seller.rating}
                        numReviews={product.seller.seller.numReviews}></Rating> */}
                  </li>
                  {product && product.sizes && (
                    <li>
                      <div>
                        <label htmlFor="sizes">Please Select A Size</label>
                        <br />
                        <select
                          id="state"
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                        >
                          <option value="">Select One</option>
                          {product.sizes["XS"] <= 0 ? (
                            <option value="XS" disabled>
                              x-small
                            </option>
                          ) : product.sizes["XS"] < 4 &&
                            product.sizes["XS"] > 0 ? (
                            <option value="XS">
                              x-small ({`only ${product.sizes["XS"]} left!`})
                            </option>
                          ) : (
                            <option value="XS">x-small</option>
                          )}
                          {product.sizes["S"] === 0 ? (
                            <option value="S" disabled>
                              small
                            </option>
                          ) : product.sizes["S"] < 4 &&
                            product.sizes["S"] > 0 ? (
                            <option value="S">
                              small ({`only ${product.sizes["S"]} left!`})
                            </option>
                          ) : (
                            <option value="S">small</option>
                          )}
                          {product.sizes["M"] === 0 ? (
                            <option value="M" disabled>
                              medium
                            </option>
                          ) : product.sizes["M"] < 4 &&
                            product.sizes["M"] > 0 ? (
                            <option value="M">
                              medium ({`only ${product.sizes["M"]} left!`})
                            </option>
                          ) : (
                            <option value="M">medium</option>
                          )}
                          {product.sizes["L"] === 0 ? (
                            <option value="L" disabled>
                              large
                            </option>
                          ) : product.sizes["L"] < 4 &&
                            product.sizes["L"] > 0 ? (
                            <option value="L">
                              large ({`only ${product.sizes["L"]} left!`})
                            </option>
                          ) : (
                            <option value="L">large</option>
                          )}
                          {product.sizes["XL"] === 0 ? (
                            <option value="XL" disabled>
                              x-large
                            </option>
                          ) : product.sizes["XL"] < 4 &&
                            product.sizes["XL"] > 0 ? (
                            <option value="XL">
                              x-large ({`only ${product.sizes["XL"]} left!`})
                            </option>
                          ) : (
                            <option value="XL">x-large</option>
                          )}
                          {product.sizes["XXL"] === 0 ? (
                            <option value="XXL" disabled>
                              xx-large
                            </option>
                          ) : product.sizes["XXL"] < 4 &&
                            product.sizes["XXL"] > 0 ? (
                            <option value="XXL">
                              xx-large ({`only ${product.sizes["XXL"]} left!`})
                            </option>
                          ) : (
                            <option value="XXL">xx-large</option>
                          )}
                          {product.sizes["XXXL"] === 0 ? (
                            <option value="XXXL" disabled>
                              xxx-large
                            </option>
                          ) : product.sizes["XXXL"] < 4 &&
                            product.sizes["XXXL"] > 0 ? (
                            <option value="XXXL">
                              xxx-large ({`only ${product.sizes["XXXL"]} left!`}
                              )
                            </option>
                          ) : (
                            <option value="XXXL">xxx-large</option>
                          )}
                        </select>
                      </div>
                    </li>
                  )}
                  {product.countInStock > 0 && (
                    <>
                      {product.category === "Merchandise" && (
                        <li>
                          <div className="row-cart">
                            <div>Qty</div>
                            <div>
                              <select
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                              >
                                {[
                                  ...Array(Number(product.countInStock)).keys(),
                                ].map((x) => {
                                  return (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </li>
                      )}
                    </>
                  )}
                  {/* {product && product.comingSoon && (
                       <li>
                       <div className="row">
                         <div>Price:</div>
                         <div className="price">${product.price}</div>
                       </div>
                     </li>
                    )} */}
                  {/* {product && product.arrPrice.length === 0 ? ( */}
                  <li>
                    <div className="row-cart">
                      <div>Price:</div>{" "}
                      {product.price > 0 ? (
                        <div className="price">
                          $ {product.price.toFixed(2)}
                        </div>
                      ) : (
                        <div>N/A</div>
                      )}
                    </div>
                  </li>
                  {/* // ) : (
                    //   <li>
                    //     <div className="disclaimer">
                    //       This item is having a portion of its proceeds being
                    //       donated to a community organization offering support
                    //       to people and communities impacted by the War on
                    //       Drugs. We will automatically donate a set portion of
                    //       the proceeds from this item, on top of this any amount
                    //       over ${product.price} will be donated.(Ex.if you pay $
                    //       {product.arrPrice[1]} a portion of the base $
                    //       {product.price.toFixed(2)} + $
                    //       {(
                    //         Number(product.arrPrice[1]) - product.price
                    //       ).toFixed(2)}{" "}
                    //       is donated).
                    //     </div>

                    //     {product.arrPrice.map((p) => {
                    //       return (
                    //         <div>
                    //           <div>
                    //             <input
                    //               id={`price${[p]}`}
                    //               type="radio"
                    //               value={`${p}`}
                    //               checked={price === Number(p)}
                    //               onClick={() =>
                    //                 setPrice(Number(p))
                    //               }></input>{" "}
                    //             <label for={`price${[p]}`}>
                    //               ${Number(p).toFixed(2)}
                    //             </label>
                    //           </div>
                    //         </div>
                    //       );
                    //     })}
                    //   </li>
                    // )} */}
                  <li>
                    <div className="row-cart">
                      <div>Status</div>
                      <div>
                        {product.brand._id === "611c0c9e55c31fc522ed3f0a" &&
                        product.countInStock > 0 ? (
                          <span className="success">In Stock</span>
                        ) : product.brand.sold === "Online" &&
                          product.brand._id === "611c0c9e55c31fc522ed3f0a" &&
                          product.countInStock <= 0 ? (
                          <span className="danger">Out of Stock</span>
                        ) : product.brand._id === "60abfa60f740de33be5643b8" &&
                          product.countInStock > 0 ? (
                          <span className="success">In Stock</span>
                        ) : product.brand._id === "60abfa60f740de33be5643b8" &&
                          product.countInStock <= 0 ? (
                          <span className="danger">Out of Stock</span>
                        ) : (
                          <span>Please check where product is sold</span>
                        )}
                      </div>
                    </div>
                  </li>
                  {/* 
                  {product.category === "Merchandise" &&  !product.cbd_product && !product.includesTHC && (
                    <li>
                      {" "}
                      {product.countInStock === 0 ? (
                        <button disabled className="primary block">
                          Add to Cart
                        </button>
                      ) : (
                        <button
                          onClick={() => addCartItem(product)}
                          className="primary block"
                        >
                          Add to Cart
                        </button>
                      )}
                    </li>
                  ) } */}
                  {product.cbd_product ? (
                    <li>
                      {" "}
                      <a
                        href={product.productLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button
                          // onClick={() => console.log("birdie")}
                          className="primary block"
                        >
                          Order Product
                        </button>
                      </a>
                    </li>
                  ) : product.includesTHC ? (
                    <li>
                      {" "}
                      <a
                        href={product.productLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button
                          // onClick={() => console.log("birdie")}
                          className="primary block"
                        >
                          Checkout Product
                        </button>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <button
                        onClick={() => addCartItem(product)}
                        className="primary block"
                      >
                        Add to Cart
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div className="card card-body">
                <ul>
                  {/* <li>
                    Brand{" "}
                    <h2>
                      <Link to={`/brands/${product.brand._id}`}>
                        {product.brand.name}
                      </Link>
                    </h2> */}
                  {/* <Rating
                        rating={product.brand.rating}
                        numReviews={product.brand.numReviews}
                      ></Rating> */}
                  {/* </li> */}
                  {/* <li>
                    <div className="row-cart">
                      <div>Price:</div>{" "}
                      {product.price > 0 ? (
                        <div className="price">
                          $ {product.price.toFixed(2)}
                        </div>
                      ) : (
                        <div>N/A</div>
                      )}
                    </div>
                  </li> */}

                  {/* {product && product.category === "Merchandise" && (
                    <li>
                      <div className="row-cart">
                        <div>Status</div>
                        <div>
                          {product.countInStock > 0 ? (
                            <span className="success">In Stock</span>
                          ) : (
                            <span className="danger">Unavailable</span>
                          )}
                        </div>
                      </div>
                    </li>
                  )} */}
                </ul>
              </div>
            </div>
          </div>
          {product && product.sizeChart && product.sizeChart !== "" && (
            <div class="text-center pt-5">
              <h2>
                <strong>Size Chart</strong>
              </h2>
              <Image
                cloudName="merp"
                publicId={product.sizeChart}
                secure="true"
                crop="scale"
                className="img-fluid"
              />
            </div>
          )}
          <div style={{ textAlign: "center" }} id="reviews">
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingTop: ".5rem",
                paddingBottom: "1rem",
              }}
            >
              Reviews
            </div>
            {product && product.reviews.length === 0 && (
              <div>
                <span
                  className="reviews-text"
                  style={{
                    fontSize: "1.35rem",
                    fontWeight: "bold",
                    paddingBottom: "1rem",
                  }}
                >
                  There are currently no reviews, be the first and get a special
                  gift from Merp!
                </span>
              </div>
            )}
            <ul>
              {product.reviews.map((review) => (
                <li className="review-card" key={review._id}>
                  <Rating rating={review.rating} caption="personal"></Rating>
                  <strong className="review-card-name">{review.name}</strong>
                  <p className="review-card-date">
                    {review.createdAt.substring(0, 10)}
                  </p>

                  <p className="review-card-text">{review.comment}</p>
                </li>
              ))}
              <li>
                {userInfo ? (
                  <form className="form" onSubmit={submitHandler}>
                    <div>
                      <h2>Write A customer review</h2>
                    </div>
                    {(product.includesTHC || product.cbd_product) && (
                      <>
                        <div>
                          <h1>How did this product make you feel?</h1>
                          <div>
                            <input
                              id="relaxed"
                              type="checkbox"
                              checked={isRelaxed}
                              onChange={(e) => setIsRelaxed(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="relaxed">Relaxed</label>
                          </div>
                          <div>
                            <input
                              id="aroused"
                              type="checkbox"
                              checked={isAroused}
                              onChange={(e) => setIsAroused(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="aroused">Aroused</label>
                          </div>
                          <div>
                            <input
                              id="anxious"
                              type="checkbox"
                              checked={isAnxious}
                              onChange={(e) => setIsAnxious(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="anxious">Anxious</label>
                          </div>
                          <div>
                            <input
                              id="paranoid"
                              type="checkbox"
                              checked={isParanoid}
                              onChange={(e) => setIsParanoid(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="paranoid">Paranoid</label>
                          </div>
                          <div>
                            <input
                              id="energized"
                              type="checkbox"
                              checked={isEnergized}
                              onChange={(e) => setIsEnergized(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="energized">Energized</label>
                          </div>
                          <div>
                            <input
                              id="hungry"
                              type="checkbox"
                              checked={isHungry}
                              onChange={(e) => setIsHungry(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="hungry">Hungry</label>
                          </div>
                          <div>
                            <input
                              id="creative"
                              type="checkbox"
                              checked={isCreative}
                              onChange={(e) => setIsCreative(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="creative">Creative</label>
                          </div>
                          <div>
                            <input
                              id="confident"
                              type="checkbox"
                              checked={isConfident}
                              onChange={(e) => setIsConfident(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="condident">More confident</label>
                          </div>
                          <div>
                            <input
                              id="painRelief"
                              type="checkbox"
                              checked={painRelief}
                              onChange={(e) => setPainRelief(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="painRelief">Pain relief</label>
                          </div>
                          <div>
                            <input
                              id="euphoric"
                              type="checkbox"
                              checked={isEuphoric}
                              onChange={(e) => setIsEuphoric(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="euphoric">Euphoric</label>
                          </div>
                          <div>
                            <input
                              id="sleepy"
                              type="checkbox"
                              checked={isSleepy}
                              onChange={(e) => setIsSleepy(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="sleepy">Sleepy</label>
                          </div>
                          <div>
                            <input
                              id="feltNothing"
                              type="checkbox"
                              checked={feltNothing}
                              onChange={(e) => setFeltNothing(e.target.checked)}
                            ></input>
                            {"   "}
                            <label htmlFor="feltNothing">
                              Didn't feel anything
                            </label>
                          </div>
                        </div>
                        {/* <div>
                        <h1>What did you do?</h1>
                        <div>
                          <input
                            id="slept"
                            type="checkbox"
                            checked={slept}
                            onChange={(e) =>
                              setSlept(e.target.checked)
                            }></input>
                          {"   "}
                          <label htmlFor="slept">Slept</label>
                        </div>
                        <div>
                          <input
                            id="intimate"
                            type="checkbox"
                            checked={intimate}
                            onChange={(e) =>
                              setIntimate(e.target.checked)
                            }></input>
                          {"   "}
                          <label htmlFor="intimate">Got intimate</label>
                        </div>
                        <div>
                          <input
                            id="socialized"
                            type="checkbox"
                            checked={socialized}
                            onChange={(e) =>
                              setSocialized(e.target.checked)
                            }></input>
                          {"   "}
                          <label htmlFor="socialized">Socialized</label>
                        </div>
                        <div>
                          <input
                            id="danced"
                            type="checkbox"
                            checked={danced}
                            onChange={(e) =>
                              setDanced(e.target.checked)
                            }></input>
                          {"   "}
                          <label htmlFor="danced">Danced</label>
                        </div>
                      </div> */}
                      </>
                    )}
                    <div>
                      <label htmlFor="rating">Overal Rating</label>
                      <select
                        id="rating"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="1">1-Poor</option>
                        <option value="2">2-Fair</option>
                        <option value="3">3-Good</option>
                        <option value="4">4-Very Good</option>
                        <option value="5">5-Excellent</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="comment">Comment</label>
                      <textarea
                        id="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div>
                    <div>
                      <label />
                      <button className="primary" type="submit">
                        Submit
                      </button>
                      <div>
                        {loadingReviewCreate && <LoadingBox></LoadingBox>}
                        {errorReviewCreate && (
                          <MessageBox variant="danger">
                            {errorReviewCreate}
                          </MessageBox>
                        )}
                      </div>
                    </div>
                  </form>
                ) : (
                  <MessageBox>
                    Please <Link to="/signin">sign in</Link> to write a review
                  </MessageBox>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductScreen;
