import { applyMiddleware, compose, combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import thunk from "redux-thunk";
import {
  productListReducer,
  productDetailsReducer,
  productCreateReducer,
  productUpdateReducer,
  productDeleteReducer,
  productCategoryListReducer,
  productCreateReviewReducer,
  productBrandsReducer,
  brandDetailsReducer,
  dispensaryListReducer,
  dispensaryCreateReducer,
  dispensaryDetailsReducer,
  dispensaryDeleteReducer,
  dispensaryUpdateReducer,
} from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReducer";
import {
  userSignInReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userTopSellerListReducer,
  sellerChartDataReducer,
  emailValidationReducer,
  resetPasswordReducer,
  resetPasswordInfoReducer,
  referralReducer,
  notifyWhenNear,
  listWithUsReducer,
  emailSignupreducer,
  getQuestionReducer,
  castMyVoteReducer,
  submitQuestionReducer,
  userRewardsReducer,
} from "./reducers/userReducer";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  myOrderListReducer,
  orderListReducer,
  orderDeleteReducer,
  orderDeliverReducer,
} from "./reducers/orderReducers";
import {
  stateTaxRevenueReducer,
  stateJobsReducer,
} from "./reducers/educationReducer";
import {
  eventSignupReducer,
  eventsReducer,
  eventReducer,
  ticketPurchaseReducer,
  ticketOrderDetails,
} from "./reducers/eventReducer";
import {
  brandListReducer,
  brandCreateReducer,
  brandDeleteReducer,
  brandUpdateReducer,
  getBrandsReducer,
  getDispensaryBrandsReducer,
} from "./reducers/brandReducer";
import {
  blogCreateReducer,
  postsListReducer,
  postDetailsReducer,
  blogUpdateReducer,
  postDeleteReducer,
  blogUpdateLinksReducer,
} from "./reducers/blogReducer";

const initialState = {
  userSignin: {
    // get rid of userInfo on local storage eventually
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],

    // get rid of address info on local storage eventually
    shippingAddress: localStorage.getItem("shippingAddress")
      ? JSON.parse(localStorage.getItem("shippingAddress"))
      : {},
    paymentMethod: "Paypal",
  },
};
const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  userSignin: userSignInReducer,
  userRegister: userRegisterReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderMineList: myOrderListReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdate: userUpdateReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,
  orderDeliver: orderDeliverReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userTopSellersList: userTopSellerListReducer,
  productCategoryList: productCategoryListReducer,
  productCreateReview: productCreateReviewReducer,
  productBrands: productBrandsReducer,
  sellerChartData: sellerChartDataReducer,
  emailValidator: emailValidationReducer,
  resetPassword: resetPasswordReducer,
  resetPasswordInfo: resetPasswordInfoReducer,
  taxRevenue: stateTaxRevenueReducer,
  referralCode: referralReducer,
  eventSignup: eventSignupReducer,
  eventsList: eventsReducer,
  eventDetails: eventReducer,
  ticketPurchase: ticketPurchaseReducer,
  stateJobs: stateJobsReducer,
  ticketOrder: ticketOrderDetails,
  brandDetails: brandDetailsReducer,
  notifyNear: notifyWhenNear,
  listWith: listWithUsReducer,
  dispensaryList: dispensaryListReducer,
  dispensaryUpdate: dispensaryUpdateReducer,
  dispensaryCreate: dispensaryCreateReducer,
  dispensaryDetails: dispensaryDetailsReducer,
  dispensaryDelete: dispensaryDeleteReducer,
  brandList: brandListReducer,
  brandCreate: brandCreateReducer,
  userRewards: userRewardsReducer,
  brandDelete: brandDeleteReducer,
  brandUpdate: brandUpdateReducer,
  emailSignup: emailSignupreducer,
  blogCreate: blogCreateReducer,
  postsList: postsListReducer,
  postDetails: postDetailsReducer,
  blogUpdate: blogUpdateReducer,
  postDelete: postDeleteReducer,
  blogUpdateLinks: blogUpdateLinksReducer,
  currentQuestion: getQuestionReducer,
  castedVote: castMyVoteReducer,
  questionSubmitted: submitQuestionReducer,
  getAllbrands: getBrandsReducer,
  dispensaryBrands: getDispensaryBrandsReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
  { reducer },
  initialState,
  composeEnhancer(applyMiddleware(thunk, LogRocket.reduxMiddleware()))
);

export default store;
