export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL";

export const BRAND_DELETE_REQUEST = "BRAND_DELETE_REQUEST";
export const BRAND_DELETE_SUCCESS = "BRAND_DELETE_SUCCESS";
export const BRAND_DELETE_FAILURE = "BRAND_DELETE_FAILURE";
export const BRAND_DELETE_RESET = "BRAND_DELETE_RESET";

export const BRAND_CREATE_REQUEST = "BRAND_CREATE_REQUEST";
export const BRAND_CREATE_SUCCESS = "BRAND_CREATE_SUCCESS";
export const BRAND_CREATE_FAILURE = "BRAND_CREATE_FAILURE";
export const BRAND_CREATE_RESET = "BRAND_CREATE_RESET";

export const BRAND_UPDATE_REQUEST = "BRAND_UPDATE_REQUEST";
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS";
export const BRAND_UPDATE_FAILURE = "BRAND_UPDATE_FAILURE";
export const BRAND_UPDATE_RESET = "BRAND_UPDATE_RESET";

export const GET_BRANDS_REQUEST = "GET_BRANDS_REQUEST";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAIL = "GET_BRANDS_FAIL";

export const GET_DISPENSARY_BRANDS_REQUEST = "GET_DISPENSARY_BRANDS_REQUEST";
export const GET_DISPENSARY_BRANDS_SUCCESS = "GET_DISPENSARY_BRANDS_SUCCESS";
export const GET_DISPENSARY_BRANDS_FAILURE = "GET_DISPENSARY_BRANDS_FAILURE";

// export const SEARCH_BRAND_REQUEST = "SEARCH_BRAND_REQUEST";
// export const SEARCH_BRAND_SUCCESS = "SEARCH_BRAND_SUCCESS";
// export const SEARCH_BRAND_FAILURE = "SEARCH_BRAND_FAILURE";
