import React from "react";

export default function VendorInfoScreen() {
  return (
    <div style={{height:"1522"}}>
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
      <iframe
        class="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shrgamOsVGVTHPAt8?backgroundColor=red"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="1521"
       
      ></iframe>
    </div>
  );
}
