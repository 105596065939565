import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Dispensary from './Dispensary'

export default function DispensarySlider({dispensaries}) {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      };
    
      return (
        <Carousel responsive={responsive}>
          {dispensaries && 
            dispensaries.map((dispensary) => (
              <Dispensary key={dispensary._id} dispensary={dispensary} />
            ))}
        </Carousel>
      );
}
