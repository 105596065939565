export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAILURE = "USER_SIGNIN_FAILURE";
export const USER_SIGNOUT = "USER_SIGNOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";
export const USER_REGISTER_RESET = " USER_REGISTER_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAILURE = "USER_DETAILS_FAILURE";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAILURE = "USER_UPDATE_PROFILE_FAILURE";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const FETCH_USER_REWARDS_REQUEST = "FETCH_USER_REWARDS_REQUEST";
export const FETCH_USER_REWARDS_SUCCESS = "FETCH_USER_REWARDS_SUCCESS";
export const FETCH_USER_REWARDS_FAILURE = "FETCH_USER_REWARDS_FAILURE";

export const USER_TOPSELLERS_LIST_REQUEST = "USER_TOPSELLERS_LIST_REQUEST";
export const USER_TOPSELLERS_LIST_SUCCESS = "USER_TOPSELLERS_LIST_SUCCESS";
export const USER_TOPSELLERS_LIST_FAILURE = "USER_TOPSELLERS_LIST_FAILURE";

export const GET_USER_ADDRESS_REQUEST = "GET_USER_ADDRESS_REQUEST";
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS";
export const GET_USER_ADDRESS_FAILURE = "GET_USER_ADDRESS_FAILURE";

export const UPDATE_SELLER_INVOICE_REQUEST = "UPDATE_SELLER_INVOICE_REQUEST";
export const UPDATE_SELLER_INVOICE_SUCCESS = "UPDATE_SELLER_INVOICE_SUCCESS";
export const UPDATE_SELLER_INVOICE_FAILURE = "UPDATE_SELLER_INVOICE_FAILURE";

export const ADD_REWARD_POINTS_REQUEST = " ADD_REWARD_POINTS_REQUEST";
export const ADD_REWARD_POINTS_SUCCESS = " ADD_REWARD_POINTS_SUCCESS";
export const ADD_REWARD_POINTS_FAILURE = " ADD_REWARD_POINTS_FAILURE";

export const SELLER_CHART_DATA_REQUEST = "SELLER_CHART_DATA_REQUEST";
export const SELLER_CHART_DATA_SUCCESS = "SELLER_CHART_DATA_SUCCESS";
export const SELLER_CHART_DATA_FAILURE = "SELLER_CHART_DATA_FAILURE";

export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILURE = "VALIDATE_TOKEN_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const RESET_PASSWORD_INFO_REQUEST = "RESET_PASSWORD_INFO_REQUEST";
export const RESET_PASSWORD_INFO_SUCCESS = "RESET_PASSWORD_INFO_SUCCESS";
export const RESET_PASSWORD_INFO_FAILURE = "RESET_PASSWORD_INFO_FAILURE";

export const REFERRAL_CODE_REQUEST = "REFERRAL_CODE_REQUEST";
export const REFERRAL_CODE_SUCCESS = "REFERRAL_CODE_SUCCESS";
export const REFERRAL_CODE_FAILURE = "REFERRAL_CODE_FAILURE";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const SELLER_UPDATE_PROFILE_REQUEST = "SELLER_UPDATE_PROFILE_REQUEST";
export const SELLER_UPDATE_PROFILE_SUCCESS = "SELLER_UPDATE_PROFILE_SUCCESS";
export const SELLER_UPDATE_PROFILE_FAILURE = "SELLER_UPDATE_PROFILE_FAILURE";

export const NOTIFY_WHEN_NEAR_SUCCESS = "NOTIFY_WHEN_NEAR_SUCCESS";
export const NOTIFY_WHEN_NEAR_REQUEST = "NOTIFY_WHEN_NEAR_REQUEST";
export const NOTIFY_WHEN_NEAR_FAILURE = "NOTIFY_WHEN_NEAR_FAILURE";

export const LIST_WITH_US_SUCCESS = "LIST_WITH_US_SUCCESS";
export const LIST_WITH_US_REQUEST = "LIST_WITH_US_REQUEST";
export const LIST_WITH_US_FAILURE = "LIST_WITH_US_FAILURE";

export const ADDTO_EMAILLIST_REQUEST = "ADDTO_EMAILLIST_REQUEST";
export const ADDTO_EMAILLIST_SUCCESS = "ADDTO_EMAILLIST_SUCCESS";
export const ADDTO_EMAILLIST_FAILURE = "ADDTO_EMAILLIST_FAILURE";

export const GET_QUESTION_REQUEST = "GET_QUESTION_REQUEST";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE";
export const GET_QUESTION_RESET = "GET_QUESTION_RESET";

export const CAST_MY_VOTE_REQUEST = "CAST_MY_VOTE_REQUEST";
export const CAST_MY_VOTE_SUCCESS = "CAST_MY_VOTE_SUCCESS";
export const CAST_MY_VOTE_FAILURE = "CAST_MY_VOTE_FAILURE";

export const SUBMIT_NEW_QUESTION_REQUEST = "SUBMIT_NEW_QUESTION_REQUEST";
export const SUBMIT_NEW_QUESTION_SUCCESS = "SUBMIT_NEW_QUESTION_SUCCESS";
export const SUBMIT_NEW_QUESTION_FAILURE = "SUBMIT_NEW_QUESTION_FAILURE";
export const SUBMIT_NEW_QUESTION_RESET = "SUBMIT_NEW_QUESTION_RESET";
