import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getAgeVerifiction } from "../utils";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const userSignin = useSelector((state) => state.userSignin);
  const ageVerified = getAgeVerifiction() 
  const { userInfo } = userSignin;
 
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          //if they're signed and accessing a private route then they have verified their age on signup and their token is still valid 
          userInfo ? (
            <Component {...props}></Component>
          ) : (
            //else if they are trying to access a private route and have  verified their agae but are not signed in they will be taken directly to sign in else they need verify first
            ageVerified ?
            <Redirect to="/signin" /> :
            <Redirect to="/age_verification"/>
          )
        }></Route>
    </div>
  );
};
