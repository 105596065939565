import React from "react";
import { Link } from "react-router-dom";
import "./vendorScreen.css";

function VendorScreen() {
  return (
    <div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
        className="img-fluid"
          style={{  padding: "5rem" }}
          src="/images/black_farmers_market_banner.png"
          alt="Merp Black Farmer's Market"
        />
      </div>
      <div  className="form about-height">
      <div style={{ textAlign: "center" }} className="about-content">
        <div className="badges-title">Join Us! </div>
        <div style={{marginTop:'3rem'}}>
          <div className="sub-title"><strong>Who Are We?</strong></div>
          <p>
            Our platform enables users to easily find cannabis companies that
            are minority-owned and/or impact-focused (doing work or employing
            business practices that aid in the restoration and transformation of
            communities affected by the War On Drugs). Some impacts of the WOD
            are lack of funding, services, jobs, and notably, grocery
            stores/fresh food in majority black neighborhoods. All these things
            play a role in an individual's health and wellness, which feeds into
            the larger community's collective health and wellness.
          </p>
          <p>
            Cannabis, an emerging multi-billion dollar industry that was used to
            destroy our communities, now has a debt to pay. We feel the funds
            created by the industry’s success should be playing a role in
            restoring and building up the same communities it negatively
            affected in the past. We’re here to make sure that happens!
          </p>
        </div>
        <div style={{marginTop:"2.5rem"}}>
          <div className="sub-title"><strong>What is It?</strong></div>
          <p>
            Merp is hosting a Black Farmer’s Market to promote wellness and
            health (Physical, Emotional & Financial) as well as showcase how
            these resources are available within the Black community. We see
            this as a way to bring healthy options into impacted communities and
            promote healthier lifestyles - your health is your wealth!
          </p>
        </div>
        <div style={{marginTop:"2.5rem"}}>
          <div className="sub-title"><strong>Why Is Merp Doing This?</strong></div>
          <p>
            We have seen many Black communities destroyed and lives massively
            impacted due to systematic divestment and repercussions that stem
            from the War on Drugs. The state even has a name for these areas in
            Illinois, R3 zones. We want to start discussions and provoke thought
            around community health, its importance, and what is wanted from
            equity funds for impacted communities. We want to make sure not only
            that Black communities are not only surviving but thriving! This
            farmer's market is an inaugural one to get a feel for the
            community's interest, with the plan for more regular markets.
          </p>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="badges-title">Looking To Be A Vendor? Click <Link to='/vendors'>here</Link> or email info@merp.page</div>

      </div>
      </div>
    </div>
  );
}

export default VendorScreen;
