import {
  BLOG_CREATE_FAILURE,
  BLOG_CREATE_SUCCESS,
  BLOG_CREATE_REQUEST,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAILURE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  BLOG_UPDATE_REQUEST,
  BLOG_UPDATE_SUCCESS,
  BLOG_UPDATE_FAILURE,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_FAILURE,
  BLOG_DELETE_SUCCESS,
  LINK_UPDATE_REQUEST,
  LINK_UPDATE_SUCCESS,
  LINK_UPDATE_FAILURE,
  LINK_UPDATE_RESET,
  BLOG_UPDATE_RESET,
  BLOG_DELETE_RESET,
} from "../constants/blogConstants";

export const blogCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_CREATE_REQUEST:
      return { loading: true };
    case BLOG_CREATE_SUCCESS:
      return { loading: false, success: true };
    case BLOG_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postsListReducer = (
  state = { loading: true, dispensaries: [] },
  action
) => {
    switch (action.type) {
        case GET_POSTS_REQUEST:
          return { loading: true };
        case GET_POSTS_SUCCESS:
          return { loading: false, blogs: action.payload };
        case GET_POSTS_FAILURE:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
};

export const postDetailsReducer = (
  state = { loading: true, dispensaries: [] },
  action
) => {
    switch (action.type) {
        case GET_POST_REQUEST:
          return { loading: true };
        case GET_POST_SUCCESS:
          return { loading: false, blog: action.payload };
        case GET_POST_FAILURE:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
};

export const blogUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_UPDATE_REQUEST:
      return { loading: true };
    case BLOG_UPDATE_SUCCESS:
      return { loading: false,success:true };
    case BLOG_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case BLOG_UPDATE_RESET:
        return {};
    default:
      return state;
  }
};

export const blogUpdateLinksReducer = (state = {}, action) => {
  switch (action.type) {
    case LINK_UPDATE_REQUEST:
      return { loading: true };
    case LINK_UPDATE_SUCCESS:
      return { loading: false, successLinks: action.payload };
    case LINK_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case LINK_UPDATE_RESET:
        return {};
    default:
      return state;
  }
};

export const postDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_DELETE_REQUEST:
      return { loading: true };
    case BLOG_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BLOG_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case BLOG_DELETE_RESET:
        return {};
    default:
      return state;
  }
};
