import React, { useState, useRef, useEffect } from "react";
import CheckoutSteps from "../components/CheckoutSteps";
import { useDispatch, useSelector } from "react-redux";
import { saveShippingAddress } from "../actions/cartActions";

const ShippingAddressScreen = (props) => {
  //google places api code
  let autoComplete;
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [scriptReady, setScriptReady] = useState(false);
  const [checked, setChecked] = useState(false);

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
      setScriptReady(true);
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setAddress(
      addressObject.address_components[0].short_name +
        " " +
        addressObject.address_components[1].short_name
    );
    setCity(addressObject.address_components[2].short_name);
    setState(addressObject.address_components[5].short_name);
    setPostalCode(addressObject.address_components[7].short_name);
    // console.log(addressObject);
  }

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  // const cart = useSelector((state) => state.cart);

  if (!userInfo) {
    props.history.push("/signin");
  }

  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (!scriptReady) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        () => handleScriptLoad(setQuery, autoCompleteRef)
      );
    }
  }, [scriptReady, address, city, state, postalCode]);

  // const [query, setQuery] = useState("")
  // const autoCompleteRef = useRef(null)

  // const [confirmedAddress, setConfirmedAddress] = useState("");
  // const [confirmedCity, setConfirmedCity] = useState("");
  // const [confirmedState, setConfirmedState] = useState("");
  // const [confirmedPostalCode, setConfirmedPostalCode] = useState("");
  const [street2, setStreet2] = useState("");
  // const [confirmedShipping, setConfirmedShipping] = useState("0");
  // const [confirmedStreet2, setConfirmedStreet2] = useState("");
  // const [confirmed, setConfirmed] = useState(false);
  // const [results, setResults] = useState([]);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      saveShippingAddress({
        fullName,
        email,
        address,
        street2,
        city,
        state,
        postalCode,
      })
    );

    //TODO: dispatch shipping address action
    props.history.push("/placeorder");
  };
  return (
    <div>
      <CheckoutSteps step1 step2></CheckoutSteps>
      {/* <div>
      <SearchLocationInput/>
      </div> */}

      <form className="form" onSubmit={submitHandler} autocomplete="on">
        <div>
          <h1>Shipping Address</h1>
        </div>
        <div>
          <label htmlFor="fullName">*Full Name</label>
          <input
            type="text"
            id="fullname"
            placeholder="Enter your full name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          ></input>
        </div>
        <div>
          <label htmlFor="email">*Email</label>
          <input
            type="text"
            id="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></input>
        </div>
        <div style={{ textAlign: "center" }}>
          <label htmlFor="autocompleteAddress">Autocomplete</label>

          <input
            ref={autoCompleteRef}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Lookup address "
            value={query}
          ></input>
        </div>
        <div style={{ textAlign: "center" }}>OR</div>
        <>
          <div>
            <label htmlFor="address">*Address</label>

            <input
              type="text"
              id="address1"
              placeholder="Enter full street address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="address2">Apt/Suite/Unit</label>
            <input
              type="text"
              id="address2"
              placeholder="Enter Apt/Suite/Unit"
              value={street2}
              onChange={(e) => setStreet2(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="city">*City</label>
            <input
              type="text"
              id="city"
              placeholder="Enter city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            ></input>
          </div>
          <div>
            <label htmlFor="state">*State</label>
            <select
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
            >
              <option value="">Select One</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <div>
            <label htmlFor="postalCode">*Zip Code</label>
            <input
              type="text"
              id="postalCode"
              placeholder="Enter Zip code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              required
            ></input>
          </div>
        </>
        <div
          style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
        >
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            required
          />
          <div style={{ marginLeft: "5px" }}>
            *I am verifying that the information input above
            (adress,city,state,zip code, and Apt/Unit/Suite) is accurate.
          </div>
        </div>

        <div>
          <label />

          <button
            className="primary"
            type="submit"
            style={{ marginBottom: "1rem" }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShippingAddressScreen;
