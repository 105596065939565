import axios from 'axios'
import { STATE_TAX_BUDGET_SUCCESS, STATE_TAX_BUDGET_FAILURE, STATE_TAX_BUDGET_REQUEST,STATE_JOBS_REQUEST,STATE_JOBS_SUCCESS,STATE_JOBS_FAILURE } from '../constants/educationConstants';


export const getStateTaxRevenue=(state)=>async(dispatch)=>{
    dispatch({ type: STATE_TAX_BUDGET_REQUEST});
    try{
        
        const { data } = await axios.post("/api/education_&_resources/taxes", {state});
      
        dispatch({ type: STATE_TAX_BUDGET_SUCCESS, payload: data });
    }
    catch(error){
        const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: STATE_TAX_BUDGET_FAILURE, payload: message })
    }
}

export const getStateJobs = (state,type)=> async(dispatch) =>{
    dispatch({type: STATE_JOBS_REQUEST})
    try{
       
        const { data } = await axios.post(`/api/education_&_resources/${type}`, {state});
   
        dispatch({ type: STATE_JOBS_SUCCESS, payload: data });
    }
    catch(error){
        const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: STATE_JOBS_FAILURE, payload: message })
    }
}