import React, { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../actions/productActions";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Product from "../../components/Product";
// import { prices, ratings } from "../../utils";
// import Rating from "../../components/Rating";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "./cbdSearchScreen.css";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CBDSearchScreen(props) {
  let query = useQuery();
  const budtenderCriteria = props.location.state;

  const {  order = "newest" } = useParams();
  // let includeLocation = {};
  // const addLocation = (location)=>{
  //    includeLocation[location] = true
  // }

  const resetLocations = () => {
    setCalifornia(false);
    setColorado(false);
    setIllinois(false);
    setMassachusetts(false);
    setArizona(false);
    setNewJersey(false);
    setWashington(false);
  };
  const dispatch = useDispatch();
  // const locations = [
  //   "California",
  //   "Colorado",
  //   "Illinois",
  //   "Massachusetts",
  //   "Arizona",
  //   "New Jersey",
  //   "Washington",
  // ];
  // const locationExist =(location)=>{
  //   if (locations.includes(location)){
  //     return true
  //   }
  //   return false
  // }
  // const [cbd, setCBD] = useState(false);
  // const [isUnionized, setIsUnionized] = useState(false);
  const [sustainable, setIsSustainable] = useState(
    query.getAll("advancedCategory").includes("isSustainable") ? true : false
  );
  const [isBlackOwned, setBlackOwned] = useState(
    query.getAll("advancedCategory").includes("isBlackOwned") ? true : false
  );
  const [isIndigenousOwned, setIndigenousOwned] = useState(
    query.getAll("advancedCategory").includes("isIndigenousOwned")
      ? true
      : false
  );
  const [isLgbt, setLgbt] = useState(
    query.getAll("advancedCategory").includes("isLgbt") ? true : false
  );
  const [isPOCOwned, setPOCOwned] = useState(
    query.getAll("advancedCategory").includes("isPOCOwned") ? true : false
  );
  const [isWomanOwned, setWomanOwned] = useState(
    query.getAll("advancedCategory").includes("isWomanOwned") ? true : false
  );
  const [isMinorityOwned, setMinorityOwned] = useState(
    isPOCOwned || isWomanOwned || isBlackOwned || isIndigenousOwned || isLgbt
      ? true
      : false
  );
  const [California, setCalifornia] = useState(false);
  const [Colorado, setColorado] = useState(false);
  const [Illinois, setIllinois] = useState(false);
  const [Arizona, setArizona] = useState(false);
  const [newJersey, setNewJersey] = useState(false);
  const [Washington, setWashington] = useState(false);
  const [Massachusetts, setMassachusetts] = useState(false);
  const [category, setCategory] = useState("all");
  const [type, setType] = useState(query.get("type"));

  // const [numberRating, setNumberRating] = useState(0);

  const [isWorkerCoop, setWorkerCoop] = useState(
    query.getAll("advancedCategory").includes("isWorkerCoop") ? true : false
  );
  const [equityVerified, setEquityVerified] = useState(
    query.getAll("advancedCategory").includes("equityVerified") ? true : false
  );
  const productList = useSelector((state) => state.productList);
  const [filterOrder, setFilterOrder] = useState(order);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [categoryShow, setCatgoryShow] = useState(false);

  const handleCategoryClose = () => {
    setCatgoryShow(false);
  };

  const handleCategoryShow = () => setCatgoryShow(true);
  const { loading, error, products, page, pages, results } = productList;

  const orderProducts = (order) => {
    if (order === "lowest") {
      products.sort((a, b) => a - b);
    }
    if (order === "highest") {
      products.sort((a, b) => b - a);
    }
  };

  const getUrlQueryStr = () => {
    let arr = [
      { isBlackOwned: isBlackOwned },
      { isIndigenousOwned: isIndigenousOwned },
      { isPOCOwned: isPOCOwned },
      { equityVerified: equityVerified },
      { isSustainable: sustainable },
      { isWomanOwned: isWomanOwned },
      { isWorkerCoop: isWorkerCoop },
    ];
    let advancedStr = "";
    let count = 0;
    arr.forEach((item) => {
      for (let key in item) {
        if (item[key]) {
          //   advancedCategory[key] = item[key];
          //   setAdvancedCategory({ ...advancedCategory });
          count++;
          if (count === 1) {
            advancedStr += `${key}&`;
          } else {
            advancedStr += `advancedCategory=${key}&`;
          }
        }
      }
    });
    return advancedStr;
  };
  const getAdvanceddCategories = () => {
    props.history.push(getFilterUrl({}));
  };

  // const productCategoryList = useSelector((state) => state.productCategoryList);
  // const {
  //   loading: loadingCategories,
  //   error: errorCategories,
  //   categories,
  // } = productCategoryList;

  const resetCategories = () => {
    setIndigenousOwned(false);
    // setIsUnionized(false);
    setMinorityOwned(false);
    setPOCOwned(false);
    setWomanOwned(false);

    setIsSustainable(false)
    // setWorkerCoop(false);
    setBlackOwned(false);
    setWorkerCoop(false);
    setEquityVerified(false);

    props.history.push("/cannabis_products");
  };

  useEffect(() => {
  
    dispatch(
      listProducts({
        pageNumber: query.get("page"),
        // name: name !== "all" ? name : "",
        type: !query.get("type") ? "all" : query.get("type"),

        // shopCriteria: budtenderCriteria,

        // min,
        // max,
        // rating,
        category: !query.get("category")
          ? "all"
          : query.get("category") === "all"
          ? "all"
          : query.get("category"),
        order: filterOrder,
        advancedCategory: {
          isSustainable: query
            .getAll("advancedCategory")
            .includes("isSustainable")
            ? true
            : false,
          isBlackOwned: query
            .getAll("advancedCategory")
            .includes("isBlackOwned")
            ? true
            : false,
          isIndigenousOwned: query
            .getAll("advancedCategory")
            .includes("isIndigenousOwned")
            ? true
            : false,
          isLgbt: query.getAll("advancedCategory").includes("isLgbt")
            ? true
            : false,
          isPOCOwned: query.getAll("advancedCategory").includes("isPOCOwned")
            ? true
            : false,
          isWomanOwned: query
            .getAll("advancedCategory")
            .includes("isWomanOwned")
            ? true
            : false,
          isWorkerCoop: query
            .getAll("advancedCategory")
            .includes("isWorkerCoop")
            ? true
            : false,
          equityVerified: query
            .getAll("advancedCategory")
            .includes("equityVerified")
            ? true
            : false,
        },
      })
    );
  }, [dispatch, props, budtenderCriteria]);

  const getFilterUrl = (filter) => {

    const filterPage = filter.page || 1;
    const filterCategory = filter.category
      ? filter.category
      : query.get("category")
      ? query.get("category")
      : "all";
    const filterType = filter.type
      ? filter.type
      : query.get("type")
      ? query.get("type")
      : "all";
    // const filterRating = filter.rating || rating;
    const advancedCategory =
      getUrlQueryStr() === "" ? "all&" : getUrlQueryStr();
   

    return `/cannabis_products?page=${filterPage}&advancedCategory=${advancedCategory}type=${filterType}&category=${filterCategory}`;
  };

  return (
    <div class="col-md-8 mx-auto" style={{ height: "auto", padding: "3rem" }}>
      <div>
        <div>
          <div className="search-header">
          <div className="search-header-title">Cannabis Products</div>
            <section className="row-nowrap search-row scroll">
              <div className="search-filter-criteria">
              
                <>
                  <Modal
                    className="modal-filter"
                    show={show}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton>
                      <div class="modal-title">
                        <strong>Advanced Category</strong>
                      </div>
                    </Modal.Header>

                    <Modal.Body>
                      <div style={{ fontSize: "1.5rem" }}>
                        Please select your desired category filter(s) :{" "}
                      </div>

                      <form>
                        <ul className="search-categories-dispensaries">
                          <li>
                            <div>
                              <input
                                id="isBlackOwned"
                                type="checkbox"
                                checked={isMinorityOwned}
                                onChange={(e) =>
                                  setMinorityOwned(e.target.checked)
                                }
                              ></input>{" "}
                              <label htmlFor="isBlackOwned">
                                {" "}
                                Minority Owned
                              </label>
                            </div>
                            {isMinorityOwned && (
                              <ul className="search-categories-dispensaries">
                                <li>
                                  <div>
                                    <input
                                      id="isBlackOwned"
                                      type="checkbox"
                                      checked={isBlackOwned}
                                      onChange={(e) =>
                                        setBlackOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isBlackOwned">
                                      {" "}
                                      Black Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isIndigenousOwned"
                                      type="checkbox"
                                      checked={isIndigenousOwned}
                                      onChange={(e) =>
                                        setIndigenousOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isIndigenousOwnedd">
                                      {" "}
                                      Indigenous Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isPOCOwned"
                                      type="checkbox"
                                      checked={isPOCOwned}
                                      onChange={(e) =>
                                        setPOCOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isPOCOwned">
                                      {" "}
                                      POC Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isWomanOwned"
                                      type="checkbox"
                                      checked={isWomanOwned}
                                      onChange={(e) =>
                                        setWomanOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isWomanOwned">
                                      {" "}
                                      Woman Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isLgbt"
                                      type="checkbox"
                                      checked={isLgbt}
                                      onChange={(e) =>
                                        setLgbt(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isLgbt"> LGBT Owned</label>
                                  </div>
                                </li>
                              </ul>
                            )}
                          </li>
                          <li>
                            <div>
                              <input
                                id="equityPlanVerified"
                                type="checkbox"
                                checked={equityVerified}
                                onChange={(e) =>
                                  setEquityVerified(e.target.checked)
                                }
                              ></input>{" "}
                              <label htmlFor="equityPlanVerified">
                                Equity Plan Verified
                              </label>
                            </div>
                          </li>
                          <li>
                            {" "}
                            <div>
                              <input
                                id="isWorkerCoop"
                                type="checkbox"
                                checked={isWorkerCoop}
                                onChange={(e) =>
                                  setWorkerCoop(e.target.checked)
                                }
                              ></input>{" "}
                              <label htmlFor="isWorkerCoop"> Worker COOP</label>
                            </div>
                          </li>
                          {/* <li>
                                <div>
                                  <input
                                    id="isUnionized"
                                    type="checkbox"
                                    checked={isUnionized}
                                    onChange={(e) =>
                                      setIsUnionized(e.target.checked)
                                    }
                                  ></input>{" "}
                                  <label htmlFor="isUnionized">Unionized</label>
                                </div>
                              </li> */}

                          <li>
                            <div>
                              <input
                                id="sustainable"
                                type="checkbox"
                                checked={sustainable}
                                onChange={(e) =>
                                  setIsSustainable(e.target.checked)
                                }
                              ></input>{" "}
                              <label htmlFor="sustainable">Sustainable</label>
                            </div>
                          </li>
                        </ul>
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        class="btn btn-default"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => resetCategories()}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => {
                          getAdvanceddCategories();
                        }}
                      >
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
                <Button className="button-search" onClick={handleCategoryShow}>
                  Category
                </Button>
                <Button className="button-search" onClick={handleShow}>
                  Advanced Category
                </Button>
              
                <>
                  <Modal
                    className="modal-filter"
                    show={categoryShow}
                    onHide={handleCategoryClose}
                  >
                    <Modal.Header closeButton>
                      <div class="modal-title">
                        <strong> Category</strong>
                      </div>
                    </Modal.Header>
                    <Modal.Body class="modal-body">
                      <div style={{ fontSize: "1.5rem" }}>
                        Please select a Category :{" "}
                      </div>
                      <form>
                        <ul className="search-categories-dispensaries">
                          {/*                         
                          <li>
                            <div>
                              <div>
                                <input
                                  id="HouseholdHemp"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "HouseholdHemp"}
                                  onClick={(e) =>
                                    setSearchCategory("HouseholdHemp")
                                  }
                                ></input>{" "}
                                <label for="HouseholdHemp">
                                  {" "}
                                  Household Hemp
                                </label>
                              </div>
                            </div>
                          </li> */}
                          <li>
                            <div>
                              <div>
                                <input
                                  id="edible"
                                  type="radio"
                                  value="Edible"
                                  checked={category === "Edible"}
                                  onClick={() => setCategory("Edible")}
                                ></input>{" "}
                                <label for="edible"> Edible</label>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div>
                              <div>
                                <input
                                  id="flower"
                                  type="radio"
                                  value="flower"
                                  checked={category === "flower"}
                                  onClick={() => setCategory("flower")}
                                ></input>{" "}
                                <label for="flower"> Flower</label>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div>
                              <div>
                                <input
                                  id="Pre-rolls"
                                  type="radio"
                                  value="Pre-rolls"
                                  checked={category === "Pre-rolls"}
                                  onClick={() => setCategory("Pre-rolls")}
                                ></input>{" "}
                                <label for="Pre-rolls"> Pre-rolls</label>
                              </div>
                            </div>
                          </li>
                          {query.get("type") !== "thc" && (
                            <>
                              <li>
                                <div>
                                  <div>
                                    <input
                                      id="tincture"
                                      type="radio"
                                      value="Tincture"
                                      checked={category === "Tincture"}
                                      onClick={() => setCategory("Tincture")}
                                    ></input>{" "}
                                    <label for="tincture"> Tincture</label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <div>
                                    <input
                                      id="topical"
                                      type="radio"
                                      value="Topical"
                                      checked={category === "Topical"}
                                      onClick={() => setCategory("Topical")}
                                    ></input>{" "}
                                    <label for="topical"> Topical</label>
                                  </div>
                                </div>
                              </li>{" "}
                            </>
                          )}
                          {query.get("type") !== "cbd" && (
                            <li>
                              <div>
                                <div>
                                  <input
                                    id="concentrate"
                                    type="radio"
                                    value="Concentrate"
                                    checked={category === "Concentrate"}
                                    onClick={() => setCategory("Concentrate")}
                                  ></input>{" "}
                                  <label for="concentrate"> Concetrates</label>
                                </div>
                              </div>
                            </li>
                          )}

                          {/* <li>
                            <div>
                              <div>
                                <input
                                  id="onlineCBD"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "onlineCBD"}
                                  onClick={(e) =>
                                    setSearchCategory("onlineCBD")
                                  }
                                ></input>{" "}
                                <label for="onlineCBD">
                                  {" "}
                                  Online CBD Products
                                </label>
                              </div>
                            </div>
                          </li> */}
                        </ul>
                      </form>
                    </Modal.Body>
                    <Modal.Footer class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-default"
                        onClick={handleCategoryClose}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => {
                          setCategory("all");
                          props.history.push(getFilterUrl({ category: "all" }));
                        }}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => {
                          props.history.push(
                            getFilterUrl({ category: category })
                          );
                        }}
                      >
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
                {/* <Button
                    type="button"
                    className="button-search"
                    data-toggle="modal"
                    data-target="#locationModal"
                  >
                    Location
                  </Button> */}
                <div
                  class="modal fade"
                  id="locationModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        {/* <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button> */}
                        <div class="modal-title" id="myModalLabel">
                          <strong>Locations</strong>
                        </div>
                      </div>
                      <div class="modal-body">
                        <div style={{ fontSize: "1.5rem" }}>
                          Please select your desired location(s) :{" "}
                        </div>

                        <form>
                          <ul className="search-categories-dispensaries">
                            <li>
                              <div>
                                <input
                                  id="California"
                                  type="checkbox"
                                  checked={California}
                                  onChange={(e) =>
                                    setCalifornia(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="California"> California</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Colorado"
                                  type="checkbox"
                                  checked={Colorado}
                                  onChange={(e) =>
                                    setColorado(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Colorado"> California</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Illinois"
                                  type="checkbox"
                                  checked={Illinois}
                                  onChange={(e) =>
                                    setIllinois(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Illinois">Illinois</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Massachusetts"
                                  type="checkbox"
                                  checked={Massachusetts}
                                  onChange={(e) =>
                                    setMassachusetts(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Massachusetts">
                                  Massachusetts
                                </label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Arizona"
                                  type="checkbox"
                                  checked={Arizona}
                                  onChange={(e) => setArizona(e.target.checked)}
                                ></input>{" "}
                                <label htmlFor="Arizona">Arizona</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="New Jersey"
                                  type="checkbox"
                                  checked={newJersey}
                                  onChange={(e) =>
                                    setNewJersey(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="New Jersey">New Jersey</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Washington"
                                  type="checkbox"
                                  checked={Washington}
                                  onChange={(e) =>
                                    setWashington(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Washington">Washington</label>
                              </div>
                            </li>
                            {/* {locations.map((l) => (
                            <li key={l}>
                              <div>
                                <input
                                  id={`${l}`}
                                  type="checkbox"
                                  checked={includeLocation[l]}
                                  onChange={(e) =>
                                   addLocation(e.target.checked)
                                  }
                                  ></input>{" "}
                                <label htmlFor={`${l}`}>{l}</label>
                              </div>
                            </li>
                          ))} */}
                          </ul>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => resetLocations()}
                        >
                          Reset
                        </button>
                        <button type="button" class="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button type="button" className="button-search">
                
              </button> */}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="filters">
                  Product type{" "}
                  <select
                    className="sort-filter"
                    style={{fontSize:"1rem"}}
                    // className="button-search"
                    value={query.get("type")}
                    onChange={(e) => {
                      props.history.push(
                        getFilterUrl({ type: e.target.value })
                      );
                    }}
                  >
                    <option value="all">All</option>
                    <option value="thc">THC Products</option>
                    <option value="cbd">CBD Products</option>
                    {/* <option value="toprated">Customer Review</option> */}
                  </select>
                </div>
                <div className="filters">
                  Sort by{" "}
                  <select
                    className="sort-filter"
                    style={{fontSize:"1rem"}}
                    // className="button-search"
                    value={filterOrder}
                    onChange={(e) => {
                      orderProducts(e.target.value);
                      getAdvanceddCategories();
                      props.history.push(
                        getFilterUrl({ order: e.target.value })
                      );
                    }}
                  >
                    <option value="newest">Newest Arrivals</option>
                    <option value="lowest">Price:Low to High</option>
                    <option value="highest">Price: High to Low</option>
                    <option value="toprated">Customer Review</option>
                  </select>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div>
          <div className="container-fluid">
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <strong>{products && results} results</strong>
              </div>
            )}

            {/* {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : shop === "dispensaries" ? (
              <div>
                <strong>{dispensaries && dispensaries.length} results</strong>
              </div>
            ) : (
              <div>
                <strong>{products && products.length} results </strong>
              </div>
            )} */}

            <div className="row">
              {/* <div className="side-filter col-md-1 col-12 p-0">
            <h3>Department</h3>
            <div>
              {loadingCategories ? (
                <LoadingBox></LoadingBox>
              ) : errorCategories ? (
                <MessageBox variant="danger">{errorCategories}</MessageBox>
              ) : (
                <ul>
                  <li>
                    <Link
                      className={"all" === category ? "active" : ""}
                      to={getFilterUrl({ category: "all" })}>
                      Any
                    </Link>
                  </li>
                  {categories.map((c) => (
                    <li key={c}>
                      <Link
                        className={c === category ? "active" : ""}
                        to={getFilterUrl({ category: c })}>
                        {c}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <h3>Price</h3>
              <ul>
                {prices.map((p) => (
                  <li key={p.name}>
                    <Link
                      to={getFilterUrl({ min: p.min, max: p.max })}
                      className={
                        `${p.min}-${p.max}` === `${min}-${max}` ? "active" : ""
                      }>
                      {p.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3>Avg. Customer Review</h3>
              <ul >
                {ratings.map((r) => (
                  <li key={r.name}>
                    <Link
                      to={getFilterUrl({ rating: r.rating })}
                      className={`${r.rating}` === `${rating}` ? "active" : ""}>
                      <Rating caption={"& up"} rating={r.rating}></Rating>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}

              <div className="col-md-11 col-12 p-0">
                {loading ? (
                  <></>
                ) : error ? (
                  <></>
                ) : (
                  <div style={{ paddingLeft: "2rem" }}>
                    <div className="row center">
                      {products && products.length> 0 ?
                        products.map((product) => (
                          <Product key={product._id} product={product} />
                        )):<MessageBox>No Products Found</MessageBox>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row center pagination ">
            {[...Array(pages).keys()].map((x) => (
              <Link
                className={x + 1 === page ? "active" : ""}
                key={x + 1}
                to={getFilterUrl({ page: x + 1 })}
              >
                {x + 1}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
