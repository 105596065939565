import React from "react";

export default function SAResourceScreen() {
  return (
    <div className="form" style={{ textAlign: "center" }}>
      <h1 style={{ color: "#57A4FF" }}>Sexual Assault Resources</h1>
      <div>
        <p>
          {" "}
          Similar to alcohol, unfortunately, cannabis may be involed in some
          ones sexual assault. We know that we can't just focus on the positives
          of this industry without talking about and taking accountability for
          some of the possible down sides as well. One major part of this is
          sexual assult and we feel an important part of accountability is
          providing education on how to possibly safely introduce cannabis into
          a intimate encounter, resources if you have been assaulted and are a
          survivor, as well as some important things to remember if you have
          been accused of sexual assault.{" "}
          <div className="disclaimer">
            *Anyone can be a perpetrator of sexual assault and anyone can be a
            victim/survivor.{" "}
          </div>
        </p>
        <h2 style={{ color: "#57A4FF" }}>
          Tips on how to possibly introduce cannabis into your intimate relationships
        </h2>
        <p
          style={{
            textAlign: "left",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}>
          <ol>
            <li>
              You & your sexual partner(s) should have a sober discussion on the
              introduction/use of cannabis prior to any sexual interaction. No
              one should be caught off guard.{" "}
            </li>
            <li>
              Like alcohol, cannabis can have an impact on a person's awareness,
              memory, and ability to consent.Prior to sex, establish a saftey
              sentence that can be reiterated during sex to indicate the person
              is still present, alert , and able to consent.
            </li>
            <li>
              Always make sure you have{" "}
              <strong>verbal, clear & enthusiastic consent</strong>
              (even in long-term relationships), before & throughout. Remember,
              consent is ongoing, be sure to check in with your partner(s) at
              every stage.
            </li>
            <li>
              If there are any gray areas or if they seem "out of it", always
              stop & wait until all parties are sober & everyone can re-asses
              the situation.
            </li>
            <li>Always discuss any possible STI OR STDs.</li>
            <li>
              Before Starting Make sure you & your partner(s) discuss what
              contraception will be used & this agreement is adhered to
              throughout the sexual encounter.
            </li>
          </ol>
        </p>
        <h2 style={{ color: "#57A4FF" }}>Resources</h2>
        <div style={{height:"auto"}}>
        <ul>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> RAINN
              </div>
              <div>
                <strong>Type:</strong>National Sexual Assault and Harassment
                Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong>800-656-4673
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="http://online.rainn.org/?_ga=2.202034002.2123643517.1618898812-496764594.1618898812"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://online.rainn.org/?_ga=2.202034002.2123643517.1618898812-496764594.1618898812
                </a>
              </div>{" "}
              <div>
                <strong>Website En espanol:</strong>{" "}
                <a
                  href="http://hotline.rainn.org/es?_ga=2.255042251.2123643517.1618898812-496764594.1618898812"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://hotline.rainn.org/es?_ga=2.255042251.2123643517.1618898812-496764594.1618898812
                </a>
              </div>
              <div>
                <strong>More Details:</strong>{" "}
                <a
                  href="https://www.rainn.org/resources"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://www.rainn.org/resources
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> 1 in 6
              </div>
              <div>
                <strong>Type:</strong>Male Survivors
              </div>{" "}
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://1in6.org/helpline/"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://1in6.org/helpline/
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> RAINN
              </div>
              <div>
                <strong>Type:</strong>Street Harassment
              </div>{" "}
              <div>
                <strong>Contact:</strong>877-995-5247
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://hotline.rainn.org/ssh-en/terms-of-service.jsp?_ga=2.186698603.2123643517.1618898812-496764594.1618898812"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://hotline.rainn.org/ssh-en/terms-of-service.jsp?_ga=2.186698603.2123643517.1618898812-496764594.1618898812
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> RAINN
              </div>
              <div>
                <strong>Type:</strong>National Domestic Violence Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong>800-799-SAVE
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="http://thehotline.org/"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://thehotline.org/
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> Love Is Respect
              </div>
              <div>
                <strong>Type:</strong>National Domestic Violence Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong>866-331-9474
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="http://www.loveisrespect.org/"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://www.loveisrespect.org/
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> National Center for Victims of Crime
              </div>
              <div>
                <strong>Type:</strong>VictimConnect
              </div>{" "}
              <div>
                <strong>Contact:</strong>855-4-VICTIM (84-2846)
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://chat.victimsofcrime.org/victim-connect/terms-of-service.jsp"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://chat.victimsofcrime.org/victim-connect/terms-of-service.jsp
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> Polaris
              </div>
              <div>
                <strong>Type:</strong>National Human Trafficking Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong> 888-373-7888
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://humantraffickinghotline.org/chat"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://humantraffickinghotline.org/chat
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> National Center for Missing and Exploited Children
              </div>
              <div>
                <strong>Contact:</strong> 800.THE.LOST (843-5678)
              </div>
            </div>
          </li>
        </ul>
        </div>

        <p>We will add more state specific resources soon.</p>
        <h2 style={{ color: "#57A4FF" }}>
          Some things to remember if you are accused of sexual assault{" "}
        </h2>
        <p>
          We decided to inclide this section because we feel there's not enough
          conversation on how to respond when you are accused, despite this
          person's response having the ability to help with reconcilation ot
          cause further trauma to a survivor.{" "}
        </p>
        <p>
          If you are accused, your first response may be to reject or deny the
          other person's truth/narrative. This can be extremely harmful to the
          person bringing it to your attention just as it may(or may not be)
          alarming for you be accused. Listen and check to see if the person is
          willing to engage in dialogue to understand both parties accounts of
          the night. If this is not an option , give the person space and if
          possible check in at a later date. We encourage engaging a nuetral
          party such as a transformative justice mediator.
        </p>
        <p>
          To help avoid being in this situation we suggest you consult the
          beginning part of this page and always engage in ongoing communication
          during an intimate encounter.
        </p>
        <div className="disclaimer">
          We are not experts in this area, however, we feel this is an important
          topic as it relates to substance use and wanted to offer some
          suggestions and support
        </div>
      </div>
    </div>
  );
}
