import React, { useState } from "react";
import "./brandCard.css";
import { Image, Transformation } from "cloudinary-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function BrandCard(props) {
  const tagsDict = {
    new: "/images/new_tag.svg",
    trending: "/images/trending_tag.svg",
    isBlackOwned: "/images/black_owned_badge_3.png",
    isPOCOwned: "/images/poc_owned_badge.png",
    isWomanOwned: "/images/woman_owned_badge.png",
    isIndigenousOwned: "/images/indigenous_owned_badge.png",
    isSustainable: "/images/leaf_badge.png",
    isWorkerCoop: "/images/worker_owned_coop.png",
    equityVerified: "/images/equity_badge_2.png",
    isUnionized: "/images/unionized_2.png",
    isLgbt: "/images/lgbtq_badge.png",
    sunGrown: "/images/category_badges/sungrown.svg",
    indoor: "/images/category_badges/indoor.svg",
    pesticideFree: "/images/category_badges/pesticide_free.svg",
  };

  const categoryBadges = {
    concentrate: "/images/category_badges/concentrate.svg",
    topical: "/images/category_badges/topical.svg",
    edible: "/images/category_badges/edible.svg",
    flower: "/images/category_badges/flower.svg",
    preroll: "/images/category_badges/preroll.svg",
    accessory: "/images/category_badges/accessory.svg",
  };

  const verfied = "/images/verified.svg";
  const like = "/heart.png";
  const unlike = "/images/Heart.svg";

  const [brandLike, setBrandLike] = useState(false);

  let brand = props.brand;

  const badges = [];
  for (const property in brand.badges) {
    if (brand.badges[property].status) {
      badges.push(property);
    }
  }

  const tags = [];
  for (const property in brand.product_type) {
    if (brand.product_type[property]) {
      tags.push(property);
    }
  }

  return (
    <div className="brand_card">
      <div className="brand_card_img">
        <Image
          class="img-fluid"
          cloudName="merp"
          publicId={brand.logo}
          effect="sharpen"
          secure="true"
          crop="scale"
        />
        {/* <img className="brand_card_img" src={brand.logo} /> */}
      </div>

      <div className="right_card">
        <div className="brand_card_text">
          {/* <div
            className="brand_card_likes"
            onClick={() => setBrandLike((prevLike) => !prevLike)}
          >
            {brandLike ? <img src={like} /> : <img src={unlike} />}
          </div> */}

          <div>
            <div className="brand-card-header">
              <div className="brand_card_title">
                <Link to={`/brands/${brand._id}`}>
                  <div className="brand_card_name">{brand.name} </div>
                </Link>
                {brand.claimed === "unclaimed" && <img src={verfied} />}
              </div>
            </div>
            {badges.length ? (
              <div>
                <div className="brand_card_tags">
                  {badges.map(
                    (b, idx) =>
                      idx < 3 && (
                        <div className="tag">
                          <img
                            className="brand_category"
                            src={tagsDict[b]}
                            alt={b}
                          />
                        </div>
                      )
                  )}
                  {badges.length > 3 && (
                    <div className="xtra_tags"> + {badges.length - 3}</div>
                  )}
                </div>
              </div>
            ) : (
              <div className="brand_card_category">
                {tags.map((type) => (
                  <img
                    className="brand_category"
                    src={categoryBadges[type]}
                    alt={type}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
