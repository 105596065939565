import React from "react";


export default function PaymentRecievedScreen() {
  return (
    <div class="container-fluid text-center p-5">
      <div class="thank-you" style={{ fontSize: "6rem" }}>
        Thank You!
      </div>
      <div >
      <img
        src="/images/merp_logo_sticker.png"
        alt="merp logo "
        className="img-fluid"
        style={{width:'200px'}}
        // style={{ height: "auto", width: "20rem" }}
      />
      </div>
      <div class="thank-you-2" style={{ fontSize: "2rem", paddingTop:"3rem" }}>
        Thank you for your purchase! Your payment has been received, you will recieve an emailed reciept shortly.
      </div>
    </div>
  );
}
