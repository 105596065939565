import React, { useEffect } from "react";
import "./posts.css";
import Post from "../Post/Post.js"
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../../actions/blogActions";
import MessageBox from "../../../components/MessageBox";
import LoadingBox from "../../../components/LoadingBox";
import { Image } from "cloudinary-react";
import { Link } from "react-router-dom";

export default function Posts() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  const postsList = useSelector((state) => state.postsList);
  const { loading, error, blogs } = postsList;

  return (
    <div className="posts" style={{display:"flex", justifyContent:"center"}} >
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
       <div className="post-page-wrapper" >

        {blogs && blogs.length ? (
            blogs.map((blog) => <div><Post key={blog._id} blog={blog} /></div>)
          ) : (
            <div>
              <MessageBox>There are currently no blog posts</MessageBox>
            </div>
          )} 
        </div>
      )}
    </div>
  );
}
