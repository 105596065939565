import React from "react";
import { Link } from "react-router-dom";
import "./termsOfUse.css"

export default function TermsOfUseScreen() {
  return (
    <div className="terms-container" >
      <div className="terms-of-use-header">Terms of Use</div>
      <div className="sub-section">
        <div className="sub-header">WEBSITE DISCLAIMER</div>
        <p>
          The information provided by Merp Inc. (“we,” “us” or “our”) on 
        {' '}<Link to="/">https://www.merp.page/</Link> (the “Site”) and our mobile
          application is for general informational purposes only. All
          information on the Site and our mobile application are provided in
          good faith, however we make no representation or warranty of any kind,
          express or implied, regarding the accuracy, adequacy, validity,
          reliability, availability, or completeness of any information on the
          Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE
          ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
          RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION AND YOUR
          RELIANCE OF ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS
          SOLELY AT YOUR OWN RISK.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">EXTERNAL LINKS DISCLAIMER</div>
        <p>
          The Site and our mobile application may contain (or you may be sent
          through the Site or our mobile application) links to other websites or
          content belonging to or originating from third parties or links to
          websites and features in banners or other advertising. Such external
          links are not investigated, monitored, or checked for accuracy,
          adequacy, validity, reliability, availability, or completeness by us.
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
          THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY
          WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN
          ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY
          WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
          THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">TESTIMONIALS DISCLAIMER</div>
        <p>
          The Site may include testimonials from users who have used our goods
          and/or services. These testimonials represent the users' real-life
          experiences and opinions. However, the experiences are unique to
          individual users and may not be reflective of all users of our goods
          and/or services. We can not guarantee, and you should not expect, that
          all users will have the same experience. YOUR INDIVIDUAL RESULTS MAY
          VARY.
        </p>
        <p>
          The Site accepts testimonials in a variety of formats, including text,
          audio, and/or services. Except for the correction of grammar or typing
          errors, they appear on the Site verbatim as provided by users. Some
          testimonials may have been reduced for brevity since the entire
          testimony contains irrelevant information to the broader audience.
        </p>
        <p>
          The views and opinions expressed in the testimonials are purely those
          of the individual user and do not reflect our own. We are not
          affiliated with any of the users that submit testimonials, and they
          are not paid or otherwise compensated for their contributions.
        </p>
        <p>
          The testimonials on the Site are not meant to be, and should not be
          regarded as, claims that our goods and/or services may be used to
          diagnose, treat, mitigate, cure, prevent, or otherwise treat any
          illness or medical condition. There have been no clinical trials or
          evaluations of testimonies.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">CORRECTION</div>
        <p>
          There may be typographical mistakes, inaccuracies, or omissions in the
          Site's content, including descriptions, price, availability, and other
          information. We retain the right, without prior notice, to rectify any
          mistakes, inaccuracies, or omissions, as well as to alter or update
          the information on the Site.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">DISCLAIMER</div>
        <p>
          THE WEBSITE IS PROVIDED ON AN AS-IS BASIS. YOU AGREE THAT USE OF THE
          SITE AND OUR SERVICES IS SOLELY AT YOUR OWN RISK. TO THE EXTENT
          PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
          CONNECTION WITH THE SITE AND YOUR USE OF IT, INCLUDING, WITHOUT
          LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
          REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE'S
          CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE, AND WE WILL
          ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
          OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
          SERVES AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
          INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
          TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN
          HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY
          ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
          MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS RESULT OF
          THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
          AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
          ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
          BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
          WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
          ADVERTISING, AND WE WILL NOT BE PARTY TO OR IN ANY WAY BE RESPONSIBLE
          FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
          PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT
          OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
          YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">LIMITATION OF LIABILITY</div>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS WILL NOT BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
          CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
          INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
          ARISING FROM YOUR USE OF THE SITE, EVEN IF WE NOTWITHSTANDING ANYTHING
          TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
          WHATSOEVER AND REGARDLESS OF THE FORM OF ACTION WILL AT ALL TIMES BE
          LIMITED TO THE LESSER OF THE AMOUNT PAID BY YOU TO US DURING THE THREE
          (3) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR USD 1,000.00.
          CERTAIN STATE LAWS IN THE UNITED STATES AND INTERNATIONAL LAWS DO NOT
          ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
          OF CERTAIN DAMAGES. SOME OR ALL OF THE ABOVE DISCLAIMERS OR
          LIMITATIONS MAY NOT APPLY TO YOU IF THESE LAWS APPLY TO YOU, AND YOU
          MAY HAVE ADDITIONAL RIGHTS.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">AGREEMENT TO TERMS</div>
        <p>
          These Terms of Use are a legally binding agreement between you,
          whether personally or on behalf of an entity ("you") and Merp Inc.
          ("Company," "we," "us," or "our") regarding your access to and use of
          the https://www.merp.page/ website, as well as any other media form,
          media channel, mobile website, or mobile application related, linked,
          or otherwise connected thereto (collectively, the "Site"). By
          accessing the Site, you confirm that you have read, understood, and
          agree to be governed by all of the Terms of Use. IF YOU DO NOT AGREE
          WITH ALL OF THESE TERMS OF USE, YOU ARE EXPRESSLY FORBIDDEN FROM USING
          THE SITE AND MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <p>
          Supplemental terms and conditions and documents that may be posted on
          the Site from time to time are expressly incorporated by reference
          herein. We retain the right, in our sole discretion, to update or
          modify these Terms of Service at any time and for any reason. We shall
          notify you of any modifications by revising the "Last Updated" date on
          these Terms of Use, and you waive any claim to particular notice of
          any such change. It is your duty to check these Terms of Service on a
          regular basis to remain up to date on any changes. You will be subject
          to and will be deemed to have been made aware of and to have accepted,
          the changes in any revised Terms of Use by your continued use of the
          Site after the date such revised Terms of Use are posted.
        </p>
        <p>
          The information on the Site is not intended for distribution to or use
          by any person or entity in any jurisdiction or country where such
          distribution or use would be contrary to law or regulation, or where
          such distribution or use would subject us to any registration
          requirement within such jurisdiction or country. As a result, users
          who choose to visit the Site from other areas do so at their own risk
          and are entirely responsible for complying with local laws, if and to
          the extent that local laws apply.
        </p>
        <p>
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to use or register for the Site.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">INTELLECTUAL PROPERTY RIGHTS</div>
        <p>
          Unless otherwise specified, the Site is our proprietary property, and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the "Content"), as well as the trademarks, service
          marks, and logos contained therein (collectively, the "Marks"), are
          owned or controlled by us or licensed to us, and are protected by
          copyright and trademark laws, as well as various other intellectual
          property rights and unfair competition laws. The Site's Content and
          Marks are provided "AS IS" for your information and personal use only.
          Unless expressly stated otherwise in these Terms of Use, no part of
          the Site, including any Content or Marks, may be copied, reproduced,
          aggregated, republished, uploaded, posted, publicly displayed,
          encoded, translated, transmitted, distributed, sold, licensed, or
          otherwise exploited for any commercial purpose without our express
          prior written permission.
        </p>
        <p>
          Provided that you are eligible to use the Site, you are granted a
          limited permission to access and use the Site, as well as to download
          or print a copy of any portion of the Content to which you have
          legitimately acquired access, exclusively for your personal,
          non-commercial use. We reserve all rights in and to the Site, the
          Content, and the Marks that are not expressly given to you.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">USER REPRESENTATIONS</div>
        <p>
          By using the Site, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to comply with these Terms
          of Use; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the Site through automated or
          non-human means, whether through a bot, script, or otherwise; (6) you
          will not use the Site for any illegal or unauthorized purpose; and (7)
          your use of the Site will not violate any applicable law or
          regulation.
        </p>
        <p>
          If you submit false, inaccurate, out-of-date, or incomplete
          information, we reserve the right to suspend or cancel your account
          and reject any and all current or future use of the Site (or any
          portion thereof).
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">USER REGISTRATION</div>
        <p>
          It is possible that you may be required to register with the Site. You
          agree to keep your password secure and accept responsibility for any
          activities that occur under your account and password. We retain the
          right to delete, reclaim, or alter a username you choose if, in our
          sole judgement, such username is improper or otherwise objectionable.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">PROHIBITED ACTIVITIES</div>
        <p>
          You may not access or use the Site for any purpose other than the one
          for which we provide it. The Site may not be used in connection with
          any commercial unless expressly sponsored or permitted by us.
        </p>
        <p>As a user of the Site, you agree not to:</p>
        <ol start="1" className="numbered-list">
          <li>
            Without our written permission, you may not systematically retrieve
            data or other content from the Site in order to establish or
            assemble a collection, compilation, database, or directory.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially if you're
            trying to discover critical account information like user passwords
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with the Site's security
            mechanisms, such as those that prohibit or restrict the use of
            copying any Content or impose limits on the use of the Site and/or
            the Content included within.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li>
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
          <li>
            Use the Site to advertise or offer to sell goods and services.
          </li>
          <li>Engage in unauthorized framing of or linking to the Site.</li>
          <li>
            Upload or transmit (or attempt to upload or transmit) viruses,
            Trojan horses, or other material, including excessive capitalization
            and spamming (continuous posting or repetitive text), that
            interferes with any party's uninterrupted use and enjoyment of the
            Site or modifies, impairs, disrupts, alters, or interferes with the
            Site's use, features, functions, operation, or maintenance.
          </li>
          <li>
            Engage in any automated use of the system, such as sending comments
            or messages with scripts, or using data mining, robots, or similar
            data collection and extraction methods
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>Sell or otherwise transfer your profile</li>
          <li>
            Upload or transmit (or try to upload or transmit) any content that
            functions as a passive or active information collecting or
            transmission mechanism, including but not limited to clear graphics
            interchange formats ("gifs"). 1 × 1 pixel, web bugs, cookies, or
            similar devices (sometimes known as "spyware," "passive collecting
            mechanisms," or "pcms").
          </li>
          <li>
            Interferes with, disrupts, or places an undue burden on the Site or
            the networks or services to which it is linked.
          </li>
          <li>
            Harass, irritate, intimidate, or threaten any of our employees or
            agents who are involved in supplying you with any aspect of the
            Site.
          </li>
          <li>
            Attempt to circumvent any Site security measures designed to
            prohibit or limit access to the Site or any element of the Site.
          </li>
          <li>
            Copy or modify any software on the Site, including but not limited
            to Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Site.
          </li>
          <li>
            Except as may be the result of standard search engine or internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software
          </li>
          <li>
            Use a buying agent or purchasing agent to make purchasing agent to
            make purchases on the Site.
          </li>
          <li>
            Any unlawful use of the Site, including gathering usernames and/or
            email addresses of users by electronic or other methods for the
            purpose of sending unsolicited email, is prohibited. Alternatively,
            establishing user accounts by automated ways or on false pretenses.
          </li>
          <li>
            Utilize the Site to compete with us, or use the Site and/or the
            Content for any revenue-generating venture or commercial business.
          </li>
          <li>
            Misreporting your age and/or allowing an underage person(s) access
            to your account.
          </li>
        </ol>
      </div>
      <div className="sub-section">
        <div className="sub-header">USER-GENERATED CONTRIBUTIONS</div>
        <p>
          The Site does not offer users to submit or post content. We may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          “Contributions”). Contributions may be viewable by other users of the
          Site and through third-party websites. As such, any Contributions you
          transmit may be subjected in accordance with the Site Privacy Policy.
          When you create or make available any Contributions, you thereby
          represent and warrant that:
        </p>
        <p>
          <ol start="1" className="numbered-list">
            <li>
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
            </li>
            <li>
              You are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to
              authorize us, the Site, and other users of the Site to use your
              Contributions in any manner contemplated by the Site and these
              Terms of Use.
            </li>
            <li>
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Site and these
              Terms of Use.
            </li>
            <li>
              Your Contributions are not false, inaccurate, or misleading.
            </li>
            <li>
              Your Contributions are not unsolicited or unauthorized
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
            </li>
            <li>
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous, or otherwise
              objectionable (as determined by us).
            </li>
            <li>
              Your Contributions do not ridicule, mock, disparage, intimidate,
              or abuse anyone.
            </li>
            <li>
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
            </li>
            <li>
              Your Contributions do not violate any applicable law, regulation,
              or rule.
            </li>
            <li>
              Your Contributions do not violate the privacy or publicity rights
              of any third party.
            </li>
            <li>
              Your Contributions do not contain any material that solicits
              personal information from anyone under the age of 18 or exploits
              people under the age of 18 sexually or violently.
            </li>
            <li>
              Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of minors.
            </li>
            <li>
              Your Contributions do not include any offensive comments that are
              connected to race, national origin, gender, sexual preference, or
              physical handicap.
            </li>
            <li>
              Your Contributions do not otherwise violate or link to material
              that violated, any provision of these Terms of Use, or any
              applicable law or regulation.
            </li>
          </ol>
        </p>
        <p>
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">CONTRIBUTION LICENSE</div>
        <p>
          You and the Site agree that we may access, store, process, and use any
          information and personal data that you provide following the terms of
          the Privacy Policy and your choices (including settings).
        </p>
        <p>
          By submitting suggestions or other feedback regarding the Site, you
          agree that we can use and share such feedback for any purpose without
          compensation to you.
        </p>
        <p>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in an area on the site. You are
          solely responsible for your Contributions to the Site and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">GUIDELINES FOR REVIEWS</div>
        <p>
          We may provide you areas on the Site to leave reviews or ratings. When
          posting a review, you must comply with the following criteria: (1) you
          should have firsthand experience with the person/entity being
          reviewed; (2) your reviews should not contain offensive profanity, or
          abusive, racist, offensive, or hate language; (3) your reviews should
          not contain discriminatory references based on religion, race, gender,
          national origin, age, marital status, sexual orientation, or
          disability; (4) your reviews should not contain a reference to illegal
          activity; (5) you should not be affiliated with competitors if posting
          negative reviews; (6) you should not make any conclusions as to the
          legality of conduct; (7) you may not post any false or misleading
          statements, and (8) you may not organize a campaign encouraging others
          to post reviews, whether positive or negative.
        </p>
        <p>
          We may accept, reject, or remove reviews in our sole discretion. We
          have absolutely no obligation to screen reviews or to delete a review,
          even if anyone considers reviews objectionable or inaccurate. Reviews
          are not endorsed by us, and do not necessarily reflect our opinions or
          the views of any of our affiliates or partners. We do not assume
          liability for any review or for any claims, liabilities, or losses
          resulting from any review. By posting a review, you hereby grant to us
          a perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
          assignable, and sublicensable right and license to reproduce, modify,
          translate, transmit, by any means, display, perform, and/or distribute
          all content relating to reviews.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">SUBMISSIONS</div>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          (“Submissions”) provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the rights to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of proprietary
          rights in your Submissions.We may provide you areas on the Site to
          leave reviews or ratings. When posting a review, you must comply with
          the following criteria: (1) you should have firsthand experience with
          the person/entity being reviewed; (2) your reviews should not contain
          offensive profanity, or abusive, racist, offensive, or hate language;
          (3) your reviews should not contain discriminatory references based on
          religion, race, gender, national origin, age, marital status, sexual
          orientation, or disability; (4) your reviews should not contain a
          reference to illegal activity; (5) you should not be affiliated with
          competitors if posting negative reviews; (6) you should not make any
          conclusions as to the legality of conduct; (7) you may not post any
          false or misleading statements, and (8) you may not organize a
          campaign encouraging others to post reviews, whether positive or
          negative.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">SUBMISSIONS</div>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          (“Submissions”) provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the rights to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of proprietary
          rights in your Submissions.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">THIRD-PARTY WEBSITE AND CONTENT</div>
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites (“Third-Party Websites”) as well as articles photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties (“Third-Party Content”). Such
          Third-Party Websites and Third-Party Content are not investigated,
          monitored, or checked for accuracy, appropriateness, or completeness y
          us, and we are not responsible for any Third-Party Websites accessed
          through the Site or any Third-Party Content posted on, available
          through, or installed from the Site, including the content, accuracy,
          offensiveness, opinions, reliability, privacy practices, or other
          policies of or conntained in the Third-Party Websites or the
          Third-Party Content. Inclusion of, linking to, or permitting the use
          or installation of any Third-Party Websites or any Third-Party Content
          does not imply approval or endorsement by thereof by us. If you decide
          to leave the Site and access the Third-Party Websites or to use or
          install any Third-Party Content, you do so at your own risk, and you
          should be aware these Terms of Use no longer gover. You should review
          the applicable terms and policies, including privacy and data
          gathering practices, of any website to which you navigate from the
          Site or relating ot any applications you use or install from the Site.
          any purchase you make through Third-Party Websites will be through
          other websites and from other companies, and we take no responsibility
          whatsoever in relation to such purchases which are exclusively between
          yo and the applicable third party. You agree and acknowledge that we
          do not endorse the products or services offered on Third-Party
          Websites and you shall hold us harmless from an harm caused by your
          purchase of such products or services. Additionally, you shall hold us
          harmless from any losses sustained by you or harm caused ot you
          relating to or resulting in any way from any Third-Party Content or
          any contact with Third-Party Websites.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">SITE MANAGEMENT</div>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these Terms of Use; (2) take appropriate legal
          action against anyone who, in our sole discretion, violates the law or
          these Terms of Use, including without limitation, reporting such user
          to law enforcement authorities; (3) in our sole discretion and without
          limitation, refuse, restrict access to, limit the availability of, or
          disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Site or
          otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systerms; and (5) otherwise manage
          the Site in a manner designed to protect our rights and property and
          to facilitate the proper functioning of the Site.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">PRIVACY POLICY</div>
        <p>
          We care about data privacy and security. By using the Site, you agree
          to be bound by our Privarcy Policy posted on the Site, which is
          incorporated into these Terms of Use. Please be advised the Site is
          hosted in the United States. If you access the Site from any other
          region of the world with laws or other region of the world with laws
          or other requirements governing personal data collection, use, or
          disclosure that differ from applicable laws in the United States, the
          through your continued use of the Site, you are transferring your data
          to the United States, and you agree to have your data transferred to
          and processed in the United States.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">TERM AND TERMINATION</div>
        <p>
          These Terms of Use shall remain in full force and effect while you use
          the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE,
          WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
          LIABILITY, DENYY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
          CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
          INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
          INFORMATION THA YOU POSTED AT ANY TIME, WITHOUT NOTICE, IN OUR SOLE
          DISCRETION.
        </p>
        <p>
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under you name,
          a fake or borrowed name, or the name of any third party, even if you
          may be acting on behalf of the third party. In addition to terminating
          or suspending you account, we reserve the right ot take appropriate
          legal action, including without limitation pursing civil, criminal and
          injunctive redress.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">MODIFICATIONS AND INTERRUPTIONS</div>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update an information on our
          Site. we alson reserve the right to modify or disocnting all or opart
          of the Site without notice at any time. We will not e liale to you or
          any third party for any modification, price change, suspension, or
          discontinuance of the Site.
        </p>
        <p>
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need ot perform
          maintenance related to the Site, resulting in interruptions, delays,
          or erros. We reserve the right to change, revise, update, suspend,
          disouctinue, or otherwise modify the Site at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site during an downtime or
          discontinuance of the Site. Nothing in these Terms of Use will be
          construed to obligate us to maintain and support the Site or to supply
          any corrections, updates, or release in connnectin therewith.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">GOVERNING LAW</div>
        <p>
          These Terms of Use and your use of the Site are governed by and
          construed in accordance with the laws of the State of Illinois
          applicable to agreements made and to be entirely performed within the
          State of Illinois, without regard to its conflict of law principles.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">DISPUTE RESOLUTION</div>
        <div className="addendum">
          <div className="addendum-header">INFORMAL NEGOTIATIONS</div>
          <p>
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Terms of Use (each a
            “Dispute” and collectively, the “Disputes”) brought by either you or
            us (individually, a “Party” and collectively, the “Parties”), the
            Parties agree to first attempt to negotiate any Dispute (execpt
            those Disputes expressly provided below) informally for at least
            thirty (30) days before initiating arbitration. Such informal
            neogtaions commence upon written notice from one Party to the other
            Party
          </p>
        </div>
        <div className="addendum">
          <div className="addendum-header">BINDING ARBITRATIONS</div>
          <p>
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved through binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbritation Rules of the merican Arbitration Association (“AAA”)
            and, where appropriate, the AAA’s Supplementary Procedures for
            Consumer Related Disputes (“AAA Consumer Rules”), both of which are
            available at the AAA website: www.adr.org . Your arbitration fees
            and your share of the arbitrator compensation shall be governed by
            the AAA Consumer Rules and, where appropriate, limited y the AAA
            Consumer Rules. The arbitration may be conducted in person, through
            the submission of documents, by phone, or online. The arbitrator
            will make a decision in writing, but need not provided a statement
            of reason unless requested by either Party. The arbitrator must
            follow applicable law, and any award may be challenged if the
            arbitrator fails to do so. Except where otherwise required by the
            applicable AAA rules or applicable law, the arbitration will take
            place in Cook County, Illinois. Except as otherwise provided herein,
            the Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitraation, or to confirm, modify, vacate, or
            enter udgement on the award entered by the arbitrator.
          </p>
          <p>
            If for any reason, a Dispute proceeds in court rather than
            aritration, the Dispute shall be commenced or prosecuted in state
            and federal courts located in Cook County, Illinois, and the Parties
            hereby consent to , and waive all defenses of lack of personal
            jurisdiction, and waive all defenses of lack of personal
            jurisdiction, and forum non conveniens with respect to venue and
            jurisdiction in such state and federal courts. Application of the
            United Nations Conventiono on Contracts for the International Sale
            of Goods and the Uniform Computer Information Transaction Act
            (UCITA) is excluded from these Terms of Use
          </p>
          <p>
            In no event shall any Dispute brought by either Party related in any
            way to the Site be commenced more that one (1) years after the cause
            of action arose. If this provision is found to illegal or
            unenforceable, then neither Party will elecet to arbitrate any
            Dispute failling within that portion of this provision found to
            illegal or unenforceable, and such Dispute sall be decided by court
            of competent jurisdiction within the courts listed for jurisdiction
            aove, and the Parties agree to submit to the personal jurisdiction
            of that court.
          </p>
        </div>
        <div className="addendum">
          <div className="addendum-header">RESTRICTIONS</div>
          <p>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to e
            arbitrated on a class-action asis or to utilize class action
            procedures; and © there is no right or authority for any dispute to
            be brought in a purported representative capacity on behalf of the
            general public or any other persons.
          </p>
        </div>
        <div className="addendum">
          <div className="addendum-header">
            EXCEPTIONS TO INFORMAL NEGOTIATIONS AND ARBITRATION
          </div>
          <p>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiation and binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegale or unenforceable, then neither Party will elect to
            arbitrate an Dispute falling within that portion of this provision
            found to be illegal or unenforceble and such Dispute shall be
            decided by a court of competent jurisdiction within the courts
            listed for jurisdiction above, and the Parties agree to submit to
            the personal jurisdiction of that court.
          </p>
        </div>
      </div>
      <div className="sub-section">
        <div className="sub-header">INDEMNIFICATION</div>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries and affiliates, as well as all of our respective
          officers, agents, partners, and employees, from and against any loss,
          damage, responsibility, claim, or demand made by any third party due
          to or arising out of: (1) use of the Site; (2) breach of these Terms
          of Use; (3) any breach of your representations and warranties set
          forth in these Terms of Use; (4) your violation of the rights of a
          third party, including ut not limited to intellectual property rights;
          or (5) any overt harmful act toward any other user of the Site with
          whom you connected via the Site. Notwithstanding the foregoing, we
          reserve the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us,
          and yoou agree to cooperate, at our expense,with our defense of such
          claims. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aware of it.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">USER DATA</div>
        <p>
          We shall maintain certain data that you provide to the Site for the
          purpose of managing the Site's operation, as well as data about your
          usage of the Site. Despite the fact that we maintain frequent data
          backups, you are entirely responsible for all data that you provide or
          that pertains to any activity you have performed while using the Site.
          You agree that we will have no obligation to you for any loss or
          corruption of such data, and you thus waive any right of action you
          may have against us as a result of such loss or corruption of such
          data.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">
          ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </div>
        <p>
          Electronic communications include visiting the Site, sending us
          emails, and completing online forms. You agree to receive electronic
          communications and agree that all agreements, notices, disclosures,
          and other communications we give to you electronically, via email, and
          on the Site, fulfill any legal need that such communication be in
          writing. YOU AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
          ORDERS, AND OTHER RECORDS, AS WELL AS THE ELECTRONIC DELIVERY OF
          NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITITATED OR COMPLETED
          BY US OR THROUGH THE SITE. You hereby waive all rights or obligations
          under any statutes, regulations, rules, ordinances, or other
          legislation in any country that require an original signature,
          delivery, or keeping of non-electronic records, or payments or credit
          granting by any means other than electronic methods.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">CALIFORNIA USERS AND RESIDENTS</div>
        <p>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">MISCELLANEOUS</div>
        <p>
          These Terms of Use, as well as other policies or operating guidelines
          issued by us on or in relation to the Site, constitute the complete
          agreement and understanding between you and us. Our omission to assert
          or enforce any right or term of these Terms of Service does not
          constitute a waiver of such right or provision. These Terms of Service
          apply to the maximum extent permitted by law. At any time, we may
          assign some or all of our rights and responsibilities to others. We
          will not be accountable or responsible for any loss, damage, delay, or
          failure to act caused by any cause beyond our reasonable control. If
          any provision or portion of a provision of these Terms of Use is found
          to be illegal, invalid, or unenforceable, that provision or part of
          the provision is deemed severable from these Terms of Use and has no
          bearing on the validity and enforceability of any remaining terms.As a
          result of these Site Terms of Use, no joint venture, partnership,
          employment, or agency relationship exists between you and us. You
          agree that the mere fact that you authored these Terms of Service will
          not be used against us. You thus waive any and all defenses you may
          have based on the electronic form of these Terms of Use and the
          parties' failure to sign these Terms of Use.
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">CONTACT US</div>
        <p>
          In order to resolve a complaint regarding the Site or to receive
          further information regarding use of the Site, please contact us at:
          Merp Inc.
          <br />
          Hazel Crest, IL 60429
          <br />
          United States
          <br />
          info@merp.page
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">RETURNS/Refunds</div>
        <p>
          <ul style={{listStyleType:"unset"}} className="numbered-list">
            <li>Items are FINAL SALE and cannot be returned or exchanged</li>
            <li>
              No Cannabis Product Nor Accessories May Be Returned or Exchanged
            </li>
            <li>
              Any Cannabis Product OR Accessories issues Shall Be Handle With
              That Specific Brand/Deispensary not Merp Inc.
            </li>
          </ul>
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">EXCEPTIONS</div>
        <p>
        For defective or damaged products, please contact us at info@merp.page to arrange a refund of exchange
        </p>
      </div>
      <div className="sub-section">
        <div className="sub-header">QUESTIONS</div>
        <p>
        If you have any questions concerning our return polic; please contact us at <a href="#">info@merp.page</a>.
        </p>
      </div>
    </div>
  );
}
