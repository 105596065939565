import React from 'react'
import { Link } from 'react-router-dom';

export default function JobResources() {
   const job ='Job'
    return (
        <div className="shop-image" id="bipoc">
          {/* <img src="/images/black_owned_badge_3.png" alt="shop-bipoc" /> */}
          <div className="details">
            <h2>
              <span>Job Resources</span> 
            </h2>
            <p>
            A resource list of job listings and organizations that support in finding jobs for people who were formally incarcerated.
            </p>
            <div className="more">
              <Link to={`/education_&_resources/${job}`} className="read-more">
                Browse <span>Now</span>
              </Link>
            </div>
          </div>
        </div>
      );
}
