import {
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_FAILURE,
  USER_SIGNOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAILURE,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAILURE,
  USER_UPDATE_PROFILE_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_DELETE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_UPDATE_RESET,
  USER_DETAILS_RESET,
  USER_TOPSELLERS_LIST_REQUEST,
  USER_TOPSELLERS_LIST_SUCCESS,
  USER_TOPSELLERS_LIST_FAILURE,
  SELLER_CHART_DATA_REQUEST,
  SELLER_CHART_DATA_SUCCESS,
  SELLER_CHART_DATA_FAILURE,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_INFO_REQUEST,
  RESET_PASSWORD_INFO_SUCCESS,
  RESET_PASSWORD_INFO_FAILURE,
  REFERRAL_CODE_REQUEST,
  REFERRAL_CODE_SUCCESS,
  REFERRAL_CODE_FAILURE,
  NOTIFY_WHEN_NEAR_REQUEST,
  NOTIFY_WHEN_NEAR_SUCCESS,
  NOTIFY_WHEN_NEAR_FAILURE,
  LIST_WITH_US_REQUEST,
  LIST_WITH_US_SUCCESS,
  LIST_WITH_US_FAILURE,
  ADDTO_EMAILLIST_SUCCESS,
  ADDTO_EMAILLIST_FAILURE,
  ADDTO_EMAILLIST_REQUEST,
  GET_QUESTION_REQUEST,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  GET_QUESTION_RESET,
  CAST_MY_VOTE_REQUEST,
  CAST_MY_VOTE_SUCCESS,
  CAST_MY_VOTE_FAILURE,
  SUBMIT_NEW_QUESTION_REQUEST,
  SUBMIT_NEW_QUESTION_SUCCESS,
  SUBMIT_NEW_QUESTION_FAILURE,
  SUBMIT_NEW_QUESTION_RESET,
  FETCH_USER_REWARDS_REQUEST,
  FETCH_USER_REWARDS_SUCCESS,
  FETCH_USER_REWARDS_FAILURE,
} from "../constants/userConstants";

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true };
    case USER_REGISTER_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const referralReducer = (state = {}, action) => {
  switch (action.type) {
    case REFERRAL_CODE_REQUEST:
      return { loading: true };
    case REFERRAL_CODE_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case REFERRAL_CODE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true };
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case RESET_PASSWORD_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const resetPasswordInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_INFO_REQUEST:
      return { loading: true };
    case RESET_PASSWORD_INFO_SUCCESS:
      return { loading: false, success: true, newData: action.payload };
    case RESET_PASSWORD_INFO_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSignInReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAILURE:
      return { loading: false, error: action.payload };
    case USER_SIGNOUT:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAILURE:
      return { loadng: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { loading: true };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_PROFILE_FAILURE:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRewardsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case FETCH_USER_REWARDS_REQUEST:
      return { loading: true };
    case FETCH_USER_REWARDS_SUCCESS:
      return { loading: false, rewards: action.payload };
    case FETCH_USER_REWARDS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const emailSignupreducer = (state = {}, action) => {
  switch (action.type) {
    case ADDTO_EMAILLIST_REQUEST:
      return { laoding: true };
    case ADDTO_EMAILLIST_SUCCESS:
      return { loading: false, email: action.payload };
    case ADDTO_EMAILLIST_FAILURE:
      return { loadng: false, error: action.payload };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userTopSellerListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_TOPSELLERS_LIST_REQUEST:
      return { loading: true };
    case USER_TOPSELLERS_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_TOPSELLERS_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellerChartDataReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case SELLER_CHART_DATA_REQUEST:
      return { loading: true };
    case SELLER_CHART_DATA_SUCCESS:
      return { loading: false, chartData: action.payload };
    case SELLER_CHART_DATA_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const emailValidationReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case VALIDATE_TOKEN_REQUEST:
      return { loading: true };
    case VALIDATE_TOKEN_SUCCESS:
      return { loading: false, success: true };
    case VALIDATE_TOKEN_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const notifyWhenNear = (state = {}, action) => {
  switch (action.type) {
    case NOTIFY_WHEN_NEAR_REQUEST:
      return { loading: true };
    case NOTIFY_WHEN_NEAR_SUCCESS:
      return { loading: false, success: true };
    case NOTIFY_WHEN_NEAR_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listWithUsReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_WITH_US_REQUEST:
      return { loading: true };
    case LIST_WITH_US_SUCCESS:
      return { loading: false, success: true };
    case LIST_WITH_US_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QUESTION_REQUEST:
      return { loading: true };
    case GET_QUESTION_SUCCESS:
      return { loading: false, success: true, question: action.payload };
    case GET_QUESTION_FAILURE:
      return { loading: false, error: false };
    case GET_QUESTION_RESET:
      return {};
    default:
      return state;
  }
};

export const castMyVoteReducer = (state = {}, action) => {
  switch (action.type) {
    case CAST_MY_VOTE_REQUEST:
      return { loading: true };
    case CAST_MY_VOTE_SUCCESS:
      return { loading: false, success: true, question: action.payload };
    case CAST_MY_VOTE_FAILURE:
      return { loading: false, error: false };
    // case GET_QUESTION_RESET:
    //   return {}
    default:
      return state;
  }
};
export const submitQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_NEW_QUESTION_REQUEST:
      return { loading: true };
    case SUBMIT_NEW_QUESTION_SUCCESS:
      return { loading: false, success: true };
    case SUBMIT_NEW_QUESTION_FAILURE:
      return { loading: false, error: false };
    case SUBMIT_NEW_QUESTION_RESET:
      return {};
    default:
      return state;
  }
};
