import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MapContainer from "../../components/MapContainer";
import "./dispensaryScreen.css";
import {
  detailDispensary,
  createDispensaryReview,
} from "../../actions/productActions";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import { Image } from "cloudinary-react";
import { Link } from "react-router-dom";
import { PRODUCT_CREATE_REVIEW_RESET } from "../../constants/productConstants";
import Rating from "../../components/Rating";
import Badges from "../../components/Badges";
import BrandCard from "../../components/BrandCard/BrandCard";
import { Button, Modal } from "react-bootstrap";

export default function DispensaryScreen(props) {
  const numElements = 6;

  const dispensaryId = props.match.params.id;
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const [isSustainable, setIsSustainable] = useState(false);
  const [isBlackOwned, setBlackOwned] = useState(false);
  const [isIndigenousOwned, setIndigenousOwned] = useState(false);
  const [isPOCOwned, setPOCOwned] = useState(false);
  const [isWomanOwned, setWomanOwned] = useState(false);
  const [isLgbt, setLgbt] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const [equityVerified, setEquityVerified] = useState(false);
  const [isMinorityOwned, setMinorityOwned] = useState(
    isPOCOwned || isWomanOwned || isBlackOwned || isIndigenousOwned || isLgbt
      ? true
      : false
  );

  const [searchedBrands, setSearchedBrands] = useState([]);

  const userSignin = useSelector((state) => state.userSignin);

  const { userInfo } = userSignin;

  const dispensaryDetails = useSelector((state) => state.dispensaryDetails);
  const { loading, error, dispensary } = dispensaryDetails;

  let brands = [];
  if (dispensary && dispensary.brands && dispensary.brands.length > 0) {
    for (let i = 0; i < numElements; i++) {
      if (
        !brands.includes(
          dispensary.brands[
            Math.floor(Math.random() * dispensary.brands.length)
          ].name
        )
      ) {
        brands.push(
          dispensary.brands[
            Math.floor(Math.random() * dispensary.brands.length)
          ]
        );
      } else {
        numElements++;
      }
    }
  }

  const [searchCategory, setSearchCategory] = useState("");
  const [show, setShow] = useState(false);
  const [categoryShow, setCatgoryShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleCategoryClose = () => {
    setCatgoryShow(false);
  };

  const handleCategoryShow = () => setCatgoryShow(true);

  const sortedArr =
    dispensary && dispensary.brands
      ? dispensary.brands.sort((a, b) => a.name - b.name)
      : [];

  function checkEntry(value) {
    const res = sortedArr.filter((obj) =>
      obj.name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchedBrands(res);
  }

  function getSearchCategory() {
    const res = sortedArr.filter((obj) => {
      return obj.product_type && obj.product_type[searchCategory] === true;
    });
    let finalResult = getAdvancedCategories(res);

    if (finalResult.length === 0) {
      setNoResults(true);
    } else {
      setSearchedBrands(finalResult);
    }
  }

  function getAdvancedCategories(filteredArr) {
    let arr = [];
    if (
      isMinorityOwned &&
      !isBlackOwned &&
      !isIndigenousOwned &&
      !isLgbt &&
      !isPOCOwned &&
      !isWomanOwned
    ) {
      arr.push(
        "isBlackOwned",
        "isIndigenousOwned",
        "isLgbt",
        "isPOCOwned",
        "isWomanOwned"
      );
    } else {
      if (isWomanOwned) arr.push("isWomanOwned");
      if (isBlackOwned) arr.push("isBlackOwned");
      if (isIndigenousOwned) arr.push("isIndigenousOwned");
      if (isLgbt) arr.push("isLgbt");
      if (isPOCOwned) arr.push("isPOCOwned");
      if (isWomanOwned) arr.push("isWomanOwned");
      if (equityVerified) arr.push("equityVerified");
      if (isSustainable) arr.push("isSustainable");
    }

    let res = [];
    if (filteredArr.length > 0) {
      for (let i = 0; i < filteredArr.length; i++) {
        let brand = filteredArr[i];
        for (let j = 0; j < arr.length; j++) {
          if (brand.badges[arr[j]].status && !res.includes(brand)) {
            res.push(brand);
          }
        }
      }
    }

    return res;
  }

  const resetCategories = () => {
    setIndigenousOwned(false);
    // setIsUnionized(false);
    setMinorityOwned(false);
    setPOCOwned(false);
    setWomanOwned(false);
    setBlackOwned(false);

    setEquityVerified(false);
    setIsSustainable(false);
    setLgbt(false);
    setNoResults(false);

    setSearchCategory("");

    handleCategoryClose();

    props.history.push(`/dispensary/${dispensaryId}`);
  };

  const productCreateReview = useSelector((state) => state.productCreateReview);
  const {
    loading: loadingReviewCreate,
    error: errorReviewCreate,
    success: successReviewCreate,
  } = productCreateReview;

  // const ref = useRef(null);
  // const [map, setMap] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(detailDispensary(dispensaryId));

    if (successReviewCreate) {
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
      window.alert("review submitted successfully");
      setRating(0);
      setComment("");
    }
  }, [dispatch, dispensaryId, successReviewCreate]);

  const unlike = "/favorite.png";
  const like = "/star.png";

  const submitHandler = (e) => {
    e.preventDefault();
    if (comment && rating && userInfo) {
      dispatch(
        createDispensaryReview(dispensaryId, {
          comment,
          rating,
          name: userInfo.username
            ? userInfo.username
            : `${userInfo.firstName} ${userInfo.lastName[0]}.`,
          userId: userInfo._id,
        })
      );
    } else {
      window.alert(
        "please make sure you have written a comment and selected a star rating for review"
      );
    }
  };

  const [favorite, setFavorite] = useState(false);

  return (
    <div style={{ padding: "2rem" }}>
      {userInfo && (
        <div
          className="favorite-button"
          onClick={() => setFavorite((prevLike) => !prevLike)}
        >
          {favorite ? <img src={like} /> : <img src={unlike} />}
        </div>
      )}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Image
              cloudName="merp"
              publicId={dispensary.logo}
              secure="true"
              width="500"
              crop="scale"
              className="img-fluid"
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <div>
              <a
                href={dispensary.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingTop: "2rem",
                    fontSize: "3rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      fontSize: "3rem",
                    }}
                  >
                    <b>{dispensary.name}</b>
                    <div style={{ paddingLeft: "1rem" }}>
                      {" "}
                      {dispensary.claimed === "claimed" ? (
                        <img src="/images/verified.svg" alt="verfied badge" />
                      ) : (
                        <button
                          disabled
                          id="unclaimed-button"
                          style={{ borderRadius: "12px" }}
                        >
                          {" "}
                          Unclaimed{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </a>
              <div style={{ fontSize: "2rem" }}>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  {" "}
                  {dispensary.address}
                  <br />
                  {dispensary.city},{dispensary.state} {dispensary.postalCode}
                </a>
              </div>

              {dispensary.telephone && (
                <div style={{ fontSize: "2rem" }}>
                  Phone: {dispensary.telephone}
                </div>
              )}
              {dispensary.reviews.length > 0 && (
                <Rating
                  rating={dispensary.rating}
                  numReviews={dispensary.reviews.length}
                />
              )}

              <Badges badges={dispensary.badges} />

              <div
                className="dispensary-banner"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "80%" }}>
                  <p style={{ fontSize: "1.75rem" }}>
                    {dispensary.description}
                  </p>
                </div>
              </div>
              <div className="container-fluid">
                <div style={{ height: 400, width: "auto" }}>
                  <MapContainer
                    latLng={{ lat: dispensary.lat, lng: dispensary.lng }}
                  />
                </div>
              </div>
              {loading ? (
                <LoadingBox></LoadingBox>
              ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
              ) : (
                <div className="brand-section">
                  <div className="brand-section-title">Brands</div>
                  <p className="subtext">
                    Don't see the brand in your hand listed below? Search below!
                  </p>
                  <div>
                    <form
                      className="search"
                      style={{ paddingBottom: "5px", paddingTop: "7px" }}
                    >
                      <div className="nav-row">
                        <input
                          type="text"
                          name="q"
                          id="q"
                          onChange={(e) => checkEntry(e.target.value)}
                        ></input>
                        <button className="primary" type="submit">
                          Merp
                        </button>
                      </div>
                    </form>
                  </div>
                  <div>
                    <div className="search-header">
                      <section className="row-nowrap search-row scroll">
                        <div className="search-filter-criteria">
                          <>
                            <Modal
                              className="modal-filter"
                              show={show}
                              onHide={handleClose}
                            >
                              <Modal.Header closeButton>
                                <div class="modal-title">
                                  <strong>Advanced Category</strong>
                                </div>
                              </Modal.Header>

                              <Modal.Body>
                                <div style={{ fontSize: "1.5rem" }}>
                                  Please select your desired category filter(s)
                                  :{" "}
                                </div>

                                <form>
                                  <ul className="search-categories-dispensaries">
                                    <li>
                                      <div>
                                        <input
                                          id="isBlackOwned"
                                          type="checkbox"
                                          checked={isMinorityOwned}
                                          onChange={(e) =>
                                            setMinorityOwned(e.target.checked)
                                          }
                                        ></input>{" "}
                                        <label htmlFor="isBlackOwned">
                                          {" "}
                                          Minority Owned
                                        </label>
                                      </div>
                                      {isMinorityOwned && (
                                        <ul className="search-categories-dispensaries">
                                          <li>
                                            <div>
                                              <input
                                                id="isBlackOwned"
                                                type="checkbox"
                                                checked={isBlackOwned}
                                                onChange={(e) =>
                                                  setBlackOwned(
                                                    e.target.checked
                                                  )
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isBlackOwned">
                                                {" "}
                                                Black Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isIndigenousOwned"
                                                type="checkbox"
                                                checked={isIndigenousOwned}
                                                onChange={(e) =>
                                                  setIndigenousOwned(
                                                    e.target.checked
                                                  )
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isIndigenousOwnedd">
                                                {" "}
                                                Indigenous Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isPOCOwned"
                                                type="checkbox"
                                                checked={isPOCOwned}
                                                onChange={(e) =>
                                                  setPOCOwned(e.target.checked)
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isPOCOwned">
                                                {" "}
                                                POC Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isWomanOwned"
                                                type="checkbox"
                                                checked={isWomanOwned}
                                                onChange={(e) =>
                                                  setWomanOwned(
                                                    e.target.checked
                                                  )
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isWomanOwned">
                                                {" "}
                                                Woman Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isLgbt"
                                                type="checkbox"
                                                checked={isLgbt}
                                                onChange={(e) =>
                                                  setLgbt(e.target.checked)
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isLgbt">
                                                {" "}
                                                LGBT Owned
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      )}
                                    </li>
                                    <li>
                                      <div>
                                        <input
                                          id="equityPlanVerified"
                                          type="checkbox"
                                          checked={equityVerified}
                                          onChange={(e) =>
                                            setEquityVerified(e.target.checked)
                                          }
                                        ></input>{" "}
                                        <label htmlFor="equityPlanVerified">
                                          Equity Plan Verified
                                        </label>
                                      </div>
                                    </li>

                                    <li>
                                      <div>
                                        <input
                                          id="sustainable"
                                          type="checkbox"
                                          checked={isSustainable}
                                          onChange={(e) =>
                                            setIsSustainable(e.target.checked)
                                          }
                                        ></input>{" "}
                                        <label htmlFor="sustainable">
                                          Sustainable
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </form>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  type="button"
                                  class="btn btn-default"
                                  onClick={handleClose}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  // onClick={() => resetCategories()}
                                >
                                  Reset
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={() => {
                                    getAdvancedCategories();
                                  }}
                                >
                                  Apply
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </>
                          <Button
                            className="button-search"
                            onClick={handleCategoryShow}
                          >
                            Filter(s)
                          </Button>
                          {/* <Button
                            className="button-search"
                            onClick={handleShow}
                          >
                            Advanced Category
                          </Button> */}

                          <>
                            <Modal
                              className="modal-filter"
                              show={categoryShow}
                              onHide={handleCategoryClose}
                            >
                              <Modal.Header closeButton>
                                <div class="modal-title" id="myModalLabel">
                                  <strong>filter(s)</strong>
                                </div>
                              </Modal.Header>
                              <Modal.Body>
                                <div style={{ fontSize: "1.5rem" }}>
                                  <strong>Please select a category : </strong>
                                </div>
                                <form>
                                  <ul className="search-categories-dispensaries">
                                    <li>
                                      <div>
                                        <div>
                                          <input
                                            id="flower"
                                            type="radio"
                                            value={searchCategory}
                                            checked={
                                              searchCategory === "flower"
                                            }
                                            onClick={() =>
                                              setSearchCategory("flower")
                                            }
                                          ></input>{" "}
                                          <label for="flower">Flower</label>
                                        </div>
                                      </div>
                                    </li>
                                    {/* <li>
                            <div>
                              <div>
                                <input
                                  id="CBDShop"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "CBDShop"}
                                  onClick={(e) => setSearchCategory("CBDShop")}
                                ></input>{" "}
                                <label for="CBDShop"> CBD Shops</label>
                              </div>
                            </div>
                          </li> */}
                                    <li>
                                      <div>
                                        <div>
                                          <input
                                            id="topical"
                                            type="radio"
                                            value={searchCategory}
                                            checked={
                                              searchCategory === "topical"
                                            }
                                            onClick={() =>
                                              setSearchCategory("topical")
                                            }
                                          ></input>{" "}
                                          <label for="topical">Topicals</label>
                                        </div>
                                      </div>
                                    </li>
                                    {/* <li>
                            <div>
                              <div>
                                <input
                                  id="HouseholdHemp"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "HouseholdHemp"}
                                  onClick={(e) =>
                                    setSearchCategory("HouseholdHemp")
                                  }
                                ></input>{" "}
                                <label for="HouseholdHemp">
                                  {" "}
                                  Household Hemp
                                </label>
                              </div>
                            </div>
                          </li> */}
                                    {/* <li>
                            <div>
                              <div>
                                <input
                                  id="petFriendly"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "petFriendly"}
                                  onClick={(e) =>
                                    setSearchCategory("petFriendly")
                                  }
                                ></input>{" "}
                                <label for="petFriendly"> Pet-friendly</label>
                              </div>
                            </div>
                          </li> */}

                                    <li>
                                      <div>
                                        <div>
                                          <input
                                            id="concentrate"
                                            type="radio"
                                            value={searchCategory}
                                            checked={
                                              searchCategory === "concentrate"
                                            }
                                            onClick={() =>
                                              setSearchCategory("concentrate")
                                            }
                                          ></input>{" "}
                                          <label for="concentrate">
                                            {" "}
                                            Concentrates
                                          </label>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div>
                                        <div>
                                          <input
                                            id="edible"
                                            type="radio"
                                            value={searchCategory}
                                            checked={
                                              searchCategory === "edible"
                                            }
                                            onClick={() =>
                                              setSearchCategory("edible")
                                            }
                                          ></input>{" "}
                                          <label for="edible"> Edibles</label>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div>
                                        <div>
                                          <input
                                            id="preroll"
                                            type="radio"
                                            value={searchCategory}
                                            checked={
                                              searchCategory === "preroll"
                                            }
                                            onClick={() =>
                                              setSearchCategory("preroll")
                                            }
                                          ></input>{" "}
                                          <label for="preroll"> Prerolls</label>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div>
                                        <div>
                                          <input
                                            id="accessory"
                                            type="radio"
                                            value={searchCategory}
                                            checked={
                                              searchCategory === "accessory"
                                            }
                                            onClick={(e) =>
                                              setSearchCategory("accessory")
                                            }
                                          ></input>{" "}
                                          <label for="accessory">
                                            {" "}
                                            Accessories (vapes, bongs, etc..)
                                          </label>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <div style={{ fontSize: "1.5rem" }}>
                                    <strong>
                                      Please select a advanced category :{" "}
                                    </strong>
                                  </div>
                                  <ul className="search-categories-dispensaries">
                                    <li>
                                      <div>
                                        <input
                                          id="isBlackOwned"
                                          type="checkbox"
                                          checked={isMinorityOwned}
                                          onChange={(e) =>
                                            setMinorityOwned(e.target.checked)
                                          }
                                        ></input>{" "}
                                        <label htmlFor="isBlackOwned">
                                          {" "}
                                          Minority Owned
                                        </label>
                                      </div>
                                      {isMinorityOwned && (
                                        <ul className="search-categories-dispensaries">
                                          <li>
                                            <div>
                                              <input
                                                id="isBlackOwned"
                                                type="checkbox"
                                                checked={isBlackOwned}
                                                onChange={(e) =>
                                                  setBlackOwned(
                                                    e.target.checked
                                                  )
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isBlackOwned">
                                                {" "}
                                                Black Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isIndigenousOwned"
                                                type="checkbox"
                                                checked={isIndigenousOwned}
                                                onChange={(e) =>
                                                  setIndigenousOwned(
                                                    e.target.checked
                                                  )
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isIndigenousOwnedd">
                                                {" "}
                                                Indigenous Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isPOCOwned"
                                                type="checkbox"
                                                checked={isPOCOwned}
                                                onChange={(e) =>
                                                  setPOCOwned(e.target.checked)
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isPOCOwned">
                                                {" "}
                                                POC Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isWomanOwned"
                                                type="checkbox"
                                                checked={isWomanOwned}
                                                onChange={(e) =>
                                                  setWomanOwned(
                                                    e.target.checked
                                                  )
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isWomanOwned">
                                                {" "}
                                                Woman Owned
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div>
                                              <input
                                                id="isLgbt"
                                                type="checkbox"
                                                checked={isLgbt}
                                                onChange={(e) =>
                                                  setLgbt(e.target.checked)
                                                }
                                              ></input>{" "}
                                              <label htmlFor="isLgbt">
                                                {" "}
                                                LGBT Owned
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      )}
                                    </li>
                                    <li>
                                      <div>
                                        <input
                                          id="equityPlanVerified"
                                          type="checkbox"
                                          checked={equityVerified}
                                          onChange={(e) =>
                                            setEquityVerified(e.target.checked)
                                          }
                                        ></input>{" "}
                                        <label htmlFor="equityPlanVerified">
                                          Equity Plan Verified
                                        </label>
                                      </div>
                                    </li>

                                    <li>
                                      <div>
                                        <input
                                          id="sustainable"
                                          type="checkbox"
                                          checked={isSustainable}
                                          onChange={(e) =>
                                            setIsSustainable(e.target.checked)
                                          }
                                        ></input>{" "}
                                        <label htmlFor="sustainable">
                                          Sustainable
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </form>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  type="button"
                                  class="btn btn-default"
                                  onClick={handleCategoryClose}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  onClick={() => resetCategories()}
                                >
                                  Reset
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={() => getSearchCategory()}
                                >
                                  Apply
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </>

                          {/* <button
                  type="button"
                  className="button-search"
                  data-toggle="modal"
                  data-target="#locationModal"
                >
                  Location
                </button> */}
                          <div
                            class="modal fade"
                            id="locationModal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="myModalLabel"
                          >
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  {/* <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button> */}
                                  <div class="modal-title" id="myModalLabel">
                                    <strong>Locations</strong>
                                  </div>
                                </div>
                                <div class="modal-body">
                                  <div style={{ fontSize: "1.5rem" }}>
                                    Please select your desired location(s) :{" "}
                                  </div>

                                  <form>
                                    <ul className="search-categories-dispensaries"></ul>
                                  </form>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-default"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    // onClick={() => resetLocations()}
                                  >
                                    Reset
                                  </button>
                                  <button type="button" class="btn btn-primary">
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <button type="button" className="button-search">
              
            </button> */}
                        </div>
                        {/* <div className="filters">
                          Sort by{" "}
                          <select
                            // className="button-search"
                            style={{ fontSize: "1rem" }}
                            className="sort-filter"
                            value={filterOrder}
                            onChange={(e) => {
                              setFilterOrder(e.target.value);
                              props.history.push(
                                getFilterUrl({ order: e.target.value })
                              );
                            }}
                          >
                            <option value="newest">Newest Arrivals</option>
                            <option value="lowest">Price:Low to High</option>
                            <option value="highest">Price: High to Low</option>
                            <option value="toprated">Customer Review</option>
                          </select>
                        </div> */}
                      </section>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div>
                      <div className="col-md-11 col-12 p-0">
                        <div style={{ paddingLeft: "2rem" }}>
                          <div className="row center">
                            {noResults ? (
                              <div
                                style={{
                                  fontSize: "1.5rem",
                                }}
                              >
                                <strong>
                                  No brand(s) matching your search criteria
                                </strong>
                              </div>
                            ) : dispensary &&
                              searchedBrands.length === 0 &&
                              brands.length > 0 ? (
                              brands.map((brand) => <BrandCard brand={brand} />)
                            ) : (
                              searchedBrands.map((brand) => (
                                <BrandCard brand={brand} />
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="col-md-8 col-12">
                <div className="text-center text-secondary">
                  <div style={{ fontSize: "2.5rem" }}>Products</div>
                  {products && products.length === 0 && (
                    <MessageBox>No Products Found</MessageBox>
                  )}
                  <div className="row p-0">
                    {products &&
                      products.map((product) => (
                        <Product key={product._id} product={product} />
                      ))}
                  </div>
                </div>
              </div> */}

              <div id="reviews">
                <ul>
                  <li>
                    {userInfo ? (
                      <form className="form" onSubmit={submitHandler}>
                        <div style={{ fontSize: "2rem" }}>
                          <strong>Write A Customer Review</strong>
                        </div>
                        <div style={{ fontSize: "1.5rem" }}>
                          <label htmlFor="rating">Overal Rating</label>
                          <select
                            id="rating"
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                          >
                            <option value="">Select...</option>
                            <option value="1">1-Poor</option>
                            <option value="2">2-Fair</option>
                            <option value="3">3-Good</option>
                            <option value="4">4-Very Good</option>
                            <option value="5">5-Excellent</option>
                          </select>
                        </div>
                        <div style={{ fontSize: "1.5rem" }}>
                          <label htmlFor="comment">Comment</label>
                          <textarea
                            id="comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></textarea>
                        </div>
                        <div>
                          <label />
                          <button className="primary" type="submit">
                            Submit
                          </button>
                          <div>
                            {loadingReviewCreate && <LoadingBox></LoadingBox>}
                            {errorReviewCreate && (
                              <MessageBox variant="danger">
                                {errorReviewCreate}
                              </MessageBox>
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      <MessageBox>
                        Please <Link to="/signin">sign in</Link> to write review
                      </MessageBox>
                    )}
                  </li>
                  <div style={{ fontSize: "2rem" }}>
                    <strong>Reviews</strong>
                  </div>
                  {/* {dispensary.reviews.length === 0 && (
                    <MessageBox>There are no reviews</MessageBox>
                  )} */}
                  {dispensary.reviews.length === 0 && (
                    <div>
                      <span
                        className="reviews-text"
                        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                      >
                        There are currently no reviews, be the first and get a
                        <Link to="/"> special gift</Link> from Merp!
                      </span>
                    </div>
                  )}
                  {dispensary.reviews.map((review) => (
                    <li className="review-card " key={review._id}>
                      <Rating
                        rating={review.rating}
                        caption="personal"
                      ></Rating>
                      {
                        <strong className="review-card-name">
                          {review.name}
                        </strong>
                      }{" "}
                      <p className="review-card-date">
                        {review.createdAt.substring(0, 10)}
                      </p>
                      <p className="review-card-text">{review.comment}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
