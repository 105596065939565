export const prices = [
  {
    name: "Any",
    min: 0,
    max: 0,
  },
  {
    name: `$1 to $25`,
    min: 1,
    max: 25,
  },
  {
    name: `$25 to $50`,
    min: 25,
    max: 50,
  },
  {
    name: `$50 to $75`,
    min: 50,
    max: 75,
  },
  {
    name: `$75 to $100`,
    min: 75,
    max: 100,
  },
  {
    name: `$100 to $125`,
    min: 100,
    max: 125,
  },
  {
    name: `$125 to $150`,
    min: 125,
    max: 150,
  },
  {
    name: `$150 to $175`,
    min: 150,
    max: 175,
  },
  {
    name: `$175 to $200`,
    min: 175,
    max: 200,
  },
];

export const ratings = [
  {
    name: "4stars & up",
    rating: 4,
  },
  {
    name: "3stars & up",
    rating: 3,
  },
  {
    name: "2stars & up",
    rating: 2,
  },
  {
    name: "1star & up",
    rating: 1,
  },
];

export const brands = [
  {
    name: "Experience Organics",
    logo: "/images/verified.svg",
    verified: true,
    tags: ["new", "trending", "isWomanOwned"],
    likes: 200,
  },
  {
    name: "Experience Organics",
    logo: "/images/verified.svg",
    verified: true,
    tags: ["new", "trending", "isWomanOwned"],
    likes: 200,
  },
  {
    name: "Experience Organics",
    logo: "/images/verified.svg",
    verified: true,
    tags: ["new", "trending", "isWomanOwned"],
    likes: 200,
  },
  {
    name: "Experience Organics",
    logo: "/images/verified.svg",
    verified: true,
    tags: ["new", "trending", "isWomanOwned"],
    likes: 200,
  },
];

export function getRandomIdx(dispensary) {
  const numElements = 6;
  let brands = [];

  if (dispensary) {
    for (let i = 0; i < numElements; i++) {
      brands.push(
        dispensary.brands[Math.floor(Math.random() * dispensary.brands.length)]
      );
    }
  }
  return brands;
}

export const getAgeVerifiction = function () {
  let verified = localStorage.getItem("isVerified");
  if (verified) {
    verified = JSON.parse(verified);
    const date = new Date();
    const time = date.getTime();
    let elapsedHours = time - verified.time;
    const checkExpiration = elapsedHours / (1000 * 60 * 60);
    if (verified.verified === "yes" && checkExpiration < 12) {
      return true;
    }
  }

  return false;
};

export const setVerified = function (props) {
  const date = new Date();
  const time = date.getTime();
  let isVerified = { time, verified: "yes" };

  localStorage.setItem("isVerified", JSON.stringify(isVerified));

  props.history.push(props.location.state.path);
};
