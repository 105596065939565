import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function InvoiceDetailsScreen() {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const sellerEmail = userInfo.email;

  const submitHandlerFunction = (e) => {
    e.preventDefault();
    if (accountNumber !== confirmAccountNumber) {
      alert("account number and account number confirmation do NOT match");
    } else {
    //   dispatch(register(name, email, password));
    }
  };

  const thisObj=[name,address,city, state, postalCode, country, email, phone, routingNumber]

  useEffect(() => {
    if (userInfo) {
      setEmail(sellerEmail);
    
    }
  }, [userInfo, sellerEmail]);
  return (
    <div>
      <form className="form" onSubmit={submitHandlerFunction}>
        <div>
          <h1>Payment Details</h1>
        </div>
        {/* {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger"> {error}</MessageBox>} */}
        <div>
          <label htmlFor="companyName">Company Name </label>
          <input
            type="text"
            id="companyName"
            placeholder="enter official company name"
            required
            onChange={(e) => setName(e.target.value)}></input>
        </div>
        <div>
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            placeholder="Enter full street address"
            onChange={(e) => setAddress(e.target.value)}
            required></input>
        </div>
        <div>
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            placeholder="Enter city"
            onChange={(e) => setCity(e.target.value)}
            required></input>
        </div>
        <div>
          <label htmlFor="state">State</label>
          <select
            id="state"
            required
            onChange={(e) => setState(e.target.value)}>
            <option value="">Select One</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <div>
          <label htmlFor="postalCode">Zip Code</label>
          <input
            type="text"
            id="postalCode"
            placeholder="Enter Zip code"
            onChange={(e) => setPostalCode(e.target.value)}
            required></input>
        </div>
        <div>
          <label htmlFor="country">Country</label>
          <select
            id="country"
            onChange={(e) => setCountry(e.target.value)}
            required>
            <option value="">Select One</option>
            <option value="US">United States</option>
          </select>
        </div>
        <div>
          <label htmlFor="senderEmail">Email Address</label>
          <input
            type="senderEmail"
            id="email"
            placeholder="enter email"
            required
            onChange={(e) => setEmail(e.target.value)}></input>
        </div>
        <div>
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            id="phone"
            placeholder="enter phone number"
            required
            onChange={(e) => setPhone(e.target.value)}></input>
        </div>
        <div>
          <label htmlFor="routingNumber"> Routing Number</label>
          <input
            type="text"
            id="routingNumber"
            placeholder="please enter routing #"
            required
            onChange={(e) => setRoutingNumber(e.target.value)}></input>
        </div>
        <div>
          <label htmlFor="accountNumber"> Account Number</label>
          <input
            type="text"
            id="accountNumber"
            placeholder="please enter account #"
            required
            onChange={(e) => setAccountNumber(e.target.value)}></input>
        </div>
        <div>
          <label htmlFor="confirmAccountNumber"> Confirm Account Number</label>
          <input
            type="text"
            id="confirmAccountNumber"
            placeholder="please confirm account #"
            required
            onChange={(e) => setConfirmAccountNumber(e.target.value)}></input>
        </div>
        <div>
          <label />
          <button className="primary" type="submit">
            Save Payment Information
          </button>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="disclaimer">
            By accessing this site you accept the{" "}
            <a
              href="https://app.termly.io/document/terms-of-use-for-website/0f753d4c-b410-48c0-97d2-759e5c98e46e"
              target="_blank"
              rel="noopener noreferrer">
              Terms of Use
            </a>{" "}
            ,{" "}
            <a
              href="https://app.termly.io/document/privacy-policy/32ddb9a9-22e8-45cb-b5c6-555a3d2aff11"
              target="_blank"
              rel="noopener noreferrer">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://app.termly.io/document/disclaimer/a1411e2e-a5ce-4ae9-a225-bc9162e7100a"
              target="_blank"
              rel="noopener noreferrer">
              Disclaimer
            </a>
            .
          </div>
        </div>
      </form>
    </div>
  );
}
