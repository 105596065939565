import React from "react";

export default function DrugAndAlcoholResourceScreen() {
  return (
    <div className="form DaA" style={{textAlign:"center", height:"auto"}}>
      <div className="black-and-pink">
      <h1 style={{ color: "#57A4FF" }}>Drug and Alcohol Addiction Resources</h1>
      <div>
        <p>
          While we LOVE cannabis over at Merp, we also understand we have a
          responsibility to speak to the sides of cannabis that aren't as
          positive as it relates to other drugs and substances.While we do not
          believe cannabis to be a "gateway drug", we know that cannabis can
          sometimes be used in combination with other more addictive substances.
          With this being, said we want to offer resources and support to anyone
          who feels that they may have an addiction to drugs and/or alcohol. If
          you decide you want this support, we want it to be available to you.
          At Merp we know that we can't just focus on the positives of this
          industry without talking about and taking accountability for some of
          the possible down sides as well.
        </p>
      </div>
      <h2 style={{ color: "#57A4FF" }}>Resources</h2>
      <div style={{height:"auto"}}>
      <p>We will have state specific resources soon.</p>
        {/* <ul>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> RAINN
              </div>
              <div>
                <strong>Type:</strong>National Sexual Assault and Harassment
                Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong>800-656-4673
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="http://online.rainn.org/?_ga=2.202034002.2123643517.1618898812-496764594.1618898812"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://online.rainn.org/?_ga=2.202034002.2123643517.1618898812-496764594.1618898812
                </a>
              </div>{" "}
              <div>
                <strong>Website En espanol:</strong>{" "}
                <a
                  href="http://hotline.rainn.org/es?_ga=2.255042251.2123643517.1618898812-496764594.1618898812"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://hotline.rainn.org/es?_ga=2.255042251.2123643517.1618898812-496764594.1618898812
                </a>
              </div>
              <div>
                <strong>More Details:</strong>{" "}
                <a
                  href="https://www.rainn.org/resources"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://www.rainn.org/resources
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> 1 in 6
              </div>
              <div>
                <strong>Type:</strong>Male Survivors
              </div>{" "}
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://1in6.org/helpline/"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://1in6.org/helpline/
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> RAINN
              </div>
              <div>
                <strong>Type:</strong>Street Harassment
              </div>{" "}
              <div>
                <strong>Contact:</strong>877-995-5247
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://hotline.rainn.org/ssh-en/terms-of-service.jsp?_ga=2.186698603.2123643517.1618898812-496764594.1618898812"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://hotline.rainn.org/ssh-en/terms-of-service.jsp?_ga=2.186698603.2123643517.1618898812-496764594.1618898812
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> RAINN
              </div>
              <div>
                <strong>Type:</strong>National Domestic Violence Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong>800-799-SAVE
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="http://thehotline.org/"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://thehotline.org/
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> Love Is Respect
              </div>
              <div>
                <strong>Type:</strong>National Domestic Violence Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong>866-331-9474
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="http://www.loveisrespect.org/"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://www.loveisrespect.org/
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> National Center for Victims of Crime
              </div>
              <div>
                <strong>Type:</strong>VictimConnect
              </div>{" "}
              <div>
                <strong>Contact:</strong>855-4-VICTIM (84-2846)
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://chat.victimsofcrime.org/victim-connect/terms-of-service.jsp"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://chat.victimsofcrime.org/victim-connect/terms-of-service.jsp
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> Polaris
              </div>
              <div>
                <strong>Type:</strong>National Human Trafficking Hotline
              </div>{" "}
              <div>
                <strong>Contact:</strong> 888-373-7888
              </div>
              <div>
                <strong>Website</strong>{" "}
                <a
                  href="https://humantraffickinghotline.org/chat"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://humantraffickinghotline.org/chat
                </a>
              </div>
            </div>
          </li>
          <li className="event" style={{ textAlign: "left" }}>
            <div className="event-details">
              <div>
                <strong>Name:</strong> National Center for Missing and Exploited Children
              </div>
              <div>
                <strong>Contact:</strong> 800.THE.LOST (843-5678)
              </div>
            </div>
          </li>
        </ul> */}
        </div>
        </div>
    </div>
  );
}
