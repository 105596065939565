import { EVENT_SIGNUP_REQUEST, EVENT_SIGNUP_SUCCESS, EVENT_SIGNUP_FAILURE, GET_EVENTS_SUCCESS, GET_EVENTS_REQUEST, GET_EVENTS_FAILURE, GET_EVENT_REQUEST, GET_EVENT_SUCCESS, GET_EVENT_FAILURE, TICKET_PURCHASE_REQUEST, TICKET_PURCHASE_SUCCESS, TICKET_PURCHASE_FAILURE, TICKET_ORDER_REQUEST, TICKET_ORDER_SUCCESS, TICKET_ORDER_FAILURE } from "../constants/eventConstants";


export const eventSignupReducer = (state = {}, action) => {
    switch (action.type) {
      case EVENT_SIGNUP_REQUEST:
        return { loading: true };
      case EVENT_SIGNUP_SUCCESS:
        return { loading: false, success:true };
      case EVENT_SIGNUP_FAILURE:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const eventsReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_EVENTS_REQUEST:
        return { loading: true };
      case GET_EVENTS_SUCCESS:
        return { loading: false, events:action.payload };
      case GET_EVENTS_FAILURE:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  export const eventReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_EVENT_REQUEST:
        return { loading: true };
      case GET_EVENT_SUCCESS:
        return { loading: false, event:action.payload };
      case GET_EVENT_FAILURE:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const ticketPurchaseReducer = (state = {}, action) => {
    switch (action.type) {
      case TICKET_PURCHASE_REQUEST:
        return { loading: true };
      case TICKET_PURCHASE_SUCCESS:
        return { loading: false, success:true, ticket_purchase:action.payload };
      case TICKET_PURCHASE_FAILURE:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const ticketOrderDetails =(state ={}, action)=>{
    switch(action.type){
      case TICKET_ORDER_REQUEST:
        return { loading: true };
      case TICKET_ORDER_SUCCESS:
        return { loading: false, success:true, order:action.payload };
      case TICKET_ORDER_FAILURE:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  } 