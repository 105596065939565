export const PRODUCT_LIST_REQUEST ='PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS= 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL ='PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST ='PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS= 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL ='PRODUCT_DETAILS_FAIL'

export const PRODUCT_CREATE_REQUEST="PRODUCT_CREATE_REQUEST"
export const PRODUCT_CREATE_SUCCESS="PRODUCT_CREATE_SUCCESS"
export const PRODUCT_CREATE_FAILURE="PRODUCT_CREATE_FAILURE"
export const PRODUCT_CREATE_RESET="PRODUCT_CREATE_RESET"

export const PRODUCT_UPDATE_REQUEST="PRODUCT_UPDATE_REQUEST"
export const PRODUCT_UPDATE_SUCCESS="PRODUCT_UPDATE_SUCCESS"
export const PRODUCT_UPDATE_FAILURE="PRODUCT_UPDATE_FAILURE"
export const PRODUCT_UPDATE_RESET="PRODUCT_UPDATE_RESET"

export const PRODUCT_DELETE_REQUEST ="PRODUCT_DELETE_REQUEST"
export const PRODUCT_DELETE_SUCCESS ="PRODUCT_DELETE_SUCCESS"
export const PRODUCT_DELETE_FAILURE="PRODUCT_DELETE_FAILURE"
export const PRODUCT_DELETE_RESET="PRODUCT_DELETE_RESET" 

export const PRODUCT_CATEGORY_LIST_REQUEST='PRODUCT_CATEGORY_LIST_REQUEST'
export const PRODUCT_CATEGORY_LIST_SUCCESS='PRODUCT_CATEGORY_LIST_SUCCESS'
export const PRODUCT_CATEGORY_LIST_FAILURE='PRODUCT_CATEGORY_LIST_FAILURE'

export const PRODUCT_CREATE_REVIEW_REQUEST="PRODUCT_CREATE_REVIEW_REQUEST"
export const PRODUCT_CREATE_REVIEW_SUCCESS="PRODUCT_CREATE_REVIEW_SUCCESS"
export const PRODUCT_CREATE_REVIEW_FAILURE="PRODUCT_CREATE_REVIEW_FAILURE"
export const PRODUCT_CREATE_REVIEW_RESET="PRODUCT_CREATE_REVIEW_RESET"

export const PRODUCT_BRANDS_SUCCESS="PRODUCT_BRANDS_SUCCESS"


export const BRAND_DETAILS_REQUEST ='BRAND_DETAILS_REQUEST'
export const BRAND_DETAILS_SUCCESS= 'BRAND_DETAILS_SUCCESS'
export const BRAND_DETAILS_FAILURE ='BRAND_DETAILS_FAIL'

export const DISPENSARY_CREATE_RESET ="DISPENSARY_CREATE_RESET"
export const DISPENSARY_CREATE_REQUEST = "DISPENSARY_CREATE_REQUEST"
export const DISPENSARY_CREATE_SUCCESS = "DISPENSARY_CREATE_SUCCESS"
export const DISPENSARY_CREATE_FAILURE = "DISPENSARY_CREATE_FAILURE"

export const DISPENSARIES_CREATE_REQUEST = "DISPENSARIES_CREATE_REQUEST"
export const DISPENSARIES_CREATE_SUCCESS = "DISPENSARIES_CREATE_SUCCESS"
export const DISPENSARIES_CREATE_FAILURE = "DISPENSARIES_CREATE_FAILURE"
export const DISPENSARIES_CREATE_RESET ="DISPENSARIES_CREATE_RESET"




export const DISPENSARY_LIST_REQUEST="DISPENSARY_LIST_REQUEST"
export const DISPENSARY_LIST_SUCCESS="DISPENSARY_LIST_SUCCESS"
export const DISPENSARY_LIST_FAIL="DISPENSARY_LIST_FAIL"

export const DISPENSARY_DETAILS_REQUEST = "DISPENSARY_DETAILS_REQUEST"
export const DISPENSARY_DETAILS_SUCCESS = "DISPENSARY_DETAILS_SUCCESS"
export const DISPENSARY_DETAILS_FAIL = "DISPENSARY_DETAILS_FAIL"


export const DISPENSARY_DELETE_RESET ="DISPENSARY_DELETE_RESET "
export const DISPENSARY_DELETE_REQUEST="DISPENSARY_DELETE_REQUEST"
export const DISPENSARY_DELETE_SUCCESS="DISPENSARY_DELETE_SUCCESS"
export const  DISPENSARY_DELETE_FAILURE=" DISPENSARY_DELETE_FAILURE"

export const DISPENSARY_UPDATE_SUCCESS = "DISPENSARY_UPDATE_SUCCESS"
export const DISPENSARY_UPDATE_REQUEST = "DISPENSARY_UPDATE_REQUEST"
export const DISPENSARY_UPDATE_FAILURE = "DISPENSARY_UPDATE_FAILURE"
export const DISPENSARY_UPDATE_RESET = "DISPENSARY_UPDATE_RESET"


export const DISPENSARY_CREATE_REVIEW_REQUEST = "DISPENSARY_CREATE_REVIEW_REQUEST"
export const DISPENSARY_CREATE_REVIEW_SUCCESS ="DISPENSARY_CREATE_REVIEW_SUCCESS"
export const DISPENSARY_CREATE_REVIEW_FAILURE ="DISPENSARY_CREATE_REVIEW_FAILURE"