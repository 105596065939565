import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_FAILURE,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_DETAILS_REQUEST,
  USER_DETAILS_FAILURE,
  USER_DETAILS_SUCCESS,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_FAILURE,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_TOPSELLERS_LIST_REQUEST,
  USER_TOPSELLERS_LIST_SUCCESS,
  USER_TOPSELLERS_LIST_FAILURE,
  // UPDATE_SELLER_INVOICE_REQUEST,
  // ADD_REWARD_POINTS_REQUEST,
  // ADD_REWARD_POINTS_SUCCESS,
  // ADD_REWARD_POINTS_FAILURE,
  SELLER_CHART_DATA_REQUEST,
  SELLER_CHART_DATA_SUCCESS,
  SELLER_CHART_DATA_FAILURE,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_INFO_REQUEST,
  RESET_PASSWORD_INFO_SUCCESS,
  RESET_PASSWORD_INFO_FAILURE,
  REFERRAL_CODE_REQUEST,
  REFERRAL_CODE_SUCCESS,
  REFERRAL_CODE_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  SELLER_UPDATE_PROFILE_REQUEST,
  SELLER_UPDATE_PROFILE_SUCCESS,
  SELLER_UPDATE_PROFILE_FAILURE,
  NOTIFY_WHEN_NEAR_REQUEST,
  NOTIFY_WHEN_NEAR_SUCCESS,
  NOTIFY_WHEN_NEAR_FAILURE,
  LIST_WITH_US_REQUEST,
  LIST_WITH_US_SUCCESS,
  LIST_WITH_US_FAILURE,
  ADDTO_EMAILLIST_REQUEST,
  ADDTO_EMAILLIST_SUCCESS,
  ADDTO_EMAILLIST_FAILURE,
  GET_QUESTION_REQUEST,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  CAST_MY_VOTE_REQUEST,
  CAST_MY_VOTE_SUCCESS,
  CAST_MY_VOTE_FAILURE,
  SUBMIT_NEW_QUESTION_REQUEST,
  SUBMIT_NEW_QUESTION_SUCCESS,
  SUBMIT_NEW_QUESTION_FAILURE,
  FETCH_USER_REWARDS_REQUEST,
  FETCH_USER_REWARDS_SUCCESS,
  FETCH_USER_REWARDS_FAILURE,
  // GET_USER_ADDRESS_FAILURE,
  // GET_USER_ADDRESS_SUCCESS,
} from "../constants/userConstants";
import axios from "axios";

export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await axios.post("/api/users/signin", { email, password });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    // get rid of userInfo on local storage eventually
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const checkReferralCode = (referralCode) => async (dispatch) => {
  dispatch({ type: REFERRAL_CODE_REQUEST, payload: { referralCode } });
  try {
    const { data } = await axios.put("/api/users/register", {
      referralCode,
    });
    dispatch({ type: REFERRAL_CODE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REFERRAL_CODE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendEmails = () => async (dispatch, getState) => {
  dispatch({ type: SEND_EMAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/users/emailing`, {
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    });
    dispatch({ type: SEND_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: SEND_EMAIL_FAILURE, payload: message });
  }
};
export const register =
  ({
    firstName,
    lastName,
    username,
    age,
    email,

    password,
    frequency,
    intake,
    referralCode,
    purchaseHabits,
    subscribeToMailing,
  }) =>
  async (dispatch) => {
    dispatch({
      type: USER_REGISTER_REQUEST,
      payload: {
        firstName,
        lastName,
        username,
        age,
        email,
        password,
        frequency,
        intake,
        referralCode,
        purchaseHabits,
        subscribeToMailing,
      },
    });
    try {
      const { data } = await axios.post("/api/users/register", {
        firstName,
        lastName,
        username,
        age,
        email,
        password,
        frequency,
        intake,
        referralCode,
        purchaseHabits,
        subscribeToMailing,
      });

      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const checkToken = (token) => async (dispatch) => {
  dispatch({ type: VALIDATE_TOKEN_REQUEST });
  try {
    const { data } = await axios.post(`/api/users/validate_account/${token}`);

    dispatch({ type: VALIDATE_TOKEN_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });

    // // get rid of userInfo on local storage eventually
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: VALIDATE_TOKEN_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("shippingAddress");
  dispatch({ type: USER_SIGNOUT });
};

export const addToEmailLIst = (email) => async (dispatch) => {
  dispatch({ type: ADDTO_EMAILLIST_REQUEST });
  try {
    const { data } = await axios.post(`/api/users/email`, { email: email });

    dispatch({ type: ADDTO_EMAILLIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADDTO_EMAILLIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAILURE, payload: message });
  }
};

export const fetchRewardsPoints = (userId) => async (dispatch, getState) => {
  dispatch({ type: FETCH_USER_REWARDS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/users/${userId}/rewards`, {
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    });

    dispatch({ type: FETCH_USER_REWARDS_SUCCESS, payload: data.points });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FETCH_USER_REWARDS_FAILURE, payload: message });
  }
};

export const notifyWhenInMyArea = (signupCredentails) => async (dispatch) => {
  dispatch({ type: NOTIFY_WHEN_NEAR_REQUEST, payloas: signupCredentails });
  try {
    const { data } = await axios.post("/api/users/notify", signupCredentails);

    dispatch({ type: NOTIFY_WHEN_NEAR_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: NOTIFY_WHEN_NEAR_FAILURE, payload: message });
  }
};

export const listWithUs = (salesLeadInfo) => async (dispatch) => {
  dispatch({ type: LIST_WITH_US_REQUEST, payload: salesLeadInfo });
  try {
    const { data } = await axios.post("/api/users/listWithUs", salesLeadInfo);

    dispatch({ type: LIST_WITH_US_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: LIST_WITH_US_FAILURE, payload: message });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.put("/api/users/profile", user, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });

    // get rid of userInfo on local storage eventually
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAILURE, payload: message });
  }
};

export const updateSellerProfile = (seller) => async (dispatch, getState) => {
  dispatch({ type: SELLER_UPDATE_PROFILE_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();

    const { data } = await axios.put("/api/seller/sellerProfile", seller, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({ type: SELLER_UPDATE_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: SELLER_UPDATE_PROFILE_FAILURE, payload: message });
  }
};

export const listUsers = () => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/users", {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_LIST_FAILURE, payload: message });
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = axios.delete(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DELETE_FAILURE, payload: message });
  }
};

export const updateUser =
  (user, rewardsPoints) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.put(
        `/api/users/${user._id}`,
        { user, rewardsPoints },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_UPDATE_FAILURE, payload: message });
    }
  };

export const resetPassword = (email) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });
  try {
    const { data } = await axios.put("/api/users/forgot_password", { email });
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: RESET_PASSWORD_FAILURE, payload: message });
  }
};

export const resetPasswordInfo =
  ({ password, token }) =>
  async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_INFO_REQUEST });
    try {
      const { data } = await axios.put(
        `/api/users/password_reset/${token.token}`,
        { password }
      );

      dispatch({ type: RESET_PASSWORD_INFO_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: RESET_PASSWORD_INFO_FAILURE, payload: message });
    }
  };
export const listTopSellers = () => async (dispatch) => {
  dispatch({ type: USER_TOPSELLERS_LIST_REQUEST });
  try {
    const { data } = await axios.get("/api/users/top-sellers");
    dispatch({ type: USER_TOPSELLERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_TOPSELLERS_LIST_FAILURE, payload: message });
  }
};

// export const updateSellerInvoice = (sellerID, order) => async (
//   dispatch,
//   getState
// ) => {
//   dispatch({ type: UPDATE_SELLER_INVOICE_REQUEST, payload: sellerID });
//   const {
//     userSignin: { userInfo },
//   } = getState();
//   try {
//     const { data } = await axios.put(
//       `/api/seller/:${sellerID}/dashboard`,
//       order,
//       {
//         headers: { Authorization: `Bearer ${userInfo.token}` },
//       }
//     );
//   } catch (error) {}
// };

export const getChartData = (sellerID) => async (dispatch, getState) => {
  dispatch({ type: SELLER_CHART_DATA_REQUEST });

  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get(`/api/seller/${sellerID}/dashboard`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: SELLER_CHART_DATA_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: SELLER_CHART_DATA_FAILURE, payload: message });
  }
};

export const getQuestion = (idx) => async (dispatch) => {
  dispatch({ type: GET_QUESTION_REQUEST });
  try {
    const { data } = await axios.put(`/api/users/questions`, { idx });

    dispatch({ type: GET_QUESTION_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_QUESTION_FAILURE, payload: message });
  }
};

export const castMyVote = (vote) => async (dispatch) => {
  dispatch({ type: CAST_MY_VOTE_REQUEST });
  try {
    const { data } = await axios.put(`/api/users/questions/${vote._id}`, vote);

    dispatch({ type: CAST_MY_VOTE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CAST_MY_VOTE_FAILURE, payload: message });
  }
};

export const submitQuestion = (question) => async (dispatch) => {
  dispatch({ type: SUBMIT_NEW_QUESTION_REQUEST });

  try {
    const { data } = await axios.post("/api/users/questions", question);
    dispatch({ type: SUBMIT_NEW_QUESTION_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: SUBMIT_NEW_QUESTION_FAILURE, payload: message });
  }
};

// export const addRewardsPoints = (userId)=>async(dispatch,getState)=>{
//   dispatch({ type: ADD_REWARD_POINTS_REQUEST, payload: userId });
//   const {
//     userSignin: { userInfo },
//   } = getState();
//   try {
//     console.log("two", userId);
//     const { data } = await axios.put(`/api/users/rewards/${userId}`, {}, {
//       headers: { Authorization: `Bearer ${userInfo.token}` },
//     });
//     console.log(data, "three");
//     dispatch({ type: ADD_REWARD_POINTS_SUCCESS, payload: data });
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;
//     dispatch({ type: ADD_REWARD_POINTS_FAILURE, payload: message });
//   }

// }
// export const getAddress = (address) => async (dispatch) => {
//   console.log(address, 'hitting here')
//   try{
//     const { data } = await axios.post(`/api/address`, address);
//     console.log(data)
// dispatch({ type: GET_USER_ADDRESS_SUCCESS, payload: data });
// }
// catch(error){
//   const message =
//   error.response && error.response.data.message
//     ? error.response.data.message
//     : error.message;

//     console.log("There is an error", error)
// dispatch({ type: GET_USER_ADDRESS_FAILURE, payload: message });
//   }
// };
