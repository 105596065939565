import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser, updateUserProfile } from "../actions/userActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";

const ProfileScreen = (props) => {
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [frequency, setFrequency] = useState("");
  const [intake, setIntake] = useState("");
  const [price, setPrice] = useState(false);
  const [quality, setQuality] = useState(false);
  const [potent, setPotent] = useState(false);
  const [ethical, setEthical] = useState(false);
  const [review, setReview] = useState(false);
  const [username, setUsername] = useState("");

  const [sellerName, setSellerName] = useState("");

  const [sellerDescription, setSellerDescription] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);

  const cannaProfiles = {
    never: {
      subtext: "Your new to this , but soon to be true to this you are a",
      profile: "cannabis newbie",
    },
    CBD: {
      subtext: "You like your weed how you like your drinks without the punch",
      profile: "'CBD ME'!",
    },
    yearly: {
      subtext:
        "You like to dip and dabble yourself, but you don't just others because your a",
      profile: "casual cannabis consumer",
    },
    monthly: {
      subtext:
        "You treat cannabis as a treat or for self-care and why not, you deserve it, you are a",
      profile: " cannabis fan!",
    },
    weekly: {
      subtext:
        "'it's been one of those days' , you had a long week and need a release a couple times a week. You are a",
      profile: "cannabis enthusiast !",
    },
    daily: {
      subtext:
        "I mean at this point we should let you take the reigns! Like us you view cannabis like a sweet realease at the end of a long day...you are a ",
      profile: "cannabis connoisseur",
    },
  };

  if (!userInfo) {
    props.history.push("/signin");
  }

  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(detailsUser(userInfo._id));
    } else {
      setFirstName(user.firstName);
      //in the interim we can delete the if blocks when we have more users and have confirmed there are  not any other bugs
      if (user.frequency) {
        setFrequency(user.frequency);
      }
      if (user.intake) {
        setIntake(user.intake);
      }
      if (user.purchaseHabits) {
        setPrice(user.purchaseHabits.price);
        setQuality(user.purchaseHabits.quality);
        setPotent(user.purchaseHabits.potent);
        setEthical(user.purchaseHabits.ethical);
        setReview(user.purchaseHabits.review);
      }
      if (userInfo.username) {
        setUsername(userInfo.username);
      }

      if (userInfo.age) {
        setAge(userInfo.age);
      }
      setLastName(user.lastName);
      setEmail(user.email);

      if (user.seller) {
        setSellerName(user.seller.name);
        setImage(user.seller.logo);
        setSellerDescription(user.seller.description);
      }
    }
  }, [dispatch, userInfo._id, user]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Password and confirm Password do not match");
    } else {
      dispatch(
        updateUserProfile({
          userId: user._id,
          firstName,
          lastName,
          username,
          age,
          email,
          password,
          frequency,
          intake,
          purchaseHabits: {
            price,
            quality,
            potent,
            ethical,
            review,
          },
          sellerName,
          sellerDescription,
        })
      );
    }
  };

  return (
    <>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ height: "auto" }}>
          <div style={{ textAlign: "center" }}>
            <div>
              {" "}
              <img
                src="/images/merp_sticker_logo_resized.png"
                alt="merp logo"
                class="img-fluid"
                style={{ height: "auto", width: "20rem", paddingTop: "2rem" }}
              />
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <div className="register-title">
              Where do you fall on the Merp scale?
            </div>
            <div style={{ fontSize: "1.5rem" }}>
              {cannaProfiles[user.frequency].subtext},{" "}
              <strong>{cannaProfiles[user.frequency].profile}</strong>
            </div>
          </div>

          <form className="form register-form" onSubmit={submitHandler}>
            <div>
              <div style={{ textAlign: "center", marginTop: "2rem" }}>
                <div className="register-title">Canna Profile</div>

                <p style={{ fontWeight: "unset", fontSize: "1.25rem" }}>
                  Your "Canna Profile" helps us match you with products and
                  brands for you based off similar Merp users canna profile!
                  PLus, your canna leaf will appear with your review letting
                  others see what kind of canna consumer you are to better gauge
                  how the same product you review might impact them based on
                  their consumption habits! Don't worry you can also review
                  ~anonymously~
                </p>
              </div>
              <div style={{ fontSize: "1.5rem" }}>
                <div>
                  <label htmlFor="frequency">
                    *How often do you use/consume cannabis (containing THC)
                  </label>
                  <br />
                  <select
                    id="frequency"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    required
                  >
                    <option value="">Select One</option>
                    <option value="never">I new to this</option>
                    <option value="CBD">I stick to CBD</option>
                    <option value="yearly">
                      A few times a year (uh barely!)
                    </option>
                    <option value="monthly">
                      Once or twice a month (just a little treat)
                    </option>
                    <option value="weekly">
                      {" "}
                      Once or twice a week (ok!ok! We see you!)
                    </option>
                    <option value="daily">
                      {" "}
                      Daily (Hell yea! We don't judge)
                    </option>
                  </select>
                </div>
                <div>
                  <label htmlFor="intake">
                    *What is your go to consumption method?
                  </label>
                  <br />
                  <select
                    id="intake"
                    value={intake}
                    onChange={(e) => setIntake(e.target.value)}
                    required
                  >
                    <option value="">Select One</option>
                    <option value="none">I'm new to add of this</option>
                    <option value="flower">Smoking (flower)</option>
                    <option value="concentrate">Vaping (concentrate)</option>
                    <option value="dab-concentrate">
                      {" "}
                      Dabs/Smokable Concentrate
                    </option>
                    <option value="Edible"> Edible</option>
                    <option value="tincture">Tincture</option>
                  </select>
                </div>
                <div>
                  <div>
                    What is important to you when selecting a product to buy?
                    (click all that apply)
                  </div>
                  <div
                    className="signup-checkbox"
                    style={{ flexDirection: "row" }}
                  >
                    <input
                      style={{ marginRight: 2 }}
                      type="checkbox"
                      checked={price}
                      onChangeCapture={(e) => setPrice(e.target.checked)}
                    />
                    <label> Price </label>
                  </div>
                  <div
                    className="signup-checkbox"
                    style={{ flexDirection: "row" }}
                  >
                    <input
                      style={{ marginRight: 2 }}
                      type="checkbox"
                      checked={quality}
                      onChangeCapture={(e) => setQuality(e.target.checked)}
                    />
                    <label> Quality of product / ingredients</label>
                  </div>
                  <div
                    className="signup-checkbox"
                    style={{ flexDirection: "row" }}
                  >
                    <input
                      style={{ marginRight: 2 }}
                      type="checkbox"
                      checked={potent}
                      onChangeCapture={(e) => setPotent(e.target.checked)}
                    />
                    <label>Potency</label>
                  </div>
                  <div
                    className="signup-checkbox"
                    style={{ flexDirection: "row" }}
                  >
                    <input
                      style={{ marginRight: 2 }}
                      type="checkbox"
                      checked={ethical}
                      onChangeCapture={(e) => setEthical(e.target.checked)}
                    />
                    <label>Brand ownership / business practices</label>
                  </div>
                  <div
                    className="signup-checkbox"
                    style={{ flexDirection: "row" }}
                  >
                    <input
                      style={{ marginRight: 2 }}
                      type="checkbox"
                      checked={review}
                      onChangeCapture={(e) => setReview(e.target.checked)}
                    />
                    <label>Reviews , Ratings , Recommendations</label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="register-title"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                User Profile
              </div>
            </div>

            {/* login information /how to update password */}
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
                {loadingUpdate && <LoadingBox></LoadingBox>}
                {errorUpdate && (
                  <MessageBox variant="danger">{errorUpdate}</MessageBox>
                )}
                {successUpdate && (
                  <MessageBox variant="success">
                    Profile Successfully Updated
                  </MessageBox>
                )}
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  ></input>
                </div>
                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  ></input>
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>
                <div>
                  <label htmlFor="age">*Age</label>
                  <select
                    id="age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    required
                  >
                    <option value="">Select One</option>
                    <option value="21-25">21-25</option>
                    <option value="26-30">26-30</option>
                    <option value="31-35">31-35</option>
                    <option value="36-40">36-40</option>
                    <option value="41-45">41-45</option>
                    <option value="46-50">46-50</option>
                    <option value="51-55">51-55</option>
                    <option value="56-60">56-60</option>
                    <option value="61-65">61-65</option>
                    <option value="66-70">66-70</option>
                    <option value="71-75">71-75</option>
                    <option value="76-80">76-80</option>
                    <option value="81-85">81-85</option>
                    <option value="86-90">86-90</option>
                    <option value="91-95">91-95</option>
                    <option value="96-100">96-100</option>
                    <option value="100+">100+</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="username">Username</label>
                  <input
                    id="username"
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  ></input>
                </div>
                {/* <div>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                id="confirmPassword"
                type="password"
                placeholder="confirm password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></input>
            </div> */}

                <div>
                  <label />
                  <button className="primary" type="submit">
                    Update
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default ProfileScreen;
