import React, { useEffect } from 'react'
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Product from '../components/Product';
import { listProducts } from '../actions/productActions';
import { useDispatch, useSelector } from 'react-redux';

export default function AllProductsScreen() {
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);
    const { loading, error, products } = productList;
  
    useEffect(() => {
        dispatch(listProducts({}));
      }, [dispatch]);
    return (
       <div>
           {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (<> {products.length === 0 && <MessageBox>No Products Found</MessageBox>}

        <div className="row center">
          {products.map((product) => (
            <Product key={product._id} product={product} />
          ))}
        </div> </>)}
       </div>
    )
}
