import React from "react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from "react-google-maps";

let latLong;
const MapContainerMap = () => {

  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={latLong}
    >
        <Marker position={latLong}/>
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(MapContainerMap));

const MapContainer = (props) => {
  
latLong = props.latLng
  return (
   
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{height:'100%'}}/> }
        mapElement={<div style={{height:'100%',borderRadius:10}}/> }
      />
   
  );
};

export default MapContainer;
