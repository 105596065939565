import axios from "axios";
import {
  BLOG_CREATE_REQUEST,
  BLOG_CREATE_SUCCESS,
  BLOG_CREATE_FAILURE,
  GET_POSTS_REQUEST,
  GET_POST_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POST_SUCCESS,
  GET_POSTS_FAILURE,
  GET_POST_FAILURE,
  BLOG_UPDATE_REQUEST,
  BLOG_UPDATE_SUCCESS,
  BLOG_UPDATE_FAILURE,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_DELETE_FAILURE,
  LINK_UPDATE_REQUEST,
  LINK_UPDATE_SUCCESS,
  LINK_UPDATE_FAILURE,
} from "../constants/blogConstants";

export const createBlogPost = (blog) => async (dispatch, getState) => {
  dispatch({ type: BLOG_CREATE_REQUEST, payload: blog });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      `/api/blog/write`,
      { blog, userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );

    dispatch({ type: BLOG_CREATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: BLOG_CREATE_FAILURE, error: message });
  }
};

export const getPosts = () => async (dispatch) => {
  dispatch({ type: GET_POSTS_REQUEST });

  try {
    const { data } = await axios.get("/api/blog");
    dispatch({ type: GET_POSTS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_POSTS_FAILURE, error: message });
  }
};

export const getPost = (id) => async (dispatch) => {
  dispatch({ type: GET_POST_REQUEST });

  try {
    const { data } = await axios.get(`/api/blog/${id}`);
    dispatch({ type: GET_POST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_POST_FAILURE, error: message });
  }
};

export const editPost = (post) => async (dispatch, getState) => {
  dispatch({ type: BLOG_UPDATE_REQUEST, payload: post });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.put(
      `/api/blog/${post.id}`,
      { post, userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );

    dispatch({ type: BLOG_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: BLOG_UPDATE_FAILURE, error: message });
  }
};

export const updateYTLinks = (links)=> async (dispatch, getState)=>{
  dispatch({ type: LINK_UPDATE_REQUEST, payload: links });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.put(
      `/api/blog/links/${links.id}`,
       links,
        {headers: { Authorization: `Bearer ${userInfo.token}` }},
    );
    

    dispatch({ type: LINK_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: LINK_UPDATE_FAILURE, error: message });
  }
}
export const deletePost = (id) => async (dispatch, getState) => {
  dispatch({ type: BLOG_DELETE_REQUEST, payload: id });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.delete(
      `/api/blog/${id}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
    dispatch({ type: BLOG_DELETE_SUCCESS});
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: BLOG_DELETE_FAILURE, error: message });
  }

};
