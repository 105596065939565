import React from "react";

const CheckoutSteps = (props) => {
  return (
    <div className="row checkout-steps">
      <div className={props.step1 ? "active" : ""}>Customer Info</div>
      {props.includesTHC ? (
        <div className={props.step2 ? "active" : ""}>Pickup Location</div>
      ) : (
        <div className={props.step2 ? "active" : ""}>Shipping</div>
      )}
      {/* {props.includesTHC ? (
        <div className={props.step3 ? "active" : ""}>Payment</div>
      ) : (
        <div className={props.step3 ? "active" : ""}>Payment</div>
      )} */}
      <div className={props.step2 ? "active" : ""}>Place Order</div>
    </div>
  );
};

export default CheckoutSteps;
