export const BLOG_CREATE_REQUEST = 'BLOG_CREATE_REQUEST'
export const BLOG_CREATE_SUCCESS = 'BLOG_CREATE_SUCCESS'
export const BLOG_CREATE_FAILURE = 'BLOG_CREATE_FAILURE'
export const BLOG_CREATE_RESET = "BLOG_CREATE_RESET"

export const BLOG_UPDATE_REQUEST = 'BLOG_UPDATE_REQUEST'
export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS'
export const BLOG_UPDATE_FAILURE = 'BLOG_UPDATE_FAILURE'
export const BLOG_UPDATE_RESET = "BLOG_UPDATE_RESET"

export const GET_POSTS_REQUEST = 'GET_POSTS_REQUEST'
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS'
export const GET_POSTS_FAILURE = 'GET_POSTS_FAILURE'

export const GET_POST_REQUEST = 'GET_POST_REQUEST'
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS'
export const GET_POST_FAILURE = 'GET_POST_FAILURE'

export const BLOG_DELETE_REQUEST ="BLOG_DELETE_REQUEST"
export const BLOG_DELETE_SUCCESS ="BLOG_DELETE_SUCCESS"
export const BLOG_DELETE_FAILURE ="BLOG_DELETE_FAILURE"
export const BLOG_DELETE_RESET = "BLOG_DELETE_RESET"

export const LINK_UPDATE_REQUEST = "LINK_UPDATE_REQUEST"
export const LINK_UPDATE_SUCCESS  = "LINK_UPDATE_SUCCESS"
export const LINK_UPDATE_FAILURE = "LINK_UPDATE_FAILURE"
export const LINK_UPDATE_RESET = "LINK_UPDATE_RESET"


