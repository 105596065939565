import React, { useEffect } from "react";
import MessageBox from "../components/MessageBox";
import LoadingBox from "../components/LoadingBox";
import { useDispatch, useSelector } from "react-redux";
import { listBrands, deleteBrand, createBrand } from "../actions/brandActions";
import { useParams, Link } from "react-router-dom";
import { BRAND_DELETE_RESET,BRAND_CREATE_RESET } from "../constants/brandConstants";

export default function BrandListScreen(props) {
    const {
        pageNumber = 1,
      } = useParams();
    const userSignin = useSelector(state=>state.userSignin)
    const {userInfo} = userSignin

    if(!userInfo || !userInfo.isAdmin){
        props.history.push("/signin");
      }

    const dispatch = useDispatch();

    const brandList = useSelector((state) => state.brandList);
  const { loading, error, brands ,page, pages } =  brandList;


 

  const brandCreate = useSelector((state) => state.brandCreate);
  
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    brand
  } = brandCreate;



  const brandDelete = useSelector((state) => state.brandDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = brandDelete;

  useEffect(() => {
    if (successCreate) {
        dispatch({ type: BRAND_CREATE_RESET });
        props.history.push(`/brand/${brand._id}/edit`);
      }
  
    if (successDelete) {
      dispatch({ type: BRAND_DELETE_RESET });
    }
    dispatch(listBrands({pageNumber,userInfo}));
  }, [dispatch, successDelete, props.history, successCreate, brand, userInfo._id,pageNumber,userInfo ]);

  const deleteHandler = (brand)=> {
    if (window.confirm("Are you sure you'd like to delete this brand?")) {
      dispatch(deleteBrand(brand._id));
    }
  };
  const createHandler = () => {
    dispatch(createBrand(userInfo));
  };
  return (
    <div class="container-fluid p-5">
      <div className="row-cart ">
        <h1>Brands</h1>
        <button type="button" className="primary" onClick={createHandler}>
          Create Brand
        </button>
      </div>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}

      {loadingCreate && <LoadingBox></LoadingBox>}
      {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <table className="table">
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>STATUS</th>
              <th>ACTIONS</th>
            </tr>
            <tbody>
              {brands && brands.length > 0 ? (
                brands.map((brand) => (
                  <tr key={brand._id}>
                    <td>{brand._id}</td>
                    <td>{brand.name}</td>
                    <td>{brand.status}</td>
                    <td>
                      <button
                        type="button"
                        className="small"
                        onClick={() =>
                          props.history.push(`/brand/${brand._id}/edit`)
                        }>
                        Edit
                      </button>
                      <button
                        type="button"
                        className="small"
                        onClick={() => deleteHandler(brand)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <MessageBox>No products listed</MessageBox>
              )}
            </tbody>
          </table>

          <div className="row center pagination">
            {[...Array(pages).keys()].map((x) => (
              <Link
                className={x + 1 === page ? "active" : ""}
                key={x + 1}
                to={`/brandlist/pageNumber/${x + 1}`}>
                {x + 1}
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
