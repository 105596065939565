import { Link } from "@material-ui/core";
import React from "react";

export default function NotFoundPageScreen() {
  return (
    <div
      className="card-body"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "1000px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src="/images/page_not_found.png"
          alt="page not found"
          style={{ width: "80%" }}
          //   style={{ width: "300px", height: "300px" }}
        />
        <p style={{ textAlign: "center" }}>
          <Link to="/"> Go to Homepage </Link>
        </p>
      </div>
    </div>
  );
}
