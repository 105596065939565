import React, { useState, useEffect } from "react";
import "./editPost.css";
import { Image } from "cloudinary-react";
import axios from "axios";

import {
  // createBlogPost,
  getPost,
  editPost,
  // deletePost,
} from "../../../actions/blogActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MessageBox from "../../../components/MessageBox";
import LoadingBox from "../../../components/LoadingBox";

import { BLOG_UPDATE_RESET } from "../../../constants/blogConstants";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";

export default function EditPost(props) {
  const editorRef = useRef(null);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [media, setMedia] = useState(false);
  const [politics, setPolitics] = useState(false);
  const [food, setFood] = useState(false);
  const [products, setProducts] = useState(false);
  const [business, setBusiness] = useState(false);
  const [dispensaries, setDispensaries] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [ytData, setYTData] = useState({});
  const [youtubeLinkNumber, setYoutubeLinkNumber] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [errorUpload, setErrorUpload] = useState("");
  const [previewSource, setPreviewSource] = useState();
  const [fileInputState, setFileInputState] = useState("");
  const [miscellaneous, setMiscellaneous] = useState(false);
  const [ytlinkPreview, setYTLinkPreview] = useState([]);

  const postDetails = useSelector((state) => state.postDetails);
  const { loading, error, blog } = postDetails;

  const blogUpdate = useSelector((state) => state.blogUpdate);

  const { loading: loadingUpdate, error: errorUpdate, success } = blogUpdate;

  const blogUpdateLinks = useSelector((state) => state.blogUpdateLinks);

  const {
    // loading: loadingUupdateLinks,
    // error: errorUpdateLinks,
    successLinks,
  } = blogUpdateLinks;

  const postDelete = useSelector((state) => state.postDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = postDelete;

  useEffect(() => {
    if (success || successDelete) {
      dispatch({ type: BLOG_UPDATE_RESET });
      props.history.push("/blog");
    }

    if (!blog || blog._id !== id) {
      dispatch(getPost(id));
    } else if (successLinks) {
      setYTData(successLinks);
      setYTLinkPreview(Object.entries(ytData));
    } else {
      setMedia(blog.categories.media);
      setPolitics(blog.categories.politics);
      setFood(blog.categories.food);
      setProducts(blog.categories.products);
      setBusiness(blog.categories.business);
      setDispensaries(blog.categories.dispensaries);
      setMiscellaneous(
        blog.categories.miscellaneous ? blog.categories.miscellaneous : false
      );
      setImage(blog.image);
      setDescription(blog.description);
      setTitle(blog.title);
    }
  }, [dispatch, blog, props, id, success, successLinks]);

  const uploadImage = async (base64EncodedImage) => {
    const bodyFormData = new FormData();
    bodyFormData.append("image", base64EncodedImage);

    try {
      const { data } = await axios.post(
        "/api/uploads",
        JSON.stringify({ data: base64EncodedImage }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const url = data.url;
      setImage(url);
    } catch (err) {
      setErrorUpload(err.message);
    }
  };
  const hadleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage(reader.result);
    };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };
  // const deleteBlog = () => {
  //   dispatch(deletePost(id));
  // };

  const submitHandler = (e) => {
    e.preventDefault();

    //TOOK OUT REQUIREMENT FOR DISPENSARY EDIT

    dispatch(
      editPost({
        title,
        description,
        image,
        categories: {
          food,
          politics,
          business,
          media,
          products,
          dispensaries,
          miscellaneous,
        },
        id: id,
        links: ytData,
      })
    );
  };
  return (
    <>
      {loadingUpdate && <LoadingBox></LoadingBox>}
      {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className="write">
          <div style={{ textAlign: "center" }}>
            {image !== "" ? (
              <div className="write-img">
                <img src={image} alt={title} className="img-container" />
              </div>
            ) : (
              <div>
                {" "}
                <img
                  className="blog-stock-img"
                  src="/images/image-solid.png"
                  alt="stock photo"
                />
              </div>
            )}
          </div>
          <form className="write-form" onSubmit={submitHandler}>
            <div className="blog-categories">
              <div className="blog-title" style={{ textAlign: "center" }}>
                <strong>
                  {" "}
                  Please select all categories that apply to this blog post{" "}
                </strong>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <div>
                  <input
                    id="Media"
                    type="checkbox"
                    checked={media}
                    onChange={(e) => setMedia(e.target.checked)}
                  ></input>{" "}
                  <label htmlFor="Media">
                    <strong> Media</strong>
                  </label>
                </div>
                <div>
                  <input
                    id="Food"
                    type="checkbox"
                    checked={food}
                    onChange={(e) => setFood(e.target.checked)}
                  ></input>{" "}
                  <label htmlFor="Food">
                    <strong>Food</strong>{" "}
                  </label>
                </div>
                <div>
                  <input
                    id="Politics"
                    type="checkbox"
                    checked={politics}
                    onChange={(e) => setPolitics(e.target.checked)}
                  ></input>{" "}
                  <label htmlFor="Politics">
                    <strong> Politics </strong>
                  </label>
                </div>
                <div>
                  <input
                    id="Products"
                    type="checkbox"
                    checked={products}
                    onChange={(e) => setProducts(e.target.checked)}
                  ></input>{" "}
                  <label htmlFor="Products">
                    <strong> Products </strong>
                  </label>
                </div>
                <div>
                  <input
                    id="Business"
                    type="checkbox"
                    checked={business}
                    onChange={(e) => setBusiness(e.target.checked)}
                  ></input>{" "}
                  <label htmlFor="Business">
                    <strong> Business </strong>
                  </label>
                </div>
                <div>
                  <input
                    id="Dispensaries"
                    type="checkbox"
                    checked={dispensaries}
                    onChange={(e) => setDispensaries(e.target.checked)}
                  ></input>{" "}
                  <label htmlFor="Dispensaries">
                    <strong> Dispensaries </strong>
                  </label>
                </div>
                <div>
                  <input
                    id="Miscellaneous"
                    type="checkbox"
                    checked={miscellaneous}
                    onChange={(e) => setMiscellaneous(e.target.checked)}
                  ></input>{" "}
                  <label htmlFor="Miscellaneous">
                    <strong> Miscellaneous</strong>
                  </label>
                </div>
              </div>
            </div>
            <div className="write-form-group">
              <label htmlFor="fileInput">
                <i className="write-icon fas fa-plus"></i>
              </label>

              <input
                type="file"
                id="fileInput"
                onChange={hadleFileInputChange}
                value={fileInputState}
                style={{ display: "none" }}
              />
              <input
                type="text"
                value={title}
                className="write-input"
                autoFocus={true}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="write-form-group">
              <Editor
                apiKey="pvmt6g2yt4z53qx089in653ms30me1rbq0yzs5vwjl7qhzyt"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={description}
                value={description}
                onEditorChange={(newValue, editor) => setDescription(newValue)}
                init={{
                  selector: "textarea#file-picker",
                  height: 500,

                  // menubar: false,

                  // file_picker_types: 'file image media',
                  file_picker_callback: (cb, value, meta) => {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.addEventListener("change", (e) => {
                      const file = e.target.files[0];

                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = async () => {
                        const bodyFormData = new FormData();
                        bodyFormData.append("image", reader.result);

                        try {
                          const { data } = await axios.post(
                            "/api/uploads",
                            JSON.stringify({ data: reader.result }),
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          );

                          const url = data.secure_url;

                          cb(url, { title: file.name });
                        } catch (err) {
                          setErrorUpload(err.message);
                        }
                      };
                    });

                    input.click();
                  },

                  media_dimensions: true,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "preview",
                    "help",
                    "wordcount",
                    "media",
                    "editimage",
                  ],

                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat |image media help",
                  // video_template_callback: (data) =>
                  // `<video width="${data.width}" height="${data.height}"${data.poster ? ` poster="${data.poster}"` : ''} controls="controls">\n` +
                  // `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ''} />\n` +
                  // (data.altsource ? `<source src="${data.altsource}"${data.altsourcemime ? ` type="${data.altsourcemime}"` : ''} />\n` : '') +
                  // '</video>',

                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>

            <button className="write-submit" type="submit">
              Publish Changes
            </button>
          </form>
        </div>
      )}
    </>
  );
}
