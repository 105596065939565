import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePaymentMethod } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";

export default function PickupPaymentMethodScreen(props) {
  const cart = useSelector((state) => state.cart);
  const [paymentMethod, setPaymentMethod] = useState(true);

  const { shippingAddress } = cart;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const pay = paymentMethod ? "Cash" : "Cash";
    dispatch(savePaymentMethod(pay));
    props.history.push("/order_summary");
  };

  if (!shippingAddress.address) {
    props.history.push("/pickup_location");
  }

  return (
    <div>
      <CheckoutSteps step1 step2 step3 includesTHC={true}></CheckoutSteps>
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h1>Confirm Payment Method</h1>
        </div>
        <div>
          <div>
            <input
              id="paymentMethod"
              type="radio"
              value={paymentMethod}
              name="method"
              checked={paymentMethod === true}
              onChange={(e) => setPaymentMethod(!e.target.value)}></input>
            <label htmlFor="paymentMethod">Cash</label>
          </div>
      
        </div>
        <div>
          <button className="primary" type="submit">
            Continue
          </button>
        </div>
        <div className="disclaimer">
          *You are NOT allowed to puchase any CBD, THC or related paraphenalia product
          through our site. All payment for these products should be handled with the dispensary the product(s) in your order are sold at.
          </div>
      </form>
    </div>
  );
}
