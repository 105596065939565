import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailProduct } from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

export default function CompanyBadgeScreen(props) {
  const productID = props.match.params.id;
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { product, loading, error } = productDetails;

  useEffect(() => {
    dispatch(detailProduct(productID));
  }, [dispatch, productID]);



  const badgeObj = product ? product.badges : {};
 
  const badges = [];

  if (!badgeObj) {
    return badges;
  } else {
    for (const key in badgeObj) {
      if (badgeObj[key].status) {
        badges.push(badgeObj[key]);
      }
    }
  }
  ;
  return (
    <div className="row center">
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className="row center">
          {badges.length !== 0 && (
            <div className="row-badges">
              {/*filtering through what badges companies get*/}
              {badges.map((b) => (
                <div key={b.alt} className="badge">
                  <img src={b.image} alt={b.alt} />
                </div>
              ))} 
            </div>
          )}
          {badgeObj!=={} && (
             <p className="badge_company_description ">
           {badgeObj.description}
           </p>
          )}
         
        </div>
      )}
    </div>
  );
}
