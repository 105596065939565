import axios from "axios";

import {
  ADD_PRODUCT_TO_CART,
  REMOVE_CART_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_ADD_ITEM_FAILURE,
} from "../constants/cartConstants";

export const addItemToCart =
  (productId, price, qty, size, redeemed) => async (dispatch, getState) => {
    try {
      const { data } = await axios.get(`/api/products/${productId}`);

      //checking to make sure products are from same seller
      // if (
      //   cartItems.length > 0 &&
      //   data.product.seller._id !== cartItems[0].seller._id
      // ) {
      //   dispatch({
      //     type: CART_ADD_ITEM_FAILURE,
      //     payload: `Cannot add to Cart. Must purchase from same seller, ${cartItems[0].seller.seller.name}  in cart`,
      //   });
      // } else {
      dispatch({
        type: ADD_PRODUCT_TO_CART,
        payload: {
          name: data.product.name,
          image: data.product.image,
          apparel: data.product.apparel,
          size: size ? size : "",
          price: redeemed ? 0 : data.product.price,

          redeemed: redeemed ? redeemed : false,
          countInStock: data.product.countInStock,
          product: data.product._id,
          includesTHC: data.product.includesTHC,
          percent_THC: data.product.percent_THC,
          //this means I can only buy from one seller at a time
          seller: data.product.seller,
          category: data.product.category,
          qty,
        },
      });

      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems)
      );
      // }
    } catch (err) {}
  };

export const removeItem = (id) => async (dispatch, getState) => {
  dispatch({ type: REMOVE_CART_ITEM, payload: id });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });
};
