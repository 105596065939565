import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import LoadingBox from "../components/LoadingBox";

export default function ResetPasswordScreen(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const { loading, error, success } = useSelector(
    (state) => state.resetPassword
  );

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/email_sent";

  const submitHandlerFunction = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email));
  };
  useEffect(() => {
    if (success) {
     
      props.history.push(redirect);
    }
  }, [props.history, redirect, success]);
  return (
    <div>
      <form className="form" onSubmit={submitHandlerFunction}>
        <div>
          {loading && <LoadingBox></LoadingBox>}
          {error && <MessageBox variant="danger">{error}</MessageBox>}
          <h1>Forgot Password</h1>
        </div>
        <div>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            placeholder="enter email"
            required
            onChange={(e) => setEmail(e.target.value)}></input>
        </div>
        <div>
          <label />
          <button className="primary" type="submit">
            send reset link
          </button>
        </div>
      </form>
    </div>
  );
}
