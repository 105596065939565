import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStateJobs } from "../actions/educationActions";
import LoadingBox from "../components/LoadingBox";
// import MessageBox from "../components/MessageBox";
import { useParams } from "react-router-dom";

export default function JobResourceScreen() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState("");


  const { loading, stateJobs } = useSelector((state) => state.stateJobs);

  useEffect(() => {
    if (type) {
      dispatch(getStateJobs(state, type));
    }
  }, [dispatch, type, state]);
  return (
    <div style={{ textAlign: "center", height: "auto" }}>
      <h1>{type} Resources</h1>
      {loading && <LoadingBox></LoadingBox>}
      <div className="form" style={{ textAlign: "center" }}>
        <label htmlFor="state">State</label>
        <select id="state" required onChange={(e) => setState(e.target.value)}>
          <option value="">Select One</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>
      <div className="form" style={{ height: "auto" }}>
        {state === "IL" && type==="Housing" && (
          <div style={{ color: "red", height: "auto" }}>
            <b>
              If you are experiencing homelessness or in need of immediate
              assistance please call 311 or go to your nearest Hospital
            </b>
          </div>
        )}
        {loading && <LoadingBox></LoadingBox>}

        {stateJobs &&
        stateJobs.job_resources &&
        stateJobs.job_resources !== [] &&
        type === "Job" ? (
          <div style={{ height: "auto" }}>
            <div>
              <img
                src={stateJobs.images}
                alt={stateJobs.alt}
                style={{ width: "25rem", height: "auto" }}
              />
            </div>
            <ul>
              {stateJobs.job_resources.map((p) => (
                <li className="event">
                  <div className="event-details">
                    <div>
                      <b>{p.Organization}</b>
                    </div>
                    <div>
                      <b>Address:</b> {p.Address}
                      <br />
                      <b>City:</b> {p.City}
                      <br />
                      <b>Website:</b>{" "}
                      {p.Website === "N/A" ? (
                        p.Website
                      ) : (
                        <a
                          href={p.Website}
                          target="_blank"
                          rel="noopener noreferrer">
                          {p.Website}
                        </a>
                      )}
                      <br />
                      <b>Phone: </b>
                      {p.Phone}
                      <br />
                      <p>
                        <b>Description:</b> {p.Description}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {stateJobs.state === "IL" && (
              <div style={{ margin: "1rem" }}>
                <b>
                  We want to give a thank you to Black and Pink for providing
                  some of the job resources on this list.
                </b>
              </div>
            )}
          </div>
        ) : stateJobs &&
          stateJobs.legal_resources &&
          stateJobs.legal_resources !== [] &&
          type === "Legal" ? (
          <div style={{ height: "auto" }}>
            <div>
              <img
                src={stateJobs.images}
                alt={stateJobs.alt}
                style={{ width: "25rem", height: "auto" }}
              />
            </div>
            <ul>
              {stateJobs.legal_resources.map((p) => (
                <li className="event">
                  <div className="event-details">
                    <div>
                      <b>{p.Organization}</b>
                    </div>
                    <div>
                      <b>Address:</b> {p.Address}
                      <br />
                      <b>City:</b> {p.City}
                      <br />
                      <b>Website:</b>{" "}
                      {p.Website === "N/A" ? (
                        p.Website
                      ) : (
                        <a
                          href={p.Website}
                          target="_blank"
                          rel="noopener noreferrer">
                          {p.Website}
                        </a>
                      )}
                      <br />
                      <b>Phone: </b>
                      {p.Phone}
                      <br />
                      <p>
                        <b>Description:</b> {p.Description}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {stateJobs.state === "IL" && (
              <div style={{ margin: "1rem" }}>
                <b>
                  We want to give a thank you to Black and Pink for providing
                  some of the legal resources on this list.
                </b>
              </div>
            )}
          </div>
        ) : stateJobs &&
          stateJobs.housing_resources &&
          stateJobs.housing_resources !== [] ? (
          <div style={{ height: "auto" }}>
            <div>
              <img
                src={stateJobs.images}
                alt={stateJobs.alt}
                style={{ width: "25rem", height: "auto" }}
              />
            </div>
            <ul>
              {stateJobs.housing_resources.map((p) => (
                <li className="event">
                  <div className="event-details">
                    <div>
                      <b>{p.Organization}</b>
                    </div>
                    <div style={{height:'auto'}}>
                      <b>Address:</b> {p.Address}
                      <br />
                      <b>City:</b> {p.City}
                      <br />
                      <div style={{padding:".5rem",height:"auto"}}>
                      <b>Website:</b>{" "}
                      {p.Website === "N/A" ? (
                        p.Website
                      ) : (
                        <a
                          href={p.Website}
                          target="_blank"
                          rel="noopener noreferrer">
                          {p.Website}
                        </a>
                      )}
                      </div>
                      <br />
                      <b>Phone: </b>
                      {p.Phone}
                      <br />
                      <p style={{height:"auto"}}> 
                        <b>Description:</b> {p.Description}
                      </p>
                      {p.Restrictions_Considerations && (
                        <p style={{height:"auto"}}>
                          <b style={{ color: "red" }}>
                            Restrictions/Considerations:{" "}
                          </b>{" "}
                          {p.Restrictions_Considerations}
                        </p>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {stateJobs.state === "IL" && (
              <>
                <div style={{ margin: "1rem" }}>
                  <b>
                    We want to give a thank you to Black and Pink for providing
                    some of the housing resources on this list.
                  </b>
                </div>
              </>
            )}
          </div>
        ) : state === "" ? (
          <div style={{ textAlign: "center", height: "auto" }}>
            <div className="black-and-pink">
              <h1>Please select a state</h1>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", height: "auto" }}>
            <div className="black-and-pink">
              <h1>No resource information for this state</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
