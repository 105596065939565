import React from 'react'
import { Link } from 'react-router-dom';

export default function AdministrationRoutes() {
  
    return (
        <div className="shop-image" id="worker_owned_coop">
          <div className="details">
            <h2>
              <span>Administration <br/>Routes and Effects</span>
            </h2>
            <p>
            Learn about some of the different ways to administer cannabis products, how these choices impact the onset of the effects, and approximately how long these effects last.
            </p>
            <div className="more">
              <Link
                to='/education/routes_of_cannabis_administration'
                className="read-more">
                Browse <span>Now</span>
              </Link>
            </div>
          </div>
        </div>
      );
}
