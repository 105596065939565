export const ORDER_CREATE_REQUEST='ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS='ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAILUIRE='ORDER_CREATE_FAILUIRE'
export const ORDER_CREATE_RESET='ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST='ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS='ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAILURE='ORDER_DETAILS_FAILURE'


export const ORDER_PAY_REQUEST ='ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS='ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAILUIRE='ORDER_PAY_FAILUIRE'
export const ORDER_PAY_RESET='ORDER_PAY_RESET'


export const ORDER_MY_LIST_REQUEST="ORDER_MY_LIST_REQUEST"
export const ORDER_MY_LIST_SUCCESS="ORDER_MY_LIST_SUCCESS"
export const ORDER_MY_LIST_FAILURE="ORDER_MY_LIST_FAILURE"

export const ORDER_LIST_REQUEST="ORDER_LIST_REQUEST"
export const ORDER_LIST_SUCCESS="ORDER_LIST_SUCCESS"
export const ORDER_LIST_FAILURE="ORDER_LIST_FAILURE"

export const ORDER_DELETE_REQUEST="ORDER_DELETE_REQUEST"
export const ORDER_DELETE_SUCCESS="ORDER_DELETE_SUCCESS"
export const ORDER_DELETE_FAILURE="ORDER_DELETE_FAILURE"
export const ORDER_DELETE_RESET='ORDER_DELETE_RESET'


export const ORDER_DELIVER_REQUEST ='ORDER_DELIVER__REQUEST'
export const ORDER_DELIVER__SUCCESS='ORDER_DELIVER__SUCCESS'
export const ORDER_DELIVER__FAILURE='ORDER_DELIVER__FAILURE'
export const ORDER_DELIVER_RESET="ORDER_DELIVER_RESET"