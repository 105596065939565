import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailProduct, updateProduct } from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";
import { Hint } from "react-autocomplete-hint";
import axios from "axios";
import StatusBox from "../components/StatusBox";
import { updateSellerProfile } from "../actions/userActions";

export default function SellerProfileScreen(props) {
  //   const productId = props.match.params.id;
  const [errorUpload, setErrorUpload] = useState("");

  const [inputList, setInputList] = useState([]);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [name, setName] = useState("");
  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState();
  const [image, setImage] = useState("");
  const [localState, setLocalState] = useState("");
  const [alias, setAlias] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [description, setDescription] = useState("");

  //   const productDetails = useSelector((state) => state.productDetails);
  //   const { loading, error, product } = productDetails;

//   const sellerProfileUpdate = useSelector((state) => state.sellerProfileUpdate);

//   const {
//     loading: loadingUpdate,
//     error: errorUpdate,
//     success: successUpdate,
//   } = sellerProfileUpdate;

  //   const getData = async () => {
  //     if (brands && brands.brands) {
  //       const approvedBrands = brands.brands.map((b) => b.name);
  //       setHintData(approvedBrands);
  //     }
  //   };

  const dispatch = useDispatch();

  useEffect(() => {
    // getData();

    // if (successUpdate) {
    //   props.history.push(`/seller/${userInfo._id}`);
    // }
  }, [dispatch, userInfo, props.history, previewSource]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!previewSource) {
      return;
    }
  
    dispatch(
      updateSellerProfile({
        _id: userInfo._id,
        name,
        image,
        description,
        dispensaryAddresses: inputList,
      })
    );
  };
  const uploadImage = async (base64EncodedImage) => {
    // try {
    //   const checking = await fetch('/api/uploads',{
    //     method:"POST",
    //     body: JSON.stringify({data:base64EncodedImage}),
    //     headers:{'Content-type':'application/json'}
    //   })
    //  console.log(checking)
    // } catch (error) {
    //   console.log("error", error)
    // }
    const bodyFormData = new FormData();
    bodyFormData.append("image", base64EncodedImage);

    try {
      const { data } = await axios.post(
        "/api/uploads",
        JSON.stringify({ data: base64EncodedImage }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      const id = data.public_id;
      setImage(id);
    } catch (err) {
      setErrorUpload(err.message);
    }
  };
  const hadleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage(reader.result);
    };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
   
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { alias, streetAddress, postalCode, localState },
    ]);
    // setAlias("");
    // setStreetAddress("");
    // setPostalCode("");
    // setLocalState("");
  };
  // const uploadFileHandler = async (e) => {
  //   const file = e.target.files[0];
  //   const bodyFormData = new FormData();
  //   bodyFormData.append("image", file);
  //   setLoadingUpload(true);
  //   try {
  //     const { data } = await axios.post("/api/uploads", bodyFormData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     });
  //     setImage(data);
  //     setLoadingUpload(false);
  //   } catch (err) {
  //     setErrorUpload(err.message);
  //     setLoadingUpload(false);
  //   }
  // };
  return (
    <div>
      <form className="form" autocomplete="off" onSubmit={submitHandler}>
        <div>
          <h1>Edit Seller Profile Infomation </h1>
        </div>
        {/* {loadingUpdate && <LoadingBox></LoadingBox>}
        {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>} */}
        {/* {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : ( */}
        <>
          {/* <StatusBox>{product.status}</StatusBox> */}
          <div>
            <label htmlFor="companyName">Company Name</label>
            <input
              id="companyName"
              type="text"
              placeholder="Enter Company Name"
              value={name}
              onChange={(e) => setName(e.target.value)}></input>
          </div>
          {/* <div>
              <label htmlFor="price">Price</label>
              <input
                id="price"
                type="text"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}></input>
            </div> */}
          <div>
            <label htmlFor="image">Please upload product image</label>
            <input
              type="file"
              name="image"
              onChange={hadleFileInputChange}
              value={fileInputState}
              className="form-input"
            />
          </div>
          {previewSource && (
            <img
              src={previewSource}
              alt="preview of uploaded image"
              style={{ height: "auto", width: "20rem" }}
            />
          )}
          {/* <div>
              <label htmlFor="image">Image</label>
              <input
                id="image"
                type="text"
                placeholder="Enter Image"
                value={image}
               ></input>
            </div>
            <div>
              <label htmlFor="imageFile">Please upload product image (product must be a jpg/jpeg to upload)</label>
              <input
                type="file"
                id="imageFile"
                label="Choose image"
                onChange={uploadFileHandler}></input>
              {loadingUpload && <LoadingBox></LoadingBox>}
              {errorUpload && (
                <MessageBox variant="danger">{errorUpload}</MessageBox>
              )}
            </div> */}
          <div>
            <label htmlFor="description">
              Description (Pease enter detailed description of your company)
            </label>
            <textarea
              id="description"
              rows="10"
              type="text"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>
          <div>
            <label style={{ textAlign: "center" }}>
              <b> Please Enter Dispensary Location(s)</b>
            </label>

            <div>
              <b>
                **NOTE: If you only have one store location you can skip the
                "Location Alias" section.
              </b>
              <br />
              <br />
              <div>
                <label htmlFor="locationAlias">
                  {" "}
                  Location Alias (ex. 'Redmond Location", "Beverly Hills
                  Location")
                </label>
                <br />
                <input
                  name="locationAlias"
                  type="text"
                  value={alias}
                  placeholder="Enter Alias"
                  onChange={(e) => setAlias(e.target.value)}></input>
                <br />
              </div>
              <br />
              <div>
                {" "}
                <label htmlFor="stretAddress"> Address</label>
                <br />
                <input
                  name="streetAddress"
                  type="text"
                  value={streetAddress}
                  placeholder="Enter Address"
                  onChange={(e) => setStreetAddress(e.target.value)}></input>
                <br />
              </div>
              <br />
              <div>
                <label htmlFor="postalCode">Zip Code</label>
                <br />
                <input
                  placeholder="Enter Zip Code"
                  name="postalCode"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}></input>
                <br />
              </div>
              <br />
              <div>
                <label htmlFor="state">State</label>
                <br />
                <select
                  id="state"
                  value={localState}
                  onChange={(e) => setLocalState(e.target.value)}
                  required>
                  <option value="">select one</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <br />
              </div>
              <br />
              <div className="btn-box">
                <button onClick={handleAddClick}>Add</button>
              </div>
              <h2 style={{ textAlign: "center" }}>Location(s)</h2>
              {inputList.length !== 0 ? (
                <ul>
                  {inputList.map((input, index) => (
                    <div className="row">
                      <div>
                        <li><b>{input.alias}</b></li>
                        <li>
                          {input.streetAddress}, {input.localState}{" "}
                          {input.postalCode}{" "}
                        </li>
                      </div>
                      <button onClick={() => handleRemoveClick(index)}>
                        Delete
                      </button>
                    </div>
                  ))}
                </ul>
              ) : (
                <div>
                  You currently don't have any dispensary locations listed
                </div>
              )}
            </div>
          </div>
          <div>
            <label />
            <button className="primary" type="submit">
              Update
            </button>
          </div>
        </>
      </form>
    </div>
  );
}
