import React from "react";
import Header from "./Header/Header";
import Posts from "./Posts/Posts";
import "./bloglist.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function BlogListScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  return (
    <div className="blogs">
      <Header />
      {/* <Categories /> */}
      <div>
        {userInfo && userInfo.isAdmin && (
          <Link to="/blog/write">
            <i className="post-create-icon far fa-edit"></i>
          </Link>
        )}
      </div>
      <Posts />
    </div>
  );
}
