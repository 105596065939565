import { STATE_TAX_BUDGET_REQUEST, STATE_TAX_BUDGET_SUCCESS, STATE_TAX_BUDGET_FAILURE, STATE_JOBS_REQUEST, STATE_JOBS_SUCCESS, STATE_JOBS_FAILURE } from "../constants/educationConstants";



export const stateTaxRevenueReducer = (state = {}, action) => {
    switch (action.type) {
      case STATE_TAX_BUDGET_REQUEST:
        return { loading: true };
      case STATE_TAX_BUDGET_SUCCESS:
        return { loading: false,success:true,revenue:action.payload };
      case STATE_TAX_BUDGET_FAILURE:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  export const stateJobsReducer = (state = {}, action) => {
    switch (action.type) {
      case STATE_JOBS_REQUEST:
        return { loading: true };
      case STATE_JOBS_SUCCESS:
        return { loading: false,success:true,stateJobs:action.payload };
      case STATE_JOBS_FAILURE:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };