import React, { useEffect, useState } from "react";
import MessageBox from "../components/MessageBox";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoadingBox from "../components/LoadingBox";

import {
  DISPENSARY_CREATE_RESET,
  DISPENSARY_DELETE_RESET,
} from "../constants/productConstants";
import {
  listDispensaries,
  createDispensary,
  deleteDispensary,
  createDispensaries,
} from "../actions/productActions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function DispensaryListScreen(props) {
  let query = useQuery();

  const dispensaryList = useSelector((state) => state.dispensaryList);
  const [fileName, setFileName] = useState("");
  // const [newDispensaries, setNewDispensaries] = useState([]);
  const { loading, error, dispensaries, page, pages } = dispensaryList;
  const [inputDispensaries, setInputDispensaries] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  //   const sellerMode=props.match.path.indexOf('/seller') >= 0;
  console.log(dispensaries, "dispensaries");
  const getSearchDispensary = (search) => {
    setSearchInput(search);
    const newDispensaries = dispensaries.filter((dispensary) =>
      dispensary.name.includes(search)
    );
    console.log(newDispensaries, "found matches");
    setInputDispensaries(newDispensaries);
  };

  const dispensaryCreate = useSelector((state) => state.dispensaryCreate);

  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    dispensary: createdDispensary,
    dispensaries: createdDispensaries,
  } = dispensaryCreate;

  const dispensaryDelete = useSelector((state) => state.dispensaryDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = dispensaryDelete;

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  if (!userInfo || !userInfo.isAdmin) {
    props.history.push("/signin");
  }

  const dispatch = useDispatch();

  const createDispensaryHandler = () => {
    dispatch(createDispensary());
  };

  const createDispensariesHandler = () => {
    dispatch(createDispensaries());
  };

  useEffect(() => {
    if (dispensaries) {
      setInputDispensaries(dispensaries);
    }
    dispatch(
      listDispensaries({
        pageNumber: query.get("page"),
        status: userInfo.isAdmin ? true : false,
        userInfo,
      })
    );
    if (successCreate && !createdDispensaries) {
      dispatch({ type: DISPENSARY_CREATE_RESET });
      props.history.push(`/dispensary/${createdDispensary._id}/edit`);
    }
    if (successCreate && createdDispensaries) {
      dispatch({ type: DISPENSARY_CREATE_RESET });
      setFileName("");
    }
    if (successDelete) {
      dispatch({ type: DISPENSARY_DELETE_RESET });
    }
  }, [
    dispatch,
    query.get("page"),

    successCreate,
    successDelete,
    createdDispensary,
    createdDispensaries,
    userInfo._id,

    userInfo,
  ]);

  const deleteHandler = (dispensary) => {
    if (window.confirm("Are you sure you sure?")) {
      dispatch(deleteDispensary(dispensary._id));
    }
  };

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1;

    // const filterName = filter.name || name;
    // const filterRating = filter.rating || rating;
    // const sortOrder = filter.order;
    // const filterMin = filter.min ? filter.min : filter.min === 0 ? 0 : min;
    // const filterMax = filter.max ? filter.max : filter.max === 0 ? 0 : max;
    // props.history.push(`/dispensaries_list?page=${filterPage}`)
    return `/dispensaries_list?page=${filterPage}`;
  };

  // const readExcel = (file) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);
  //     fileReader.onload = (e) => {
  //       const bufferArray = e.target.result;
  //       const wb = XLSX.read(bufferArray, { type: "buffer" });

  //       //picking the first sheet in our excel file
  //       // const wsname = wb.SheetNames[0];
  //       const wsdata = wb.SheetNames.map((sheet) => {
  //         const ws = wb.Sheets[sheet];
  //         const data = XLSX.utils.sheet_to_json(ws);

  //         return data;
  //       });
  //       // const ws = wb.Sheets[wsname];

  //       // const data = XLSX.utils.sheet_to_json(ws);

  //       resolve(wsdata);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  //   promise.then((d) => {
  //     var newArr = [];

  //     for (var i = 0; i < d.length; i++) {
  //       newArr = newArr.concat(d[i]);
  //     }
  //     console.log(newArr)
  //     setNewDispensaries(newArr)
  //   });
  // };
  //   const createHandler = () => {
  //     dispatch(createDispensary());
  //   };

  return (
    <div class="container-fluid" style={{ padding: "2rem" }}>
      <form>
        <input
          type="text"
          placeholder="enter dispensary name"
          style={{ width: "80%", margin: 10 }}
          value={searchInput}
          onChange={(e) => getSearchDispensary(e.target.value)}
        />
      </form>
      <div className="row-cart">
        <h1>Dispensaries</h1>
        <button
          type="button"
          className="primary"
          onClick={createDispensaryHandler}
        >
          Create Dispensary
        </button>
        <div>
          {/* <input
            type="file"
            value={fileName}
            onChange={(e) => {
              const file = e.target.files[0];
              setFileName(e.target.value);
              readExcel(file);
            }}
          /> */}
          <button
            type="button"
            className="primary"
            onClick={createDispensariesHandler}
          >
            Bulk Create (Airtable)
          </button>
        </div>
      </div>
      {/* {loadingDelete && <LoadingBox></LoadingBox>}
          {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>} */}

      {loadingCreate && <LoadingBox></LoadingBox>}
      {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ paddingTop: "1.5rem" }}>
          <table className="table">
            <tr>
              <th>NAME</th>
              <th>ID</th>
              <th>URL</th>
              <th>ADDRESS</th>
              {/* <th>LOGO</th> */}
              <th>DESCRIPTION</th>
              <th>Approved</th>
              <th>ACTIONS</th>
            </tr>
            <tbody>
              {dispensaries.length > 0 ? (
                inputDispensaries.map((dispensary) => {
                  const fullAddress = dispensary
                    ? `${dispensary.address}, ${dispensary.city} ${dispensary.state} ${dispensary.postalCode}`
                    : "N/A";

                  return (
                    <tr key={dispensary._id}>
                      <td>{dispensary.name}</td>
                      <td>{dispensary._id}</td>
                      <td>{dispensary.website}</td>
                      <td>{fullAddress}</td>
                      {/* <td>{dispensary.image}</td> */}
                      <td>{dispensary.description}</td>
                      {dispensary.approved === "Y" ? (
                        <td style={{ color: "green" }}>
                          <strong>{dispensary.approved}</strong>
                        </td>
                      ) : (
                        <td style={{ color: "red" }}>
                          <strong>{dispensary.approved}</strong>
                        </td>
                      )}
                      <td>
                        <button
                          type="button"
                          className="small"
                          onClick={() =>
                            props.history.push(
                              `/dispensary/${dispensary._id}/edit`
                            )
                          }
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="small"
                          onClick={() => deleteHandler(dispensary)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <MessageBox>No dispensaries listed</MessageBox>
              )}
            </tbody>
          </table>

          <div className="row center pagination">
            {[...Array(pages).keys()].map((x) => (
              <Link
                className={x + 1 === page ? "active" : ""}
                key={x + 1}
                to={getFilterUrl({ page: x + 1 })}
              >
                {x + 1}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
