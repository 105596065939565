import React from "react";
import "./ageVerification.css";
import { setVerified } from "../../utils";

export default function AgeVericationScreen(props) {
  return (
    <div className="age-verify">
      <div className="age-verify-text">
        <h1>Are you over 21 years old?</h1>
        <div className="button-div">
          <button
            className="verify-button"
            type="button"
            onClick={() => setVerified(props)}
          >
            Yes
          </button>
          <button className="verify-button" type="button">
            No
          </button>
        </div>
      </div>
    </div>
  );
}
