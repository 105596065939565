import React, { useEffect } from "react";
import Rating from "./Rating";
import { Link } from "react-router-dom";
import { Image } from "cloudinary-react";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser } from "../actions/userActions";
import { addItemToCart } from "../actions/cartActions";

const Product = ({ product }) => {
  const badgeArr = [];
  const alt = [];
  const badges = {
    isBlackOwned: "/images/black_owned_badge_3.png",
    isPOCOwned: "/images/poc_owned_badge.png",
    isWomanOwned: "/images/woman_owned_badge.png",
    isIndigenousOwned: "/images/indigenous_owned_badge.png",
    sustainable: "/images/leaf_badge.png",
    isWorkerCoop: "/images/worker_owned_coop.png",
    equityVerified: "/images/equity_badge_2.png",
    isUnionized: "/images/unionized_2.png",
    isLgbt: "/images/lgbtq_badge.png",
  };

  const category = {
    Edible: "/images/category_badges/edible.svg",
    Concentrate: "/images/category_badges/concentrate.svg",
    flower: "/images/category_badges/flower.svg",
    Tincture: "/images/category_badges/concentrate.svg",
    Topical: "fimages/category_badges/topical.svg",
    "Pre-rolls": "/images/category_badges/preroll.svg",
    Accessory: "images/category_badges/accessory.svg",
    Merchandise: "/images/category_badges/merch.svg",
  };

  const cart = useSelector((state) => state.cart);
  const { error, cartItems } = cart;
  const badgeObj = product.brand ? product.brand.badges : {};
  const advObj = product.advancedCategory ? product.advancedCategory : {};

  const dispatch = useDispatch();

  const addCartItem = (product) => {
    dispatch(addItemToCart(product._id, product.price, 1));
  };

  for (const key in badgeObj) {
    if (badgeObj[key].status) {
      badgeArr.push(badges[key]);
      alt.push(badgeObj[key]);
    }
  }

  for (const key in advObj) {
    if (advObj[key]) {
      badgeArr.push(badges[key]);
      alt.push(advObj[key]);
    }
  }

  // ? []
  // : product.seller.seller.badges.filter(
  //     (b) =>
  //       b.isSustainable.status ||
  //       b.isWomanOwned.status ||
  //       b.isWorkerCoop.status ||
  //       b.isBlackOwned.status ||
  //       b.isIndigenousOwned.status ||
  //       b.isPOCOwned.status ||
  //       b.isLgbt.stauts ||
  //       b.equityVerified.status
  //   );

  return (
    <div
      className="product pt-md-5 text-center"
      style={{ margin: "2rem", padding: "2rem" }}
    >
      <Link to={`/product/${product._id}`}>
        <Image
          class="img-fluid"
          cloudName="merp"
          publicId={product.image}
          secure="true"
          width="200"
          height="200"
          crop="scale"
        />
      </Link>

      <div className="card-details">
        <div className="row-title p-0">
          <Link to={`/product/${product._id}`}>
            <div className="product-name">
              <strong>{product.name} </strong>
            </div>
          </Link>
          {/* {product &&
              product.seller &&
              (product.seller.seller.rewardsProgram || product.seller) && (
                <div>
                  <img
                    id="rewards-img"
                    src="/images/rewards_star_smaller.png"
                    alt="rewards-doubled"
                  />
                </div>
              )} */}

          <Rating rating={product.rating} numReviews={product.numReviews} />
          <div style={{ marginBottom: "1rem" }}>
            {product.price && product.price > 0 ? (
              <div className="py-2">
                <div style={{ fontSize: "1.75rem" }}>
                  <span>$ {Number(product.price).toFixed(2)}</span>
                </div>
              </div>
            ) : (
              <div className="py-2">
                <div style={{ fontSize: "1.75rem" }}>
                  <span>N/A</span>
                </div>
              </div>
            )}
            <div className="brand-name">
              {" "}
              <Link to={`/brands/${product.brand._id}`}>
                {product.brand.name}
              </Link>
            </div>
          </div>
          {badges.length !== 0 && (
            <div>
              <div style={{ marginBottom: "1rem" }}>
                {/*filtering through what badges companies get*/}
                {badgeArr.map((b, idx) => (
                  <div key={alt[idx].alt} className="badge">
                    <img src={b} alt={alt[idx].alt} />
                  </div>
                ))}
              </div>
            </div>
          )}
          {!product.includesTHC && product.cbd_product ? (
            <div className="product-info-link">
              <Link to={`/product/${product._id}`}>What's in my product?</Link>
            </div>
          ) : (
            <></>
          )}
          <div className="mt-4">
            {product.category === "Merchandise" &&
            product.sizes &&
            product.sizes.length !== 0 ? (
              <Link to={`/product/${product._id}`}>
                <button className="border site-btn btn-span buy-now">
                  Add to Cart
                </button>
              </Link>
            ) : product.category === "Merchandise" ? (
              <button
                className="border site-btn btn-span buy-now"
                onClick={() => addCartItem(product)}
              >
                Add to Cart
              </button>
            ) : (
              <button className="border site-btn btn-span buy-now">
                <a
                  href={product.productLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy Now
                </a>
              </button>
            )}
          </div>
        </div>

        {/* SHOWS WHETHER PRODUCT IS SOLD ONLINE OR IN-STORE */}
        {/* <div style={{ marginBottom: "1rem" }}>
            {product.brand.sold !== "Online" ? (
              <div className="row">
                <div>Sold at: </div>
                <div>
                  <Link to={`/seller/${product.seller._id}`}>
                    {product.seller.seller.name}
                  </Link>
                </div>{" "}
              </div>
            ) : (
              <div className="row">
                <div>Sold: </div>
                <div>Online</div>{" "}
              </div>
            )}
          </div> */}

        {/* Only show this div if the company has at least 1 badge */}
      </div>
    </div>
  );
};

export default Product;
