import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStateTaxRevenue } from "../actions/educationActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import BrandsDoughnutGraph from "../components/BrandsDoughnutGraph";

export default function TaxesScreen() {
  const dispatch = useDispatch();
  const [state, setState] = useState("");

  const { loading, error, revenue } = useSelector((state) => state.taxRevenue);
  const breakdown = revenue ? revenue.tax_breakdown : [];

  useEffect(() => {
  
    dispatch(getStateTaxRevenue(state));
  }, [dispatch, state]);

  return (
    <div >
      <div className="form" >
        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>}
        <div className="taxesMe" style={{height:"auto"}}>
        <div style={{textAlign:"center"}}>
  <label htmlFor="state"><strong>State</strong></label>{" "}
          <select
            id="state"
            required
            onChange={(e) => setState(e.target.value)}>
            <option value="">Select One</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <>
          {revenue ? (
            <div style={{height:"auto"}}>
              <div style={{ textAlign: "center", paddingBottom:"4rem"}}>
                <div style={{ textAlign: "center", height:"auto"}}>
                  <BrandsDoughnutGraph breakdown={breakdown} />

                  {/* <img src={revenue.images} alt={revenue.alt} style={{width:'20rem', height:"auto"}}/> */}
                </div>
                {revenue.cannabis_tax_budget.map((p) => (
                  <p>{p}</p>
                ))}
                <div className="disclaimer" style={{ textAlign: "center" }}>
                  {" "}
                  source:{" "}
                  <a
                    href={revenue.source}
                    target="_blank"
                    rel="noopener noreferrer">
                    {revenue.source}
                  </a>
                </div>
              </div>
            </div>
          ) : state === "" ? (
            <div>
              <h1>No State selected</h1>
            </div>
          ) : revenue === "" ? (
            <div>
              <h1>No Tax Revenue Information On This State</h1>
            </div>
          ) : (
            <div>
              <h1>No Tax Revenue Information On This State</h1>
            </div>
          )}
        </>
        </div>
      </div>
    </div>
  );
}
