import React from "react";

export default function ClaimBrandScreen() {
  return (
    <div >
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
      <div style={{height:"auto"}}>
      <iframe
        class="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shr64ySEctT28oZuW?backgroundColor=purple"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="767"
        // style="background: transparent; border: 1px solid #ccc;"
      ></iframe>
      </div>
    </div>
  );
}
