import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { useEffect } from "react";
import { checkToken } from "../actions/userActions";
// import { USER_REGISTER_RESET } from "../constants/userConstants";

export default function EmailValidationScreen(props) {
  // let history = useHistory();
  const token = useParams();
  const dispatch = useDispatch();
  // const userSignin = useSelector(state=>state.userSignin)
  // const {userInfo} = userSignin
  const { success, error } = useSelector(
    (state) => state.emailValidator
  );
  const checkUserToken = () => {

    dispatch(checkToken(token.token));

  };
  const redirect = props.location.search
  ? props.location.search.split("=")[1]
  : "/";
   
useEffect(()=>{
 if (success) {
  // dispatch({type:USER_REGISTER_RESET})
  props.history.push(redirect);
    }
},[dispatch,success,props.history,redirect])
 
  return (
    <div>
      {/* {loading && <LoadingBox></LoadingBox>} */}
      {error && <MessageBox variant="danger">{error}</MessageBox>}
      <h1>Email validation screen</h1>
      <button type="button" onClick={()=>checkUserToken()}>
        Validate Email
      </button>
    </div>
  );
}
