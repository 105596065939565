import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordInfo } from "../actions/userActions";
import { useParams } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

export default function EnterNewPasswordScreen(props) {
  const token = useParams();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { loading, error, newData } = useSelector(
    (state) => state.resetPasswordInfo
  );


  
  // const redirect = props.location.search
  // ? props.location.search.split("=")[1]
  // : "/";

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Password and confirm Password do not match");
    } else {
      dispatch(resetPasswordInfo({ password, token }));
    }
  };

  useEffect(()=>{
    
    if (newData) {
     
        props.history.push("/password_reset");
      }
  },[props.history,newData])
  return (
    <div>
      <form className="form" onSubmit={submitHandler}>
        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>}
        {/* {newData && <MessageBox>You have sucessfully reset your password!</MessageBox>} */}
        <h1>Reset Password screen</h1>
        <div>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}></input>
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            id="confirmPassword"
            type="password"
            placeholder="confirm password"
            onChange={(e) => setConfirmPassword(e.target.value)}></input>
        </div>
        <div>
          <label />
          <button className="primary" type="submit">
            reset password
          </button>
        </div>
      </form>
    </div>
  );
}
