import React, { useState } from "react";

export default function SearchBox(props) {
  const [name, setName] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    props.history.push(`/search?q=${name}`);
  };
  return (
    <form className="search" onSubmit={submitHandler} style={{paddingBottom:"5px", paddingTop:"7px", }}>
      <div className="nav-row">
        <input
          type="text"
          name="q"
          id="q"
          onChange={(e) => setName(e.target.value)} ></input>
          <button className="primary" type="submit">
            <i className="fa fa-search"></i>
          </button>
      </div>
    </form>
  );
}
