import React from "react";

export default function ContactUsScreen() {
  return (
    <div
      
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "800px",
      }}>
      <div style={{ width: "50%", textAlign: "center" }}>
      <div className="merp-logo-contact justify-content-center p-4" style={{display:"flex"}} >
        <div>
          {" "}
          <img
            src="/images/merp_logo_sticker.png"
            alt="merp logo "
            className="img-fluid"
            // style={{ height: "auto", width: "20rem" }}
          />
        </div>
      </div> 
        <div style={{ textAlign: "center" }}>
          <h1>Contact Us!</h1>
          <a href="#">info@merp.page</a>
          <div className="disclaimer" style={{ marginTop: "2rem" }}>
            If you are experiencing technical difficulties please, try logging
            out and logging back in to your account.{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
