import React, { useEffect, useState } from "react";
import CheckoutSteps from "../components/CheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { createOrder } from "../actions/orderActions";
// import { savePaymentMethod } from "../actions/cartActions";

export default function OrderSummaryScreen(props) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const userSignin = useSelector((state) => state.userSignin);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const { userInfo } = userSignin;
  // const { cartItems } = cart;
 
  const toPrice = (number) => Number(number.toFixed(2));
  cart.itemsPrice = toPrice(
    cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0)
  );

  const regularProducts = (products) => {
    let tax = 0;
    products.map((c) =>
      (c.category !== "edible" || c.category !== "tincture") &&
      Number(c.percent_THC) * 100 < 0.35
        ? (tax += c.price * 0.1)
        : (tax += c.price * 0.25)
    );
    return tax;
  };
 

  cart.saleTaxPrice = toPrice(0.0625 * cart.itemsPrice);

  cart.exciserTax = regularProducts(cart.cartItems);

  cart.totalPrice = cart.itemsPrice + cart.saleTaxPrice + cart.exciserTax;

  const pickUpInfo = cart.cartItems[0].seller.seller.companyAddress;
  

  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading, success, error, order } = orderCreate;

  const placeOrderHandler = () => {
 

    // cart.shippingAddress.fullName = fullName;
    // cart.shippingAddress.email = email;
    dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
    // props.history.push('')
    //TODO place order action
  };
  useEffect(() => {
    if (success) {
      props.history.push(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [success, order, props.history, dispatch]);
  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4 includesTHC={true}></CheckoutSteps>
      <div className="row top">
        <div className="col-2">
          <ul>
            <li>
              <div className="checkout-card checkout-card-body">
                <h2>Pickup</h2>
                {pickUpInfo && (
                  <div>
                    <strong>Dispensary Name:</strong>
                    {pickUpInfo.fullName}
                    <br />
                    <p>
                      <strong>Location:</strong>
                      <br />
                      {pickUpInfo.address}
                      <br />
                      {pickUpInfo.city},{pickUpInfo.state}{" "}
                      {pickUpInfo.postalCode}
                      <br />
                      {pickUpInfo.country}
                    </p>
                  </div>
                )}
              </div>
            </li>
            <li>
              <div className="checkout-card checkout-card-body">
                <h2>Payment</h2>
                <p>
                  <strong>Method:</strong> Cash
                  <br />
                </p>
              </div>
            </li>
            <li>
              <div className="checkout-card checkout-card-body">
                <h2>Order Items</h2>
                <div>
                  <ul>
                    {cart.cartItems.map((item) => (
                      <li key={item.product}>
                        <div className="row">
                          <div>
                            <img
                              src={item.image}
                              alt={item.name}
                              className="small"></img>
                          </div>
                          <div className="min-30">
                            <Link to={`/product/${item._id}`}>{item.name}</Link>
                          </div>

                          <div>
                            {item.qty} x ${item.price} = $
                            {item.qty * item.price}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-1">
          <div className="checkout-card checkout-card-body">
            <ul>
              <li>
                <h2>Order Summary</h2>
              </li>
              <li>
                <div className="row">
                  <div>Items</div>
                  <div>${cart.itemsPrice.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>Approx. {pickUpInfo.state} Purchaser Excise Tax </div>$
                  {cart.exciserTax.toFixed(2)}
                </div>
              </li>
              <li>
                <div className="row">
                  <div> Approx. {pickUpInfo.state} Sales Tax</div>
                  <div>${cart.saleTaxPrice.toFixed(2)}</div>
                </div>
              </li>

              <li>
                <Link to="#">
                  <div className="disclaimer">
                    click here to find out where the taxes from your purchase is
                    going
                  </div>
                </Link>
              </li>
              <li>
                <div className="row">
                  <div>
                    <strong>Approx. Order Total</strong>
                  </div>
                  <div>
                    <strong>${cart.totalPrice.toFixed(2)}</strong>
                  </div>
                </div>
              </li>
              <li>
                {!userInfo && (
                  <>
                    {" "}
                    <div>
                      <label htmlFor="fullName">Full Name</label>
                      <input
                        type="text"
                        id="fullname"
                        placeholder="Enter your full name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required></input>
                    </div>
                    <div>
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required></input>
                    </div>
                  </>
                )}
              </li>
              <li>
                <button
                  type="button"
                  onClick={placeOrderHandler}
                  className="primary block"
                  disable={(cart.cartItems.length === 0).toString()}>
                  Place Pre-Order
                </button>
              </li>
              {loading && <LoadingBox></LoadingBox>}
              {error && <MessageBox variant="danger">{error}</MessageBox>}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
