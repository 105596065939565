import React from 'react'
import { Image } from 'cloudinary-react';
import { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import MessageBox from '../components/MessageBox';
import LoadingBox from '../components/LoadingBox';
import StatusBox from '../components/StatusBox';
import { useEffect } from 'react';
import { detailBrand, updateBrand } from '../actions/brandActions';
import { BRAND_UPDATE_RESET } from '../constants/brandConstants';

export default function BrandEditScreen(props) {
    const  brandId = props.match.params.id;
   
    const [errorUpload, setErrorUpload] = useState("");
    const [fileInputState, setFileInputState] = useState("");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [brandLink, setBrandLink] = useState("");
    const[cbd,setCBD]= useState("yes")
    const [isTHC, setIsTHC] = useState(false)
  const [previewSource, setPreviewSource] = useState();
  const [isBlackOwned, setBlackOwned] = useState(false);
  const [isIndigenousOwned, setIndigenousOwned] = useState(false);
  const [isPOCOwned, setPOCOwned] = useState(false);
  const [isWomanOwned, setWomanOwned] = useState(false);
  const [isWorkerCoop, setWorkerCoop] = useState(false);
  const [equityVerified, setEquityVerified] = useState(false);
  const [isSustainable, setSustainable] = useState(false);
  const [isLgbt, setLgbt] = useState(false);
  const [isUnionized, setIsUnionized] = useState(false);
  const [description, setDescription] = useState("");
  const [claimed, setClaimed] = useState("unclaimed");

  const brandDetails = useSelector((state) => state.brandDetails);
  const { loading, error, brand } = brandDetails;

  

  const brandUpdate = useSelector((state) => state.brandUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = brandUpdate;

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(detailBrand(brandId));

    if (successUpdate) {
      props.history.push("/brandlist");
    }
    if (!brand || brand._id !== brandId || successUpdate) {
        dispatch({ type: BRAND_UPDATE_RESET });
        dispatch(detailBrand(brandId));
      } else {
        setName(brand.name);
        
        setImage(brand.logo);
       
        setDescription(brand.description);
        setBrandLink(brand.brandLink);
       
      
        if (!brand.badges || brand.badges === {}) {
            setBlackOwned(isBlackOwned);
            setEquityVerified(equityVerified);
            setIndigenousOwned(isIndigenousOwned);
            setLgbt(isLgbt);
            setPOCOwned(isPOCOwned);
            setSustainable(isSustainable);
            setWomanOwned(isWomanOwned);
            setWorkerCoop(isWorkerCoop);
            setIsUnionized(isUnionized);
          } else {
            setLgbt(brand.badges.isLgbt.status);
            setBlackOwned(brand.badges.isBlackOwned.status);
            setEquityVerified(brand.badges.equityVerified.status);
            setIndigenousOwned(brand.badges.isIndigenousOwned.status);
            setPOCOwned(brand.badges.isPOCOwned.status);
            setSustainable(brand.badges.isSustainable.status);
            setWomanOwned(brand.badges.isWomanOwned.status);
            setWorkerCoop(brand.badges.isWorkerCoop.status);
            setIsUnionized(brand.badges.isUnionized.status);
          }
          setName(brand.name);
          setImage(brand.logo);
          setClaimed(brand.claimed);
          setBrandLink(brand.brandLink)
          setCBD(brand.cbd)
          setIsTHC(brand.isTHC)
          
      }
  },[dispatch, brandId, successUpdate])

  const submitHandler = (e) => {
  
    e.preventDefault();

    //if we do not have a preview source new not let me submit
    // if (!previewSource) {
    //   return;
    // }

    dispatch(
      updateBrand({
        _id: brandId,
        name,
        logo: image,
        description,
        brandLink,
        claimed,
        cbd,
        isTHC,
        badges: {
            isLgbt,
            isBlackOwned,
            isIndigenousOwned,
            isPOCOwned,
            isWomanOwned,
            isSustainable,
            isWorkerCoop,
            equityVerified,
            isUnionized,
          },
      })
    );
  };


    
    const uploadImage = async (base64EncodedImage) => {
        // try {
        //   const checking = await fetch('/api/uploads',{
        //     method:"POST",
        //     body: JSON.stringify({data:base64EncodedImage}),
        //     headers:{'Content-type':'application/json'}
        //   })
        //  console.log(checking)
        // } catch (error) {
        //   console.log("error", error)
        // }
        const bodyFormData = new FormData();
        bodyFormData.append("image", base64EncodedImage);
    
        try {
          const { data } = await axios.post(
            "/api/uploads",
            JSON.stringify({ data: base64EncodedImage }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        
          const id = data.public_id;
          setImage(id);
        } catch (err) {
          setErrorUpload(err.message);
        }
      };
      const hadleFileInputChange = (e) => {
        const file = e.target.files[0];
        previewFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          uploadImage(reader.result);
        };
      };
    
      const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setPreviewSource(reader.result);
        };
      };
    return (
        <div>
          <form className="form" autocomplete="off" onSubmit={submitHandler}>
            <div>
              <h1>Edit Brand{brandId}</h1>
            </div>
            {loadingUpdate && <LoadingBox></LoadingBox>}
            {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
                <StatusBox>{brand.claimed}</StatusBox>
                <div>
              <label htmlFor="claimed">
                <strong>Claimed/Unclaimed</strong>
              </label>
              <div>
                <div>
                  <input
                    id="claimed"
                    type="radio"
                    value="unclaimed"
                    checked={claimed === "claimed"}
                    onClick={() => setClaimed("claimed")}></input>
                  <label for="claimed">Claimed</label>
                </div>
              </div>
              <div>
                <div>
                  <input
                    id="unclaimed"
                    type="radio"
                    value="claimed"
                    checked={claimed==="unclaimed"}
                    onClick={() => setClaimed("unclaimed")}></input>
                  <label for="unclaimed">Un-claimed</label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="claimed">
                <strong>CBD?</strong>
              </label>
              <div>
                <div>
                  <input
                    id="yes"
                    type="radio"
                    value="yes"
                    checked={cbd === "yes"}
                    onClick={() => setCBD("yes")}></input>
                  <label for="yes">YES</label>
                </div>
              </div>
              <div>
                <div>
                  <input
                    id="no"
                    type="radio"
                    value="no"
                    checked={cbd==="no"}
                    onClick={() => setCBD("no")}></input>
                  <label for="no">NO</label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="isTHC">
                <strong>Does this brand sell THC products?</strong>
              </label>
              <div>
                <div>
                  <input
                    id="yes"
                    type="checkbox"
                  
                    checked={isTHC}
                    onChange={(e) => setIsTHC(e.target.checked)}></input>
                  <label for="yes">Sells THC products</label>
                </div>
              </div>
            
            </div>
                <div>
                  <label htmlFor="name">Brand Name</label>
                  <input
                    id="name"
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}></input>
                </div>
               
                {/* <div>
                  <label htmlFor="image">Please upload product image</label>
                  <input
                    type="file"
                    name="image"
                    onChange={hadleFileInputChange}
                    value={fileInputState}
                    className="form-input"
                  />
                </div>
                {previewSource && (
                  <img
                    src={previewSource}
                    alt="preview of uploaded image"
                    style={{ height: "auto", width: "20rem" }}
                  />
                )} */}
                 {brand && brand.logo===" " ||brand.logo==="" ? (
                  <>
                    <div>
                      <label htmlFor="image">
                        <strong>Please upload brand logo</strong>
                      </label>
                      <input
                        type="file"
                        name="image"
                        onChange={hadleFileInputChange}
                        value={fileInputState}
                        className="form-input"
                      />
                    </div>
                    {previewSource && (
                      <img
                        src={previewSource}
                        alt="preview of uploaded image"
                        style={{ height: "auto", width: "20rem" }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <div>
                      <label htmlFor="image">
                        <strong>Please upload brand logo</strong>
                      </label>
                      <input
                        type="file"
                        name="image"
                        onChange={hadleFileInputChange}
                        value={fileInputState}
                        className="form-input"
                      />
                    </div>
                    <Image
                      cloudName="merp"
                      publicId={brand.logo}
                      secure="true"
                      width="300"
                      crop="scale"
                    />
                  </>
                )}
                {/* <div>
                  <label htmlFor="image">Image</label>
                  <input
                    id="image"
                    type="text"
                    placeholder="Enter Image"
                    value={image}
                   ></input>
                </div>
                <div>
                  <label htmlFor="imageFile">Please upload product image (product must be a jpg/jpeg to upload)</label>
                  <input
                    type="file"
                    id="imageFile"
                    label="Choose image"
                    onChange={uploadFileHandler}></input>
                  {loadingUpload && <LoadingBox></LoadingBox>}
                  {errorUpload && (
                    <MessageBox variant="danger">{errorUpload}</MessageBox>
                  )}
                </div> */}
               
               <div>
              <h1>Which badge(s) apply to this business?</h1>
              <div>
                <input
                  id="isBlackOwned"
                  type="checkbox"
                  checked={isBlackOwned}
                  onChange={(e) => setBlackOwned(e.target.checked)}></input>
                <label htmlFor="isBlackOwned">{" "}Black Owned</label>
              </div>
              <div>
                <input
                  id="isIndigenousOwned"
                  type="checkbox"
                  checked={isIndigenousOwned}
                  onChange={(e) =>
                    setIndigenousOwned(e.target.checked)
                  }></input>
                <label htmlFor="isIndigenousOwned">{" "}Indigenous Owned</label>
              </div>
              <div>
                <input
                  id="isPOCOwned"
                  type="checkbox"
                  checked={isPOCOwned}
                  onChange={(e) => setPOCOwned(e.target.checked)}></input>
                <label htmlFor="isPOCOwned">{' '}POC Owned</label>
              </div>
              <div>
                <input
                  id="isWomanOwned"
                  type="checkbox"
                  checked={isWomanOwned}
                  onChange={(e) => setWomanOwned(e.target.checked)}></input>
                <label htmlFor="isWomanOwned">{" "}Woman Owned</label>
              </div>
              <div>
                <input
                  id="isWorkerCoop"
                  type="checkbox"
                  checked={isWorkerCoop}
                  onChange={(e) => setWorkerCoop(e.target.checked)}></input>
                <label htmlFor="isWorkerCoop">{" "}Worker COOP</label>
              </div>
              <div>
                <input
                  id="isLgbt"
                  type="checkbox"
                  checked={isLgbt}
                  onChange={(e) => setLgbt(e.target.checked)}></input>
                <label htmlFor="isLgbt">{" "}LGBT Owned</label>
              </div>
              <div>
                <input
                  id="equityPlanVerified"
                  type="checkbox"
                  checked={equityVerified}
                  onChange={(e) => setEquityVerified(e.target.checked)}></input>{" "}
                <label htmlFor="equityPlanVerified">Equity Plan Verified</label>
              </div>
              <div>
                <input
                  id="isSustainable"
                  type="checkbox"
                  checked={isSustainable}
                  onChange={(e) => setSustainable(e.target.checked)}></input>
                <label htmlFor="isSustainable">{" "}Sustainably grown/sourced</label>
              </div>
              <div>
                <input
                  id="isUnionized"
                  type="checkbox"
                  checked={isUnionized}
                  onChange={(e) => setIsUnionized(e.target.checked)}></input>
                <label htmlFor="isUnionized">{" "}Unionized</label>
              </div>
            </div>
                
                
               
                <div>
                  <label htmlFor="description">
                    Description (Pease enter detailed description of your product)
                  </label>
                  <textarea
                    id="description"
                    rows="10"
                    type="text"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
                <>
                  <div>
                    <label htmlFor="brandLink">Please enter brand link</label>
                    <input
                      id="brandLink"
                      type="url"
                      value={brandLink}
                      onChange={(e) => setBrandLink(e.target.value)}></input>
                  </div>
                  <div className="disclaimer" style={{ color: "red" }}>
                    Link must be prepended with "https:"
                  </div>
                </>
               
              </>
            )}
             <div>
                  <label />
                  <button className="primary" type="submit">
                    Update
                  </button>
                </div>
          </form>
        </div>
      );
}
