import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getAgeVerifiction } from "../utils";
export const AdminRoute = ({ component: Component, ...rest }) => {
  const userSignin = useSelector((state) => state.userSignin);
  const ageVerified = getAgeVerifiction() 
  const { userInfo } = userSignin;
  
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          userInfo && userInfo.isAdmin ?(
            <Component {...props}></Component>
          ) : (
            ageVerified ?
            <Redirect to="/signin" /> :
            <Redirect to="/age_verification"/>
          )
        }></Route>
    </div>
  );
};
