import React from "react";
import { Link } from "react-router-dom";

export default function Badges({badges}) {
 ;
  const badgesArr = [];




  

  if (!badges) {
    return badgesArr;
  } else {
    for (const key in badges) {
      if (badges[key].status) {
        badgesArr.push(badges[key]);
      }
    }
  }
  return (
    <div className="shop-row">
      <div className="row-badges" style={{ marginBottom: "1rem",marginTop:"2rem" }}>
        {/*filtering through what badges companies get*/}

        {badgesArr.map((b) => (
          <div key={b.alt} className="badge" >
            <img src={b.image} alt={b.alt}  />
          </div>
        ))}
      </div>
    </div>
  );
}
