import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  listProducts,
  createProduct,
  deleteProduct,
} from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DELETE_RESET,
} from "../constants/productConstants";
import { Link, useParams } from "react-router-dom";

const ProductListScreen = (props) => {
  const {
    pageNumber = 1,
  } = useParams();

  //FIX SELLER MODE because Kyra can't access dispensaries or products and sellers shouldn't be able to edit dispensaries or products that aren't theres
  const sellerMode=props.match.path.indexOf('/seller') >= 0;
  const productList = useSelector((state) => state.productList);
  const { loading, error, products,page, pages } = productList;
 

  const productCreate = useSelector((state) => state.productCreate);
  
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const userSignin = useSelector(state=>state.userSignin)
  const {userInfo} = userSignin

  //making sure when people aren't logged in or they logout on this page the are redirected to signin
  if(!userInfo || !userInfo.isSeller){
    props.history.push("/signin");
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (successCreate) {
      dispatch({ type: PRODUCT_CREATE_RESET });
      props.history.push(`/product/${createdProduct._id}/edit`);
    }
    if (successDelete) {
      dispatch({ type: PRODUCT_DELETE_RESET });
    }
    
    dispatch(listProducts({seller: sellerMode?userInfo._id:'',pageNumber, status:userInfo.isAdmin ? true : false,userInfo}));
  }, [dispatch, successDelete, props.history, successCreate, createdProduct,sellerMode, userInfo._id,pageNumber,userInfo ]);

  const deleteHandler = (product) => {
    if (window.confirm("Are you sure you'd like to delete this product?")) {
      dispatch(deleteProduct(product._id));
    }
  };
  const createHandler = () => {
    dispatch(createProduct(userInfo));
  };
  return (
    <div class="container-fluid" style={{padding:"2rem"}}>
      <div className="row-cart" style={{paddingBottom:"2rem"}}>
        <h1>Products</h1>
        <button type="button" className="primary" onClick={createHandler}>
          Create Product
        </button>
      </div>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}

      {loadingCreate && <LoadingBox></LoadingBox>}
      {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div >
        <table className="table">
          <tr>
            <th>ID</th>
            <th>NAME</th>
            <th>PRICE</th>
            <th>CATEGORY</th>
            <th>PRODUCT STATUS</th>
            <th>BRAND</th>
            <th>LINK</th>
            <th>ACTIONS</th>
          </tr>
          <tbody>
            {products.length>0 ?products.map((product) => (
              <tr key={product._id}>
                <td>{product._id}</td>
                <td>{product.name}</td>
                <td>{ product.price  }</td>
                <td>{product.category}</td>
                <td>{product.status}</td>
                <td>{product.brand.name}</td>
                <td>{product.productLink ? product.productLink : " " }</td>
                <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() =>
                      props.history.push(`/product/${product._id}/edit`)
                    }>
                    Edit
                  </button>
                  <button
                    type="button"
                    className="small"
                    onClick={() => deleteHandler(product)}>
                    Delete
                  </button>
                </td>
              </tr>
            )): <MessageBox>No products listed</MessageBox>}
          </tbody>
        </table>
       
        <div className="row center pagination">
        {[...Array(pages).keys()].map((x) => (
          <Link className={x+1===page?'active':''} key={x + 1} to={`/productlist/pageNumber/${x+1}`}>
            {x + 1}
          </Link>
        ))}
      </div>
      </div>
      )}
    </div>
  );
};

export default ProductListScreen;
