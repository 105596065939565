import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailProduct, updateProduct } from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";
import { Hint } from "react-autocomplete-hint";
import axios from "axios";
import StatusBox from "../components/StatusBox";
import { Image } from "cloudinary-react";
import expressAsyncHandler from "express-async-handler";
import { getBrands } from "../actions/brandActions";

export default function ProductEditScreen(props) {
  const productId = props.match.params.id;
  // const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [name, setName] = useState("");
  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState();
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [productLink, setProductLink] = useState("");
  const [petFriendly, setPetFriendly] = useState(false);
  const [houseHemp, setHouseHemp] = useState(false);
  const [sustainable, setSustainable] = useState(false);
  const [minorityOwned, setMinorityOwned] = useState(false);
  const [coop, setCoop] = useState(false);
  const [equityVerified, setEquityVerified] = useState(false);
  const [description, setDescription] = useState("");
  const [includesTHC, setIncludesTHC] = useState(false);
  const [cbdProduct, setCbdProduct] = useState(false);
  const [apparel, setApparel] = useState(false)
  const [percentTHC, setPercentTHC] = useState(0);
  const [searchBrands, setSearchBrands] = useState([])
  const [size, setSize] = useState("");
  const [units, setUnits] = useState("");
  const [photoUrl, setPhotoUrl] = useState('')
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const [brand, setBrand] = useState({name:"please select a brand", isTHC:false, sold:""});



 
  const [text, setText] = useState("");

  const getAllBrands = useSelector((state) => state.getAllbrands);
  const {
    loading:loadingBrands,
    error:loadingError,
    brands
  } = getAllBrands
 

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  

  const getBrandInput = (searchInput)=>{
 
    setText(searchInput)
    const foundBrands = brands.filter((brand)=> brand.name.includes(searchInput))
 
    setSearchBrands(foundBrands)
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrands());
   
   

    
 

    if (successUpdate) {
      props.history.push("/productlist/seller");
    }
    if (!product || product._id !== productId || successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch(detailProduct(productId));
    } else {
      if(product.brand && product.brand.name){
        setText(product.brand.name);
        setBrand(product.brand)
      }
      setName(product.name);
      setPrice(product.price);
      setImage(product.image);
      setCategory(product.category);
      setCountInStock(product.countInStock);
      
     
      setDescription(product.description);
      setSize(product.size);
      setUnits(product.units);
      setIncludesTHC(product.includesTHC);
      setCbdProduct(product.cbd_product);
      setPercentTHC(product.percent_THC);
      setApparel(product.apparel)
      if (product.productLink) {
        setProductLink(product.productLink);
      }
      if (product.advancedCategory) {
        setPetFriendly(product.advancedCategory.pet);
        setHouseHemp(product.advancedCategory.houseHemp);
        setSustainable(product.advancedCategory.sustainable);
      }
    }
  }, [
    dispatch,
    productId,
    product,
    props.history,
    successUpdate,
    previewSource,
  ]);

  const setNewBrand = (brandID)=>{
   
    const found = brands.filter((brand)=> brand._id === brandID)
   
    setBrand(found[0])
    setText(found[0].name)
  
  }

  const submitHandler = (e) => {
    e.preventDefault();

    //if we do not have a preview source new not let me submit
    // if (!previewSource) {
    //   return;
    // }
   
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        image,
        category,
        brand:{_id:brand._id, name:brand.name,badges:brand.badges},
        countInStock,
        description,
        includesTHC,
       cbdProduct,
        percentTHC,
        size,
        units,
        productLink,
        apparel,
        advancedCategory: {
          sustainable,
          petFriendly,
          houseHemp,
        },
      })
    );
  };

  const uploadImageUrl = async()=>{
    try {
      const { data } = await axios.post(
        "/api/uploads",
        JSON.stringify({ data: photoUrl }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
     
      const id = data.public_id;
      setImage(id);
    } catch (err) {
      setErrorUpload(err.message);
    }

  }
  const uploadImage = async (base64EncodedImage) => {
    // try {
    //   const checking = await fetch('/api/uploads',{
    //     method:"POST",
    //     body: JSON.stringify({data:base64EncodedImage}),
    //     headers:{'Content-type':'application/json'}
    //   })
    //  console.log(checking)
    // } catch (error) {
    //   console.log("error", error)
    // }
    const bodyFormData = new FormData();
    bodyFormData.append("image", base64EncodedImage);

    try {
      const { data } = await axios.post(
        "/api/uploads",
        JSON.stringify({ data: base64EncodedImage }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
     
      const id = data.public_id;
      setImage(id);
    } catch (err) {
      setErrorUpload(err.message);
    }
  };
  const hadleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage(reader.result);
    };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };
  // const uploadFileHandler = async (e) => {
  //   const file = e.target.files[0];
  //   const bodyFormData = new FormData();
  //   bodyFormData.append("image", file);
  //   setLoadingUpload(true);
  //   try {
  //     const { data } = await axios.post("/api/uploads", bodyFormData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     });
  //     setImage(data);
  //     setLoadingUpload(false);
  //   } catch (err) {
  //     setErrorUpload(err.message);
  //     setLoadingUpload(false);
  //   }
  // };

  
  return (
    <div>
      <form className="form" autocomplete="off" onSubmit={submitHandler}>
        <div>
          <h1>Edit Product {productId}</h1>
        </div>
        {loadingUpdate && <LoadingBox></LoadingBox>}
        {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <StatusBox>{product.status}</StatusBox>
            <div>
              <label htmlFor="name">Product Name</label>
              <input
                id="name"
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}></input>
            </div>
            <div>
              <label htmlFor="price">Price</label>
              <input
                id="price"
                type="text"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}></input>
            </div>
            {/* <div>
              <label htmlFor="image">Please upload product image</label>
              <input
                type="file"
                name="image"
                onChange={hadleFileInputChange}
                value={fileInputState}
                className="form-input"
              />
            </div>
            {previewSource &&  reader.readAsDataURL(file); (
              <img
                src={previewSource}
                alt="preview of uploaded image"
                style={{ height: "auto", width: "20rem" }}
              />
            )} */}
            {(product && product.image === " ") || product.image === "" ? (
              <>
                <div>
                  <label htmlFor="image">
                    <strong>Please upload dispensary logo</strong>
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={hadleFileInputChange}
                    value={fileInputState}
                    className="form-input"
                  />
                </div>
                {previewSource && (
                  <img
                    src={previewSource}
                    alt="preview of uploaded image"
                    style={{ height: "auto", width: "20rem" }}
                  />
                )}
              </>
            ) : (
              <>
                <div>
                  <label htmlFor="image">
                    <strong>Please upload dispensary logo</strong>
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={hadleFileInputChange}
                    value={fileInputState}
                    className="form-input"
                  />
                </div>
                <Image
                  cloudName="merp"
                  publicId={product.image}
                  secure="true"
                  width="300"
                  crop="scale"
                />
              </>
            )}
            <div>
              <strong>
            OR
            </strong>
            </div>

            <label htmlFor="image">
                    <strong>Please upload product image</strong>
                  </label>
                  <input
                    type="text"
                    name="image"
                    onChange={(e)=>{
                      setPhotoUrl(e.target.value)
                      setImage(e.target.value)}}
                    value={photoUrl}
                    className="form-input"
                  />
                  <button type="button" onClick={()=>uploadImageUrl()}>upload Image</button>
              
             
            {/* <div>
              <label htmlFor="image">Image</label>
              <input
                id="image"
                type="text"
                placeholder="Enter Image"
                value={image}
               ></input>
            </div>
            <div>
              <label htmlFor="imageFile">Please upload product image (product must be a jpg/jpeg to upload)</label>
              <input
                type="file"
                id="imageFile"
                label="Choose image"
                onChange={uploadFileHandler}></input>
              {loadingUpload && <LoadingBox></LoadingBox>}
              {errorUpload && (
                <MessageBox variant="danger">{errorUpload}</MessageBox>
              )}
            </div> */}
            <div>
              <label htmlFor="category">Category</label>
              <select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required>
                <option value="Select">select one</option>
                <option value="Edible">edible</option>
                <option value="flower">flower</option>
                <option value="Tincture">tincture</option>
                <option value="Topical">topical</option>
                <option value="Pre-rolls">pre-rolls</option>
                <option value="Accessory">accessory</option>
                <option value="Concentrate">concentrate</option>
                <option value="Merchandise">merchandise</option>
              </select>
            </div>
            {category === "Merchandise" && (
              <li>
                <input
                  id="apparel"
                  type="checkbox"
                  checked={apparel}
                  onChange={(e) =>
                    setApparel(e.target.checked)
                  }></input>{" "}
                <label htmlFor="apparel">Apparel</label>
              </li>
            )}
            <div>
              <div>
                <div>Advanced Categories</div>
                <ul>
                  <li>
                    <input
                      id="petFriendly"
                      type="checkbox"
                      checked={petFriendly}
                      onChange={(e) =>
                        setPetFriendly(e.target.checked)
                      }></input>{" "}
                    <label htmlFor="petFriendly">Pet-friendly</label>
                  </li>
                  <li>
                    <input
                      id="houseHemp"
                      type="checkbox"
                      checked={houseHemp}
                      onChange={(e) =>
                        setHouseHemp(e.target.checked)
                      }></input>{" "}
                    <label htmlFor="houseHemp">Household Hemp</label>
                  </li>
                  <li>
                    <input
                      id="sustainable"
                      type="checkbox"
                      checked={sustainable}
                      onChange={(e) =>
                        setSustainable(e.target.checked)
                      }></input>{" "}
                    <label htmlFor="sustainable">Sustainable</label>
                  </li>
                </ul>
              </div>
            </div>
            {brand && brand.sold==="Omline" && (
              <div>
                <label htmlFor="countInStock">Count In Stock</label>
                <input
                  id="countInStock"
                  type="text"
                  placeholder="Enter count in stock"
                  value={countInStock}
                  onChange={(e) => setCountInStock(e.target.value)}></input>
              </div>
           )}
            <div>
              <label htmlFor="brand">Brand</label>
              
              <input
                id="state"
                value={text}
                onChange={(e) => getBrandInput(e.target.value)}
                required>
                 
                  </input>
                  <ul>
                   {brands && searchBrands.map((brand)=>(  <li style={{backgroundColor:"lightblue"}} key={brand._id} onClick={()=>setNewBrand(brand._id)}>{brand.name}</li>))}
                   </ul>
              
            </div>
            <div>
              <label htmlFor="sizeAndWeight">Size or Weight of product</label>
              <div className="row-product">
                <input
                  id="sizeAndWeight"
                  placeholder="enter weight or size"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}></input>
                <select
                  id="units"
                  value={units}
                  onChange={(e) => setUnits(e.target.value)}
                  required>
                  <option value="">select one</option>
                  <option value="oz">oz</option>
                  <option value="ml">ml</option>
                  <option value="g">grams</option>
                </select>
                {/* <label htmlFor="units">Units</label> */}
              </div>
              <div className="disclaimer" style={{ color: "red" }}>
                If you are listing a merchandise or accessory item please enter
                size/demensions in the description of the product.
              </div>
            </div>
            <div>
              <label htmlFor="description">
                Description (Pease enter detailed description of your product)
              </label>
              <textarea
                id="description"
                rows="10"
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
            <>
              <div>
                <label htmlFor="productLink">Please enter product link</label>
                <input
                  id="productLink"
                  type="url"
                  value={productLink}
                  onChange={(e) => setProductLink(e.target.value)}></input>
              </div>
              <div className="disclaimer" style={{ color: "red" }}>
                Link must be prepended with "https:"
              </div>
            </>

            { brand && brand.isTHC  && (
              <>
                <div>
                  <div>
                    <input
                      id="includesTHC"
                      type="checkbox"
                      checked={includesTHC}
                      onChange={(e) => setIncludesTHC(e.target.checked)}
                    ></input>
                    <label htmlFor="IncludesTHC">
                      This item contains THC or CBD
                    </label>
                  </div>
                  {includesTHC && (
                    <div>
                      <div>
                        <input
                          id="percentTHC"
                          type="text"
                          value={percentTHC}
                          onChange={(e) =>
                            setPercentTHC(e.target.value)
                          }></input>
                        <label htmlFor="percentTHC">percent of THC</label>
                      </div>
                    </div>
                  )}
                  <div>
                    <input
                      id="cbdProduct"
                      type="checkbox"
                      checked={cbdProduct}
                      onChange={(e) => setCbdProduct(e.target.checked)}></input>
                    <label htmlFor="cbdProduct">is this a CBD Product</label>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div>
          <label />
          <button className="primary" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
