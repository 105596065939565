import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listOrders,
  deleteOrder,
  deliverOrder,
  detailsOrder,
} from "../actions/orderActions";
// import {updateSellerInvoice} from "../actions/userActions"

import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import {
  ORDER_DELETE_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";

export default function OrderListScreen(props) {
  const sellerMode = props.match.path.indexOf("/seller") >= 0;
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading: loadingDetails, error: errorDetails } = orderDetails;

  // console.log(errorDetails, "let us see this");

  const [orderNumber, setOrderNumber] = useState("");

  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = orderDelete;

  // console.log(order, "BANANABBNSKBHBSJ");
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  //making sure when people aren't logged in or they logout on this page the are redirected to signin
  if (!userInfo && !userInfo.isSeller) {
    props.history.push("/signin");
  }

  const fulfilledOrders = userInfo.isAdmin
    ? orders
    : !userInfo.isAdmin && userInfo.seller.sellTHC && orders
    ? orders.filter((order) => order.isDelivered === true)
    : orders;

  // console.log(userInfo, "checking something");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listOrders({ seller: sellerMode ? userInfo._id : "" }));

    dispatch({ type: ORDER_DELETE_RESET });
    if (successDeliver) {
      // dispatch(updateSellerInvoice(order.seller))

      dispatch(detailsOrder(orderNumber));

      dispatch(listOrders({ seller: sellerMode ? userInfo._id : "" }));

      dispatch({ type: ORDER_DELIVER_RESET });
      //todo update our invoice
    }
  }, [
    dispatch,
    successDelete,
    userInfo._id,
    order,
    sellerMode,
    successDeliver,
  ]);
  const deleteHandler = (order) => {
    if (window.confirm("are you sure you want to delete this order")) {
      dispatch(deleteOrder(order._id));
    }
  };

  const findOrder = () => {
    dispatch(deliverOrder(orderNumber));
  };
  return (
    <div class="container-fluid"style={{ height: "auto" }}>
      <div>
        {userInfo.seller.sellTHC && (
          <div>
            <label htmlFor="orderID"></label>
            <input
              id="orderID"
              type="text"
              placeholder="Enter Name"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}></input>
            <button type="button" onClick={findOrder}>
              Find Order
            </button>
          </div>
        )}
        {/*figure out why error message box won't pop up if order isn't found} */}
        {order && order.deliveredAt && (
          <div>
            <div>
              <h1>Order Found</h1>
            </div>
            {loadingDeliver && <LoadingBox></LoadingBox>}
            {errorDeliver && (
              <MessageBox variant="danger">{errorDeliver}</MessageBox>
            )}
            {loadingDetails ? (
              <LoadingBox></LoadingBox>
            ) : errorDetails ? (
              <MessageBox variant="danger">{errorDetails}</MessageBox>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>USER</th>
                    <th>DATE</th>
                    <th>TOTAL</th>
                    <th>PAID</th>
                    <th>ARRIVED</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{order._id}</td>
                    <td>{order.user.name}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>{order.totalPrice.toFixed(2)}</td>
                    <td>
                      {order.isPaid ? order.paidAt.substring(0, 10) : "No"}
                    </td>
                    <td>{order.deliveredAt.substring(0, 10)}</td>
                    <td>
                      <button
                        type="button"
                        className="small"
                        onClick={() => {
                          props.history.push(`/order/${order._id}`);
                        }}>
                        Details
                      </button>
                      <button
                        type="button"
                        className="small"
                        onClick={() => deleteHandler(order)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
        <div className="orders">
          <h1>Orders</h1>
          {loadingDelete && <LoadingBox></LoadingBox>}
          {errorDelete && (
            <MessageBox variant="danger">{errorDelete}</MessageBox>
          )}
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>USER</th>
                  <th>DATE</th>
                  <th>TOTAL</th>
                  <th>PAID</th>
                  {userInfo.seller.sellTHC ? (
                    <th>ARRIVED</th>
                  ) : (
                    <th>DELIVERED</th>
                  )}
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {fulfilledOrders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{order.user.name}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>{order.totalPrice.toFixed(2)}</td>
                    <td>
                      {order.isPaid ? order.paidAt.substring(0, 10) : "No"}
                    </td>
                    <td>
                      {order.isDelivered
                        ? order.deliveredAt.substring(0, 10)
                        : "No"}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="small"
                        onClick={() => {
                          props.history.push(`/order/${order._id}`);
                        }}>
                        Details
                      </button>
                      <button
                        type="button"
                        className="small"
                        onClick={() => deleteHandler(order)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}
