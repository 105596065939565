import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { detailsUser } from "../actions/userActions";
import { listProducts } from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import Rating from "../components/Rating";
import Product from "../components/Product";
import { detailBrand } from "../actions/brandActions";
import { detailsUser } from "../actions/userActions";
import { Image } from "cloudinary-react";

export default function SellerScreen(props) {
  const brandId = props.match.params.id;
  //   const userDetails = useSelector((state) => state.userDetails);
  //   const { loading, error, user } = userDetails;

  const brandDetails = useSelector((state) => state.brandDetails);
  const { loading, error, brand, products } = brandDetails;

  // const productList = useSelector((state) => state.productList);
  // const {
  //   loading: loadingProducts,
  //   error: errorProducts,
  //   products,
  //   page,
  //   pages,

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(detailBrand(brandId));
  }, [dispatch, brandId]);

  const badgeObj = brand ? brand.badges : {};
  const badges = [];

  if (!badgeObj) {
    return badges;
  } else {
    for (const key in badgeObj) {
      if (badgeObj[key].status) {
        badges.push(badgeObj[key]);
      }
    }
  }

  return (
    <div style={{ padding: "2.5rem" }}>
      <div className="row top">
        <div className="col-md-4 col-12">
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <ul>
              <li>
                <div>
                  <div className="brand-logo">
                    <Image
                      cloudName="merp"
                      publicId={brand.logo}
                      secure="true"
                      crop="scale"
                    />
                  </div>
                  <div className="p-1">
                    <div className="brand-screen-title">
                      <a
                        href={brand.brandLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {brand.name}
                      </a>
                    </div>

                    <>
                      {brand.claimed === "claimed" ? (
                        <button
                          disabled
                          id="claimed-button"
                          style={{ borderRadius: "12px", color: "green" }}
                        >
                          {" "}
                          Claimed{" "}
                        </button>
                      ) : (
                        <button
                          disabled
                          id="unclaimed-button"
                          style={{ borderRadius: "12px" }}
                        >
                          {" "}
                          Unclaimed{" "}
                        </button>
                      )}
                    </>
                  </div>
                </div>
              </li>
              <li>
                <Rating
                  rating={brand.rating}
                  numReviews={brand.numReviews}
                ></Rating>
              </li>
              <li>
                <p className="brand_page_description">{brand.description}</p>
              </li>
              <li>
                <div className="brand_page_categories">
                  <strong>Product Categories</strong>
                </div>
                <div className="brand_category_section">
                  {brand.product_type &&
                    brand.product_type.length > 0 &&
                    brand.product_type.map((type) => (
                      <div className="brand_category_screen">
                        <img src={type.img} alt={type.type} />
                      </div>
                    ))}
                </div>
              </li>
              <li>
                Website:{" "}
                <a
                  href={brand.brandLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {brand.brandLink}
                </a>
              </li>
              <li>
                {badges.length !== 0 && (
                  <div>
                    <div
                      className="row-badges"
                      style={{ marginBottom: "1rem" }}
                    >
                      {/*filtering through what badges companies get*/}
                      {badges.map((b) => (
                        <div key={b.alt} className="badge">
                          <img src={b.image} alt={b.alt} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
            </ul>
          )}
        </div>
        <div className="col-md-8 col-12">
          <div className="text-center text-secondary">
            <div style={{ fontSize: "2.5rem" }}>Products</div>
            {products && products.length === 0 && (
              <MessageBox>No Products Found</MessageBox>
            )}
            <div className="row p-0">
              {products &&
                products.map((product) => (
                  <Product key={product._id} product={product} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
