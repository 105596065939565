import axios from "axios";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_FAILURE,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_FAILURE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAILURE,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAILURE,
  PRODUCT_BRANDS_SUCCESS,
  BRAND_DETAILS_REQUEST,
  BRAND_DETAILS_SUCCESS,
  BRAND_DETAILS_FAILURE,
  DISPENSARY_LIST_REQUEST,
  DISPENSARY_LIST_SUCCESS,
  DISPENSARY_LIST_FAIL,
  DISPENSARY_DELETE_REQUEST,
  DISPENSARY_DELETE_SUCCESS,
  DISPENSARY_DELETE_FAILURE,
  DISPENSARY_CREATE_REQUEST,
  DISPENSARY_CREATE_SUCCESS,
  DISPENSARY_CREATE_FAILURE,
  DISPENSARY_DETAILS_REQUEST,
  DISPENSARY_DETAILS_SUCCESS,
  DISPENSARY_DETAILS_FAIL,
  DISPENSARY_UPDATE_REQUEST,
  DISPENSARY_UPDATE_SUCCESS,
  DISPENSARY_UPDATE_FAILURE,
  DISPENSARY_CREATE_REVIEW_FAILURE,
  DISPENSARY_CREATE_REVIEW_SUCCESS,
  DISPENSARY_CREATE_REVIEW_REQUEST,
  DISPENSARIES_CREATE_REQUEST,
  DISPENSARIES_CREATE_SUCCESS,
  DISPENSARIES_CREATE_FAILURE,
} from "../constants/productConstants";

export const listProducts =
  ({
    pageNumber = "",
    seller = "",
    name = "",
    type = "",
    category = "",
    rating = 0,
    order = "",
    shop = "",
    // shopCriteria,
    status = "",
    advancedCategory,
    userInfo,
  }) =>
  async (dispatch) => {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });

    try {
      const { data } = await axios.put(
        `/api/products?pageNumber=${pageNumber}&shop=${shop}&seller=${seller}&name=${name}&category=${category}&rating=${rating}&order=${order}&status=${status}&type=${type}`,
        { userInfo, advancedCategory }
      );

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload: error.message,
      });
    }
  };

export const listDispensaries =
  ({
    pageNumber = "",
    name = "",
    type = "",
    category = "",
    min = 0,
    max = 0,
    rating = 0,
    order = "",
    shop = "",
    advancedCategory = "",
    shopCriteria,
    status = "",
    userInfo,
  }) =>
  async (dispatch) => {
    dispatch({
      type: DISPENSARY_LIST_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `/api/dispensaries?pageNumber=${pageNumber}&name=${name}&type=${type}&category=${category}&min=${min}&max=${max}&rating=${rating}&order=${order}&status=${status}&shop=${shop}&shopCriteria=${shopCriteria}`,
        { userInfo, advancedCategory }
      );

      dispatch({
        type: DISPENSARY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DISPENSARY_LIST_FAIL,
        payload: error.message,
      });
    }
  };

export const detailProduct = (productId) => async (dispatch) => {
  dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
  try {
    const { data } = await axios.get(`/api/products/${productId}`);

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data.product });
    dispatch({ type: PRODUCT_BRANDS_SUCCESS, payload: data.brands });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailDispensary = (dispensaryId) => async (dispatch) => {
  dispatch({ type: DISPENSARY_DETAILS_REQUEST, payload: dispensaryId });
  try {
    const { data } = await axios.get(`/api/dispensaries/${dispensaryId}`);

    dispatch({ type: DISPENSARY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DISPENSARY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailBrand = (brandId) => async (dispatch) => {
  dispatch({ type: BRAND_DETAILS_REQUEST, payload: brandId });
  try {
    const { data } = await axios.get(`/api/brands/${brandId}`);

    dispatch({ type: BRAND_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BRAND_DETAILS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProduct = () => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      "/api/products",
      { userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_CREATE_FAILURE, payload: message });
  }
};

export const createDispensary = () => async (dispatch, getState) => {
  dispatch({ type: DISPENSARY_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      "/api/dispensaries/one",
      { userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: DISPENSARY_CREATE_SUCCESS,
      payload: data.dispensary,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DISPENSARY_CREATE_FAILURE, payload: message });
  }
};

export const createDispensaries = () => async (dispatch, getState) => {
  dispatch({ type: DISPENSARIES_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      "/api/dispensaries",
      { userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );

    dispatch({
      type: DISPENSARIES_CREATE_SUCCESS,
      payload: data.dispensaries,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DISPENSARIES_CREATE_FAILURE, payload: message });
  }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_UPDATE_REQUEST, payload: product });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.put(
      `/api/products/${product._id}`,
      { product, userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );

    dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_UPDATE_FAILURE, error: message });
  }
};

export const updateDispensary = (dispensary) => async (dispatch, getState) => {
  dispatch({ type: DISPENSARY_UPDATE_REQUEST, payload: dispensary });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.put(
      `/api/dispensaries/${dispensary._id}`,
      { dispensary, userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );

    dispatch({ type: DISPENSARY_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: DISPENSARY_UPDATE_FAILURE, error: message });
  }
};

export const deleteProduct = (productId) => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_DELETE_REQUEST, payload: productId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = axios.delete(`/api/products/${productId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({ type: PRODUCT_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_DELETE_FAILURE, payload: message });
  }
};

export const deleteDispensary =
  (dispensaryId) => async (dispatch, getState) => {
    dispatch({ type: DISPENSARY_DELETE_REQUEST, payload: dispensaryId });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = axios.delete(`/api/dispensaries/${dispensaryId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });

      dispatch({ type: DISPENSARY_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: DISPENSARY_DELETE_FAILURE, payload: message });
    }
  };

export const listProductCategories = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_CATEGORY_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(`/api/products/categories`);

    dispatch({
      type: PRODUCT_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_CATEGORY_LIST_FAILURE,
      payload: error.message,
    });
  }
};

export const createReview =
  (productId, review) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_CREATE_REVIEW_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.post(
        `/api/products/${productId}/reviews`,
        review,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS,
        payload: data.review,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_CREATE_REVIEW_FAILURE, payload: message });
    }
  };

export const createDispensaryReview =
  (dispensaryId, review) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_CREATE_REVIEW_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.post(
        `/api/dispensaries/${dispensaryId}/reviews`,
        review,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_CREATE_REVIEW_FAILURE, payload: message });
    }
  };
