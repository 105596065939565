import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { listDispensaries } from "../../actions/productActions";
import "./dispensarySearch.css";
import Dispensary from "../../components/Dispensary";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function DispensarySearchScreen(props) {
  const budtenderCriteria = props.location.state;
  //we are not setting priced dispensaries at the moment
  // const { order = "newest" } = useParams();

  let query = useQuery();

  // let includeLocation = {};
  // const addLocation = (location)=>{
  //    includeLocation[location] = true
  // }

  const resetLocations = () => {
    setCalifornia(false);
    setColorado(false);
    setIllinois(false);
    setMassachusetts(false);
    setArizona(false);
    setNewJersey(false);
    setWashington(false);
  };
  const dispatch = useDispatch();
  // const locations = [
  //   "California",
  //   "Colorado",
  //   "Illinois",
  //   "Massachusetts",
  //   "Arizona",
  //   "New Jersey",
  //   "Washington",
  // ];
  // const locationExist =(location)=>{
  //   if (locations.includes(location)){
  //     return true
  //   }
  //   return false
  // }
  const [cbd, setCBD] = useState(false);
  const [advancedCategory, setAdvancedCategory] = useState({});
  const [isUnionized, setIsUnionized] = useState(false);
  const [isMinorityOwned, setMinorityOwned] = useState(false);
  const [sustainable, setIsSustainable] = useState(false);
  const [isBlackOwned, setBlackOwned] = useState(false);
  const [isIndigenousOwned, setIndigenousOwned] = useState(false);
  const [isPOCOwned, setPOCOwned] = useState(false);
  const [isWomanOwned, setWomanOwned] = useState(false);
  const [California, setCalifornia] = useState(false);
  const [Colorado, setColorado] = useState(false);
  const [Illinois, setIllinois] = useState(false);
  const [Arizona, setArizona] = useState(false);
  const [newJersey, setNewJersey] = useState(false);
  const [Washington, setWashington] = useState(false);
  const [Massachusetts, setMassachusetts] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // const [numberRating, setNumberRating] = useState(0);
  const [isLgbt, setLgbt] = useState(false);
  const [isWorkerCoop, setWorkerCoop] = useState(false);
  const [equityVerified, setEquityVerified] = useState(false);

  const dispensaryList = useSelector((state) => state.dispensaryList);
  //for most expensive places to chepeast or vice versa
  // const [filterOrder, setFilterOrder] = useState(order);
  const [searchCategory, setSearchCategory] = useState("");

  const { loading, error, dispensaries, page, pages, results } = dispensaryList;

  // const dispensaryList = useSelector((state) => state.dispensaryList);
  // const {
  //   loading: loadingDispensary,
  //   error: errorDispensay,
  //   dispensaries,
  //   page: dispensaryPage,
  //   pages: dispensaryPages,
  // } = dispensaryList;

  const getAdvanceddCategories = () => {
    let arr = [
      { isBlackOwned: isBlackOwned },
      { isIndigenousOwned: isIndigenousOwned },
      { isPOCOwned: isPOCOwned },
      { equityVerified: equityVerified },
      { isSustainable: sustainable },
      { isUnionized: isUnionized },
      { isWomanOwned: isWomanOwned },
      { isWorkerCoop: isWorkerCoop },
    ];
    let advancedStr = "";
    arr.forEach((item, idx) => {
      for (let key in item) {
        advancedCategory[key] = item[key];
        setAdvancedCategory({ ...advancedCategory });
        if (item[key]) {
          if (idx === 0) {
            advancedStr += key;
          } else {
            advancedStr += `&${key}`;
          }
        }
      }
    });
    props.history.push(getFilterUrl({ advancedCategory: advancedStr }));
  };

  const resetCategories = () => {
    setIndigenousOwned(false);
    setIsUnionized(false);
    setMinorityOwned(false);
    setPOCOwned(false);
    setWomanOwned(false);
    setWorkerCoop(false);
    setBlackOwned(false);
    setWorkerCoop(false);
    setEquityVerified(false);
    setAdvancedCategory({});
    props.history.push("/dispensaries");
  };

  const getSearchCategory = () => {
    props.history.push(getFilterUrl({ category: searchCategory }));
  };

  useEffect(() => {
    dispatch(
      listDispensaries({
        pageNumber: query.get("page"),
        //   name: name !== "all" ? name : "",
        //   type: type !== "all" ? type : "",
        //   category: category !== "all" ? category : "",
        //   shopCriteria: budtenderCriteria,
        //   shop: shop !== "all" ? searchCategory : "",
        //   min,
        //   max,
        //   rating,
        //   order: filterOrder,
        advancedCategory,
      })
    );
  }, [
    dispatch,
    // filterOrder,
    // name,
    // category,
    // shop,
    // min,
    // max,
    // rating,
    props,
    // type,
    budtenderCriteria,
  ]);

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || query.get("page");
    const advancedCategoryFilter =
      filter.advancedCategory || query.get("advancedCategory") !== null ?  query.get("advancedCategory"): 'all';
    // const filterName = filter.name || name;
    // const filterRating = filter.rating || rating;
    // const sortOrder = filter.order;
    // const filterMin = filter.min ? filter.min : filter.min === 0 ? 0 : min;
    // const filterMax = filter.max ? filter.max : filter.max === 0 ? 0 : max;
    return `/dispensaries?page=${filterPage}&advancedCategory=${advancedCategoryFilter}`;
  };

  return (
    <div class="col-md-8 mx-auto" style={{ height: "auto", padding: "3rem" }}>
      <div>
        <div>
          <div className="search-header">
            <div className="search-header-title">Dispensaries</div>
            <section className="row-nowrap search-row scroll">
              <div className="search-filter-criteria">
                <>
                  <Modal
                    className="modal-filter"
                    show={show}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton>
                      <div class="modal-title" >
                        <strong>Advanced Category</strong>
                      </div>
                    </Modal.Header>
                    <Modal.Body>{" "}
                    <div style={{ fontSize: "1.5rem" }}>
                      Please select your desired category filter(s) :{" "}
                    </div>
                    <form>
                      <ul className="search-categories-dispensaries">
                        <li>
                          <div>
                            <input
                              id="isBlackOwned"
                              type="checkbox"
                              checked={isMinorityOwned}
                              onChange={(e) =>
                                setMinorityOwned(e.target.checked)
                              }
                            ></input>{" "}
                            <label htmlFor="isBlackOwned">
                              {" "}
                              Minority Owned
                            </label>
                          </div>
                          {isMinorityOwned && (
                            <ul className="search-categories-dispensaries">
                              <li>
                                <div>
                                  <input
                                    id="isBlackOwned"
                                    type="checkbox"
                                    checked={isBlackOwned}
                                    onChange={(e) =>
                                      setBlackOwned(e.target.checked)
                                    }
                                  ></input>{" "}
                                  <label htmlFor="isBlackOwned">
                                    {" "}
                                    Black Owned
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input
                                    id="isIndigenousOwned"
                                    type="checkbox"
                                    checked={isIndigenousOwned}
                                    onChange={(e) =>
                                      setIndigenousOwned(e.target.checked)
                                    }
                                  ></input>{" "}
                                  <label htmlFor="isIndigenousOwnedd">
                                    {" "}
                                    Indigenous Owned
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input
                                    id="isPOCOwned"
                                    type="checkbox"
                                    checked={isPOCOwned}
                                    onChange={(e) =>
                                      setPOCOwned(e.target.checked)
                                    }
                                  ></input>{" "}
                                  <label htmlFor="isPOCOwned"> POC Owned</label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input
                                    id="isWomanOwned"
                                    type="checkbox"
                                    checked={isWomanOwned}
                                    onChange={(e) =>
                                      setWomanOwned(e.target.checked)
                                    }
                                  ></input>{" "}
                                  <label htmlFor="isWomanOwned">
                                    {" "}
                                    Woman Owned
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input
                                    id="isLgbt"
                                    type="checkbox"
                                    checked={isLgbt}
                                    onChange={(e) => setLgbt(e.target.checked)}
                                  ></input>{" "}
                                  <label htmlFor="isLgbt"> LGBT Owned</label>
                                </div>
                              </li>
                            </ul>
                          )}
                        </li>
                        <li>
                          <div>
                            <input
                              id="equityPlanVerified"
                              type="checkbox"
                              checked={equityVerified}
                              onChange={(e) =>
                                setEquityVerified(e.target.checked)
                              }
                            ></input>{" "}
                            <label htmlFor="equityPlanVerified">
                              Equity Plan Verified
                            </label>
                          </div>
                        </li>
                        <li>
                          {" "}
                          <div>
                            <input
                              id="isWorkerCoop"
                              type="checkbox"
                              checked={isWorkerCoop}
                              onChange={(e) => setWorkerCoop(e.target.checked)}
                            ></input>{" "}
                            <label htmlFor="isWorkerCoop"> Worker COOP</label>
                          </div>
                        </li>
                        <li>
                          <div>
                            <input
                              id="isUnionized"
                              type="checkbox"
                              checked={isUnionized}
                              onChange={(e) => setIsUnionized(e.target.checked)}
                            ></input>{" "}
                            <label htmlFor="isUnionized">Unionized</label>
                          </div>
                        </li>
                        <li>
                          <div>
                            <input
                              id="sustainable"
                              type="checkbox"
                              checked={sustainable}
                              onChange={(e) =>
                                setIsSustainable(e.target.checked)
                              }
                            ></input>{" "}
                            <label htmlFor="sustainable">Sustainable</label>
                          </div>
                        </li>
                      </ul>
                    </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        class="btn btn-default"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => resetCategories()}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => {
                          getAdvanceddCategories();
                        }}
                      >
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
                <Button className="button-search" onClick={handleShow}>
                  Advanced Category
                </Button>
              
                {/* <button
                  type="button"
                  className="button-search"
                  data-toggle="modal"
                  data-target="#ratingModal"
                >
                  Category
                </button> */}
                <div
                  class="modal fade"
                  id="ratingModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <div class="modal-title" id="myModalLabel">
                          <strong>Category</strong>
                        </div>
                      </div>
                      <div class="modal-body">
                        <div style={{ fontSize: "1.5rem" }}>
                          Please select a Category :{" "}
                        </div>
                        <form>
                          <ul className="search-categories-dispensaries">
                            <li>
                              <div>
                                <div>
                                  <input
                                    id="dispensaries"
                                    type="radio"
                                    value={searchCategory}
                                    checked={searchCategory === "dispensaries"}
                                    onClick={(e) =>
                                      setSearchCategory("dispensaries")
                                    }
                                  ></input>{" "}
                                  <label for="dispensaries">
                                    {" "}
                                    Dispensaries
                                  </label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div>
                                <div>
                                  <input
                                    id="CBDShop"
                                    type="radio"
                                    value={searchCategory}
                                    checked={searchCategory === "CBDShop"}
                                    onClick={(e) =>
                                      setSearchCategory("CBDShop")
                                    }
                                  ></input>{" "}
                                  <label for="CBDShop"> CBD Shops</label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div>
                                <div>
                                  <input
                                    id="Merchandise"
                                    type="radio"
                                    value={searchCategory}
                                    checked={searchCategory === "Merchandise"}
                                    onClick={(e) =>
                                      setSearchCategory("Merchandise")
                                    }
                                  ></input>{" "}
                                  <label for="Merchandise"> Merchandise</label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div>
                                <div>
                                  <input
                                    id="HouseholdHemp"
                                    type="radio"
                                    value={searchCategory}
                                    checked={searchCategory === "HouseholdHemp"}
                                    onClick={(e) =>
                                      setSearchCategory("HouseholdHemp")
                                    }
                                  ></input>{" "}
                                  <label for="HouseholdHemp">
                                    {" "}
                                    Household Hemp
                                  </label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div>
                                <div>
                                  <input
                                    id="petFriendly"
                                    type="radio"
                                    value={searchCategory}
                                    checked={searchCategory === "petFriendly"}
                                    onClick={(e) =>
                                      setSearchCategory("petFriendly")
                                    }
                                  ></input>{" "}
                                  <label for="petFriendly"> Pet-friendly</label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div>
                                <div>
                                  <input
                                    id="onlineCBD"
                                    type="radio"
                                    value={searchCategory}
                                    checked={searchCategory === "onlineCBD"}
                                    onClick={(e) =>
                                      setSearchCategory("onlineCBD")
                                    }
                                  ></input>{" "}
                                  <label for="onlineCBD">
                                    {" "}
                                    Online CBD Products
                                  </label>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => setSearchCategory("")}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={() => getSearchCategory()}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  type="button"
                  className="button-search"
                  data-toggle="modal"
                  data-target="#locationModal"
                >
                  Location
                </button> */}
                <div
                  class="modal fade"
                  id="locationModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        {/* <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button> */}
                        <div class="modal-title" id="myModalLabel">
                          <strong>Locations</strong>
                        </div>
                      </div>
                      <div class="modal-body">
                        <div style={{ fontSize: "1.5rem" }}>
                          Please select your desired location(s) :{" "}
                        </div>

                        <form>
                          <ul className="search-categories-dispensaries">
                            <li>
                              <div>
                                <input
                                  id="California"
                                  type="checkbox"
                                  checked={California}
                                  onChange={(e) =>
                                    setCalifornia(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="California"> California</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Colorado"
                                  type="checkbox"
                                  checked={Colorado}
                                  onChange={(e) =>
                                    setColorado(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Colorado"> California</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Illinois"
                                  type="checkbox"
                                  checked={Illinois}
                                  onChange={(e) =>
                                    setIllinois(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Illinois">Illinois</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Massachusetts"
                                  type="checkbox"
                                  checked={Massachusetts}
                                  onChange={(e) =>
                                    setMassachusetts(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Massachusetts">
                                  Massachusetts
                                </label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Arizona"
                                  type="checkbox"
                                  checked={Arizona}
                                  onChange={(e) => setArizona(e.target.checked)}
                                ></input>{" "}
                                <label htmlFor="Arizona">Arizona</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="New Jersey"
                                  type="checkbox"
                                  checked={newJersey}
                                  onChange={(e) =>
                                    setNewJersey(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="New Jersey">New Jersey</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Washington"
                                  type="checkbox"
                                  checked={Washington}
                                  onChange={(e) =>
                                    setWashington(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Washington">Washington</label>
                              </div>
                            </li>
                            {/* {locations.map((l) => (
                          <li key={l}>
                            <div>
                              <input
                                id={`${l}`}
                                type="checkbox"
                                checked={includeLocation[l]}
                                onChange={(e) =>
                                 addLocation(e.target.checked)
                                }
                                ></input>{" "}
                              <label htmlFor={`${l}`}>{l}</label>
                            </div>
                          </li>
                        ))} */}
                          </ul>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => resetLocations()}
                        >
                          Reset
                        </button>
                        <button type="button" class="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button type="button" className="button-search">
              
            </button> */}
              </div>
              {/* <div className="filters">
                Sort by{" "}
                <select
                  // className="button-search"
                  value={filterOrder}
                  onChange={(e) => {
                    setFilterOrder(e.target.value);
                    props.history.push(getFilterUrl({ order: e.target.value }));
                  }}
                >
                  <option value="newest">Newest Arrivals</option>
                  <option value="lowest">Price:Low to High</option>
                  <option value="highest">Price: High to Low</option>
                  <option value="toprated">Customer Review</option>
                </select>
              </div> */}
            </section>
          </div>
        </div>
        <div>
          <div className="container-fluid">
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <strong>{dispensaries && results} results</strong>
              </div>
            )}

            {/* {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : shop === "dispensaries" ? (
            <div>
              <strong>{dispensaries && dispensaries.length} results</strong>
            </div>
          ) : (
            <div>
              <strong>{products && products.length} results </strong>
            </div>
          )} */}

            <div className="row">
              {/* <div className="side-filter col-md-1 col-12 p-0">
          <h3>Department</h3>
          <div>
            {loadingCategories ? (
              <LoadingBox></LoadingBox>
            ) : errorCategories ? (
              <MessageBox variant="danger">{errorCategories}</MessageBox>
            ) : (
              <ul>
                <li>
                  <Link
                    className={"all" === category ? "active" : ""}
                    to={getFilterUrl({ category: "all" })}>
                    Any
                  </Link>
                </li>
                {categories.map((c) => (
                  <li key={c}>
                    <Link
                      className={c === category ? "active" : ""}
                      to={getFilterUrl({ category: c })}>
                      {c}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <h3>Price</h3>
            <ul>
              {prices.map((p) => (
                <li key={p.name}>
                  <Link
                    to={getFilterUrl({ min: p.min, max: p.max })}
                    className={
                      `${p.min}-${p.max}` === `${min}-${max}` ? "active" : ""
                    }>
                    {p.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Avg. Customer Review</h3>
            <ul >
              {ratings.map((r) => (
                <li key={r.name}>
                  <Link
                    to={getFilterUrl({ rating: r.rating })}
                    className={`${r.rating}` === `${rating}` ? "active" : ""}>
                    <Rating caption={"& up"} rating={r.rating}></Rating>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

              <div className="col-md-11 col-12 p-0">
                {loading ? (
                  <LoadingBox></LoadingBox>
                ) : error ? (
                  <MessageBox variant="danger">{error}</MessageBox>
                ) : (
                  <div style={{ paddingLeft: "2rem" }}>
                    {dispensaries.length === 0 && (
                      <MessageBox>
                        No dispensaries found for your filter(s)
                      </MessageBox>
                    )}

                    <div className="row center">
                      {dispensaries &&
                        dispensaries.map((dispensary) => (
                          <Dispensary
                            key={dispensary._id}
                            dispensary={dispensary}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pagination ">
            {[...Array(pages).keys()].map((x) => (
              <Link
                className={x + 1 === page ? "active" : ""}
                key={x + 1}
                to={getFilterUrl({ page: x + 1 })}
              >
                {x + 1}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
