import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_FAILUIRE,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_FAILURE,
  ORDER_DETAILS_SUCCESS,
  ORDER_PAY_REQUEST,
  ORDER_PAY_FAILUIRE,
  ORDER_PAY_SUCCESS,
  ORDER_MY_LIST_REQUEST,
  ORDER_MY_LIST_FAILURE,
  ORDER_MY_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_LIST_FAILURE,
  ORDER_LIST_SUCCESS,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAILURE,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER__SUCCESS,
  ORDER_DELIVER__FAILURE
} from "../constants/orderConstants";
import axios from "axios";
import { CART_EMPTY } from "../constants/cartConstants";

export const createOrder = (order,paymentResult) => async (dispatch, getState) => {
  dispatch({ type: ORDER_CREATE_REQUEST, payload: order });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.post("/api/orders", {order,paymentResult,userInfo},{
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
 
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.order });
    dispatch({ type: CART_EMPTY });
    localStorage.removeItem("cartItems");
  } catch (error) {

    dispatch({
      type: ORDER_CREATE_FAILUIRE,
      payload:
        error.reponse && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailsOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/orders/${orderId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.reponse && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DETAILS_FAILURE, payload: message });
  }
};

export const payOrder = (order, paymentResult) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ORDER_PAY_REQUEST, payload: { order, paymentResult } });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
 
    const { data } = axios.put(`/api/orders/${order._id}/pay`, paymentResult, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_PAY_SUCCESS, payload: data });
   
  } catch (error) {
    const message =
      error.reponse && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_PAY_FAILUIRE, payload: message });
  }
};

export const listOrderMine = () => async (dispatch, getState) => {
  dispatch({ type: ORDER_MY_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get("/api/orders/mine", {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_MY_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.reponse && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_MY_LIST_FAILURE, payload: message });
  }
};

export const listOrders = ({seller=''}) => async (dispatch, getState) => {
  dispatch({ type: ORDER_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/orders?seller=${seller}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.reponse && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({ type: ORDER_LIST_FAILURE, payload: message });
  }
};

export const deleteOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DELETE_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.delete(`/api/orders/${orderId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_DELETE_SUCCESS ,payload:data});
  } catch (error) {
    const message =
      error.reponse && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DELETE_FAILURE, payload: message });
  }
};

export const deliverOrder = (orderId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ORDER_DELIVER_REQUEST, payload:  orderId});
  const {
    userSignin: { userInfo },
  } = getState();
  try {

    const { data } = axios.put(`/api/orders/${orderId}/deliver`, {}, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
 
    dispatch({ type: ORDER_DELIVER__SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.reponse && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DELIVER__FAILURE, payload: message });
  }
};

