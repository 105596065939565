import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getAgeVerifiction } from "../utils";

export const VerifiedRoute = ({ component: Component, ...rest }) => {
  const ageVerified = getAgeVerifiction();
  let { path } = { ...rest };

  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          ageVerified ? (
            //pass the current route using props
            <Component {...props}></Component>
          ) : (
            <Redirect
              to={{
                pathname: "/age_verification",
                state: { path: path },
              }}
            />
          )
        }
      ></Route>
    </div>
  );
};
