import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CartItem from "../components/CartItem";
import MessageBox from "../components/MessageBox";
import { Link } from "react-router-dom";

const CartScreen = (props) => {
  const cart = useSelector((state) => state.cart);
  const { error, cartItems } = cart;

  // redirects to shipping screen

  const checkoutHandler = () => {
    props.history.push("/signin?redirect=shipping");
  };
  return (
    <div className="container p-4">
      <div style={{ justifyContent: "center" }}>
        <div>
          {error && <MessageBox variant="danger">{error}</MessageBox>}
          {cartItems.length === 0 ? (
            <div className="row justify-content-center">
              <MessageBox>
                Cart is empty <Link to="/">Go Shopping</Link>
              </MessageBox>
            </div>
          ) : (
            <div>
              {cartItems.map((item) => (
                <CartItem key={item.product} item={item} />
              ))}
            </div>
          )}
          <div className="checkout-btn ">
            <div>
              <ul>
                <li>
                  <h2>
                    <strong>
                      Subtotal (
                      {cartItems.reduce((a, c) => Number(a) + Number(c.qty), 0)}{" "}
                      items): ${" "}
                      {cartItems
                        .reduce((a, c) => a + c.price * c.qty, 0)
                        .toFixed(2)}
                    </strong>
                  </h2>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={checkoutHandler}
                    className="primary block"
                  >
                    Proceed to checkout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartScreen;
