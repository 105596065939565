import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailProduct,
  updateProduct,
  detailDispensary,
  updateDispensary,
  deleteDispensary,
} from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { Hint } from "react-autocomplete-hint";
import {
  PRODUCT_UPDATE_RESET,
  DISPENSARY_UPDATE_RESET,
} from "../constants/productConstants";
import axios from "axios";
import StatusBox from "../components/StatusBox";
import { listUsers } from "../actions/userActions";
import { Image } from "cloudinary-react";

export default function DispensaryEditScreen(props) {
  const dispatch = useDispatch();
  const dispensaryId = props.match.params.id;

  // const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [name, setName] = useState("");
  const [claimed, setClaimed] = useState("unclaimed");
  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState();
  const [owner, setOwner] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [isBlackOwned, setBlackOwned] = useState(false);
  const [isIndigenousOwned, setIndigenousOwned] = useState(false);
  const [isPOCOwned, setPOCOwned] = useState(false);
  const [isWomanOwned, setWomanOwned] = useState(false);
  const [isWorkerCoop, setWorkerCoop] = useState(false);
  const [equityVerified, setEquityVerified] = useState(false);
  const [isSustainable, setSustainable] = useState(false);
  const [isLgbt, setLgbt] = useState(false);
  const [isUnionized, setIsUnionized] = useState(false);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [telephone, setTelephone] = useState("");
  const [approved, setApproved] = useState("Y");
  const [latLng, setLatLng] = useState({});
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const dispensaryDetails = useSelector((state) => state.dispensaryDetails);
  const { loading, error, dispensary } = dispensaryDetails;

  const userList = useSelector((state) => state.userList);

  const [hintData, setHintData] = useState([]);
  const [text, setText] = useState("");
  const dispensaryUpdate = useSelector((state) => state.dispensaryUpdate);

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = dispensaryUpdate;

  useEffect(() => {
    dispatch(listUsers());

    if (successUpdate) {
      props.history.push("/dispensaries");
    }

    if (!dispensary || dispensary._id !== dispensaryId || successUpdate) {
      dispatch({ type: DISPENSARY_UPDATE_RESET });
      dispatch(detailDispensary(dispensaryId));
    } else {
      if (
        !dispensary.lat ||
        !dispensary.lng ||
        dispensary.lat === null ||
        dispensary.lng === null
      ) {
        let formattedAddress = dispensary.address.split(" ").join("+");
        let formattedCity = `${dispensary.city.split(" ").join("+")}`;
        console.log(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress},+${formattedCity},+${dispensary.state}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
          "address"
        );

        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress},+${formattedCity},+${dispensary.state}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        )
          .then((response) => response.json())
          .then((data) => {
            setLatLng({
              lat: data.results[0].geometry.location.lat,
              lng: data.results[0].geometry.location.lng,
            });

            setAddress(dispensary.address);
            setCity(dispensary.city);
            setState(dispensary.state);
            setPostalCode(dispensary.postalCode);
          });
        console.log(latLng, "looking");
      }
      setAddress(dispensary.address);
      setCity(dispensary.city);
      setState(dispensary.state);
      setPostalCode(dispensary.postalCode);

      if (!dispensary.badges || Array.isArray(dispensary.badges)) {
        setBlackOwned(isBlackOwned);
        setEquityVerified(equityVerified);
        setIndigenousOwned(isIndigenousOwned);
        setLgbt(isLgbt);
        setPOCOwned(isPOCOwned);
        setSustainable(isSustainable);
        setWomanOwned(isWomanOwned);
        setWorkerCoop(isWorkerCoop);
        setIsUnionized(isUnionized);
      } else {
        setLgbt(dispensary.badges.isLgbt.status);
        setBlackOwned(dispensary.badges.isBlackOwned.status);
        setEquityVerified(dispensary.badges.equityVerified.status);
        setIndigenousOwned(dispensary.badges.isIndigenousOwned.status);
        setPOCOwned(dispensary.badges.isPOCOwned.status);
        setSustainable(dispensary.badges.isSustainable.status);
        setWomanOwned(dispensary.badges.isWomanOwned.status);
        setWorkerCoop(dispensary.badges.isWorkerCoop.status);
        setIsUnionized(dispensary.badges.isUnionized.status);
      }
      setName(dispensary.name);
      setImage(dispensary.logo);
      setCategory(dispensary.category);
      setDescription(dispensary.description);
      setClaimed(dispensary.claimed);
      setWebsite(dispensary.website);
      setTelephone(dispensary.telephone);
      setApproved(dispensary.approved);
    }
  }, [
    dispatch,
    dispensaryId,
    dispensary,
    props.history,
    successUpdate,
    previewSource,
    listUsers,
  ]);

  const returnBack = () => {
    props.history.push("/dispensaries");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    //TOOK OUT REQUIREMENT FOR DISPENSARY EDIT
    // if (!previewSource) {
    //   return;
    // }

    dispatch(
      updateDispensary({
        _id: dispensaryId,
        name,
        logo: image,
        category,
        approved,
        description,
        claimed,
        website,
        telephone,
        brands: dispensary.brands ? dispensary.brands : [],
        badges: {
          isLgbt,
          isBlackOwned,
          isIndigenousOwned,
          isPOCOwned,
          isWomanOwned,
          isSustainable,
          isWorkerCoop,
          equityVerified,
          isUnionized,
        },
        //check this later
        owner:
          claimed === "claimed" ? "60048f8832e8fe000477bea0" : dispensary.owner,
        address,
        city,
        state,
        postalCode,
        lat: latLng.lat,
        lng: latLng.lng,
      })
    );
  };

  const uploadImage = async (base64EncodedImage) => {
    // try {
    //   const checking = await fetch('/api/uploads',{
    //     method:"POST",
    //     body: JSON.stringify({data:base64EncodedImage}),
    //     headers:{'Content-type':'application/json'}
    //   })
    //  console.log(checking)
    // } catch (error) {
    //   console.log("error", error)
    // }
    const bodyFormData = new FormData();
    bodyFormData.append("image", base64EncodedImage);

    try {
      const { data } = await axios.post(
        "/api/uploads",
        JSON.stringify({ data: base64EncodedImage }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const id = data.public_id;
      setImage(id);
    } catch (err) {
      setErrorUpload(err.message);
    }
  };
  const hadleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage(reader.result);
    };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const deleteHandler = (dispensary) => {
    if (window.confirm("Are you sure you sure?")) {
      dispatch(deleteDispensary(dispensary._id));
      props.history.push("/dispensaries");
    }
  };

  // const uploadFileHandler = async (e) => {
  //   const file = e.target.files[0];
  //   const bodyFormData = new FormData();
  //   bodyFormData.append("image", file);
  //   setLoadingUpload(true);
  //   try {
  //     const { data } = await axios.post("/api/uploads", bodyFormData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     });
  //     setImage(data);
  //     setLoadingUpload(false);
  //   } catch (err) {
  //     setErrorUpload(err.message);
  //     setLoadingUpload(false);
  //   }
  // };

  return (
    <div>
      <form className="form" autoComplete="off" onSubmit={submitHandler}>
        <div className="row-nowrap-trash">
          <h1>Edit Dispensary {dispensaryId}</h1>

          <div>
            <button
              type="button"
              variant="danger"
              style={{ backgroundColor: "#dc3545", color: "white" }}
              onClick={() => deleteHandler(dispensary)}
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
        {loadingUpdate && <LoadingBox></LoadingBox>}
        {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            {claimed === "claimed" ? (
              <StatusBox>Claimed</StatusBox>
            ) : (
              <StatusBox>Not Claimed</StatusBox>
            )}
            <div>
              <label htmlFor="claimed">
                <strong>Claimed/Unclaimed</strong>
              </label>
              <div>
                <div>
                  <input
                    id="claimed"
                    type="radio"
                    value="unclaimed"
                    checked={claimed === "claimed"}
                    onChange={() => setClaimed("claimed")}
                  ></input>
                  <label htmlFor="claimed">Claimed</label>
                </div>
              </div>
              <div>
                <div>
                  <input
                    id="unclaimed"
                    type="radio"
                    value="claimed"
                    checked={claimed === "unclaimed"}
                    onChange={() => setClaimed("unclaimed")}
                  ></input>
                  <label htmlFor="unclaimed">Un-claimed</label>
                </div>
              </div>
            </div>
            {claimed === "claimed" && (
              <div>
                <label htmlFor="owner">
                  <strong>Owner</strong>
                </label>
                <Hint options={hintData} allowTabFill>
                  <input
                    className="input-with-hint"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </Hint>
              </div>
            )}
            <div>
              <label htmlFor="name">
                <strong>Dispensary Name</strong>
              </label>
              <input
                id="name"
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>
            {(dispensary && dispensary.logo === " ") ||
            dispensary.logo === "" ? (
              <>
                <div>
                  <label htmlFor="image">
                    <strong>Please upload dispensary logo</strong>
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={hadleFileInputChange}
                    value={fileInputState}
                    className="form-input"
                  />
                </div>
                {previewSource && (
                  <img
                    src={previewSource}
                    alt="preview of uploaded image"
                    style={{ height: "auto", width: "20rem" }}
                  />
                )}
              </>
            ) : (
              <>
                <div>
                  <label htmlFor="image">
                    <strong>Please upload dispensary logo</strong>
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={hadleFileInputChange}
                    value={fileInputState}
                    className="form-input"
                  />
                </div>
                <Image
                  cloudName="merp"
                  publicId={dispensary.logo}
                  secure="true"
                  width="300"
                  crop="scale"
                />
              </>
            )}
            {/* <div>
              <label htmlFor="image">Image</label>
              <input
                id="image"
                type="text"
                placeholder="Enter Image"
                value={image}
               ></input>
            </div>
            <div>
              <label htmlFor="imageFile">Please upload product image (product must be a jpg/jpeg to upload)</label>
              <input
                type="file"
                id="imageFile"
                label="Choose image"
                onChange={uploadFileHandler}></input>
              {loadingUpload && <LoadingBox></LoadingBox>}
              {errorUpload && (
                <MessageBox variant="danger">{errorUpload}</MessageBox>
              )}
            </div> */}

            <div>
              <label htmlFor="website">
                <strong>Website Link</strong>
              </label>
              <input
                id="website"
                type="url"
                value={website}
                required
                onChange={(e) => setWebsite(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor="address">
                <strong>Dispensary Address</strong>
              </label>
              <input
                type="text"
                id="address"
                placeholder="Enter full street address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              ></input>
            </div>
            <div>
              <label htmlFor="city">
                <strong>Dispensary City</strong>
              </label>
              <input
                type="text"
                id="city"
                placeholder="Enter city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              ></input>
            </div>
            <div>
              <label htmlFor="state">
                <strong>Dispensary State</strong>
              </label>
              <select
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              >
                <option value="">Select One</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>

            <div>
              <label htmlFor="postalCode">
                <strong>Dispensary Zip Code</strong>
              </label>

              <input
                type="text"
                id="postalCode"
                placeholder="Enter Zip code"
                required
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="postalCode">
                <strong>Dispensary Phone Number</strong> (format
                ex.000-000-0000)
              </label>

              <input
                type="text"
                id="telepone"
                placeholder="Enter Dispensary Phone #"
                required
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              ></input>
            </div>

            <div>
              <label htmlFor="description">
                <strong>
                  Description (Pease enter details about dispensary, if
                  dispensary is "Un-claimed"/"Not Claimed" reference
                  dispensaries "About" section)
                </strong>
              </label>
              <textarea
                id="description"
                rows="10"
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div>
              <h1>Which badge(s) apply to this business?</h1>
              <div>
                <input
                  id="isBlackOwned"
                  type="checkbox"
                  checked={isBlackOwned}
                  onChange={(e) => setBlackOwned(e.target.checked)}
                ></input>
                <label htmlFor="isBlackOwned"> Black Owned</label>
              </div>
              <div>
                <input
                  id="isIndigenousOwned"
                  type="checkbox"
                  checked={isIndigenousOwned}
                  onChange={(e) => setIndigenousOwned(e.target.checked)}
                ></input>
                <label htmlFor="isIndigenousOwned"> Indigenous Owned</label>
              </div>
              <div>
                <input
                  id="isPOCOwned"
                  type="checkbox"
                  checked={isPOCOwned}
                  onChange={(e) => setPOCOwned(e.target.checked)}
                ></input>
                <label htmlFor="isPOCOwned"> POC Owned</label>
              </div>
              <div>
                <input
                  id="isWomanOwned"
                  type="checkbox"
                  checked={isWomanOwned}
                  onChange={(e) => setWomanOwned(e.target.checked)}
                ></input>
                <label htmlFor="isWomanOwned"> Woman Owned</label>
              </div>
              <div>
                <input
                  id="isWorkerCoop"
                  type="checkbox"
                  checked={isWorkerCoop}
                  onChange={(e) => setWorkerCoop(e.target.checked)}
                ></input>
                <label htmlFor="isWorkerCoop"> Worker COOP</label>
              </div>
              <div>
                <input
                  id="isLgbt"
                  type="checkbox"
                  checked={isLgbt}
                  onChange={(e) => setLgbt(e.target.checked)}
                ></input>
                <label htmlFor="isLgbt"> LGBT Owned</label>
              </div>
              <div>
                <input
                  id="equityPlanVerified"
                  type="checkbox"
                  checked={equityVerified}
                  onChange={(e) => setEquityVerified(e.target.checked)}
                ></input>{" "}
                <label htmlFor="equityPlanVerified">Equity Plan Verified</label>
              </div>
              <div>
                <input
                  id="isSustainable"
                  type="checkbox"
                  checked={isSustainable}
                  onChange={(e) => setSustainable(e.target.checked)}
                ></input>
                <label htmlFor="isSustainable">
                  {" "}
                  Sustainably grown/sourced
                </label>
              </div>
              <div>
                <input
                  id="isUnionized"
                  type="checkbox"
                  checked={isUnionized}
                  onChange={(e) => setIsUnionized(e.target.checked)}
                ></input>
                <label htmlFor="isUnionized"> Unionized</label>
              </div>
            </div>
            {userInfo.isAdmin && (
              <div>
                <label htmlFor="approved">
                  <strong>Approved</strong>
                </label>
                <div>
                  <div>
                    <input
                      id="approved"
                      type="radio"
                      value="approved"
                      checked={approved === "Y"}
                      onChange={() => setApproved("Y")}
                    ></input>
                    <label for="claimed">Approved</label>
                  </div>
                </div>
                <div>
                  <div>
                    <input
                      id="notApproved"
                      type="radio"
                      value="approved"
                      checked={approved === "N"}
                      onChange={() => setApproved("N")}
                    ></input>
                    <label for="unclaimed">Not Approved</label>
                  </div>
                </div>
              </div>
            )}

            <div>
              <label />
              <button className="primary" type="submit">
                Update
              </button>
            </div>
            <div>
              <label />
              <button type="button" onClick={() => returnBack()}>
                Cancel
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}
