import React, { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listProducts, listDispensaries } from "../../actions/productActions";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Product from "../../components/Product";
import "./searchScreen.css";
import { prices, ratings } from "../../utils";
import Rating from "../../components/Rating";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SearchScreen(props) {
  let query = useQuery();
  const budtenderCriteria = props.location.state;
  const {
    // name = "all",
    category = "all",

    min = 0,
    max = 0,
    rating = 0,
    order = "newest",
    pageNumber = 1,
    shop = "all",
  } = useParams();

  // let includeLocation = {};
  // const addLocation = (location)=>{
  //    includeLocation[location] = true
  // }

  const resetLocations = () => {
    setCalifornia(false);
    setColorado(false);
    setIllinois(false);
    setMassachusetts(false);
    setArizona(false);
    setNewJersey(false);
    setWashington(false);
  };
  const dispatch = useDispatch();
  // const locations = [
  //   "California",
  //   "Colorado",
  //   "Illinois",
  //   "Massachusetts",
  //   "Arizona",
  //   "New Jersey",
  //   "Washington",
  // ];
  // const locationExist =(location)=>{
  //   if (locations.includes(location)){
  //     return true
  //   }
  //   return false
  // }
  // const [cbd, setCBD] = useState(false);
  // const [isUnionized, setIsUnionized] = useState(false);

  const [sustainable, setIsSustainable] = useState(
    query.getAll("advancedCategory").includes("isSustainable") ? true : false
  );
  const [isBlackOwned, setBlackOwned] = useState(
    query.getAll("advancedCategory").includes("isBlackOwned") ? true : false
  );
  const [isIndigenousOwned, setIndigenousOwned] = useState(
    query.getAll("advancedCategory").includes("isIndigenousOwned")
      ? true
      : false
  );
  const [isPOCOwned, setPOCOwned] = useState(
    query.getAll("advancedCategory").includes("isPOCOwned") ? true : false
  );
  const [isWomanOwned, setWomanOwned] = useState(
    query.getAll("advancedCategory").includes("isWomanOwned") ? true : false
  );
  const [California, setCalifornia] = useState(false);
  const [Colorado, setColorado] = useState(false);
  const [Illinois, setIllinois] = useState(false);
  const [Arizona, setArizona] = useState(false);
  const [newJersey, setNewJersey] = useState(false);
  const [Washington, setWashington] = useState(false);
  const [Massachusetts, setMassachusetts] = useState(false);
  // const [numberRating, setNumberRating] = useState(0);
  const [isLgbt, setLgbt] = useState(
    query.getAll("advancedCategory").includes("isLgbt") ? true : false
  );
  const [isWorkerCoop, setWorkerCoop] = useState(false);
  const [equityVerified, setEquityVerified] = useState(
    query.getAll("advancedCategory").includes("equityVerified") ? true : false
  );
  const [isMinorityOwned, setMinorityOwned] = useState(
    isPOCOwned || isWomanOwned || isBlackOwned || isIndigenousOwned || isLgbt
      ? true
      : false
  );
  const productList = useSelector((state) => state.productList);
  const [filterOrder, setFilterOrder] = useState(order);
  const [searchCategory, setSearchCategory] = useState("");
  const [show, setShow] = useState(false);
  const [categoryShow, setCatgoryShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleCategoryClose = () => {
    setCatgoryShow(false);
  };

  const handleCategoryShow = () => setCatgoryShow(true);

  const { loading, error, products, page, pages, results } = productList;

  const dispensaryList = useSelector((state) => state.dispensaryList);
  const {
    dispensaries,
    pages: dispensaryPages,
    results: dispensaryResults,
  } = dispensaryList;

  const getUrlQueryStr = () => {
    let arr = [
      { isBlackOwned: query
        .getAll("advancedCategory")
        .includes("isBlackOwned")
        ? true : isBlackOwned },
      { isIndigenousOwned: query
        .getAll("advancedCategory")
        .includes("isIndigenousOwned")
        ? true:isIndigenousOwned },
      { isPOCOwned: query.getAll("advancedCategory").includes("isPOCOwned")
      ? true:isPOCOwned },
      { equityVerified:  query
        .getAll("advancedCategory")
        .includes("equityVerified")
        ? true:equityVerified },
      { isSustainable:  query
        .getAll("advancedCategory")
        .includes("isSustainable")
        ? true:sustainable },
      { isWomanOwned: query
        .getAll("advancedCategory")
        .includes("isWomanOwned")
        ? true:isWomanOwned },
      { isWorkerCoop: query.getAll("advancedCategory").includes("coop")
      ? true:isWorkerCoop },
      {isMinorityOwned: query
        .getAll("advancedCategory")
        .includes("isMinorityOwned")
        ? true
        : false,}
    ];
    let advancedStr = "";
    let count = 0;
    arr.forEach((item) => {
      for (let key in item) {
        if (item[key]) {
          //   advancedCategory[key] = item[key];
          //   setAdvancedCategory({ ...advancedCategory });
          count++;
          if (count === 1) {
            advancedStr += `${key}&`;
          } else {
            advancedStr += `advancedCategory=${key}&`;
          }
        }
      }
    });
    return advancedStr;
  };

  const getAdvanceddCategories = () => {
    props.history.push(getFilterUrl({}));
  };

  // const productCategoryList = useSelector((state) => state.productCategoryList);
  // const {
  //   loading: loadingCategories,
  //   error: errorCategories,
  //   categories,
  // } = productCategoryList;

  const resetCategories = () => {
    setIndigenousOwned(false);
    // setIsUnionized(false);
    setMinorityOwned(false);
    setPOCOwned(false);
    setWomanOwned(false);
    setBlackOwned(false);
    setWorkerCoop(false);
    setEquityVerified(false);

    props.history.push("/search?");
  };

  const getSearchCategory = () => {
    props.history.push(getFilterUrl({ category: searchCategory }));
  };

  // const allSite = dispensaries && products ? [...dispensaries,...products] : []
  
  useEffect(() => {
    dispatch(
      listProducts({
        pageNumber,
        name: !query.get("q") || query.get("q") === null ? "" : query.get("q"),
        // type: type !== "all" ? type : "",
        category: category !== "all" ? category : "",
        // shopCriteria: budtenderCriteria,
        shop:!query.get("shop")  || query.get("shop") === null ? "" : query.get("shop")  ,
        // min,
        // max,
        // rating,
        order: filterOrder,
        advancedCategory: {
          isSustainable: query
            .getAll("advancedCategory")
            .includes("isSustainable")
            ? true
            : false,
          isBlackOwned: query
            .getAll("advancedCategory")
            .includes("isBlackOwned")
            ? true
            : query.getAll("advancedCategory").includes("isMinorityOwned")
            ? true
            : false,
          isIndigenousOwned: query
            .getAll("advancedCategory")
            .includes("isIndigenousOwned")
            ? true
            : query.getAll("advancedCategory").includes("isMinorityOwned")
            ? true
            : false,
          isLgbt: query.getAll("advancedCategory").includes("isLgbt")
            ? true
            : query.getAll("advancedCategory").includes("isMinorityOwned")
            ? true
            : false,
          isPOCOwned: query.getAll("advancedCategory").includes("isPOCOwned")
            ? true
            : query.getAll("advancedCategory").includes("isMinorityOwned")
            ? true
            : false,
          isWomanOwned: query
            .getAll("advancedCategory")
            .includes("isWomanOwned")
            ? true
            : query.getAll("advancedCategory").includes("isMinorityOwned")
            ? true
            : false,
          equityVerified: query
            .getAll("advancedCategory")
            .includes("equityVerified")
            ? true
            : false,
          isWorkerCoop: query.getAll("advancedCategory").includes("coop")
            ? true
            : false,
          isMinorityOwned: query
            .getAll("advancedCategory")
            .includes("isMinorityOwned")
            ? true
            : false,
        },
      })
    );
    dispatch(
      listDispensaries({
        pageNumber,
        order: filterOrder,
        name: !query.get("q") || query.get("q") === null ? "" : query.get("q"),
        advancedCategory: {
          isSustainable: query
            .getAll("advancedCategory")
            .includes("isSustainable")
            ? true
            : false,
          isBlackOwned: query
            .getAll("advancedCategory")
            .includes("isBlackOwned")
            ? true
            : false,
          isIndigenousOwned: query
            .getAll("advancedCategory")
            .includes("isIndigenousOwned")
            ? true
            : false,
          isLgbt: query.getAll("advancedCategory").includes("isLgbt")
            ? true
            : false,
          isPOCOwned: query.getAll("advancedCategory").includes("isPOCOwned")
            ? true
            : false,
          isWomanOwned: query
            .getAll("advancedCategory")
            .includes("isWomanOwned")
            ? true
            : false,
          equityVerified: query
            .getAll("advancedCategory")
            .includes("equityVerified")
            ? true
            : false,
          isWorkerCoop: query.getAll("advancedCategory").includes("coop")
            ? true
            : false,
          isMinorityOwned: query
            .getAll("advancedCategory")
            .includes("isMinorityOwned")
            ? true
            : false,
        },
      })
    );
  }, [
    dispatch,
    filterOrder,
    // name,
    category,
    shop,
    props,
    min,
    max,
    rating,
    pageNumber,
    budtenderCriteria,
  ]);

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || pageNumber;
    const filterCategory = filter.category || category;
    const filterRating = filter.rating || rating;
    const advancedCategory = getUrlQueryStr() || "all&";
    const sortOrder = filter.order;
    const filterMin = filter.min ? filter.min : filter.min === 0 ? 0 : min;
    const filterMax = filter.max ? filter.max : filter.max === 0 ? 0 : max;
    if(query.get("shop") !== null && query.get("shop")){
      return `/search?shop=${query.get("shop")}&advancedCategory=${advancedCategory}category=${filterCategory}/min/${filterMin}/max/${filterMax}/rating/${filterRating}/order/${sortOrder}/page/${filterPage}` 
    }

    if(!query.get("q") || query.get("q") === null || query.get("q") ===''){
      return `/search?&advancedCategory=${advancedCategory}category=${filterCategory}/min/${filterMin}/max/${filterMax}/rating/${filterRating}/order/${sortOrder}/page/${filterPage}` 
    }
    else{
      return `/search?q=${query.get(
        "q"
      )}&advancedCategory=${advancedCategory}category=${filterCategory}/min/${filterMin}/max/${filterMax}/rating/${filterRating}/order/${sortOrder}/page/${filterPage}` 
    }   
  };

  return (
    <div class="col-md-8 mx-auto" style={{ height: "auto", padding: "3rem" }}>
      <div>
        <div>
          <div className="search-header">
            {query.get('shop')==="mothers_day" && <div className="search-header-title">Mother's Day Gifts</div>}
            <section className="row-nowrap search-row scroll">
              <div className="search-filter-criteria">
                <>
                  <Modal
                    className="modal-filter"
                    show={show}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton>
                      <div class="modal-title">
                        <strong>Advanced Category</strong>
                      </div>
                    </Modal.Header>

                    <Modal.Body>
                      <div style={{ fontSize: "1.5rem" }}>
                        Please select your desired category filter(s) :{" "}
                      </div>

                      <form>
                        <ul className="search-categories-dispensaries">
                          <li>
                            <div>
                              <input
                                id="isBlackOwned"
                                type="checkbox"
                                checked={isMinorityOwned}
                                onChange={(e) =>
                                  setMinorityOwned(e.target.checked)
                                }
                              ></input>{" "}
                              <label htmlFor="isBlackOwned">
                                {" "}
                                Minority Owned
                              </label>
                            </div>
                            {isMinorityOwned && (
                              <ul className="search-categories-dispensaries">
                                <li>
                                  <div>
                                    <input
                                      id="isBlackOwned"
                                      type="checkbox"
                                      checked={isBlackOwned}
                                      onChange={(e) =>
                                        setBlackOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isBlackOwned">
                                      {" "}
                                      Black Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isIndigenousOwned"
                                      type="checkbox"
                                      checked={isIndigenousOwned}
                                      onChange={(e) =>
                                        setIndigenousOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isIndigenousOwnedd">
                                      {" "}
                                      Indigenous Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isPOCOwned"
                                      type="checkbox"
                                      checked={isPOCOwned}
                                      onChange={(e) =>
                                        setPOCOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isPOCOwned">
                                      {" "}
                                      POC Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isWomanOwned"
                                      type="checkbox"
                                      checked={isWomanOwned}
                                      onChange={(e) =>
                                        setWomanOwned(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isWomanOwned">
                                      {" "}
                                      Woman Owned
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <input
                                      id="isLgbt"
                                      type="checkbox"
                                      checked={isLgbt}
                                      onChange={(e) =>
                                        setLgbt(e.target.checked)
                                      }
                                    ></input>{" "}
                                    <label htmlFor="isLgbt"> LGBT Owned</label>
                                  </div>
                                </li>
                              </ul>
                            )}
                          </li>
                          <li>
                            <div>
                              <input
                                id="equityPlanVerified"
                                type="checkbox"
                                checked={equityVerified}
                                onChange={(e) =>
                                  setEquityVerified(e.target.checked)
                                }
                              ></input>{" "}
                              <label htmlFor="equityPlanVerified">
                                Equity Plan Verified
                              </label>
                            </div>
                          </li>
                          {/* <li>
                              {" "}
                              <div>
                                <input
                                  id="isWorkerCoop"
                                  type="checkbox"
                                  checked={isWorkerCoop}
                                  onChange={(e) =>
                                    setWorkerCoop(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="isWorkerCoop">
                                  {" "}
                                  Worker COOP
                                </label>
                              </div>
                            </li> */}
                          {/* <li>
                              <div>
                                <input
                                  id="isUnionized"
                                  type="checkbox"
                                  checked={isUnionized}
                                  onChange={(e) =>
                                    setIsUnionized(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="isUnionized">Unionized</label>
                              </div>
                            </li> */}

                          <li>
                            <div>
                              <input
                                id="sustainable"
                                type="checkbox"
                                checked={sustainable}
                                onChange={(e) =>
                                  setIsSustainable(e.target.checked)
                                }
                              ></input>{" "}
                              <label htmlFor="sustainable">Sustainable</label>
                            </div>
                          </li>
                        </ul>
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        class="btn btn-default"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => resetCategories()}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => {
                          getAdvanceddCategories();
                        }}
                      >
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
                <Button className="button-search" onClick={handleCategoryShow}>
                  Category
                </Button>
                <Button className="button-search" onClick={handleShow}>
                  Advanced Category
                </Button>

                <>
                  <Modal
                    className="modal-filter"
                    show={categoryShow}
                    onHide={handleCategoryClose}
                  >
                    <Modal.Header closeButton>
                      <div class="modal-title" id="myModalLabel">
                        <strong>Category</strong>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <div style={{ fontSize: "1.5rem" }}>
                        Please select a Category :{" "}
                      </div>
                      <form>
                        <ul className="search-categories-dispensaries">
                          <li>
                            <div>
                              <div>
                                <input
                                  id="dispensaries"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "dispensaries"}
                                  onClick={(e) =>
                                    setSearchCategory("dispensaries")
                                  }
                                ></input>{" "}
                                <label for="dispensaries"> Dispensaries</label>
                              </div>
                            </div>
                          </li>
                          {/* <li>
                            <div>
                              <div>
                                <input
                                  id="CBDShop"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "CBDShop"}
                                  onClick={(e) => setSearchCategory("CBDShop")}
                                ></input>{" "}
                                <label for="CBDShop"> CBD Shops</label>
                              </div>
                            </div>
                          </li> */}
                          <li>
                            <div>
                              <div>
                                <input
                                  id="Merchandise"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "Merchandise"}
                                  onClick={(e) =>
                                    setSearchCategory("Merchandise")
                                  }
                                ></input>{" "}
                                <label for="Merchandise"> Merchandise</label>
                              </div>
                            </div>
                          </li>
                          {/* <li>
                            <div>
                              <div>
                                <input
                                  id="HouseholdHemp"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "HouseholdHemp"}
                                  onClick={(e) =>
                                    setSearchCategory("HouseholdHemp")
                                  }
                                ></input>{" "}
                                <label for="HouseholdHemp">
                                  {" "}
                                  Household Hemp
                                </label>
                              </div>
                            </div>
                          </li> */}
                          {/* <li>
                            <div>
                              <div>
                                <input
                                  id="petFriendly"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "petFriendly"}
                                  onClick={(e) =>
                                    setSearchCategory("petFriendly")
                                  }
                                ></input>{" "}
                                <label for="petFriendly"> Pet-friendly</label>
                              </div>
                            </div>
                          </li> */}
                          <li>
                            <div>
                              <div>
                                <input
                                  id="onlineCBD"
                                  type="radio"
                                  value={searchCategory}
                                  checked={searchCategory === "onlineCBD"}
                                  onClick={(e) =>
                                    setSearchCategory("onlineCBD")
                                  }
                                ></input>{" "}
                                <label for="onlineCBD"> CBD Products</label>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        class="btn btn-default"
                        onClick={handleCategoryClose}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => setSearchCategory("")}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => getSearchCategory()}
                      >
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>

                {/* <button
                  type="button"
                  className="button-search"
                  data-toggle="modal"
                  data-target="#locationModal"
                >
                  Location
                </button> */}
                <div
                  class="modal fade"
                  id="locationModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        {/* <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button> */}
                        <div class="modal-title" id="myModalLabel">
                          <strong>Locations</strong>
                        </div>
                      </div>
                      <div class="modal-body">
                        <div style={{ fontSize: "1.5rem" }}>
                          Please select your desired location(s) :{" "}
                        </div>

                        <form>
                          <ul className="search-categories-dispensaries">
                            <li>
                              <div>
                                <input
                                  id="California"
                                  type="checkbox"
                                  checked={California}
                                  onChange={(e) =>
                                    setCalifornia(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="California"> California</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Colorado"
                                  type="checkbox"
                                  checked={Colorado}
                                  onChange={(e) =>
                                    setColorado(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Colorado"> California</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Illinois"
                                  type="checkbox"
                                  checked={Illinois}
                                  onChange={(e) =>
                                    setIllinois(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Illinois">Illinois</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Massachusetts"
                                  type="checkbox"
                                  checked={Massachusetts}
                                  onChange={(e) =>
                                    setMassachusetts(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Massachusetts">
                                  Massachusetts
                                </label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Arizona"
                                  type="checkbox"
                                  checked={Arizona}
                                  onChange={(e) => setArizona(e.target.checked)}
                                ></input>{" "}
                                <label htmlFor="Arizona">Arizona</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="New Jersey"
                                  type="checkbox"
                                  checked={newJersey}
                                  onChange={(e) =>
                                    setNewJersey(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="New Jersey">New Jersey</label>
                              </div>
                            </li>
                            <li>
                              <div>
                                <input
                                  id="Washington"
                                  type="checkbox"
                                  checked={Washington}
                                  onChange={(e) =>
                                    setWashington(e.target.checked)
                                  }
                                ></input>{" "}
                                <label htmlFor="Washington">Washington</label>
                              </div>
                            </li>
                            {/* {locations.map((l) => (
                          <li key={l}>
                            <div>
                              <input
                                id={`${l}`}
                                type="checkbox"
                                checked={includeLocation[l]}
                                onChange={(e) =>
                                 addLocation(e.target.checked)
                                }
                                ></input>{" "}
                              <label htmlFor={`${l}`}>{l}</label>
                            </div>
                          </li>
                        ))} */}
                          </ul>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => resetLocations()}
                        >
                          Reset
                        </button>
                        <button type="button" class="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button type="button" className="button-search">
              
            </button> */}
              </div>
              <div className="filters">
                Sort by{" "}
                <select
                  // className="button-search"
                  style={{fontSize:"1rem"}}
                  className="sort-filter"
                  value={filterOrder}
                  onChange={(e) => {
                    setFilterOrder(e.target.value);
                    props.history.push(getFilterUrl({ order: e.target.value }));
                  }}
                >
                  <option value="newest">Newest Arrivals</option>
                  <option value="lowest">Price:Low to High</option>
                  <option value="highest">Price: High to Low</option>
                  <option value="toprated">Customer Review</option>
                </select>
              </div>
            </section>
          </div>
        </div>
        <div>
          <div className="container-fluid">
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <strong>{products && results} results</strong>
              </div>
            )}

            {/* {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : shop === "dispensaries" ? (
            <div>
              <strong>{dispensaries && dispensaries.length} results</strong>
            </div>
          ) : (
            <div>
              <strong>{products && products.length} results </strong>
            </div>
          )} */}

            <div className="row">
              {/* <div className="side-filter col-md-1 col-12 p-0">
          <h3>Department</h3>
          <div>
            {loadingCategories ? (
              <LoadingBox></LoadingBox>
            ) : errorCategories ? (
              <MessageBox variant="danger">{errorCategories}</MessageBox>
            ) : (
              <ul>
                <li>
                  <Link
                    className={"all" === category ? "active" : ""}
                    to={getFilterUrl({ category: "all" })}>
                    Any
                  </Link>
                </li>
                {categories.map((c) => (
                  <li key={c}>
                    <Link
                      className={c === category ? "active" : ""}
                      to={getFilterUrl({ category: c })}>
                      {c}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <h3>Price</h3>
            <ul>
              {prices.map((p) => (
                <li key={p.name}>
                  <Link
                    to={getFilterUrl({ min: p.min, max: p.max })}
                    className={
                      `${p.min}-${p.max}` === `${min}-${max}` ? "active" : ""
                    }>
                    {p.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Avg. Customer Review</h3>
            <ul >
              {ratings.map((r) => (
                <li key={r.name}>
                  <Link
                    to={getFilterUrl({ rating: r.rating })}
                    className={`${r.rating}` === `${rating}` ? "active" : ""}>
                    <Rating caption={"& up"} rating={r.rating}></Rating>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

              <div className="col-md-11 col-12 p-0">
                {loading ? (
                  <></>
                ) : error ? (
                  <></>
                ) : (
                  <div style={{ paddingLeft: "2rem" }}>
                    <div className="row center">
                      {products && products.length > 0 ? (
                        products.map((product) => (
                          <Product key={product._id} product={product} />
                        ))
                      ) : (
                        <MessageBox>No Products Found</MessageBox>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row center pagination ">
            {[...Array(pages).keys()].map((x) => (
              <Link
                className={x + 1 === page ? "active" : ""}
                key={x + 1}
                to={getFilterUrl({ page: x + 1 })}
              >
                {x + 1}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
