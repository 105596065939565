import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser, fetchRewardsPoints } from "../../actions/userActions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { addItemToCart } from "../../actions/cartActions";
import { detailProduct } from "../../actions/productActions";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";

export default function RewardsScreen(props) {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const productDetails = useSelector((state) => state.productDetails);
  const userRewards = useSelector((state) => state.userRewards);
  const [tsize, setTSize] = useState("");
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const { loading: loadingRewards, error: errorRewards, rewards } = userRewards;

  const {
    product,
    loading: loadingProduct,
    error: errorProduct,
  } = productDetails;

  if (!userInfo) {
    props.history.push("/signin");
  }

  const rewardsBank = {
    tier_1: [
      {
        img: "/images/merp_sticker_1.jpg",
        title:
          "1 - 'The Minimum : Merp Inc. X Abundance Baby Collages' sticker",
        id: "611abe9e29648d2793209a6f",
        price: 4,
      },
      {
        img: "/images/merp_sticker_2.jpg",
        title:
          " 1 - 'It's About Equity, Baby! : Merp Inc X Abundance Baby Collages' sticker",
        id: "611ad660b160d0785f3b1fdd",
        price: 4,
      },
      {
        img: "/images/merp_sticker_3.jpg",
        title:
          " 1 - 'Cultivate Relaxation: Merp X Abundance Baby Collages' sticker",
        id: "611ad941b160d0785f3b1fde",
        price: 4,
      },
    ],
    tier_2: [
      {
        img: "/images/merp_sticker_1.jpg",
        title:
          "1 - 'The Minimum : Merp Inc. X Abundance Baby Collages' sticker",
        id: "611abe9e29648d2793209a6f",
        price: 4,
      },
      {
        img: "/images/merp_sticker_2.jpg",
        title:
          " 1 - 'It's About Equity, Baby! : Merp Inc X Abundance Baby Collages' sticker",
        id: "611ad660b160d0785f3b1fdd",
        price: 4,
      },
      {
        img: "/images/merp_sticker_3.jpg",
        title:
          " 1 - 'Cultivate Relaxation: Merp X Abundance Baby Collages' sticker",
        id: "611ad941b160d0785f3b1fde",
        price: 4,
      },
      {
        img: "/images/3_pck_sticker.png",
        title: " Three Pack Stickers : Merp Inc. x Abundance Baby Collages",
        id: "61a2e16f66a84a77e827bc5b",
        price: 10,
      },
    ],
    tier_3: [
      {
        img: "/images/merp_sticker_1.jpg",
        title:
          "1 - 'The Minimum : Merp Inc. X Abundance Baby Collages' sticker",
        id: "611abe9e29648d2793209a6f",
        price: 4,
      },
      {
        img: "/images/merp_sticker_2.jpg",
        title:
          " 1 - 'It's About Equity, Baby! : Merp Inc X Abundance Baby Collages' sticker",
        id: "611ad660b160d0785f3b1fdd",
        price: 4,
      },
      {
        img: "/images/merp_sticker_3.jpg",
        title:
          " 1 - 'Cultivate Relaxation: Merp X Abundance Baby Collages' sticker",
        id: "611ad941b160d0785f3b1fde",
        price: 4,
      },
      {
        img: "/images/3_pck_sticker.png",
        title: " Three Pack Stickers : Merp Inc. x Abundance Baby Collages",
        id: "61a2e16f66a84a77e827bc5b",
        price: 10,
      },
      {
        img: "/images/tote.png",
        title: "'Catnip' tote: Merp x Clare Kim Art",
        id: "62447b3659554300047b1a41",
        prcie: 24.99,
      },
    ],
    tier_4: [
      {
        img: "/images/merp_sticker_1.jpg",
        title:
          "1 - 'The Minimum : Merp Inc. X Abundance Baby Collages' sticker",
        id: "611abe9e29648d2793209a6f",
        price: 4,
      },
      {
        img: "/images/merp_sticker_2.jpg",
        title:
          " 1 - 'It's About Equity, Baby! : Merp Inc X Abundance Baby Collages' sticker",
        id: "611ad660b160d0785f3b1fdd",
        price: 4,
      },
      {
        img: "/images/merp_sticker_3.jpg",
        title:
          " 1 - 'Cultivate Relaxation: Merp X Abundance Baby Collages' sticker",
        id: "611ad941b160d0785f3b1fde",
        price: 4,
      },
      {
        img: "/images/3_pck_sticker.png",
        title: " Three Pack Stickers : Merp Inc. x Abundance Baby Collages",
        id: "61a2e16f66a84a77e827bc5b",
        price: 10,
      },
      {
        img: "/images/tote.png",
        title: "'Catnip' tote: Merp x Clare Kim Art",
        id: "62447b3659554300047b1a41",
        price: 24.99,
      },
      {
        img: "/images/cream_t_mockup.png",
        title: "'Catnip' tee: Merp x Clare Kim Art",
        id: "611c0e0d80250757d4ef90e0",
        price: 32,
      },
    ],
  };

  const addCartItem = (redeem) => {
    const size = redeem.size ? redeem.size : "";
    const redeemed = true;
    if (redeem.id === "611c0e0d80250757d4ef90e0" && size === "") {
      alert("please select a size");
    } else {
      dispatch(addItemToCart(redeem.id, redeem.price, 1, size, redeemed));
    }
  };

  useEffect(() => {
    dispatch(detailsUser(userInfo._id));

    if (!rewards) {
      dispatch(fetchRewardsPoints(userInfo._id));
    }

    dispatch(detailProduct("611c0e0d80250757d4ef90e0"));
  }, [dispatch, userInfo]);

  const points = rewards;

  return (
    <div className="container" style={{ textAlign: "center" }}>
      <div className="col justify-content-center ">
        <div className="fullscreen-rewards">
          <div
            id="rewards-details"
            style={{
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            Merp Rewards!
          </div>
          {/* <div style={{ textAlign: "center" }}>
            <h2>
              <strong>Coins: {points} </strong>{" "}
            </h2>
          </div> */}
          <div className="rewards">
            <img
              style={{ width: "20rem" }}
              src="/images/coin.png"
              alt="rmerp rewards cannabis coin"
              className="img-fluid"
            />
          </div>

          <div>
            <div className="rewards-available-title">Rewards Offered</div>
            <ul className="rewards-items">
              <li>
                1 review ={" "}
                <Link to="/brands/60abfa60f740de33be5643b8">
                  {" "}
                  1 sticker from Merp Inc. x Abundance Baby Collages collection
                </Link>
              </li>
              <li>
                3 reviews =
                <Link to="product/61a2e16f66a84a77e827bc5b">
                  {" "}
                  Three Pack Stickers : Merp Inc. x Abundance Baby Collages
                </Link>
              </li>
              <li>
                5 reviews ={" "}
                <Link to="/product/62447b3659554300047b1a41">
                  'Catnip' tote: Merp x Clare Kim Art
                </Link>
              </li>
              <li>
                9 reviews ={" "}
                <Link to="/product/611c0e0d80250757d4ef90e0">
                  'Catnip' tee: Merp x Clare Kim Art
                </Link>
              </li>
            </ul>
          </div>
          <div className="rewards-section">
            {loadingRewards ? (
              <LoadingBox></LoadingBox>
            ) : errorRewards ? (
              <MessageBox>{error}</MessageBox>
            ) : (
              <div className="rewards-available-title">
                Number of Reviews : {points}
              </div>
            )}
          </div>
          <div
            style={{
              marginBottom: "2rem",
              marginTop: "2rem",
            }}
          >
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox>{error}</MessageBox>
            ) : (
              <div style={{ padding: "2rem" }}>
                <div style={{ textAlign: "center" }}>
                  <div className="rewards-available-title">
                    Rewards Available
                  </div>
                  <div className="row justify-content-center">
                    <div>
                      {points <= 0 ? (
                        <div>You currently don't have any rewards earned</div>
                      ) : points >= 1 && points < 3 ? (
                        rewardsBank["tier_1"].map((item) => (
                          <div className="reward-card">
                            <div style={{ margin: "4px" }}>
                              <img className="reward-img" src={item.img} />
                            </div>
                            <div
                              style={{ textAlign: "left", marginLeft: "8px" }}
                            >
                              <Link to={`/product/${item.id}`}></Link>
                              {item.title}
                              <br />
                              <button
                                onClick={() =>
                                  addCartItem({
                                    id: item.id,
                                    price: item.price,
                                  })
                                }
                                className="redeem-btn"
                              >
                                redeem
                              </button>
                            </div>
                          </div>
                        ))
                      ) : points >= 3 && points < 5 ? (
                        rewardsBank["tier_2"].map((item) => (
                          <div className="reward-card">
                            <div style={{ margin: "4px" }}>
                              <img className="reward-img" src={item.img} />
                            </div>
                            <div
                              style={{ textAlign: "left", marginLeft: "8px" }}
                            >
                              <Link to={`/product/${item.id}`}>
                                {item.title}
                              </Link>
                              <br />
                              <button
                                onClick={() =>
                                  addCartItem({
                                    id: item.id,
                                    price: item.price,
                                  })
                                }
                                className="redeem-btn"
                              >
                                redeem
                              </button>
                            </div>
                          </div>
                        ))
                      ) : points >= 5 && points < 9 ? (
                        rewardsBank["tier_3"].map((item) => (
                          <div className="reward-card">
                            <div style={{ margin: "4px" }}>
                              <img className="reward-img" src={item.img} />
                            </div>
                            <div
                              style={{ textAlign: "left", marginLeft: "8px" }}
                            >
                              <Link to={`/product/${item.id}`}>
                                {item.title}
                              </Link>
                              <br />
                              <button
                                onClick={() =>
                                  addCartItem({
                                    id: item.id,
                                    price: item.price,
                                  })
                                }
                                className="redeem-btn"
                              >
                                redeem
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        rewardsBank["tier_4"].map((item) => (
                          <div className="reward-card">
                            <div style={{ margin: "4px" }}>
                              <img className="reward-img" src={item.img} />
                            </div>
                            <div
                              style={{ textAlign: "left", marginLeft: "8px" }}
                            >
                              <Link to={`/product/${item.id}`}>
                                {item.title}
                              </Link>
                              <br />
                              <div>
                                {item.id !== "611c0e0d80250757d4ef90e0" && (
                                  <button
                                    onClick={() =>
                                      addCartItem({
                                        id: item.id,
                                        price: item.price,
                                      })
                                    }
                                    className="redeem-btn"
                                  >
                                    redeem
                                  </button>
                                )}
                                {item.id === "611c0e0d80250757d4ef90e0" &&
                                  product &&
                                  product.sizes && (
                                    <div>
                                      <label htmlFor="sizes"></label>
                                      <br />
                                      <select
                                        id="size"
                                        value={tsize}
                                        className="size-dropdwn"
                                        onChange={(e) =>
                                          setTSize(e.target.value)
                                        }
                                      >
                                        <option value="">Select a size</option>
                                        {product.sizes["XS"] <= 0 ? (
                                          <option value="XS" disabled>
                                            x-small
                                          </option>
                                        ) : product.sizes["XS"] < 4 &&
                                          product.sizes["XS"] > 0 ? (
                                          <option value="XS">
                                            x-small (
                                            {`only ${product.sizes["XS"]} left!`}
                                            )
                                          </option>
                                        ) : (
                                          <option value="XS">x-small</option>
                                        )}
                                        {product.sizes["S"] === 0 ? (
                                          <option value="S" disabled>
                                            small
                                          </option>
                                        ) : product.sizes["S"] < 4 &&
                                          product.sizes["S"] > 0 ? (
                                          <option value="S">
                                            small (
                                            {`only ${product.sizes["S"]} left!`}
                                            )
                                          </option>
                                        ) : (
                                          <option value="S">small</option>
                                        )}
                                        {product.sizes["M"] === 0 ? (
                                          <option value="M" disabled>
                                            medium
                                          </option>
                                        ) : product.sizes["M"] < 4 &&
                                          product.sizes["M"] > 0 ? (
                                          <option value="M">
                                            medium (
                                            {`only ${product.sizes["M"]} left!`}
                                            )
                                          </option>
                                        ) : (
                                          <option value="M">medium</option>
                                        )}
                                        {product.sizes["L"] === 0 ? (
                                          <option value="L" disabled>
                                            large
                                          </option>
                                        ) : product.sizes["L"] < 4 &&
                                          product.sizes["L"] > 0 ? (
                                          <option value="L">
                                            large (
                                            {`only ${product.sizes["L"]} left!`}
                                            )
                                          </option>
                                        ) : (
                                          <option value="L">large</option>
                                        )}
                                        {product.sizes["XL"] === 0 ? (
                                          <option value="XL" disabled>
                                            x-large
                                          </option>
                                        ) : product.sizes["XL"] < 4 &&
                                          product.sizes["XL"] > 0 ? (
                                          <option value="XL">
                                            x-large (
                                            {`only ${product.sizes["XL"]} left!`}
                                            )
                                          </option>
                                        ) : (
                                          <option value="XL">x-large</option>
                                        )}
                                        {product.sizes["XXL"] === 0 ? (
                                          <option value="XXL" disabled>
                                            xx-large
                                          </option>
                                        ) : product.sizes["XXL"] < 4 &&
                                          product.sizes["XXL"] > 0 ? (
                                          <option value="XXL">
                                            xx-large (
                                            {`only ${product.sizes["XXL"]} left!`}
                                            )
                                          </option>
                                        ) : (
                                          <option value="XXL">xx-large</option>
                                        )}
                                        {product.sizes["XXXL"] === 0 ? (
                                          <option value="XXXL" disabled>
                                            xxx-large
                                          </option>
                                        ) : product.sizes["XXXL"] < 4 &&
                                          product.sizes["XXXL"] > 0 ? (
                                          <option value="XXXL">
                                            xxx-large (
                                            {`only ${product.sizes["XXXL"]} left!`}
                                            )
                                          </option>
                                        ) : (
                                          <option value="XXXL">
                                            xxx-large
                                          </option>
                                        )}
                                      </select>
                                    </div>
                                  )}
                                {tsize &&
                                  item.id === "611c0e0d80250757d4ef90e0" && (
                                    <button
                                      onClick={() =>
                                        addCartItem({
                                          id: item.id,
                                          price: item.price,
                                          size: tsize,
                                        })
                                      }
                                      className="redeem-btn"
                                    >
                                      redeem
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
