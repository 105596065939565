import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import LoadingBox from "../components/LoadingBox";

const SignInScreen = (props) => {
 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  // const [guestSignin, setGuestSignin] = useState(false);

  const dispatch = useDispatch();
  const submitHandlerFunction = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  };

  // const continueAsGuestHandler = (e) => {
  //   e.preventDefault();
  //   setGuestSignin(true);
  // };
  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    // if (guestSignin) {
    //   props.history.push(redirect);
    // }
  }, [props.history, redirect, userInfo]);
  return (
    <div>
      <form className="form" onSubmit={submitHandlerFunction}>
        <div>
          <h1>Sign In</h1>
        </div>
        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger"> {error}</MessageBox>}
        <div>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            placeholder="enter email"
            required
            onChange={(e) => setEmail(e.target.value)}></input>
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="enter password"
            required
            onChange={(e) => setPassword(e.target.value)}></input>
        </div>
        <div>
          <label />
          <button className="primary" type="submit">
            Sign In
          </button>
        </div>
        <div className="signin_sub">
          <div>
            New customer?{" "}
            <a href={`/register?redirect=${redirect}`}>
              Create your account.
            </a> {" "}
            Forgot Password?{" "} <Link to={"/forgot_password"}>Reset Password</Link>
          </div>
        </div>
      </form>
     
      {/* <form className="form" onSubmit={continueAsGuestHandler}  >
          <div style={{ textAlign: "center" }}>
            <b>OR</b>
          </div>
          <div>
            <label />
            <button
              className="primary"
              type="submit">
              Continue as a guest
            </button>
          </div>
        </form> */}
    </div>
  );
};

export default SignInScreen;
