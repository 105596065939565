import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILURE,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_UPDATE_RESET,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_FAILURE,
  PRODUCT_DELETE_RESET,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAILURE,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAILURE,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_BRANDS_SUCCESS,
  BRAND_DETAILS_FAILURE,
  BRAND_DETAILS_SUCCESS,
  BRAND_DETAILS_REQUEST,
  DISPENSARY_LIST_REQUEST,
  DISPENSARY_LIST_SUCCESS,
  DISPENSARY_LIST_FAIL,
  DISPENSARY_CREATE_REQUEST,
  DISPENSARY_CREATE_SUCCESS,
  DISPENSARY_CREATE_FAILURE,
  DISPENSARY_CREATE_RESET,
  DISPENSARY_DETAILS_REQUEST,
  DISPENSARY_DETAILS_SUCCESS,
  DISPENSARY_DETAILS_FAIL,
  DISPENSARY_DELETE_REQUEST,
  DISPENSARY_DELETE_SUCCESS,
  DISPENSARY_DELETE_FAILURE,
  DISPENSARY_DELETE_RESET,
  DISPENSARY_UPDATE_REQUEST,
  DISPENSARY_UPDATE_SUCCESS,
  DISPENSARY_UPDATE_FAILURE,
  DISPENSARY_UPDATE_RESET,
  DISPENSARIES_CREATE_REQUEST,
  DISPENSARIES_CREATE_SUCCESS,
  DISPENSARIES_CREATE_FAILURE,
  DISPENSARIES_CREATE_RESET,
} from "../constants/productConstants";

export const productListReducer = (
  state = { loading: true, product: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        dispensaries: action.payload.dispensaries,
        pages: action.payload.pages,
        page: action.payload.page,
        results: action.payload.results,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dispensaryListReducer = (
  state = { loading: true, dispensaries: [] },
  action
) => {
  switch (action.type) {
    case DISPENSARY_LIST_REQUEST:
      return { loading: true };
    case DISPENSARY_LIST_SUCCESS:
      return {
        loading: false,
        dispensaries: action.payload.dispensaries,
        pages: action.payload.pages,
        page: action.payload.page,
        results: action.payload.results,
      };
    case DISPENSARY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCategoryListReducer = (
  state = { loading: true, product: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_CATEGORY_LIST_SUCCESS:
      return { loading: false, categories: action.payload };
    case PRODUCT_CATEGORY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const productDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dispensaryDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case DISPENSARY_DETAILS_REQUEST:
      return { loading: true };
    case DISPENSARY_DETAILS_SUCCESS:
      return { loading: false, dispensary: action.payload };
    case DISPENSARY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const dispensaryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPENSARIES_CREATE_REQUEST:
      return { loading: true };
    case DISPENSARY_CREATE_REQUEST:
      return { loading: true };
    case DISPENSARY_CREATE_SUCCESS:
      return { loading: false, success: true, dispensary: action.payload };
    case DISPENSARIES_CREATE_SUCCESS:
      return { loading: false, success: true, dispensaries: action.payload };
    case DISPENSARY_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case DISPENSARIES_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case DISPENSARY_CREATE_RESET:
      return {};
    case DISPENSARIES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const dispensaryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPENSARY_UPDATE_REQUEST:
      return { loading: true };
    case DISPENSARY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case DISPENSARY_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case DISPENSARY_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const dispensaryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPENSARY_DELETE_REQUEST:
      return { loading: true };
    case DISPENSARY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DISPENSARY_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case DISPENSARY_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const productCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case PRODUCT_CREATE_REVIEW_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

export const productBrandsReducer = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_BRANDS_SUCCESS:
      return { brands: action.payload };
    default:
      return state;
  }
};

export const brandDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case BRAND_DETAILS_REQUEST:
      return { loading: true };
    case BRAND_DETAILS_SUCCESS:
      return {
        loading: false,
        brand: action.payload.brand,
        products: action.payload.products,
      };
    case BRAND_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
