import React from "react";
import "./post.css";
import { Link } from "react-router-dom";
import { Image } from "cloudinary-react";

export default function Post({ blog }) {
  const getCategories = (blogCategories) => {
    let categoryItems = [];

    for (var key in blogCategories) {
      if (blogCategories[key]) {
        categoryItems.push(key);
      }
    }

    return categoryItems;
  };

  const arr = getCategories(blog.categories);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  }

  return (
    <Link to={`/blog/${blog._id}`}>
    <div className="post">
   
      <div className="post-info ">
        <span className="post-title" style={{color:"black"}}>
         {blog.title}
        </span>
        <div className="post-categories">
          {arr.length &&
            arr.map((category) => (
              <span className="post-category" id={category}>
                {" "}
                {category}
              </span>
            ))}
        </div>
        {/* <hr /> */}
        <span className="post-date">
          Published On {formatDate(blog.createdAt)}
        </span>
      </div>
      <div className="post-img ">
       
          <img src={blog.image} alt={blog.title} className="post-img" />
     
      </div>
    
      {/* <p className="post-description" >{parse(blog.description)}</p> */}
    </div>
    </Link>
  );
}
