import React, { useEffect, useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MessageBox from "../components/MessageBox";
import LoadingBox from "../components/LoadingBox";
import { detailsOrder, payOrder, deliverOrder } from "../actions/orderActions";
import axios from "axios";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import { updateUser } from "../actions/userActions";
import { Image } from "cloudinary-react";

const OrderDetailsScreen = (props) => {
  const orderID = props.match.params.id;
  const [sdkready, setSdkReady] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const orderDetails = useSelector((state) => state.orderDetails);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const { order, loading, error } = orderDetails;
  const [rewardsPoints, setRewardsPoints] = useState(false);

  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;
  const dispatch = useDispatch();

  useEffect(() => {
    const addPayPayScript = async () => {
      const { data } = await axios.get("/api/config/paypal");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=sb&disable-funding=paylater`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };
    if (order && order.isPaid && order.isDelivered) {
      //gets rewards points
      setRewardsPoints(true);
      dispatch(updateUser(userInfo, rewardsPoints));
      //makes sure they don't get it again
    }
    if (
      !order ||
      successPay ||
      successDeliver ||
      (order && order._id !== orderID)
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      //give members points on successPay
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(detailsOrder(orderID));
    } else {
      if (!order.isPaid) {
        if (!window.paypal) {
          addPayPayScript();
        } else {
          setSdkReady(true);
        }
      }
    }
  }, [orderID, dispatch, order, setSdkReady, successPay, successDeliver]);
  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
  };

  const verifyPayment = () => {
    dispatch(
      payOrder(order, {
        id: order._id,
        status: "COMPLETED",
        update_time: Date.now(),
        email_address: userInfo.email,
      })
    );
  };
  

  const deliverHandler = () => {
    dispatch(deliverOrder(order._id));
  };

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div class="container-fluid">
      <h1>Order {order._id}</h1>
      <div className="row">
        <div className="col-md-8">
          <ul>
            <li>
              <div className="checkout-card checkout-card-body">
                <h2>Customer Information</h2>
                <strong>Customer Name:</strong>
                {order.user.name}
              </div>
            </li>
            <li>
              <div className="checkout-card checkout-card-body">
                {order.exciserTax > 0 ? (
                  <h2>Pick-up Location</h2>
                ) : (
                  <h2>Shipping</h2>
                )}
                <strong>Name:</strong> {order.shippingAddress.fullName}
                <br />
                <strong>Address:</strong>
                {order.shippingAddress.address},{order.shippingAddress.city},
                {order.shippingAddress.postalCode},
                {order.shippingAddress.country}
                {/* </p> */}
                {order.isDelivered ? (
                  <MessageBox variant="success">
                    {" "}
                    Shipped at {order.deliveredAt}
                  </MessageBox>
                ) : (
                  <MessageBox variant="danger">Not Shipped</MessageBox>
                )}
              </div>
            </li>
            <li>
              <div className="checkout-card checkout-card-body">
                <h2>Payment</h2>
                <p>
                  <strong>Method:</strong> {order.paymentMethod}
                  <br />
                </p>
                {order.isPaid ? (
                  <MessageBox variant="success">
                    {" "}
                    Paid {order.paidAt}
                  </MessageBox>
                ) : (
                  <MessageBox variant="danger">Not Paid</MessageBox>
                )}
              </div>
            </li>
            <li>
              <div className="checkout-card checkout-card-body">
                <h2>Order Items</h2>
                <div>
                  <ul>
                    {order.orderItems.map((item) => (
                      <li key={item.product}>
                        <div className="row-cart">
                          <div>
                            <Image
                              cloudName="merp"
                              publicId={item.image}
                              secure="true"
                              width="100"
                              crop="scale"
                              class="img-fluid"
                            />
                          </div>
                          <div>Size: <strong>{item.size}</strong></div>
                          <div className="min-30">
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </div>

                          <div>
                            {item.qty} x ${item.price.toFixed(2)} = $
                            {item.qty * item.price.toFixed(2)}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-md-4">
          <div className="checkout-card checkout-card-body">
            {order.exciserTax > 0 ? (
              <div>
                <ul>
                  <li>
                    <h2>Order Summary</h2>
                  </li>
                  <li>
                    <div className="row-cart">
                      <div>Items</div>
                      <div>${order.itemsPrice.toFixed(2)}</div>
                    </div>
                  </li>
                  <li>
                    <div className="row-cart">
                      <div>
                        Approx. {order.shippingAddress.state} Purchaser Excise
                        Tax{" "}
                      </div>
                      ${order.exciserTax.toFixed(2)}
                    </div>
                  </li>
                  <li>
                    <div className="row-cart">
                      <div>
                        {" "}
                        Approx. {order.shippingAddress.state} Sales Tax
                      </div>
                      <div>${order.saleTaxPrice.toFixed(2)}</div>
                    </div>
                  </li>

                  <li>
                    <Link to="#">
                      <div className="disclaimer">
                        click here to find out where the taxes from your
                        purchase are going
                      </div>
                    </Link>
                  </li>
                  <li>
                    <div className="row-cart">
                      <div>
                        <strong>Order #</strong>
                      </div>
                      <div>{order._id}</div>
                    </div>
                  </li>
                  <li>
                    <div className="row-cart">
                      <div>
                        <strong>Order Total</strong>
                      </div>
                      <div>
                        <strong>${order.totalPrice.toFixed(2)}</strong>
                      </div>
                    </div>
                  </li>
                  {!order.isPaid && userInfo.isSeller && (
                    <li>
                      <>
                        {errorPay && (
                          <MessageBox variant="danger">{errorPay}</MessageBox>
                        )}
                        {loadingPay && <LoadingBox></LoadingBox>}

                        <button type="button" onClick={verifyPayment}>
                          {" "}
                          Order Paid
                        </button>
                      </>
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <ul>
                <li>
                  <h2>Order Summary</h2>
                </li>
                <li>
                  <div className="row-cart">
                    <div>Items</div>
                    <div>${order.itemsPrice.toFixed(2)}</div>
                  </div>
                </li>
                <li>
                  <div className="row-cart">
                    <div>shipping</div>
                    <div>${order.shippingPrice.toFixed(2)}</div>
                  </div>
                </li>
                <li>
                  <div className="row-cart">
                    <div>Tax</div>
                    <div>${order.taxPrice.toFixed(2)}</div>
                  </div>
                </li>
                <li>
                  <div className="row-cart">
                    <div>
                      <strong>Order Total</strong>
                    </div>
                    <div>
                      <strong>${order.totalPrice.toFixed(2)}</strong>
                    </div>
                  </div>
                </li>
                {/* {!order.isPaid && (
                  <li>
                    {!sdkready ? (
                      <LoadingBox></LoadingBox>
                    ) : (
                      <>
                        {errorPay && (
                          <MessageBox variant="danger">{errorPay}</MessageBox>
                        )}
                        {loadingPay && <LoadingBox></LoadingBox>}

                        <PayPalButton
                          amount={order.totalPrice}
                          onSuccess={successPaymentHandler}></PayPalButton>
                      </>
                    )}
                  </li>
                )} */}

                {userInfo.isAdmin && !order.isDelivered && order.isPaid && (
                  <li>
                    <form>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      {errorDeliver && (
                        <MessageBox variant="danger"></MessageBox>
                      )}
                      <div
                        className="row-cart"
                        style={{ paddingBottom: "2rem" }}>
                        <label htmlFor="trackingNumber">
                          {" "}
                          Shipping Tracking Number
                        </label>
                        <input
                          type="text"
                          id="trackingNumber"
                          placeholder="Enter Tracking Number"
                          value={trackingNumber}
                          onChange={(e) => setTrackingNumber(e.target.value)}
                          required></input>
                      </div>
                      <button
                        type="button"
                        className="primary block"
                        onClick={deliverHandler}>
                        Shipped
                      </button>
                    </form>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsScreen;
