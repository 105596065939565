import React from "react";

export default function MerpEquityScreen() {
   
  return (
    <div className="form" style={{textAlign:"center", height:"auto"}}>
      <div >
         <div className="merp-logo justify-content-center p-4" style={{display:"flex"}}  >
        <div>
          {" "}
          <img
            src="/images/merp_logo_sticker.png"
            alt="merp logo "
            className="img-fluid"
            // style={{ height: "auto", width: "20rem" }}
          />
        </div>
        </div>
      </div>
      <h1 style={{ color: "#7989DC" }}>
        What does Merp plan to do to add more equity in the legal cannabis
        industry?
      </h1>
      <p>
        We talk a lot about companies who talk about equity but don’t mention a
        clear goal or plan to get there. While we are a new company, we feel
        it’s important to be transparent on our plans for the future, especially
        as it pertains to equity measures. Merp’s mission is to speak to the
        root causes of in-equity in the cannabis industry. In our business
        model, we allocate a percentage of every dollar we get to go into a fund
        to redistribute to the people and communities most negatively impacted
        by the War on Drugs.
      </p>
      <h2 style={{ color: "#7989DC" }}>Entrepreneurs in Cannabis</h2>
      <p>
        Merp understands that there is no one size fits all solution to the lack
        of equity in the cannabis industry. However, we know there are some
        consistent barriers to entry across most markets. These include but are
        not limited to: the high cost of starting and maintaining a cannabis
        business, finding out where you can operate a dispensary since some
        states have restrictions on where you can or can’t open one, the social
        capital that usually is required since you can’t get a loan with a
        traditional bank, meaning you would need to turn to investors who
        usually aren’t readily in many marginalized people’s circles. However,
        only a fraction of all investor money goes towards black entrepreneurs
        and it usually comes with the brutal cost of selling a large portion of
        their company off to these investors, or they may not have the access to
        the tools needed to run and operate a successful business in the legal
        cannabis marketplace. We are trying to partner with non-profits that
        specialize in assisting entrepreneurs from marginalized backgrounds be
        able to start a business.
      </p>
      <h2 style={{ color: "#7989DC" }}>People who were incarcerated for non-violent drug charges</h2>
      <p>
        We have made sure we compiled a list of resources such as housing,
        legal, and jobs for people who were incarcerated for non-violent
        offenses. We are working with organizers on the ground and we have
        dedicated people on the Merp team who are constantly looking for new
        resources to input into our resource page, including state-by-state
        resources. That list is growing each day.{" "}
      </p>
      <h2 style={{ color: "#7989DC" }}>Communities</h2>
      <p>
        We can’t just talk about individuals harmed by the war on drugs without
        talking about the community. Merp has a team dedicated to reaching out
        and talking to grassroots organizers and community leaders in every
        market we enter. We hope to better understand how the cannabis industry
        negatively impacts the people in each state and market we enter, and how
        to purposefully push funds and programming towards black and brown
        communities to offer some sort of restorative justice.
      </p>
      <h2 style={{ color: "#7989DC" }}>The Environment</h2>
      <p>
        At the end of the day, cannabis is a plant, meaning that it is impacted
        by the environment and will impact the environment. So every person
        connected to this plant should be thinking about their environmental
        impact. At Merp we are trying to educate ourselves on the ways in which
        cannabis impacts our environment and ways we can use cannabis to create
        sustainable products and solutions. We are concerned with the impacts on
        the water, the soil, and the people doing the farming. We are making
        sure there is also diversity and equity down to who’s growing the crop
        which we are sharing with you as well.{" "}
      </p>
    </div>
  );
}
