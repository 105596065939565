import React from 'react'
import { Link } from 'react-router-dom';

export default function Taxes() {
    return (
        <div className="shop-image" id="worker_owned_coop">
          <div className="details">
            <h2>
              <span>Where are your <br/>taxes going?</span>
            </h2>
            <p>
            We know taxes on cannabis products can add a notable amount to your purchases, find out where your taxes from cannabis purchases are going in your state.
            </p>
            <div className="more">
              <Link
                to='/education/taxes'
                className="read-more">
                Browse <span>Now</span>
              </Link>
            </div>
          </div>
        </div>
      );
}
