import React from "react";

const Rating = (props) => {
  const { rating, numReviews, caption } = props;

  return (
    <div className="rating" style={{ marginBottom: "1rem" }}>
      {rating !== 0 && (
        <>
          <span>
            <i
              className={
                rating >= 1
                  ? "fa fa-star"
                  : rating >= 0.5
                  ? "fa fa-star-half-o"
                  : "fa fa-star-o"
              }
            ></i>
          </span>
          <span>
            <i
              className={
                rating >= 2
                  ? "fa fa-star"
                  : rating >= 1.5
                  ? "fa fa-star-half-o"
                  : "fa fa-star-o"
              }
            ></i>
          </span>
          <span>
            <i
              className={
                rating >= 3
                  ? "fa fa-star"
                  : rating >= 2.5
                  ? "fa fa-star-half-o"
                  : "fa fa-star-o"
              }
            ></i>
          </span>
          <span>
            <i
              className={
                rating >= 4
                  ? "fa fa-star"
                  : rating >= 3.5
                  ? "fa fa-star-half-o"
                  : "fa fa-star-o"
              }
            ></i>
          </span>
          <span>
            <i
              className={
                rating >= 5
                  ? "fa fa-star"
                  : rating >= 4.5
                  ? "fa fa-star-half-o"
                  : "fa fa-star-o"
              }
            ></i>
          </span>
        </>
      )}
      {caption === "" && (
        <div>
          {numReviews === 0 ? (
            <></>
          ) : numReviews === 1 ? (
            <span className="reviews-text" style={{ fontSize: "1.5rem" }}>
              {" "}
              {numReviews + " review"}
            </span>
          ) : (
            <span className="reviews-text" style={{ fontSize: "1.5rem" }}>
              {" "}
              {numReviews + " reviews"}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Rating;
