import React from 'react'
import { Link } from 'react-router-dom';

export default function HousingResources() {
    return (
        <div className="shop-image" id="bipoc">
          {/* <img src="/images/black_owned_badge_3.png" alt="shop-bipoc" /> */}
          <div className="details">
            <h2>
               <span>Housing Resources</span> 
            </h2>
            <p>
            A resource list of housing options and organizations that support in finding housing for people who were formally incarcerated.
            </p>
            <div className="more">
              <Link to={'/education_&_resources/Housing'} className="read-more">
                Browse <span>Now</span>
              </Link>
            </div>
          </div>
        </div>
      );
}
