import axios from "axios";
import {
  BRAND_DETAILS_REQUEST,
  BRAND_DETAILS_SUCCESS,
  BRAND_DETAILS_FAILURE,
} from "../constants/productConstants";
import {
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_LIST_FAIL,
  GET_DISPENSARY_BRANDS_REQUEST,
  GET_DISPENSARY_BRANDS_SUCCESS,
  GET_DISPENSARY_BRANDS_FAILURE,
  BRAND_DELETE_REQUEST,
  BRAND_DELETE_SUCCESS,
  BRAND_DELETE_FAILURE,
  BRAND_CREATE_REQUEST,
  BRAND_CREATE_SUCCESS,
  BRAND_CREATE_FAILURE,
  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_SUCCESS,
  BRAND_UPDATE_FAILURE,
  SEARCH_BRAND_REQUEST,
  SEARCH_BRAND_SUCCESS,
  SEARCH_BRAND_FAILURE,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAIL,
} from "../constants/brandConstants";

export const listBrands =
  ({ pageNumber = "", userInfo }) =>
  async (dispatch) => {
    dispatch({
      type: BRAND_LIST_REQUEST,
    });
    try {
      const { data } = await axios.put(`/api/brands?pageNumber=${pageNumber}`);

      dispatch({
        type: BRAND_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BRAND_LIST_FAIL,
        payload: error.message,
      });
    }
  };

export const getBrands = () => async (dispatch) => {
  dispatch({
    type: GET_BRANDS_REQUEST,
  });

  try {
    const { data } = await axios.get(`/api/brands`);

    dispatch({
      type: GET_BRANDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BRANDS_FAIL,
      payload: error.message,
    });
  }
};

export const getDispensaryBrands =
  ({
    dispensaryId,
    category,
    isMinorityOwned,
    isBlackOwned,
    isWomanOwned,
    isLgbt,
    isIndigenousOwned,
    isPOCOwned,
  }) =>
  async (dispatch) => {
    dispatch({ type: GET_DISPENSARY_BRANDS_REQUEST, payload: dispensaryId });

    try {
      const { data } = await axios.put(
        `/api/dispensaries/brands/${dispensaryId}`,
        {
          category,
          isMinorityOwned,
          isBlackOwned,
          isWomanOwned,
          isLgbt,
          isIndigenousOwned,
          isPOCOwned,
        }
      );
      console.log(data, "got dispensary");
      dispatch({ type: GET_DISPENSARY_BRANDS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_DISPENSARY_BRANDS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createBrand = () => async (dispatch, getState) => {
  dispatch({ type: BRAND_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      "/api/brands",
      { userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: BRAND_CREATE_SUCCESS,
      payload: data.brand,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: BRAND_CREATE_FAILURE, payload: message });
  }
};

export const detailBrand = (brandId) => async (dispatch) => {
  dispatch({ type: BRAND_DETAILS_REQUEST, payload: brandId });

  try {
    const { data } = await axios.get(`/api/brands/${brandId}`);

    dispatch({ type: BRAND_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BRAND_DETAILS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBrand = (brand) => async (dispatch, getState) => {
  dispatch({ type: BRAND_UPDATE_REQUEST, payload: brand });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.put(
      `/api/brands/${brand._id}`,
      { brand, userInfo },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );

    dispatch({ type: BRAND_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: BRAND_UPDATE_FAILURE, error: message });
  }
};

// export const searchBrands = (brandName) => async (dispatch, getState) => {
//   dispatch({ type: SEARCH_BRAND_REQUEST, payload: brandName });
//   try {
//     const { data } = await axios.get(`/api/brands/find/${brandName}`);

//     dispatch({ type: SEARCH_BRAND_SUCCESS, payload: data });
//   } catch (error) {
//     dispatch({
//       type: SEARCH_BRAND_FAILURE,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

export const deleteBrand = (brandId) => async (dispatch, getState) => {
  dispatch({ type: BRAND_DELETE_REQUEST, payload: brandId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = axios.delete(`/api/brands/${brandId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({ type: BRAND_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: BRAND_DELETE_FAILURE, payload: message });
  }
};
