import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./mainslider.css";
import { Link } from "react-router-dom";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function MainSlider() {
  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      // infinite={true}
      // transitionDuration={2000}
      // showDots={false}
      // autoPlay={true}
      // autoPlaySpeed={4500}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      containerClass="carousel-container"
    >
      <div className="slider-slide-cont">
        {" "}
        <div className="img_cont">
          <img
            src="/images/badges_sporadic.png"
            className="img-fluid"
            alt="merp badges"
            width="100%"
          />
          <div className="overlay">
            <div className="overlay-content" id="overlay-1">
              <div
                id="badges-slide"
                style={{ color: "black", textAlign: "left" }}
              >
                <div className="slider-h1-2"> What are Merp Badges?</div>
                <div className="slider-subtext-2">
                  It's one of the many ways Merp is unique! Merp badges allow
                  you to easily identify a business's practices and/or
                  ownership!
                </div>
                <div style={{ textAlign: "center" }} className="slider-btn-box">
                  <Link to="/about">
                    <button
                      type="button"
                      className="slider-button slider-btn"
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="img_cont">
          <img
            src="/images/black_farmers_market_banner.png"
            className="img-fluid"
            alt="merp badges"
            width="100%"
          />
          <div className="overlay">
            <div class="overlay-content" >
              <div >
                <div className="slider-h1-2"> </div>
                <div className="slider-subtext-2">
                
                </div>
              
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop:"32%" }} className="slider-btn-box">
                  <Link to='/black_farmers_market'
                  >
                    <button
                      type="button"
                      class="slider-button"
                      className="slider-btn"
                      style={{ backgroundColor: "black", color:"white", }}
                    >
                      More Info
                    </button>
                  </Link>
                </div>
          </div>
         
        </div> */}
        {/* <div class="img_cont">
          <img
          
            src="/images/bootcamp2.png"
            className="slider-img"
            alt="Cannabis business bootcamp"
            // width="100%"
          />
          <div className="overlay">
            <div class="overlay-content-2">
              <div id="slide-2" style={{ color: "white" }}>
                <div className="slider-h1-3">
                  {" "}
                  Canna-business Bootcamp Coming!
                </div>
                <div className="slider-subtext-3">
                  You will learn the ins-and-outs of different sectors of the
                  industry and get hands on assistance in starting your
                  business. Pre-register to get more info and be notifed when
                  applications go live!
                </div>
                <div style={{ textAlign: "center" }} className="slider-btn-box">
                  <Link to="/bootcamp/pre-registration">
                    <button
                      type="button"
                      className="slider-btn-2"
                      id="caro"
                      style={{ backgroundColor: "#F5C116" }}
                    >
                      Pre-register
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div> */}

      {/* 
      </div> */}

      <div>
        <div className="img_cont">
          <img
            src="/images/merch2.png"
            className="img-fluid"
            alt="shop artist merchandise"
            width="100%"
          />
          <div className="overlay">
            <div className="overlay-content-2">
              <div id="slide-2" style={{ color: "black" }}>
                <div className="slider-h1-2"> Checkout Our Merch!</div>
                <div className="slider-subtext-2">
                  Designed by some amazing BIPOC & LGBTQ artists. It's
                  sustainable,cute and we donate a portion of the proceeds to
                  some great organizations!
                </div>
                <div style={{ textAlign: "center" }} className="slider-btn-box">
                  <Link to="/merchandise">
                    <button
                      type="button"
                      className="slider-btn-2"
                      style={{ backgroundColor: "#F5C116" }}
                    >
                      Shop Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="img_cont">
          <img
            src="/images/coins2.png"
            className="img-fluid"
            alt="get rewarded for browsing cannbis products and dispensaries"
            width="100%"
          />

          <div className="overlay">
            <div className="overlay-content-2">
              <div id="slide-2" style={{ color: "black" }}>
                <div className="slider-h1-2"> Start Stacking Your Coins!</div>
                <div className="slider-subtext-2">
                  Signup today to join our rewards program to earn canna-coins
                  and get rewarded for simply using our site! Click below to
                  find out more.
                </div>
                <div style={{ textAlign: "center" }} className="slider-btn-box">
                  <Link to="/rewards/info">
                    <button
                      type="button"
                      className="slider-btn-2"
                      style={{ backgroundColor: "#F5C116" }}
                    >
                      More Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="img_cont">
          <img
            src="/images/spotify.png"
            className="img-fluid"
            alt="image 3"
            width="100%"
          />
          <div className="overlay">
            <div className="overlay-content">
              <div id="slide-3">
                <div className="slider-h1">
                  {" "}
                  Need a Fresh Playlist to Sesh To?
                </div>
                <div className="slider-subtext">
                  Follow our team playlist to stay up-to-date on what the Merp
                  team is listening to!
                </div>
                <div style={{ textAlign: "center" }} className="slider-btn-box">
                  <a
                    href="https://open.spotify.com/playlist/6Jmh5u7cPWelrwapT829Sj?si=DMxpWFEiSWeStyMT2TCkNQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button type="button" className="slider-button slider-btn">
                      Listen Now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="img_cont">
          <img
            src="/images/3.png"
            className="img-fluid"
            alt="merp blog"
            width="100%"
          />
          <div className="overlay">
            <div className="overlay-content">
              <div id="slide-4">
                <div className="slider-h1"> Looking for Some Fun Content?</div>
                <div className="slider-subtext">
                  Checkout our blogs! Where you can get the latest cannabis
                  related news and content!
                </div>
                <div style={{ textAlign: "center" }} className="slider-btn-box">
                  <Link to="/blog">
                    <button type="button" className="slider-button slider-btn">
                      Read Blogs
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}
