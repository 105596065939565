import React from "react";
import { Link } from "react-router-dom";
import { Image } from "cloudinary-react";
import Card from "react-bootstrap/Card";
import Rating from "./Rating";

export default function Dispensary({ dispensary }) {
  // const descriptionArr = dispensary.description.split(" ").splice( 0,10).join(" ")

  const badgeObj = dispensary.badges;
  const badges = [];

  if (!badgeObj) {
    return badges;
  } else {
    for (const key in badgeObj) {
      if (badgeObj[key].status) {
        badges.push(badgeObj[key]);
      }
    }
  }

  
  return (
    <div style={{ margin: "4px"}}>
      <Card style={{ width: "20rem", padding:".5rem", margin:"3rem" }}>
        <Link to={`/dispensary/${dispensary._id}`}>
          <Image
            cloudName="merp"
            publicId={dispensary.logo}
            secure="true"
            width="200"
            height="200"
            crop="scale"
            class="img-thumbnail"
          />
        </Link>

        <Card.Body style={{paddingTop:"2rem"}}>
          <Card.Title>
              <div>
                  <div className="card-row">
            <strong>{dispensary.name}</strong>{" "}
            {dispensary.claimed === "claimed" ? <button disabled id="claimed-button" style={{borderRadius:"12px"}}> Claimed </button> :  <button disabled id="unclaimed-button" style={{borderRadius:"12px"}}> Unclaimed </button> }
            </div>
            <div style={{ fontSize: "10px", marginTop:"3px" }}>
              <Rating rating={dispensary.rating} numReviews={dispensary.numReviews} />
            </div>
            
            </div>
          </Card.Title>
          

          <Card.Subtitle style={{ fontSize: "11.5px" }}>
            <Link to="#">
              {dispensary
                ? `${dispensary.address}, ${dispensary.city} ${dispensary.state} ${dispensary.postalCode}`
                : "N/A"}
            </Link>
          </Card.Subtitle>
          
          {/* <Card.Text  style={{ fontSize: "10.5px" }}>{descriptionArr}{" "}<Link to={`/dispensary/${dispensary._id}`}>...more</Link></Card.Text> */}
         
          {badges.length !== 0 && (
            <div>
              <div style={{ marginBottom: "1rem" }}>
                {/*filtering through what badges companies get*/}
                {badges.map((b) => (
                  <div key={b.alt} className="badge">
                    <img src={b.image} alt={b.alt} />
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* <Button variant="primary">Go somewhere</Button> */}
          {/* <div style={{ textAlign: "right" }}>
            <Link to={`/dispensary/${dispensary._id}`}>More...</Link>
          </div> */}
        </Card.Body>
      </Card>
    </div>
  );
}
