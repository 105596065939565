import React from "react";
import { Link } from "react-router-dom";
import "./aboutUsScreen.css";

export default function AboutUsScreen() {
  return (
    <div
      className="form about-height"
      style={{
        marginTop: "2rem",
        marginBottom: "2rem",
        textAlign: "center",
        height: "auto",
      }}
    >
      <div className="about-content">
        <div
          className="merp-logo justify-content-center p-4"
          style={{ display: "flex" }}
        >
          <div>
            {" "}
            <img
              src="/images/merp_logo_sticker.png"
              alt="merp logo "
              className="header-info-img"
              // style={{ height: "auto", width: "20rem" }}
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="badges-title">
          About Merp
        </div>
        <div>
          <p>
            Merp is focused on the{" "}
            <strong>experience, people, and equity measures</strong> behind the
            businesses and products we promote.
          </p>
          <p>
            Our founder and CEO, Symone Varnado, built Merp in 2021 because she
            was tired of having no simple way to buy cannabis products from
            businesses and brands that reflected her values. After seeing the
            lack of women and people of color within the cannabis industry,
            despite communities of color being the most adversely affected by
            the historical sale and use of cannabis, Symone decided to fill the
            gap she saw in the market herself by building an equity-focused
            cannabis tech company.
          </p>
          <p>
            At Merp, we believe that inclusion and equity are more than just
            buzzwords: cannabis consumers deserve transparency about who
            benefits when they buy.
          </p>

          <p>
            Given its growth and economic promise with expanding legalization,
            we're optimistic that the cannabis industry can be used to restore
            communities and uplift people who have been disenfranchised by The
            War on Drugs. We're excited to shake the industry up and amplify
            businesses that share our commitment to a more equitable future for
            cannabis.
          </p>

          {/* <p>
            Merp is constantly looking forward. We're learning and growing every
            day and we're excited to be in an industry that is one of the
            fastest-growing in this country. We are excited to shake the
            industry up and be the change our founder wanted and hoped to see
            when she was on the outside as just a consumer.
          </p> */}
        </div>
      </div>
      <div>
        <div className="badges-title">Our Badges</div>
        <div className="badge-subtext">
          <p>
            Our mission is to build a trustworthy and honest third-party
            destination for modern cannabis consumers and brands alike.
            Consumers deserve transparent access to information before making a
            purchase decision. Brands deserve a vetted space where they can put
            their product and brand on display, highlight their business and
            tell their story and brand identity.
          </p>
          <p>
            At Merp we accomplish this mission through our badge program. Our
            badges help our users easily identify who is behind a brand and
            their business practices. PLUS, only Merp can assign badges and
            there is always a member of our team researching, approving and
            verifying badges to make sure we are offering the most accurate
            information.
          </p>
          <p>
            We encourage all brands on Merp to get involved by claiming their
            brand page <Link to="/claim_brand">here</Link>. It’s free and gives
            you the ability to update key facts on your page as well as
            communications on how to best engage further with users as we keep
            building out Merp.
          </p>
        </div>
        <ul>
          <li className="badge-description-item ">
            <div>
              <img
                src="/images/merp_verified_badge_1.png"
                alt="merp-verfied badge"
                className="badge-info-img "
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">Merp Verified Badge</div>
              <>
                <ul className="badge-description">
                  <li>An organization is Merp verified when: </li>
                  <li>
                    There's been a process of research to establish and or prove
                    the validity, authenticity, and or truthfulness of any given
                    subject matter
                  </li>

                  <li>
                    When a representative of Merp has contacted persons of any
                    given business on merp.page and we have been able to review
                    materials that can affect the validity of business
                    descriptions, policies, and practices.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item ">
            <div>
              <img
                src="/images/black_owned_badge_3.png"
                alt="black owned badge"
                className="badge-info-img "
              />
            </div>
            <div className="description  " style={{ textAlign: "left" }}>
              <div className="badge-title">Black-Owned Badge</div>
              <>
                <ul className="badge-description">
                  <li>
                    An organization is certified as Black-owned by Merp when:{" "}
                  </li>
                  <li>
                    At least 51% of it is owned by one or more Black persons
                    (defined by Merp as individuals whose origins are in any of
                    the Black racial groups of the Black Diaspora).
                  </li>

                  <li>
                    The organization’s daily business operations are controlled,
                    managed, and operated by one or more Black persons.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/indigenous_owned_badge.png"
                alt="indigenous owned badge"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">Indigenous-Owned Badge</div>
              <>
                <ul className="badge-description">
                  <li>
                    Merp considers Indigenous and/or Aboriginal persons to be
                    individuals who are native to or descended from the earliest
                    time of a specific region and (if Indigenous to North
                    America) are currently registered with a tribe.
                  </li>
                  <li>
                    An organization is considered to be Indigenous-Owned by Merp
                    when:
                  </li>
                  <li>
                    At least 51% of the organization is owned by one or more
                    persons of Indigenous background.
                  </li>
                  <li>
                    The organization’s daily business operations are controlled,
                    managed, and operated by one or more persons of Indigenous
                    background.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/poc_owned_badge.png"
                alt="p.o.c. owned badge"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">POC-Owned Badge</div>
              <>
                <ul className="badge-description">
                  <li>
                    At Merp, we use People of Color (POC) to broadly define
                    persons who are not white, Black, and/or Indigenous.
                  </li>
                  <li>An organization is considered POC-owned by Merp when:</li>
                  <li>
                    At least 51% of the organization is owned by one or more POC
                    persons.
                  </li>
                  <li>
                    The organization’s daily business operations are controlled,
                    managed, and operated by one or more POC persons.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/woman_owned_badge.png"
                alt="woman owned badge"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">Woman-Owned Badge</div>
              <>
                <ul className="badge-description">
                  <li>
                    At Merp, "woman" is inclusively defined to welcome
                    trans-women, as well as genderqueer or non-binary persons
                    who identify as women in ways that are meaningful to them.
                  </li>
                  <li>
                    An organization is certified as woman-owned by Merp when:
                  </li>

                  <li>
                    At least 51% of the organization is owned by one or more
                    women.
                  </li>
                  <li>
                    The organization’s daily business operations are controlled,
                    managed, and operated by one or more women.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/leaf_badge.png"
                alt="sustainability badge (green leaf)"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">Sustainability Badge</div>
              <div className="badge-disclaimer">
                {" "}
                <i>
                  * A company or individual products can qualify for this badge
                </i>
              </div>
              <>
                <ul className="badge-description">
                  <li>
                    At Merp, "sustainability" broadly refers to efforts to
                    conserve natural resources, biodiversity, and the
                    environment.
                  </li>
                  <li>
                    An organization or product is considered to be operating
                    sustainably by Merp when:
                  </li>
                  <li>
                    It employs practices to reduce its carbon footprint in
                    respective sectors of the industry.
                  </li>
                  <li>
                    The business' practices and/or policies are proportionate to
                    the organization’s capacity/impact, and they're traceable.
                  </li>
                  <li>
                    (If a product) the product employs practices to reduce its
                    carbon footprint to a degree that is proportionate to the
                    organization’s capacity/impact, and is traceable.{" "}
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/equity_badge_2.png"
                alt="equity planned verified with green check"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">Social Equity Badge</div>

              <>
                <ul className="badge-description">
                  <li>
                    At Merp, "social equity" refers to recognizing and
                    understanding that individuals/businesses do not all start
                    from the same place of privilege and efforts must be made to
                    acknowledge and adjust imbalances.
                  </li>
                  <li>
                    Merp considers an organization to be operating equitably
                    when:
                  </li>
                  <li>
                    It has verifiable practices and/or policies identifying
                    imbalances in its community due to systemic structures and
                    bias (intentional and unintentional) and tangible action
                    plans to reduce and eradicate those barriers.
                  </li>
                  <li>
                    The practices and/or policies are proportionate to the
                    organization’s capacity, and they are traceable.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/unionized_2.png"
                alt="rainbow flag /LGBTQ badge"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">Unionized Badge</div>

              <>
                <ul className="badge-description">
                  <li>
                    Merp defines unions as associations that workers form
                    primarily to improve negotiating leverage and working
                    conditions of their respective members.
                  </li>
                  <li>Merp considers an organization to be unionized when:</li>
                  <li>
                    Its workers are able to form and establish a union under no
                    penalty and/or negative impact.
                  </li>
                  <li>
                    A contract between the union and the employer has been
                    established.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/lgbtq_badge.png"
                alt="rainbow flag /LGBTQ badge"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">LGBTQ-Owned Badge</div>

              <>
                <ul className="badge-description">
                  <li>
                    LGBTQ is an initialism that stands for Lesbian, Gay,
                    Bisexual, Transgender, and Queer. Merp welcomes and
                    recognizes all persons of various gender expressions and
                    sexual orientations.
                  </li>
                  <li>An organization is certified as LGBTQ by Merp when:</li>
                  <li>
                    At least 51% of the organization is owned by one or more
                    LGBTQ persons.
                  </li>
                  <li>
                    The organization’s daily business operations are controlled,
                    managed, and operated by one or more LGBTQ persons.
                  </li>
                </ul>
              </>
            </div>
          </li>
          <li className="badge-description-item">
            <div>
              <img
                src="/images/worker_owned_coop.png"
                alt="rainbow flag /LGBTQ badge"
                className="badge-info-img"
              />
            </div>
            <div className="description" style={{ textAlign: "left" }}>
              <div className="badge-title">Worker Co-Op Badge</div>

              <>
                <ul className="badge-description">
                  <li>
                    Merp defines a worker cooperative(co-op) business as one in
                    which:
                    <ul className="sub-list" style={{ paddingLeft: "1.5rem" }}>
                      <li>The business is self-managed by its workers</li>
                      <li>
                        Workers equally own the business and have access to its
                        revenue.
                      </li>
                      <li>The business is values-driven.</li>
                      <li>
                        Benefits are equally extended to founding members and
                        future members.
                      </li>
                    </ul>
                  </li>
                </ul>
              </>
            </div>
          </li>
        </ul>
      </div>
      <div className="disclaimer-text">
        Disclaimer: Merp reserves the right to adjust the qualification and
        terms of badges to listings on an ongoing basis.
      </div>
    </div>
  );
}
