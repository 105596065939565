import React from "react";
import LegalResources from "../components/LegalResources";
import HousingResources from "../components/HousingResources";
import JobResources from "../components/JobResources";
import AdministrationRoutes from "../components/AdministrationRoutes";
import Taxes from "../components/Taxes";
import Badges from "../components/Badges";
import SA from "../components/SA";
import DrugAndAlcohol from "../components/DrugAndAlcohol";

export default function EducationalListScreen() {
  return (
    <div>
      <h1 style={{textAlign:"center"}}>Educational Resources for Cannabis Consumers</h1>
      <div className="shop-row">
        <AdministrationRoutes />
        <Taxes />
        
      </div>
      <h1 style={{textAlign:"center"}}>Resources for Formerly Incarcerated People</h1>
      <div className="shop-row">
        <LegalResources />
        <HousingResources />
        <JobResources />
      </div>
      <h1 style={{textAlign:"center"}}>Crisis Resources</h1>
      <div className="shop-row">
        <SA/>
        <DrugAndAlcohol />
      </div>
    </div>
  );
}
