import React from "react";
import ReactDOM from "react-dom";


import "./index.css";
import App from "./App";

import store from "./store";
import { Provider } from "react-redux";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <div className="page-layout">
        <div className="page-container">
          <App />
        </div>
      </div>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
