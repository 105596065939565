import {
  ADD_PRODUCT_TO_CART,
  REMOVE_CART_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_EMPTY,
  CART_ADD_ITEM_FAILURE,
} from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x.product === item.product);
      if (existItem) {
        return {
          ...state,
          error: '',
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
        };
      } else {
        return { ...state,error: '', cartItems: [...state.cartItems, item] };
      }
    
    case REMOVE_CART_ITEM:
      return {
        ...state,
        error:'',
        cartItems: state.cartItems.filter(
          (item) => item.product !== action.payload
        ),
      };
    case CART_SAVE_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.payload };
    case CART_SAVE_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    case CART_ADD_ITEM_FAILURE:
      return { ...state, error: action.payload };
    case CART_EMPTY:
      return { ...state,error:'', cartItems: [] };
    default:
      return state;
  }
};
