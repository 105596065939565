import React, { useEffect, useState, useRef } from "react";
import CheckoutSteps from "../components/CheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { createOrder, payOrder, detailsOrder } from "../actions/orderActions";
import {
  ORDER_CREATE_RESET,
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import MessageBox from "../components/MessageBox";
import LoadingBox from "../components/LoadingBox";
import { savePaymentMethod } from "../actions/cartActions";
import { Image } from "cloudinary-react";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import { updateUser } from "../actions/userActions";
import { CART_EMPTY } from "../constants/cartConstants";

const PlaceOrderScreen = (props) => {
  const cart = useSelector((state) => state.cart);

  const [sdkready, setSdkReady] = useState(false);

  // if (!cart.paymentMethod) {
  //   props.history.push("/payment");
  // }
  const orderCreate = useSelector((state) => state.orderCreate);
  const orderPay = useSelector((state) => state.orderPay);

  const [paypalData, setPaypalData] = useState({});
  const [totalPrice, setTotalPrice] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;
  const { loading, success, error, order } = orderCreate;
  const toPrice = (number) => Number(number.toFixed(2));
  // cart.itemsPrice = toPrice(
  //   cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0)
  const cartItemsPrice = toPrice(
    cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0)
  );
  // );

  cart.shippingPrice = 0;

  cart.taxPrice =
    cart.shippingAddress.state === "IL" ? toPrice(0.15 * cartItemsPrice) : 0;
  cart.totalPrice =
    cartItemsPrice + cart.shippingPrice + cart.taxPrice + cart.shippingPrice;

  const dispatch = useDispatch();
  const placeOrderHandler = (paymentResult) => {
    cart.itemsPrice = cartItemsPrice;
    dispatch(
      createOrder({ ...cart, orderItems: cart.cartItems }, paymentResult)
    );

    //TODO place order action
  };

  useEffect(() => {
    const addPayPayScript = async () => {
      const { data } = await axios.get("/api/config/paypal");
      setPaypalData(data);
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${data.ID}&disable-funding=paylater,card,venmo`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };

      document.body.appendChild(script);
    };

    if (order && success) {
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ dispatch: ORDER_PAY_RESET });
      dispatch({ type: CART_EMPTY });
      props.history.push(`/thank_you`);
    }

    if (cart && cart.totalPrice) {
      setTotalPrice(cart.totalPrice);
    }

    //     if(order && order.isPaid&& order.isDelivered){

    //       setRewardsPoints(true)
    //       dispatch(updateUser(userInfo,rewardsPoints))

    //     }

    if (!window.paypal) {
      addPayPayScript();
    } else {
      setSdkReady(true);
    }
  }, [dispatch, setSdkReady, order, successPay]);

  return (
    <div>
      <div className="container-fluid p-5">
        <CheckoutSteps step1 step2 step3></CheckoutSteps>

        <div className="row p-5">
          <div className="col-md-7 col-12 placeorder-info">
            <ul>
              <li>
                <div className="checkout-card checkout-card-body">
                  <div
                    style={{ fontSize: "2.5rem", color: "rgb(218, 163, 92)" }}
                  >
                    Shipping
                  </div>
                  <p>
                    <div className="row-cart">
                      <strong>Name:</strong> {cart.shippingAddress.fullName}
                      <br />
                    </div>
                    <div className="row-cart">
                      <strong>Email:</strong> {cart.shippingAddress.email}
                      <br />
                    </div>
                    <div className="row-cart">
                      <strong>Address:</strong>
                      {cart.shippingAddress.address},
                      {cart.shippingAddress.street2} {cart.shippingAddress.city}
                      , {cart.shippingAddress.state}{" "}
                      {cart.shippingAddress.postalCode}
                    </div>
                  </p>
                </div>
              </li>
              {/* <li>
                <div className="">
                  <div
                    style={{ fontSize: "2.5rem", color: "rgb(218, 163, 92)" }}>
                    Payment
                  </div>
                  <p className="row-cart">
                    <strong>Method:</strong> {cart.paymentMethod}
                    <br />
                  </p>
                </div>
              </li> */}
              <li>
                <div className="checkout-card checkout-card-body">
                  <div
                    style={{ fontSize: "2.5rem", color: "rgb(218, 163, 92)" }}
                  >
                    Order Items
                  </div>
                  <div>
                    <ul>
                      {cart.cartItems.map((item) => (
                        <li key={item.product}>
                          <div className="row-cart">
                            <div>
                              <Image
                                cloudName="merp"
                                publicId={item.image}
                                secure="true"
                                width="100"
                                crop="scale"
                              />
                            </div>
                            <div className="min-30">
                              <Link to={`/product/${item.product}`}>
                                {item.name}
                              </Link>
                            </div>
                            {item.size ? (
                              <div>Size: {item.size}</div>
                            ) : (
                              <div>{"  "}</div>
                            )}

                            <div>
                              {item.qty} x ${item.price.toFixed(2)} = $
                              {(item.qty * item.price).toFixed(2)}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-5 col-12">
            <div className="checkout-card checkout-card-body">
              <ul>
                <li>
                  <div
                    style={{ fontSize: "2.5rem", color: "rgb(218, 163, 92)" }}
                  >
                    Order Summary
                  </div>
                </li>
                <li>
                  <div className="row-cart">
                    <div>
                      <strong>Items</strong>
                    </div>
                    <div>${cartItemsPrice}</div>
                  </div>
                </li>
                <li>
                  <div className="row-cart">
                    <div>
                      <strong>Shipping</strong>
                    </div>
                    ${cart.shippingPrice.toFixed(2)}
                    {/* <div>${cart.shippingPrice}</div> */}
                    {/* <div>${cart.shippingPrice.toFixed(2)}</div> */}
                  </div>
                </li>
                <li>
                  <div className="row-cart">
                    <div>
                      <strong>Tax</strong>
                    </div>
                    <div>${cart.taxPrice.toFixed(2)}</div>
                  </div>
                </li>
                <li>
                  <div className="row-cart">
                    <div>
                      <strong>Order Total</strong>
                    </div>
                    <div>
                      <strong>${cart.totalPrice.toFixed(2)}</strong>
                    </div>
                  </div>
                </li>
                {/* <li>
                <button
                  type="button"
                  onClick={placeOrderHandler}
                  className="primary block"
                  disable={(cart.cartItems.length === 0).toString()}>
                  Place Order
                </button>
              </li> */}
                {cart.cartItems.length && cartItemsPrice > 0 ? (
                  <li>
                    {!sdkready ? (
                      <LoadingBox></LoadingBox>
                    ) : (
                      <>
                        {errorPay && (
                          <MessageBox variant="danger">{errorPay}</MessageBox>
                        )}
                        {loadingPay && <LoadingBox></LoadingBox>}
                        <div style={{ width: "100%" }}>
                          <PayPalButton
                            createOrder={(data, actions) => {
                              return actions.order.create({
                                purchase_units: [
                                  {
                                    amount: {
                                      currency_code: "USD",
                                      value: cart.totalPrice,
                                    },
                                  },
                                ],
                                // application_context: {
                                //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                                // }
                              });
                            }}
                            onApprove={(data, actions) => {
                              // Capture the funds from the transaction
                              return actions.order
                                .capture()
                                .then(function (details) {
                                  placeOrderHandler(details);
                                  // dispatch({ type: ORDER_DELIVER_RESET })
                                  // props.history.push(`/thank_you`);

                                  // Show a success message to your buyer
                                  // alert(
                                  //   "Transaction completed by " +
                                  //     details.payer.name.given_name
                                  // );

                                  // OPTIONAL: Call your server to save the transaction
                                  // return fetch("/paypal-transaction-complete", {
                                  //   method: "post",
                                  //   body: JSON.stringify({
                                  //     orderID: data.orderID,
                                  //   }),
                                  // });
                                });
                            }}
                            onError={(err) => {
                              console.log(err, "SOMETHING WENT WRONG");
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div style={{ fontSize: "1.5rem" }}>
                      <strong>
                        *** If you would like to use a card please select the
                        "Pay with Debit or Credit Card" option when the Paypal
                        screen appears
                      </strong>
                    </div>
                    <div className="disclaimer">
                      * Be advised that at this time we are not accepting
                      returns or exchanges. We apologize in advance for any
                      inconvience this may cause.
                    </div>
                    <div className="disclaimer">
                      ** Items ordered through Merp arriveup to 5-7 business
                      days once shipped. Packages ship within 1-2 business days.
                    </div>
                  </li>
                ) : (
                  <li style={{ textAlign: "center", width: "100%" }}>
                    <button
                      type="button"
                      onClick={() => placeOrderHandler({})}
                      className="place-order-btn"
                    >
                      Place Order
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrderScreen;
