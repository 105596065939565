import React from "react";

export default function CannabisAdministrationInfoScreen() {
  return (
    <div id="cannabis_administration_info">
      <div>
        <h1>Administration Methods and Things to Consider</h1>
      </div>
      <img className="info-image" src="/images/joint.png" alt="joint" />
      <h3> Smoking</h3>
      <p>
        Easy to use, with a quick onset and a titrated dose. The
        effects come on in less than 3 minutes & can last anywhere from 1-3
        hours
      </p>
      <img className="info-image" src="/images/vaporizor.png" alt="vaporizor" />
      <h3> Vaporizing </h3>
      <p>
         Like smoking, it will take effect in 1-3 minutes & lasts 1-3
        hours, making dosing easier. When vaporizing the cannabis flowers, you
        extract the highest number of cannabinoids & terpenes possible. It's
        convenient & has little to no odor.
      </p>
      <img className="info-image" src="/images/cookie.png" alt="cookie" />
      <h3> Edibles </h3>
      <p>
       These are long-acting & can take anywhere from 30-120 minutes
        to take effect. Many users report increased tactile sensitivity with
        edibles. Dosing can be inconsistent from one batch or individual item to
        the next. *Some of the most unpleasant dosing errors can occur with
        edibles, so new users should use caution!*{" "}
      </p>
      <img className="info-image" src="/images/tincture.png" alt="tincture" />
      <h3>Tinctures</h3>
      <p>
         Think of a tincture as anything that absorbs into the mouth
        instead of the stomach. That would include oil & alcohol-based
        tinctures, RSOs, mouth sprays, etc. They take effect in 15-30 minutes &
        last 4-6 hours. Unlike edibles, they will produce a consistent &
        predictable effect.
      </p>
      <img className="info-image" src="/images/lube2.png" alt="lube-bottle" />
      <h3>  Lubes</h3>
      <p>
       Cannabis-infused lubes are gaining popularity, but have little to
        no scientific backing behind them. It is said that they can relax the
        blood vessels, supporting arousal & enhancing sensation. Some claim you
        cannot get high from lube. While the mucus membranes of the vagina will
        readily absorb THC, the dose & quantity of cannabinoids will be so low,
        it is unlikely to produce a psychoactive effect.
      </p>
      <img className="info-image" src="/images/cream.png" alt="topical-cream" />
      <h3>Topicals </h3>
      <p>
        Topical administration(skin) allows a steady infusion of a
        drug to be delivered over a prolonged period of time, while also
        minimising the adverse effects of higher drug peak concentrations, which
        can improve patient adherence.Topical cannabis offers a fast-acting
        localized effect and is potentially ideal for localised symptoms, such
        as those found in dermatological conditions and arthritis. However,
        there are some disadvantages to consider, such as the possibility of
        local irritation and the low skin penetration of drugs with a
        hydrophilic structure.
      </p>
      <div className="disclaimer" >
        <p>
          *We are not healthcare professionals , always speak to a healthcare
          professional regarding cannabis use during sex and its effects. Always
          discuss about cannabis use with your sexual partner(s) beforehand.
          Refer to the final slide for resources on the information in this
          post.
        </p>
        <div>
          <a
            href="https://www.cannabisclinicians.org/2020/03/09/sex-intimacy-and-cannabis-research-studies/"
            target="_blank"
            rel="noopener noreferrer">
            Source 1
          </a>,
          <a
            href="https://dixieelixirs.com/education-2/"
            target="_blank"
            rel="noopener noreferrer">
            Source 2
          </a>,
          <a
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6222489/"
            target="_blank"
            rel="noopener noreferrer">
            Source 3
          </a>
          <div>
            Icons made by{" "}
            <a href="https://www.freepik.com" title="Freepik">
              Freepik
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
          <div>
            Icons made by{" "}
            <a href="https://creativemarket.com/eucalyp" title="Eucalyp">
              Eucalyp
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
          <div>
            Icons made by{" "}
            <a
              href="https://www.flaticon.com/authors/flat-icons"
              title="Flat Icons">
              Flat Icons
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
          <div>
            Icons made by{" "}
            <a
              href="https://www.flaticon.com/authors/pongsakornred"
              title="pongsakornRed">
              pongsakornRed
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
