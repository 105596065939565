import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listWithUs } from "../../actions/userActions";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./listScreens.css";

export default function ListWithUsInfoScreen() {
  const dispatch = useDispatch();
  const [isMerch, setIsMerch] = useState(false);
  const [isCBD, setIsCBD] = useState(false);
  const [isTHC, setIsTHC] = useState(false);
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [website, setWebsite] = useState("");

  const listWith = useSelector((state) => state.listWith);

  const { loading, error, success } = listWith;

  const submitHandler = (e) => {
    e.preventDefault();
    if (!agreeToTerms) {
      alert(
        "You must read and agree to Terms before signing up and while accessing the site"
      );
    }
    dispatch(
      listWithUs({
        name: name,
        contactName: contactName,
        contactEmail: contactEmail,
        website: website,
        isDispensary: false,
        isBrand: true,
        isTHC: isTHC,
        isCBD: isCBD,
        isMerch: isMerch,
        locations: [],
      })
    );
  };

  useEffect(() => {}, [dispatch, success]);
  return (
    <>
      <div className="form">
        <div>
          <div
            className="merp-logo justify-content-center p-4"
            style={{ display: "flex" }}
          >
            <div>
              {" "}
              <img
                src="/images/merp_logo_sticker.png"
                alt="merp logo"
                className="merp-logo-list"
                // style={{ height: "auto", width: "20rem" }}
              />
            </div>
          </div>

          <div className="brand_list_subsection">
            <div className="brand_list_main_header" s>
              Looking to list a brand on Merp?
            </div>
            <div className="brand_list_section">
              Merp’s goal is to amplify and empower cannabis brands in an
              accessible way while giving cannabis consumers better information
              about what they’re buying. We’re always looking to add new brands
              to our platform - if you’re interested in listing with us, please
              fill out the form below to get started.
            </div>
            <Link to="/dispensary_listing_info">
              <p
                style={{
                  marginTop: "1rem",

                  fontWeight: "bold",
                }}
                className="brand_list_section"
              >
                Not a brand? Tap here to list a dispensary
              </p>
            </Link>
            <div className="brand_list_subsection">
              <div className="brand_list_main_header">
                Why list your brand on Merp?
              </div>
              <ul className="brand_list_section bullets">
                <li>
                  <strong>Free for you:</strong> We know that marketing budgets,
                  especially for newer brands, aren’t unlimited. Your brand and
                  products deserve hype without a price tag - that’s why we
                  don’t charge you to be listed on Merp
                </li>
                <li>
                  <strong>Unlock customer insights:</strong> When your customers
                  aren’t buying directly from you, it can be hard to understand
                  them. We’re focused on capturing feedback directly from
                  consumers so you can learn more about what your biggest fans
                  love.habits
                </li>
                <li>
                  <strong>Focused on your unique story: </strong>We care about
                  the “who” not just the “what” - we work to spend time getting
                  to know the team behind the brands we list because we believe
                  that understanding the unique people behind a business makes a
                  difference. We want to help share your story with consumers
                  beyond a one-line description.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!success ? (
        <form className="form brand_list_form" onSubmit={submitHandler}>
          <div className="brand_list_section" style={{ paddingTop: "2rem" }}>
            <div
              className="brand_list_main_header"
              style={{ textAlign: "center" }}
            >
              Apply to List Your Brand with Merp
            </div>
            <section>
              <div className="brand_list_section">
                <p style={{ textAlign: "center" }}>
                  Please complete the following form to begin the listing
                  process. . If you are approved, you will receive a link to set
                  up your account.
                </p>
              </div>
            </section>
          </div>

          <div>
            <label>
              <b>*Company Name</b>
            </label>
            <input
              type="text"
              id="companyName"
              placeholder="enter company name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            ></input>
            <br />
          </div>
          <div>
            <label>
              <b>*Company Contact Person Name</b>
            </label>
            <input
              type="text"
              id="contactName"
              placeholder="enter company contact name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
            ></input>
            <br />
          </div>
          <div>
            <label>
              <b>*Company Contact Person Email</b>
            </label>
            <input
              type="email"
              id="contactEmail"
              placeholder="enter company contact email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              required
            ></input>
            <br />
          </div>
          <div>
            <label>
              <b>Website link (if applicable)</b>
            </label>
            <input
              type="url"
              id="websiteLink"
              placeholder="enter your website url"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            ></input>
            <br />
          </div>
          <div>
            <label>
              <b> Please check which option(s) describe your business</b>
            </label>
            <div className="checkbox_section">
              {" "}
              <input
                id="CBD"
                type="checkbox"
                checked={isCBD}
                onChange={(e) => setIsCBD(e.target.checked)}
              ></input>
              <label htmlFor="CBD">My brand carries CBD products</label>
            </div>
            <div className="checkbox_section">
              {" "}
              <input
                id="THC"
                type="checkbox"
                checked={isTHC}
                onChange={(e) => setIsTHC(e.target.checked)}
              ></input>
              <label htmlFor="THC">My brand carries THC products</label>
            </div>
            <div className="checkbox_section">
              <input
                id="merch"
                type="checkbox"
                checked={isMerch}
                onChange={(e) => setIsMerch(e.target.checked)}
              ></input>
              <label htmlFor="merch">
                My brand carries merchandise (Accessories, T-shirts, etc...)
              </label>
            </div>
          </div>
          <div className="signup-checkbox" style={{ flexDirection: "row" }}>
            <input
              style={{ marginRight: 2 }}
              id="agreeToTerms"
              type="checkbox"
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
            ></input>
            <label>
              {"   "}* “I understand that Merp will use the information provided
              for contact purposes only and will not share my information with
              other parties.”
            </label>
          </div>
          <div className="brand_list_section" style={{ paddingTop: "1.5rem" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      ) : (
        <div
          className="form"
          style={{ textAlign: "center", fontSize: "3rem", padding: "3rem" }}
        >
          <div>
            <b>SUCCESS!</b>
          </div>
          <b>
            Thanks for your interest! Some one from our team will reach out to
            you soon!
          </b>
        </div>
      )}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <div className="brand_list_main_header_footnote">
          Just a friendly reminder...
        </div>
        <section>
          <div
            className="brand_list_section_footnote"
            style={{ textAlign: "center" }}
          >
            We are excited that you are considering listing your business on our
            site! Merp has diligently thought about how to make our platform the
            best it can be not just for our users but also for our brand
            partners, which could be you! There's a just few things you should
            be informed of before reaching out:
          </div>
        </section>
        <ol className="brand_list_section_footnote bullets-nums">
          <li>
            <p>
              It has to be said that we can only list, market, and/or work
              directly with brands and dispensaries that are legally operating
              in your state of business.
            </p>
            <p>
              We understand there are plenty of barriers to entry that keep many
              people from being able to start and operate a fully legal and
              standalone cannabis business. If you are not able to fully operate
              a legal dispensary or cannabis brand because of the associated
              prices or other barriers to entry, we would still love to hear
              from you to see how we could be of support in the future or assist
              you in finding the correct resources to get you to a point of
              owning and operating a fully legal cannabis business.
            </p>
          </li>
          <li>
            <p>
              We <strong>CANNOT</strong> promote the sell of and/or purchasing
              of anything that contains THC and is sold and shipped online. At
              this time cannabis products that contain THC are not federally
              legal and are illegal to sell online. Merp itself does not sell
              any THC products through our site and any brand we list that
              contains THC must be sold through a registered dispensary to be
              able to list on our platform.
            </p>
            <p>
              At this time Merp is not offering direct purchasing or direct sell
              of CBD products (we can list your brand and link to external
              site/product) through our site. We are sorry for any inconvience
              this may cause and hope to be able to offer direct sell and
              purchasing of CBD products on our platform in the near future.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
}
