import React from "react";
import { Doughnut } from "react-chartjs-2";

export default function BrandsDoughnutGraph({ breakdown }) {
  let type = breakdown.map((item) => {
    let newItem = Object.keys(item);
    return newItem[0];
  });

  let values = breakdown.map((item)=>{
    let newItem = Object.values(item);
    return newItem[0];
  })

  // console.log(breakdown, "checking this too");
  let title = "Cannabis Tax Budget Breakdown";
  let labels =
    type !== []
      ? type
      : [
          "Cresco",
          "Dank Weed",
          "Chronic Canna",
          "Vizeo",
          "Good Good",
          "Morning Riser",
        ];
  let colors = [
    "#5BCEAE",
    "#F77D21",
    "#F64034",
    "#0BD46A",
    "#6C1922",
    "#750FD0",
  ];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Tax Breakdown",
        backgroundColor: colors,
        data: values ? values : [1,3,5,5,6,8]
      },
    ],
  };

  return (
    <div style={{ textAlign:"center", height:"auto" }}>
      <Doughnut
        data={data}
        options={{
          maintainAspectRatio: true,
          title: { display: true, text: title },
        }}
      />
    </div>
  );
}
