import React from 'react'
import { Link } from 'react-router-dom';

export default function LegalResources() {

   const legal = "Legal"
    return (
        <div className="shop-image" id="bipoc">
          {/* <img src="/images/black_owned_badge_3.png" alt="shop-bipoc" /> */}
          <div className="details">
            <h2>
              <span>Legal Resources</span> 
            </h2>
            <p>
              A resource list of legal services to aid in the re-entry of people who were formally incarcerated.
            </p>
            <div className="more">
              <Link to={`/education_&_resources/${legal}`} className="read-more">
                Browse <span>Now</span>
              </Link>
            </div>
          </div>
        </div>
      );
}
